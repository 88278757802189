import styled from 'styled-components';

import { EditButton as EditButtonOriginal } from '@xing-com/crate-entity-pages-common';
import { Dialog as OriginalDialog } from '@xing-com/dialog';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { spaceXS } from '@xing-com/tokens';

export const ActorActions = styled.section<{ isDesktop: boolean }>`
  position: relative;
  justify-self: end;
  align-items: center;
  display: ${({ isDesktop }) => (isDesktop ? 'flex' : 'none')};

  @media ${mediaSmallOrTiny} {
    display: ${({ isDesktop }) => (isDesktop ? 'none' : 'flex')};
    flex: 0 1 auto;
  }
`;

export const EditButton = styled(EditButtonOriginal)`
  display: inline-flex;
  margin-right: ${spaceXS};
`;

export const PromoteButton = styled(EditButtonOriginal)`
  margin-left: 20px;
`;

export const Dialog = styled(OriginalDialog)`
  z-index: 502;
`;
