import { useMutation } from '@apollo/client';

import type {
  ContentError,
  ArticlesError,
} from '@xing-com/crate-common-graphql-types';

import { EntityPagesArticlesModuleArticlesArticleDeteleDocument } from '../graphql/mutations/entity-pages-articles-module-articles-article-delete.gql-types';
import { EntityPagesArticlesModuleContentArticleDeteleDocument } from '../graphql/mutations/entity-pages-articles-module-content-article-delete.gql-types';
import type { ArticleCardView } from '../types/types';

type RemoveArticleParams = {
  article: ArticleCardView;
};

export const useRemoveArticleMutation = (options?: {
  onCompleted?: (article: ArticleCardView) => void;
  onError?: (error: ContentError | ArticlesError | Error) => void;
}) => {
  const [handleContentArticleDeletion, contentArticleState] = useMutation(
    EntityPagesArticlesModuleContentArticleDeteleDocument
  );

  const [handleArticlesArticleDeletion, articlesArticleState] = useMutation(
    EntityPagesArticlesModuleArticlesArticleDeteleDocument
  );

  const handleRemoveArticle = ({ article }: RemoveArticleParams) => {
    if (article.typename === 'ContentArticle') {
      handleContentArticleDeletion({
        variables: {
          input: { globalId: article.globalId },
        },
        onCompleted: (data) => {
          const error = data.contentDeleteArticle?.error;
          if (error) {
            options?.onError?.(error);
          } else {
            options?.onCompleted?.(article);
          }
        },
        onError: (error) => {
          options?.onError?.(error);
        },
      });
    } else {
      handleArticlesArticleDeletion({
        variables: {
          input: { articleGlobalId: article.globalId },
        },
        onCompleted: (data) => {
          const error =
            data.articlesDeleteArticle?.__typename === 'ArticlesError'
              ? data.articlesDeleteArticle
              : null;
          if (error) {
            options?.onError?.(error);
          } else {
            options?.onCompleted?.(article);
          }
        },
        onError: (error) => {
          options?.onError?.(error);
        },
      });
    }
  };

  return {
    handleRemoveArticle,
    loading: contentArticleState.loading || articlesArticleState.loading,
  };
};
