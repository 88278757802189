import { useLocation } from '@reach/router';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import type { EntityMedia } from '@xing-com/crate-common-graphql-types';
import { SmallGrid } from '@xing-com/crate-entity-pages-common';
import { trackOpenGallery } from '@xing-com/crate-entity-pages-common/src/tracking';
import { IconVideo } from '@xing-com/icons';
import { OmView } from '@xing-com/platform-layout-om';
import Video from '@xing-com/video';

import { GalleryEditButton } from '../../about-us-edit/gallery-edit/gallery-edit-button/gallery-edit-button';
import { GalleryContainer } from '../../gallery/gallery-container';
import aboutUsImage from '../assets/about-us.svg'; // eslint-disable-line

import * as Styled from './gallery-preview.styles';

type ExternalIconProps = {
  small: boolean;
};
const ExternalIcon: FC<ExternalIconProps> = ({ small }) => (
  <Styled.ExternalIcon small={small}>
    <IconVideo width={small ? 15 : 30} height={small ? 15 : 30} />
  </Styled.ExternalIcon>
);

type MediaProps = {
  imageUrl?: string;
  videoId?: string;
  videoButtonSize: number;
  externalUrl?: string;
};
const Media: FC<MediaProps> = ({
  imageUrl,
  videoId,
  videoButtonSize,
  externalUrl,
}) => (
  <>
    {videoId && (
      <Video
        id={videoId}
        onError={() => undefined}
        previewModeAction={() => undefined}
        previewModeButtonSize={videoButtonSize}
        section={'entity_pages_mainpage_preview_gallery'}
      />
    )}
    {externalUrl && <ExternalIcon small={videoButtonSize === 32} />}
    {imageUrl && (
      <>
        <Styled.BlurredImageWrapper>
          <Styled.Gradient />
          <Styled.BlurredImage
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
        </Styled.BlurredImageWrapper>
        <Styled.Image src={imageUrl} loading="lazy" alt="Gallery image" />
      </>
    )}
  </>
);

type GalleryItemsProps = {
  galleryItems: Partial<EntityMedia>[];
  itemClicked?: number | null;
  onClick: (itemNumber: number) => void;
};
const GalleryItems: FC<GalleryItemsProps> = ({ galleryItems, onClick }) => {
  const smallItems = galleryItems.slice(1);

  const firstItem = galleryItems[0];

  return (
    <>
      <Styled.GalleryMediaMainWrapper>
        <Styled.GalleryMediaMain
          onClick={() => onClick(0)}
          data-testid="gallery-items-main"
        >
          {firstItem.media?.__typename === 'EntityExternalVideo' && (
            <Media
              imageUrl={firstItem.media?.url ?? ''}
              externalUrl={firstItem.media?.externalLink}
              videoButtonSize={64}
            />
          )}
          {firstItem.media?.__typename === 'EntityVideo' && (
            <Media
              videoId={firstItem.media?.videoReferenceV2}
              videoButtonSize={64}
            />
          )}
          {firstItem.media?.__typename === 'ScaledImage' && (
            <Media imageUrl={firstItem.media?.url ?? ''} videoButtonSize={64} />
          )}
        </Styled.GalleryMediaMain>
      </Styled.GalleryMediaMainWrapper>
      <Styled.SmallItemsContainer>
        {smallItems.map((item: any, index: number) => (
          <Styled.GalleryMedia
            key={index}
            onClick={() => onClick(index + 1)}
            data-testid="gallery-items-small"
          >
            <Media
              imageUrl={item.media?.url}
              videoId={item.media?.videoReferenceV2}
              externalUrl={item.media?.externalLink}
              videoButtonSize={32}
            />
          </Styled.GalleryMedia>
        ))}
      </Styled.SmallItemsContainer>
    </>
  );
};

type SliderItemsProps = GalleryItemsProps;
const SliderItems: FC<SliderItemsProps> = ({ galleryItems, onClick }) => {
  return (
    <Styled.Carousel>
      {galleryItems.map((item, index: number) => (
        <Styled.SliderItemWrapper key={`sliderImte-${item.id}`}>
          <Styled.SliderItem onClick={() => onClick(index)}>
            {item.media?.__typename === 'EntityExternalVideo' && (
              <Media
                imageUrl={item.media?.url ?? ''}
                videoButtonSize={64}
                externalUrl={item.media?.externalLink}
              />
            )}
            {item.media?.__typename === 'EntityVideo' && (
              <Media
                videoId={item.media?.videoReferenceV2}
                videoButtonSize={64}
              />
            )}
            {item.media?.__typename === 'ScaledImage' && (
              <Media imageUrl={item.media?.url ?? ''} videoButtonSize={64} />
            )}
          </Styled.SliderItem>
        </Styled.SliderItemWrapper>
      ))}
    </Styled.Carousel>
  );
};

type ItemsWrapperProps = GalleryItemsProps & {
  onLoad?: () => void;
  onUnLoad?: () => void;
};
const ItemsWrapper: FC<ItemsWrapperProps> = ({
  onLoad = () => undefined,
  onUnLoad = () => undefined,
  ...props
}) => {
  useEffect(() => {
    onLoad();
    return () => {
      onUnLoad();
    };
  });
  return (
    <>
      {/* Desktop displays a Gallery */}
      <Styled.GalleryPreviewMediaWrapper data-testid="itemsTriggerWrapper">
        <GalleryEditButton inFullWidth={true} />
        <GalleryItems {...props} />
      </Styled.GalleryPreviewMediaWrapper>
      {/* Mobile displays a Slider */}
      <Styled.SliderPreviewMediaWrapper>
        <GalleryEditButton inFullWidth={true} />
        <SliderItems {...props} />
      </Styled.SliderPreviewMediaWrapper>
    </>
  );
};

type GalleryPreviewProps = {
  galleryItems: Partial<EntityMedia>[];
  pageSlug: string;
};
export const GalleryPreview: FC<GalleryPreviewProps> = ({
  pageSlug,
  galleryItems,
}) => {
  const location = useLocation();
  const locationParams = new URLSearchParams(location.search);

  const openOmView = useRef<any | undefined>();
  const closeOmView = useRef<any | undefined>();

  const openGallery = locationParams.get('action') === 'openGallery';
  const locationSearchItem = locationParams.get('item');

  const [itemClicked, setItemClicked] = useState<number | undefined>(
    openGallery && locationSearchItem ? parseInt(locationSearchItem) : undefined
  );

  useEffect(() => {
    if (openGallery) {
      trackOpenGallery('entity_page_mainpage');
      if (openOmView.current) openOmView.current();
    }
  }, [openGallery]);
  useEffect(() => {
    return () => closeOmView.current && closeOmView.current();
  }, []);

  return (
    <>
      {galleryItems?.length > 0 ? (
        <OmView
          onClose={() => {
            setItemClicked(undefined);
          }}
          trigger={(activateOmView: any) => {
            openOmView.current = activateOmView;
            return (
              <ItemsWrapper
                galleryItems={galleryItems}
                itemClicked={itemClicked}
                onClick={(itemNumber: number) => {
                  setItemClicked(itemNumber);
                  trackOpenGallery('entity_page_mainpage');
                  activateOmView();
                }}
              />
            );
          }}
        >
          {({ handleClose }) => {
            closeOmView.current = handleClose;
            return (
              <SmallGrid>
                <GalleryContainer
                  pageSlug={pageSlug}
                  itemClicked={itemClicked}
                  onError={() => {
                    handleClose();
                  }}
                />
              </SmallGrid>
            );
          }}
        </OmView>
      ) : (
        <Styled.GalleryFallbackContainer>
          <GalleryEditButton />
          <img
            data-testid="gallery-preview-fallback-image"
            src={aboutUsImage}
            aria-hidden="true"
            loading="lazy"
            alt="About us image"
          />
        </Styled.GalleryFallbackContainer>
      )}
    </>
  );
};
