/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationSpotTop = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationSpotTop"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m43.637 36.485 16.096-10.759-19.645-.106-6.285-19.238-8.147 19.161-20.82-.114 15.868 11.757-8.69 20.434 19.07-12.745 20.178 14.948z"
        clipRule="evenodd"
      />
      <path
        fill="var(--xdlColorIllustrationOutlinePrimary)"
        d="m33.926 3.538 6.888 21.086 21.633.117h3.77l-20.83 13.883 6.343 19.413 2.512 6.425-23.344-16.155L8.323 63.386l2.173-4.748 8.978-21.118-17.69-13.108 23.211.127zm-.246 5.688-7.364 17.32-18.429-.1 14.046 10.406-7.685 18.072 16.866-11.271 18.223 13.5-6.886-21.078 14.013-9.368-17.102-.09z"
      />
      <path
        fill="#27DBA8"
        d="m13.545 12.832 6.182 4.82-1.23 1.578-6.182-4.821zm8.468-3.926.898 7.787-1.986.229-.898-7.787z"
      />
    </svg>
  );
};
export default IllustrationSpotTop;
