import { Children, cloneElement } from 'react';

/**
 * A component that adds the Braze Tag Manager ID to its children.
 *
 * @param {string} id
 * @param {ReactNode} children
 * @returns {ReactNode}
 */
export const TagManagerId = ({ id, children }) => {
  try {
    if (Children.only(children)) {
      return cloneElement(children, {
        'data-tm-id': id,
      });
    }
  } catch (e) {
    console.warn('TagManagerId can only accept one React element as children');
    return children;
  }
};
