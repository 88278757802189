import { Fragment, type FC } from 'react';

import {
  EditPage,
  EDIT_ITEMS_MAP,
} from '@xing-com/crate-entity-pages-edit-page';

type EditPageTransitionProps = {
  pageSlug: string;
  item: string;
  isWebview?: boolean;
};
export const EditPageTransition: FC<EditPageTransitionProps> = ({
  pageSlug,
  item,
  isWebview = false,
}) => {
  const EditPageItem = EDIT_ITEMS_MAP[item]?.edit ?? Fragment;

  return (
    <EditPage pageSlug={pageSlug} item={item} isWebview={isWebview}>
      {({ setBackLink, backLinkDefault }) => (
        <EditPageItem
          pageSlug={pageSlug}
          setBackLink={setBackLink}
          backLinkDefault={backLinkDefault}
        />
      )}
    </EditPage>
  );
};
