import type * as React from 'react';
import { useIntl } from 'react-intl';

import { ErrorState as ErrorStateComponent } from '@xing-com/empty-state';

type ErrorStateProps = {
  loading: boolean;
  refetch: () => void;
};

export const ErrorState: React.FC<ErrorStateProps> = ({ loading, refetch }) => {
  const intl = useIntl();

  return (
    <ErrorStateComponent
      headlineText={intl.formatMessage({ id: 'EP_GENERAL_ERROR_TITLE' })}
      bodyCopyText={intl.formatMessage({ id: 'EP_GENERAL_ERROR_TEXT' })}
      buttonText={intl.formatMessage({ id: 'EP_GENERAL_ERROR_BUTTON' })}
      size="medium"
      withBackground
      buttonProps={{
        loading,
        onClick: () => refetch(),
      }}
    />
  );
};
