/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationSpotPen = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationSpotPen"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m60.255 22.215-14.55-14.58L7.884 47.344 3.966 63.99a.59.59 0 0 0 .707.707l16.647-3.918z"
        clipRule="evenodd"
      />
      <path
        fill="#B7E854"
        fillRule="evenodd"
        d="m45.863 6.682 1.517-1.638c4.276-4.275 11.206-4.275 15.481 0s4.276 11.205 0 15.48l-1.897 1.794zM4.14 56.287l7.122 7.451-8.183 2.034z"
        clipRule="evenodd"
      />
      <path
        fill="#27DBA8"
        fillRule="evenodd"
        d="M50.868 13.128 11.955 52.04l3.91 3.909 38.912-38.912z"
        clipRule="evenodd"
      />
      <path
        fill="var(--xdlColorIllustrationOutlinePrimary)"
        d="m46.327 6.168 15.411 15.41-40.6 40.6-17.242 3.939c-1.153.279-2.193-.761-1.92-1.889l3.746-17.456zM7.229 49.275 4.064 64.026l14.583-3.332zm39.098-40.28L8.051 47.27l12.582 12.583L58.91 21.577z"
      />
    </svg>
  );
};
export default IllustrationSpotPen;
