// eslint-disable-next-line monorepo/forbidden-imports
import type React from 'react';
import { lazy, Suspense } from 'react';

import type { ShareToFeedType } from '@xing-com/crate-social-share-to-feed';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { SkeletonBody } from '@xing-com/skeleton';

const LazyShareToFeedComponent = lazy(() => {
  return import('@xing-com/crate-social-share-to-feed').then((module) => ({
    default: module.ShareToFeed,
  }));
});

export const LazyShareToFeed: React.FC<ShareToFeedType> = (props) => {
  const fallback = <SkeletonBody amountRows={4} />;

  return (
    <NoSSR fallback={fallback}>
      <Suspense fallback={fallback}>
        <LazyShareToFeedComponent {...props} />
      </Suspense>
    </NoSSR>
  );
};
