import { useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import type { EntityMedia } from '@xing-com/crate-common-graphql-types';
import type { EditItemProps } from '@xing-com/crate-entity-pages-common';
import {
  useDialogContext,
  usePageContext,
  useEditContext,
  WarningMessageBox as WarningBox,
} from '@xing-com/crate-entity-pages-common';
import { trackEditSaving } from '@xing-com/crate-entity-pages-common/src/tracking';

import { EditMediaDocument } from '../../../graphql/queries/edit-media-query.gql-types';

import * as Styled from './gallery-edit.styles';
import { GalleryManager } from './gallery-manager/gallery-manager';
import { GalleryImageEdit } from './image-manager/image-edit';
import { GalleryImageUpload } from './image-manager/image-upload';
import { NavBar } from './navbar/navbar';
import { GalleryManagerSkeleton } from './skeleton/skeleton';
import { VideoUpload } from './video-upload/video-upload';

const NUMBER_MEDIA_ITEMS = 100;

type GalleryEditContainerProps = EditItemProps;
export const GalleryEditContainer: FC<GalleryEditContainerProps> = ({
  pageSlug,
  setBackLink,
  backLinkDefault,
}) => {
  const { search } = useLocation();
  const { setDataChanged, dataChanged, setDialogConfirmation } =
    useDialogContext();
  const { pageContext } = usePageContext();
  const { trackingData } = useEditContext();

  const [activeIndex, setActiveIndex] = useState(0);
  const [editingItem, setEditingItem] = useState<EntityMedia | undefined>();
  const [mediaItemsList, setMediaItemsList] = useState<EntityMedia[]>([]);

  useEffect(() => {
    const type = new URLSearchParams(search).get('type');
    setActiveIndex(type === 'image' ? 1 : type === 'video' ? 2 : 0);
  }, []);

  const { loading, error, refetch } = useQuery(EditMediaDocument, {
    variables: {
      id: pageSlug,
      first: NUMBER_MEDIA_ITEMS,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const aboutUsEntityPage =
        data?.pagesAboutUs?.__typename === 'AboutEntity'
          ? data?.pagesAboutUs
          : null;

      const aboutUsMedia =
        aboutUsEntityPage?.media?.__typename === 'EntityMediaConnection'
          ? aboutUsEntityPage?.media
          : null;

      const mediaEdges = aboutUsMedia?.edges;

      const galleryItemsEdges = mediaEdges?.filter((edge) => edge?.node);
      const galleryItemsNodes =
        galleryItemsEdges?.map((edge) => edge?.node).filter((node) => !!node) ??
        [];

      // @ts-expect-error TODO: fix this type
      setMediaItemsList(galleryItemsNodes);
    },
  });

  const hasError = !loading && error;

  const trackingMediaGallery = () => {
    if (
      pageContext.focusType &&
      pageContext.pageId &&
      trackingData?.module &&
      trackingData?.part
    ) {
      trackEditSaving({
        focusType: pageContext.focusType,
        itemId: pageContext.pageId,
        module: trackingData?.module,
        part: trackingData?.part,
      });
    }
  };

  const handlePageChange = (id: number) => {
    const handleDefault = () => {
      setDataChanged(false);
      setActiveIndex(id);
    };

    if (dataChanged) {
      setDialogConfirmation({
        dialogAction: () => {
          setDialogConfirmation(null);
          handleDefault();
        },
      });
    } else {
      handleDefault();
    }
  };
  const handleEditItem = (id: string) => {
    const editItem = mediaItemsList?.find((node) => node?.id === id);

    if (editItem) setEditingItem(editItem);
  };
  const handleOnVideoItemSuccess = (id: string) => {
    const videoIndex = mediaItemsList.findIndex(
      (item) =>
        item?.media?.__typename === 'EntityVideo' &&
        item?.media?.videoReferenceV2 === id
    );

    if (videoIndex > -1) {
      const videoItem: any = mediaItemsList[videoIndex];

      videoItem.media = {
        ...videoItem.media,
        videoDetailsV2: {
          ...videoItem?.media?.videoDetailsV2,
          status: 'AVAILABLE',
        },
      };

      const newList = [...mediaItemsList];
      newList[videoIndex] = videoItem;

      setMediaItemsList(newList);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeIndex]);

  useEffect(() => {
    if (editingItem) {
      setBackLink({
        labelKey: 'BUTTON_BACK',
        action: () => {
          setEditingItem(undefined);
          setDialogConfirmation(null);
          setDataChanged(false);
        },
      });
    } else {
      setBackLink(backLinkDefault);
    }
  }, [editingItem]);

  if (editingItem) {
    const itemMedia =
      editingItem?.media?.__typename === 'EntityVideo'
        ? editingItem?.media
        : undefined;

    if (itemMedia?.videoReferenceV2) {
      return (
        <>
          <VideoUpload
            videoId={itemMedia?.videoReferenceV2}
            mediaId={editingItem.id}
            title={editingItem?.description || ''}
            onCancel={() => {
              setEditingItem(undefined);
            }}
            onSave={() => {
              setEditingItem(undefined);
              refetch();
            }}
            onDelete={() => {
              setEditingItem(undefined);
              refetch();
            }}
          />
        </>
      );
    }

    return (
      <GalleryImageEdit
        item={editingItem}
        onDiscard={() => {
          setEditingItem(undefined);
        }}
        onDelete={() => {
          setEditingItem(undefined);
          refetch();
        }}
        onSave={() => {
          setEditingItem(undefined);
          refetch();
        }}
      />
    );
  }

  return (
    <>
      <Styled.EditGalleryHeadline size={'xxlarge'}>
        <FormattedMessage
          id="EP_EDIT_GALLERY"
          defaultMessage="EP_EDIT_GALLERY"
        />
      </Styled.EditGalleryHeadline>
      {loading && <GalleryManagerSkeleton />}
      {hasError && (
        <WarningBox
          headerText="EP_ERROR_HEADER"
          bodyText="EP_ERROR_BODY"
          buttonText="EP_ERROR_RELOAD_CTA"
          onClick={() => refetch()}
        />
      )}
      {!loading && !hasError && (
        <>
          <NavBar activeIndex={activeIndex} onPageChange={handlePageChange} />
          {activeIndex === 0 && (
            <GalleryManager
              mediaItemsList={mediaItemsList}
              onEditItem={handleEditItem}
              onSave={(newList) => {
                trackingMediaGallery();
                setMediaItemsList(newList);
              }}
              onVideoItemSuccess={handleOnVideoItemSuccess}
            />
          )}
          {activeIndex === 1 && (
            <GalleryImageUpload
              onDiscard={() => {
                setActiveIndex(0);
                refetch();
              }}
              onSave={() => {
                trackingMediaGallery();
                setActiveIndex(0);
                refetch();
              }}
            />
          )}
          {activeIndex === 2 && (
            <>
              <VideoUpload
                onCancel={() => {
                  setActiveIndex(0);
                  refetch();
                }}
                onSave={() => {
                  trackingMediaGallery();
                  setActiveIndex(0);
                  refetch();
                }}
                onDelete={() => {
                  setActiveIndex(0);
                  refetch();
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default GalleryEditContainer;
