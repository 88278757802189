/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconListView = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconListView"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20 16H8v2h12zM6 11H4v2h2zM6 16H4v2h2zM6 6H4v2h2zM20 6H8v2h12zM18 11H8v2h10z"
    />
  </svg>
);
export default IconListView;
