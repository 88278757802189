import * as React from 'react';
import styled from 'styled-components';

import { SkeletonButton } from '@xing-com/skeleton';
import {
  scale180,
  spaceS,
  xdlColorSkeletonPrimaryAnimation,
} from '@xing-com/tokens';

export type SocialBarSkeletonProps = {
  className?: string;
};

export const SocialBarSkeleton: React.VFC<SocialBarSkeletonProps> = React.memo(
  ({ className }) => {
    const socialIcons = Array.from({ length: 3 }, (_, index) => (
      <Button key={index} size="small" />
    ));

    return (
      <Container className={className} data-qa="social-bar-skeleton">
        {socialIcons}
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  border-top: 1px solid ${xdlColorSkeletonPrimaryAnimation};
  padding-top: ${spaceS};
`;

const Button = styled(SkeletonButton)`
  max-width: ${scale180};

  &:not(:last-child) {
    margin-right: ${spaceS};
  }
`;
