import styled from 'styled-components';

import {
  SkeletonFormField as SkeletonFormFieldComponent,
  SkeletonButton as SkeletonButtonComponent,
  SkeletonBodyCopy as SkeletonBodyCopyComponent,
} from '@xing-com/skeleton';
import { scale090, scale230, spaceM, spaceS, spaceXL } from '@xing-com/tokens';
import { BodyCopy as BodyCopyComponent } from '@xing-com/typography';

export const FormFieldContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > li:not(:last-child) {
    margin-bottom: ${spaceM};
  }
`;

export const CharacterCounter = styled.span`
  display: block;
  margin-bottom: -${spaceS};
`;

export const BodyCopy = styled(BodyCopyComponent)`
  margin: ${spaceXL} 0 ${spaceM};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spaceXL};
`;

export const SkeletonFormField = styled(SkeletonFormFieldComponent)`
  margin-bottom: ${spaceM};
`;

export const SkeletonButtonContainer = styled.div`
  display: flex;
  gap: ${spaceXL};
  justify-content: flex-end;
  margin-top: ${spaceXL};
  align-items: center;
`;

export const SkeletonButton = styled(SkeletonButtonComponent)`
  width: ${scale230};
`;

export const SkeletonBodyCopy = styled(SkeletonBodyCopyComponent)`
  height: ${scale090};
  width: ${scale230};
`;
