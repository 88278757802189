import * as React from 'react';

import * as Styled from './star-list.styles';
import type { StarListProps } from './star-list.types';

export const StarList = ({
  rating = 5,
  emptyState,
}: StarListProps): JSX.Element => {
  const list: Array<JSX.Element> = [];
  let i = 0;
  const whole = Math.trunc(rating);
  const decimal = rating - whole;

  while (i++ < Math.floor(whole)) {
    list.push(
      <Styled.StarFilled $emptyState={emptyState} role="presentation" key={i} />
    );
  }

  if (decimal > 0.3 && decimal < 0.9) {
    list.push(
      <Styled.StarHalfFilled
        $emptyState={emptyState}
        key={i}
        role="presentation"
      />
    );
    i++;
  }

  if (decimal >= 0.9) {
    list.push(
      <Styled.StarFilled $emptyState={emptyState} key={i} role="presentation" />
    );
    i++;
  }

  while (i++ <= 5) {
    list.push(
      <Styled.StarEmpty $emptyState={emptyState} key={i} role="presentation" />
    );
  }

  return <React.Fragment>{list}</React.Fragment>;
};
