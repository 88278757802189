import type React from 'react';

import { EntityPageContractType } from '@xing-com/crate-common-graphql-types';
import {
  useEditContext,
  usePageContext,
  EmployerBrandingFlag,
} from '@xing-com/crate-entity-pages-common';
import { useFeatureSwitch } from '@xing-com/hub';
import { SkeletonImage } from '@xing-com/skeleton';
import { useMediaListener } from '@xing-com/util';

import type { CoverImageFragment } from '../../graphql/queries/cover-image-query.gql-types';
import { EditCoverMenu } from '../edit/edit-cover/edit-cover-menu';

import { CoverMedia } from './cover-media';
import * as Styled from './cover-media.styles';
import FreeCover from './free-cover.svg';
import { PreloadCover } from './preload-cover';
import useCoverMedia from './use-cover-media';

export const ENTITY_PAGES_UNFENCE_HEADER = 'entity_pages_unfence_header';

export interface CoverMediaImagesUrlType {
  [reference: string]: any;
}

const getImagesUrl = (
  coverData: CoverImageFragment[]
): CoverMediaImagesUrlType =>
  coverData.reduce(
    (obj, item) => Object.assign(obj, { [String(item.reference!)]: item.url }),
    {}
  );

type CoverMediaContainerProps = {
  isPageLoading?: boolean;
  canBeEdited?: boolean;
};
export const CoverMediaContainer: React.FC<CoverMediaContainerProps> = ({
  isPageLoading,
}) => {
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext();
  const breakpoint = useMediaListener();

  const isFreePage = pageContext.contractType === EntityPageContractType.Free;

  const { data, loading, refetch } = useCoverMedia(
    pageContext.pageSlug as string
  );

  const coverMediaData =
    data?.entityPageEX?.__typename === 'EntityPage'
      ? data?.entityPageEX?.coverImage
      : undefined;

  const hasFenceFS = useFeatureSwitch(ENTITY_PAGES_UNFENCE_HEADER, false);

  const isFreeHeaderProfile =
    hasFenceFS && isFreePage && pageContext.isCompanyPage;

  if ((!hasFenceFS && isFreePage) || (isFreeHeaderProfile && !isEditing)) {
    return <CoverMedia noImage={true} src={FreeCover} />;
  }

  if (isPageLoading || loading || !data) {
    return (
      <Styled.CoverImageWrapper>
        <Styled.CoverImageInner as={SkeletonImage} />
      </Styled.CoverImageWrapper>
    );
  }

  const covers = coverMediaData ? getImagesUrl(coverMediaData) : undefined;
  const cover = covers?.[breakpoint];

  return (
    <>
      {coverMediaData && <PreloadCover covers={coverMediaData} />}

      <CoverMedia src={cover ?? FreeCover} noImage={!cover} />
      {isEditing && !loading && !pageContext.isInsiderPage && (
        <Styled.EditingWrapperCover>
          <>
            {isFreeHeaderProfile && (
              <EmployerBrandingFlag hasImage={!!coverMediaData || !!cover} />
            )}
            <EditCoverMenu
              hasCover={!!coverMediaData || !!cover}
              isFreePage={isFreePage}
              onDeleteCover={() => refetch()}
              onUpdateCover={() => refetch()}
            />
          </>
        </Styled.EditingWrapperCover>
      )}
    </>
  );
};
