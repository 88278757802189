import { useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import { useEffect, useState, type FC, type PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import { EntityPageFocusType } from '@xing-com/crate-common-graphql-types';
import { useFeatureSwitch, useViewerData } from '@xing-com/hub';

import type { AppcuesGetCompanyInfoQuery } from '../../graphql/queries/appcues-get-company-info.gql-types';
import { AppcuesGetCompanyInfoDocument } from '../../graphql/queries/appcues-get-company-info.gql-types';
import { usePageContext } from '../../hooks/use-page-context/use-page-context';
import type { AppcuesCompanyData } from '../../types/appcues';
import * as appcues from '../../utils/appcues';

const APPCUES_FOCUSTYPES: Array<string> = [EntityPageFocusType.Company];
export const APPCUES_FEATURESWITCH = 'use_appcues';

export const Appcues: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const { data } = useViewerData();
  const user = data?.user;

  const { pageContext } = usePageContext();

  const companyId = pageContext.companyId;
  const focusType = pageContext.focusType || '';

  const hasFeatureSwitch = useFeatureSwitch(APPCUES_FEATURESWITCH, false);
  const hasFocusType = Boolean(APPCUES_FOCUSTYPES.includes(focusType));
  const hasRole = Boolean(pageContext.isEditor);

  const hasAppcues = hasFeatureSwitch && hasFocusType && hasRole;

  const [appcuesComanyData, setAppcuesCompanyData] =
    useState<AppcuesCompanyData>(null);

  const onCompletedQuery = (data: AppcuesGetCompanyInfoQuery) => {
    setAppcuesCompanyData({
      companyId: companyId ?? '',
      permissions: data?.company?.userContext?.userRole ?? '',
      companyName: data?.company?.companyName ?? '',
      contractType: data?.company?.contractPackageName ?? '',
      companySizeId: data?.company?.companySizeId ?? '',
      companySize: data?.company?.companySize ?? '',
      createdDate: data?.company?.createdAt ?? '',
      hasXamAccount: data?.company?.hasXamAccount ?? '',
      industry: data?.company?.industry?.localizationValue ?? '',
      industryId: data?.company?.industry?.id ?? '',
      language: intl?.locale ?? '',
      pageSlug: pageContext.pageSlug ?? '',
    });
  };

  useQuery(AppcuesGetCompanyInfoDocument, {
    variables: {
      id: companyId ?? '',
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    skip: !hasAppcues || !companyId,
    onCompleted: onCompletedQuery,
  });

  useEffect(() => {
    const element = appcues.getElement();
    const isLoaded = appcues.isLoaded();

    if (hasAppcues && user) {
      const id = String(user?.id);

      if (!element) {
        appcues.createElement(id, appcuesComanyData);
      } else if (isLoaded) {
        appcues.loadResources(id, appcuesComanyData);
      }
    } else {
      if (element) {
        appcues.deleteElement();
        if (isLoaded) appcues.resetResources();
      }
    }

    return () => {
      const isLoaded = appcues.isLoaded();
      if (hasAppcues && isLoaded) {
        appcues.resetResources();
      }
    };
  }, [hasAppcues, user, pathname, appcuesComanyData]);

  return children;
};
