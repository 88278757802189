import { useLocation } from '@reach/router';
import { useEffect, useRef, type FC } from 'react';
import { useIntl } from 'react-intl';

import { CreatePostButton } from '@xing-com/crate-entity-pages-common';

import { useNewsContext } from '../../hooks/use-news-context/use-news-context';

import * as Styled from './create-post-trigger.styles';

type CreatePostTriggerProps = {
  param?: string;
  onCompleted?: () => void;
};
export const CreatePostTrigger: FC<CreatePostTriggerProps> = ({
  param = 'create',
  onCompleted = () => undefined,
}) => {
  const { isDetailPage } = useNewsContext();
  const { $t } = useIntl();
  const { pathname, search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const page = pathname.includes('news')
    ? 'entity_pages/subpage/news'
    : 'entity_pages/overview';

  const buttonRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const create = urlSearchParams.get(param);

    if (create === 'post' && !isDetailPage) {
      buttonRef.current?.click();
    }
  }, []);

  return (
    <Styled.CommboxInput data-testid="commboxTrigger">
      <CreatePostButton {...{ onCompleted, page }}>
        {(onClick) => (
          <Styled.TextInput ref={buttonRef} onClick={onClick}>
            {$t({ id: 'COMMBOX_EMPTY_TEXT_FIELD' })}
          </Styled.TextInput>
        )}
      </CreatePostButton>
    </Styled.CommboxInput>
  );
};
