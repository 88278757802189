import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Headline } from '@xing-com/typography';

import { usePageContext } from '../../hooks/use-page-context/use-page-context';

import Icon from './assets/upsell_icon_purple.png';
import * as Styled from './upsell-banner.styles';
import { getMarketingPageUrl } from './utils';

type UpsellBannerWithIconProps = {
  headerCopyKey: string;
  bodyCopyKey: string;
  moduleName: string;
};

export const UpsellBannerWithIcon: FC<UpsellBannerWithIconProps> = ({
  headerCopyKey,
  bodyCopyKey,
  moduleName,
}) => {
  const intl = useIntl();
  const { pageContext } = usePageContext();

  const marketingPageUrl = getMarketingPageUrl({
    locale: intl.locale,
    moduleName,
    focusType: pageContext.focusType,
  });

  return (
    <Styled.PremiumWrapperWithIcon>
      <Styled.ContentWrapper>
        <Styled.TextWrapper>
          <Styled.Flag>
            <FormattedMessage id="EP_FLAG_EMPLOYER_BRANDING_PROFILE" />
          </Styled.Flag>
          <Styled.HeaderText>
            <Headline size="xlarge">
              <FormattedMessage id={headerCopyKey} />
            </Headline>
          </Styled.HeaderText>
          <Styled.Text>
            <FormattedMessage id={bodyCopyKey} />
          </Styled.Text>
        </Styled.TextWrapper>
        <Styled.PremiumButtonMargin>
          <Styled.PremiumButton
            size="medium"
            variant="proBusiness"
            href={marketingPageUrl}
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            target="_blank"
            rel="noopener nofollow"
          >
            <FormattedMessage id="EP_UPSELL_GALLERY_BUTTON" />
          </Styled.PremiumButton>
        </Styled.PremiumButtonMargin>
      </Styled.ContentWrapper>
      <Styled.Icon src={Icon} alt="Upsell icon purple" />
    </Styled.PremiumWrapperWithIcon>
  );
};
