/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconEdit"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m14.998 3 3.998 4-9.998 10.008H5.011v-3.996zM15 5.83l-7.99 8.008v1.17h1.16L16.169 7zM19 18v2H5v-2z"
    />
  </svg>
);
export default IconEdit;
