import { useLazyQuery, useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useState, useReducer, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { StatusBanner } from '@xing-com/banner';
import type { EditItemProps } from '@xing-com/crate-entity-pages-common';
import {
  PageError as Error,
  EditHeader,
  usePageContext,
  useEditContext,
  EditActions,
  useErrorContext,
} from '@xing-com/crate-entity-pages-common';
import { trackEditSaving } from '@xing-com/crate-entity-pages-common/src/tracking';
import { Dialog } from '@xing-com/dialog';
import { IconPlus } from '@xing-com/icons';

import { UpdateAffiliatesDocument } from '../../../graphql/mutation/affiliates-update-query.gql-types';
import { AffiliatesEditDocument } from '../../../graphql/queries/affiliates-edit-query.gql-types';

import {
  setInitialState,
  deleteRow,
  addRow,
  companyChange,
  affiliateCategoryChange,
  setErrorMessage,
  setOrder,
} from './affiliates-editor-actions';
import { affiliatesFormReducer } from './affiliates-editor-reducer';
import * as Styled from './affiliates-editor.styles';
import AffiliatesFormRow from './affiliates-form-row';
import { handleCache } from './handle-cache';
import type {
  AffiliatesReducerRow,
  AffiliateReducerErrors,
  AffiliatesReducerState,
} from './types';

const LIMIT_AFFILIATES_LIST = 100;

const initialFormState: AffiliatesReducerState = {
  waitingForSetup: true,
  rows: [],
};

type RenderErrorProps = {
  refetch?(): void;
};
const RenderError: FC<RenderErrorProps> = ({ refetch }) => (
  <div data-testid="errorContainer">
    <Error
      // @ts-expect-error TODO: fix this type
      headerText="EP_ERROR_HEADER"
      bodyText="EP_ERROR_BODY"
      buttonText="EP_ERROR_RELOAD_CTA"
      onClick={() => refetch && refetch()}
    />
  </div>
);

const RenderLoadingRow: FC = () => (
  <Styled.FormLoadingRowContainer>
    {/* @ts-expect-error FIXME: SC6 */}
    <Styled.FormLoadingRow size="large" />
    {/* @ts-expect-error FIXME: SC6 */}
    <Styled.FormLoadingRow size="large" />
  </Styled.FormLoadingRowContainer>
);

const RenderLoading: FC = () => (
  <div data-testid="affiliatesLoadingContainer">
    <EditHeader
      titleKey="EP_EDIT_AFFILIATES"
      descriptionKey="EP_EDIT_AFFILIATES_INFO"
    />
    <RenderLoadingRow />
    <RenderLoadingRow />
    <RenderLoadingRow />
  </div>
);

const EmptyEditorBanner: FC = () => (
  <StatusBanner variant="info" display="inline">
    <Styled.EmptyBannerContent>
      <Styled.HeaderText>
        <FormattedMessage id="EP_AFFILIATES_NO_DATA_HEADER" />
      </Styled.HeaderText>
      <Styled.BodyText>
        <FormattedMessage id="EP_AFFILIATES_NO_DATA_DESCRIPTION" />
      </Styled.BodyText>
    </Styled.EmptyBannerContent>
  </StatusBanner>
);

type AffiliatesEditorProps = EditItemProps;
export const AffiliatesEditor: FC<AffiliatesEditorProps> = ({
  setBackLink,
  backLinkDefault,
}) => {
  const intl = useIntl();
  const [formState, dispatch] = useReducer(
    affiliatesFormReducer,
    initialFormState
  );
  const [showDialog, setShowDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [selectedRowCompanyName, setSelectedRowCompanyName] = useState('');
  const [isPublishing, setIsPublishing] = useState(false);
  const { pageContext } = usePageContext();
  const { trackingData } = useEditContext();
  const { showError } = useErrorContext();
  const companyId = pageContext.companyId;
  const goBackUrl = pageContext.goBackUrl;
  setBackLink(backLinkDefault);

  const [fetchAffiliates, { data, loading, refetch, error }] = useLazyQuery(
    AffiliatesEditDocument,
    {
      // @ts-expect-error TODO: fix this type
      variables: { id: companyId, total: LIMIT_AFFILIATES_LIST },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    }
  );

  const displayError = (error: any) =>
    showError({
      message: 'EP_GENERAL_FEEDBACK_ERROR',
      error,
    });

  const [updateAffiliates] = useMutation(UpdateAffiliatesDocument, {
    onCompleted: (data) => {
      setIsPublishing(false);

      if (data?.companyCreateAffiliates?.error) {
        // @ts-expect-error TODO: fix this type
        setErrorMessage(dispatch)(data.companyCreateAffiliates.error);
        return;
      }

      if (goBackUrl) goBackUrl();
    },
    onError: (error) => {
      setIsPublishing(false);
      displayError(error);
    },
    update: (cache) =>
      handleCache(
        cache,
        {
          query: AffiliatesEditDocument,
          variables: {
            id: companyId,
            total: LIMIT_AFFILIATES_LIST,
          },
        },
        formState.rows
      ),
    awaitRefetchQueries: true,
  });

  const affiliates = data?.company?.affiliates;

  useEffect(() => {
    if (companyId) fetchAffiliates();
  }, [companyId]);

  useEffect(() => {
    if (formState.waitingForSetup && affiliates) {
      // @ts-expect-error TODO: fix this type
      setInitialState(dispatch)(affiliates);
    }
  }, [affiliates]);

  if (loading) return <RenderLoading />;

  if (!loading && error) return <RenderError refetch={refetch} />;

  const showDeleteDialog = (index: number, name: string) => {
    setSelectedRow(index);
    setSelectedRowCompanyName(name);
    setShowDialog(true);
  };

  const hideDeleteDialog = () => {
    setSelectedRow(-1);
    setSelectedRowCompanyName('');
    setShowDialog(false);
  };

  const publishAffiliates = () => {
    const errors: AffiliateReducerErrors = { collection: [] };
    const payload = formState.rows.map(
      ({ category, company }: AffiliatesReducerRow, index: number) => {
        if (!company.id) {
          errors.collection.push({
            index,
            message: intl.formatMessage({
              id: 'EP_AFFILIATES_VALIDATION_NO_NAME_ERROR',
            }),
          });
        }

        return {
          companyId: company.id,
          categoryId: parseInt(category.id, 10),
        };
      }
    );

    if (errors.collection.length) {
      setErrorMessage(dispatch)(errors);
      return;
    }

    setIsPublishing(true);

    if (
      pageContext.focusType &&
      pageContext.pageId &&
      trackingData?.module &&
      trackingData?.part
    ) {
      trackEditSaving({
        focusType: pageContext.focusType,
        itemId: pageContext.pageId,
        isCreate: false,
        module: trackingData?.module,
        part: trackingData?.part,
      });
    }

    updateAffiliates({
      variables: {
        // @ts-expect-error TODO: fix this type
        id: companyId,
        affiliates: payload,
      },
    });
  };

  return (
    <Styled.Container data-cy="affiliatesEditor">
      <Dialog
        show={showDialog}
        isDestructive={true}
        text={
          // @ts-expect-error strong replacement
          intl.formatMessage(
            { id: 'EP_EDIT_AFFILIATES_CONFIRM_DIALOG_BODY_MARKUP' },
            {
              companyName: selectedRowCompanyName,
              strong: (msg) => <strong>{msg}</strong>,
            }
          )
        }
        cancelLabel={intl.formatMessage({
          id: 'EP_EDIT_AFFILIATES_CONFIRM_DIALOG_CANCEL',
        })}
        confirmLabel={intl.formatMessage({
          id: 'EP_EDIT_AFFILIATES_CONFIRM_DIALOG_CONFIRM',
        })}
        onCancel={hideDeleteDialog}
        onConfirm={() => {
          deleteRow(dispatch)(selectedRow);
          hideDeleteDialog();
        }}
      />
      <EditHeader
        titleKey="EP_EDIT_AFFILIATES"
        descriptionKey="EP_EDIT_AFFILIATES_INFO"
        cta={{
          onClick: addRow(dispatch),
          icon: IconPlus,
          children: intl.formatMessage({
            id: 'EP_ADD_MORE_COMPANIES',
          }),
        }}
      />
      {formState.rows.length === 0 && <EmptyEditorBanner />}
      <Styled.Form>
        <Styled.SortableContainer
          setList={(modules: any) => setOrder(dispatch)(modules)}
          list={formState.rows.map((item, index) => ({
            ...item,
            id: `affiliates-edit-row-${index}`,
          }))}
          ghostClass="placeholder"
          forceFallback={true}
          animation={200}
          handle=".handler"
          // small hack to display the correct cursor while grabbing, only IE has window.document.documentMode prop
          onStart={() =>
            // @ts-expect-error TODO: fix this type
            window.document.documentMode
              ? (document.body.style.cursor = 'move')
              : (document.body.style.cursor = 'grabbing')
          }
          onEnd={() => (document.body.style.cursor = 'default')}
        >
          {formState.rows.map((row: AffiliatesReducerRow, index: number) => (
            <AffiliatesFormRow
              key={`affiliates-edit-row-${index}`}
              affiliate={row.company}
              onAffiliateCategoryChange={affiliateCategoryChange(dispatch)(
                index
              )}
              onDelete={() => showDeleteDialog(index, row.company.companyName)}
              onCompanyChange={companyChange(dispatch)(index)}
              affiliateCategory={row.category}
              // @ts-expect-error TODO: fix this type
              affiliationCategories={
                data?.companyAffiliationCategories?.collection || []
              }
              error={row.error?.message}
            />
          ))}
        </Styled.SortableContainer>
      </Styled.Form>
      <EditActions
        disabled={isPublishing}
        discardAction={() => goBackUrl && goBackUrl()}
        saveAction={publishAffiliates}
        isSaving={isPublishing}
      />
    </Styled.Container>
  );
};
