import { useIntl } from 'react-intl';

import { useEditContext } from '@xing-com/crate-entity-pages-common';
import { EmptyState as EmptyStateComponent } from '@xing-com/empty-state';
import {
  IllustrationEmptyStateArticle,
  IllustrationSpotPen,
} from '@xing-com/illustrations';

type Props = {
  path?: string;
};

export const EmptyStateSwitch: React.FC<Props> = ({ path }) => {
  const { formatMessage } = useIntl();
  const { isEditing } = useEditContext();

  const icon = isEditing ? IllustrationSpotPen : IllustrationEmptyStateArticle;
  const variant = isEditing ? 'engage' : 'default';

  const headlineTextCopyKey = isEditing
    ? 'EP-ARTICLES-MODULE-EMPTY-STATE-EDIT-TITLE'
    : 'EP-ARTICLES-MODULE-EMPTY-STATE-TITLE';

  const bodyTextCopyKey = isEditing
    ? 'EP-ARTICLES-MODULE-EMPTY-STATE-EDIT-TEXT'
    : 'EP-ARTICLES-MODULE-EMPTY-STATE-TEXT';

  const buttonText =
    path && isEditing
      ? formatMessage({ id: 'EP-ARTICLES-MODULE-WRITE-NEW-ARTICLE-BUTTON' })
      : undefined;

  return (
    <>
      <EmptyStateComponent
        illustration={icon}
        headlineText={formatMessage({ id: headlineTextCopyKey })}
        bodyCopyText={formatMessage({ id: bodyTextCopyKey })}
        buttonText={buttonText}
        buttonProps={{
          to: path,
        }}
        variant={variant}
        size="medium"
        withBackground
      />
    </>
  );
};
