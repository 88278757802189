import { useQuery } from '@apollo/client';
import type { FC } from 'react';

import { WarningMessageBox as Error } from '@xing-com/crate-entity-pages-common';

import { AboutUsSubpageMediaDocument } from '../../graphql/queries/about-us-subpage-media.gql-types';

import { Gallery } from './gallery';
import { AboutUsSkeleton } from './skeleton/skeleton';

const NUMBER_MEDIA_ITEMS = 100;

type GalleryContainerProps = {
  pageSlug: string;
  itemClicked?: number;
  onError?: (error: Error) => void;
};

export const GalleryContainer: FC<GalleryContainerProps> = ({
  pageSlug,
  itemClicked,
  onError = () => undefined,
}) => {
  const { data, loading, refetch, error } = useQuery(
    AboutUsSubpageMediaDocument,
    {
      variables: {
        id: pageSlug,
        first: NUMBER_MEDIA_ITEMS,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onError: (error) => {
        onError(error);
      },
    }
  );

  const entityPage =
    data?.pagesAboutUs?.__typename === 'AboutEntity'
      ? data.pagesAboutUs
      : undefined;

  const entityMedia =
    entityPage?.media?.__typename === 'EntityMediaConnection'
      ? entityPage.media
      : undefined;

  const mediaEdges = entityMedia?.edges ?? [];

  const mediaNodes = mediaEdges
    .map((edge) => edge?.node)
    .filter((node) => !!node);

  return (
    <>
      {loading && <AboutUsSkeleton />}
      {error ? (
        <Error
          headerText="EP_ERROR_HEADER"
          bodyText="EP_ERROR_BODY"
          buttonText="EP_ERROR_RELOAD_CTA"
          onClick={() => refetch()}
        />
      ) : (
        <Gallery itemClicked={itemClicked} galleryItems={mediaNodes} />
      )}
    </>
  );
};
