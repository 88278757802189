import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import { Headline, BodyCopy } from '@xing-com/typography';

import { PAGE_INFO_HELP_URL, XBM_URL } from '../../config/urls';
import { usePageContext } from '../../hooks/use-page-context/use-page-context';

import * as Styled from './page-info.styles';

export const PageInfo: FC = () => {
  const { formatMessage } = useIntl();
  const { pageContext } = usePageContext();

  const flagKey = pageContext.isAdmin
    ? 'EP_PAGE_INFO_ROLE_ADMINISTRATOR'
    : pageContext.isEditor
      ? 'EP_PAGE_INFO_ROLE_EDITOR'
      : null;

  return (
    <>
      <Styled.Headline size="xxxlarge">
        {formatMessage({ id: 'EP_PAGE_INFO_TITLE' })}
      </Styled.Headline>
      <Styled.HeaderDetail>
        {flagKey && (
          <Styled.Role>
            <Styled.headerDetailLabel size="small">
              {formatMessage({ id: 'EP_PAGE_INFO_ROLE_LABEL' })}
            </Styled.headerDetailLabel>
            <Styled.Flag>{formatMessage({ id: flagKey })}</Styled.Flag>
          </Styled.Role>
        )}
        {pageContext.contractType && (
          <Styled.PageType>
            <Styled.headerDetailLabel size="small">
              {formatMessage({ id: 'EP_PAGE_INFO_PAGE_TYPE_LABEL' })}
            </Styled.headerDetailLabel>
            <Styled.Flag>
              {formatMessage({
                id: `EP_PAGE_INFO_PAGE_TYPE_${pageContext.contractType}`,
              })}
            </Styled.Flag>
          </Styled.PageType>
        )}
      </Styled.HeaderDetail>
      <Styled.MainArea>
        <Styled.BlockContainer>
          <div>
            <Headline size="large" className="blockHeader">
              {formatMessage({ id: 'EP_PAGE_INFO_MANAGE_TITLE' })}
            </Headline>
            <BodyCopy size="small" className="blockBody">
              {formatMessage({ id: 'EP_PAGE_INFO_MANAGE_BODY' })}
            </BodyCopy>
          </div>
          <div>
            <Button
              variant="secondary"
              size="small"
              href={XBM_URL(pageContext.pageSlug)}
              // @ts-expect-error FIXME: SC6
              target="_blank"
            >
              {formatMessage({ id: 'EP_PAGE_INFO_MANAGE_CTA' })}
            </Button>
          </div>
        </Styled.BlockContainer>
        <Styled.BlockContainer>
          <div>
            <Headline size="large" className="blockHeader">
              {formatMessage({ id: 'EP_PAGE_INFO_HELP_TITLE' })}
            </Headline>
            <BodyCopy size="small" className="blockBody">
              {formatMessage({ id: 'EP_PAGE_INFO_HELP_BODY' })}
            </BodyCopy>
          </div>
          <div>
            <Button
              variant="secondary"
              size="small"
              href={PAGE_INFO_HELP_URL()}
              // @ts-expect-error FIXME: SC6
              target="_blank"
            >
              {formatMessage({ id: 'EP_PAGE_INFO_HELP_CTA' })}
            </Button>
          </div>
        </Styled.BlockContainer>
      </Styled.MainArea>
    </>
  );
};
