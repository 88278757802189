import type { FC } from 'react';

import {
  redirectToLogin,
  useLoggedoutAction,
} from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { Menu } from '@xing-com/menu';
import type { PopOverType } from '@xing-com/pop-over';

import List from './list';

type MenuContainerProps = {
  popOver: PopOverType;
  sharePage: () => void;
};
const MenuContainer: FC<MenuContainerProps> = ({ popOver, sharePage }) => {
  const { isLoggedIn } = useLoginState();

  const share = (closeMenu: () => void, sharePage: () => void) => {
    if (!isLoggedIn) {
      redirectToLogin('shareEntityPage');
    } else {
      closeMenu();
      sharePage();
    }
  };

  useLoggedoutAction(sharePage, 'shareEntityPage');

  return (
    <Menu
      onOutsideClick={popOver.handleHide}
      triggerRef={popOver.triggerRef}
      show={popOver.show}
    >
      <List
        sharePage={() => share(popOver.handleHide, sharePage)}
        closePopUp={popOver.handleHide}
      />
    </Menu>
  );
};

export default MenuContainer;
