import styled from 'styled-components';

import { mediaSmallOnly } from '@xing-com/layout-tokens';
import { xdlColorBackground } from '@xing-com/tokens';
import {
  Row as DefaultRow,
  Column as DefaultColumn,
} from '@xing-com/xing-grid';

export const Row = styled(DefaultRow)`
  @media ${mediaSmallOnly} {
    padding: 0;
  }
`;
export const Column = styled(DefaultColumn)`
  @media ${mediaSmallOnly} {
    padding: 0;
  }
`;

export const ModuleWrapper = styled.div.attrs({
  className: 'moduleWrapper',
})`
  position: relative;
  background-color: ${xdlColorBackground};
`;
