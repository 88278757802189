import styled from 'styled-components';

import {
  spaceXS,
  xdlColorBorderSoft,
  scale070,
  scale080,
  xdlColorTextSecondary,
} from '@xing-com/tokens';

import { dotSeparator, iconWrapper } from '../../../utils/shared-styles';

export const SocialInfoWrapper = styled.div`
  border-bottom: 1px solid ${xdlColorBorderSoft};
  color: ${xdlColorTextSecondary};
  display: flex;
  font-size: ${scale070};
  line-height: ${scale080};
  padding-bottom: ${spaceXS};
`;

export const SocialInfoLeft = styled.div`
  flex: 1 1 auto;

  ${dotSeparator}
`;

export const SocialInfoRight = styled.div`
  flex: 0 0 auto;
`;

export const IconTextWrapper = styled.span`
  ${iconWrapper}
`;
