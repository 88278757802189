import styled from 'styled-components';

import { IconButton as InnerIconButton } from '@xing-com/button';
import {
  spaceL,
  xdlColorBorderSoft,
  xdlColorLinkHover,
} from '@xing-com/tokens';

export const ExpandableWrapper = styled.div`
  border-bottom: 1px solid ${xdlColorBorderSoft};
  display: flex;
  flex-direction: column;
`;

export const ExpandableContent = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  height: ${(props) => (props.isExpanded ? 'auto' : '0px')};
  overflow: ${(props) => (props.isExpanded ? 'auto' : 'hidden')};
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${spaceL};
  cursor: pointer;
`;

export const IconButton = styled(InnerIconButton)`
  color: ${xdlColorLinkHover};
  height: auto;
  width: auto;
  &:hover {
    color: ${xdlColorLinkHover};
    &:after {
      background-color: transparent;
    }
  }
`;
