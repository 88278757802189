/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconLike = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconLike"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.5 2a2.5 2.5 0 0 1 2.495 2.336L16 4.5V8h4a2 2 0 0 1 1.994 1.85L22 10v3c0 4.446-3.36 7.869-7.746 7.996L14 21H7.914A1.5 1.5 0 0 1 6.5 22H2V8h4.5a1.5 1.5 0 0 1 1.34.825c.403-.18.74-.492.952-.894l.068-.143 1.63-3.803A3.28 3.28 0 0 1 13.5 2M6 10H4v10h2zm7.5-6c-.464 0-.887.251-1.11.649l-.062.124-1.63 3.803a4 4 0 0 1-2.697 2.302L8 19h6c3.33 0 5.882-2.46 5.996-5.768L20 13v-3h-6V4.5a.5.5 0 0 0-.5-.5"
    />
  </svg>
);
export default IconLike;
