import { useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { TextButton } from '@xing-com/button';
import { PostingPublicationState } from '@xing-com/crate-common-graphql-types';
import {
  useDialogContext,
  useErrorContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import {
  trackHideAction,
  trackUnhideAction,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { IconHide, IconShow } from '@xing-com/icons';

import { CONTENT_FEED_TYPE_GENERIC } from '../../../config/constants';
import { HidePostingDocument } from '../../../graphql/mutations/hide-posting.gql-types';
import { UnhidePostingDocument } from '../../../graphql/mutations/unhide-posting.gql-types';
import { useNewsItemContext } from '../../../hooks/use-news-item-context/use-news-item-context';

import * as Styled from './news-item-actions.styles';

type ActionHideProps = {
  desktop?: boolean;
  itemId?: string;
  onItemActionCompleted?: () => void;
  onItemActionStarted?: () => void;
};
export const ActionHide: FC<ActionHideProps> = ({
  desktop = true,
  itemId,
  onItemActionCompleted = () => undefined,
  onItemActionStarted = () => undefined,
}) => {
  const { $t } = useIntl();
  const { setDialogConfirmation } = useDialogContext();
  const { showError } = useErrorContext();
  const { post, publicationState } = useNewsItemContext();
  const { pageContext } = usePageContext();

  const [hidePosting, { loading: hideLoading }] =
    useMutation(HidePostingDocument);

  const [unhidePosting, { loading: unhideLoading }] = useMutation(
    UnhidePostingDocument
  );

  const handleOnClick = () => {
    if (!itemId) {
      return;
    }
    onItemActionStarted();
    if (publicationState === PostingPublicationState.Hidden) {
      //   actionsDescriptionObj.unhide.action();
      unhidePosting({
        variables: {
          id: itemId,
        },
        onCompleted: (data) => {
          const error = data?.postingsUnhidePosting?.error;

          if (error) {
            showError(error);
          } else {
            onItemActionCompleted();

            trackUnhideAction({
              itemId,
              pageId: pageContext.pageId as string,
              focusType: pageContext.focusType as string,
            });
          }
        },
      });
    } else {
      //   setActionConfirmDialog(actionsDescriptionObj.hide);
      setDialogConfirmation({
        headline: $t({ id: 'NEWS_MODULE_DIALOG_HIDE_HEADLINE' }),
        text: $t({ id: 'NEWS_MODULE_DIALOG_HIDE_TEXT' }),
        cancel: $t({ id: 'NEWS_MODULE_DIALOG_HIDE_NO' }),
        confirm: $t({ id: 'NEWS_MODULE_DIALOG_HIDE_YES' }),
        dialogAction: () => {
          hidePosting({
            variables: {
              id: itemId,
            },
            onCompleted: (data) => {
              const error = data?.postingsHidePosting?.error;

              if (error) {
                showError(error);
              } else {
                onItemActionCompleted();

                trackHideAction({
                  itemId,
                  pageId: pageContext.pageId as string,
                  focusType: pageContext.focusType as string,
                });
              }
              setDialogConfirmation(null);
            },
          });
        },
      });
    }
  };

  const isLoading = hideLoading || unhideLoading;

  const disabled =
    !itemId ||
    post.object?.__typename !== CONTENT_FEED_TYPE_GENERIC ||
    isLoading ||
    publicationState === PostingPublicationState.Draft ||
    publicationState === PostingPublicationState.Scheduled;

  if (desktop === false) {
    return (
      <TextButton
        size="medium"
        icon={IconShow}
        data-testid="HIDE_POSTING"
        onClick={handleOnClick}
        disabled={disabled}
      >
        {$t({
          id:
            publicationState === PostingPublicationState.Hidden
              ? 'NEWS_MODULE_SHOW_BUTTON'
              : 'NEWS_MODULE_HIDE_BUTTON',
        })}
      </TextButton>
    );
  }

  return (
    <Styled.EditButton
      size="small"
      data-testid="HIDE_POSTING"
      onClick={handleOnClick}
      icon={
        publicationState === PostingPublicationState.Published
          ? IconHide
          : IconShow
      }
      ariaLabel={
        publicationState === PostingPublicationState.Published ? 'Hide' : 'Show'
      }
      disabled={disabled}
    />
  );
};
