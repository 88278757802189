import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useState } from 'react';

import type {
  ContentTopic,
  EntityPageContractType,
  EntityPageFocusType,
  EntityPagePublicationStatus,
  EntityPageSocialProofConnection,
  EntityPageVisibility,
} from '@xing-com/crate-common-graphql-types';

import type {
  EntityPageModulesFragment,
  FragmentEntityPageHeaderModuleFragment,
} from '../../graphql/fragments/entity-pages-modules.gql-types';

export type CoverMediaContextType = {
  cover?: string;
};

export type EntityPageContextShared = {
  globalId: string;
  pageSlug: string;
  pageId: string;
  focusType: EntityPageFocusType;
  isAmbassadorPage: boolean;
  isCompanyPage: boolean;
  isGroupPage: boolean;
  isIndustryPage: boolean;
  isInsiderPage: boolean;
  isPublisherPage: boolean;
  isTopicPage: boolean;
  pageSlogan: string;
  isFollowingPage?: boolean | null;
  companyId?: string | null;
  cppId?: string | null;
  socialProof?: EntityPageSocialProofConnection;
  basePath: string;
  contractType: EntityPageContractType;
  publicationStatus: EntityPagePublicationStatus;
  isPageCreated: boolean;
  isPageDraft: boolean;
  isPagePublished: boolean;
  visibility?: EntityPageVisibility | null;
  url?: string;
  pageTitle: string;
  upsellActive?: boolean;
  logo?: string;
  isShowingCompanyBanner?: boolean;
  isEditor: boolean;
  isAdmin: boolean;
  contentTopicCollection?: ContentTopic[];
};

export type PageContextType = EntityPageContextShared & {
  modules?: EntityPageModulesFragment;
  industryId?: string | null;
  hasNewsModule?: boolean;
  moduleTitle?: string;
  coverMedia?: CoverMediaContextType;
  isWebview?: boolean;
  goBackUrl?: () => void;
  moduleGlobalId?: string;
  pageModulesByType: string[];
  pageModules: {
    type?: string;
    globalId?: string;
    properties?: any;
    content?: any;
    followers?: any;
  }[];
  headerModule?: FragmentEntityPageHeaderModuleFragment;
  setModuleLoaded?: (type: string) => void;
  modulesLoaded?: {
    [key: string]: boolean;
  };
};

type PageContextValues = {
  pageContext: PageContextType;
  setPageContext: Dispatch<SetStateAction<PageContextType>>;
};
export const PageContext = createContext<PageContextValues | undefined>(
  undefined
);

type PageContextProviderProps = {
  initialValue: PageContextType;
};
export const PageContextProvider: FC<
  PropsWithChildren<PageContextProviderProps>
> = ({ initialValue, children }) => {
  const [pageContext, setPageContext] = useState<PageContextType>(initialValue);

  return (
    <PageContext.Provider
      value={{
        pageContext,
        setPageContext,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
