import type { PageContextType } from '../context/page-context/page-context';

import {
  PROP_MODULES,
  PROP_CONTEXT_DIMENSION3,
  PROP_ENTITY_PAGE_ID,
  PROP_ACTOR_TYPE,
} from './constants';
import { modulesOrder } from './helpers';
import { trackPage, trackSubpage } from './tracker';

type trackPageviewModulesType = {
  isLoggedIn: boolean;
  isEditor: boolean;
  isEditing: boolean;
  modulesByType: string[];
  id: string;
  contractType: string;
  focusType: string;
  isFollowing: boolean;
};

type trackPageDetailProps = {
  pageId: string;
  subpage: string;
  isEditor: boolean;
  contractType: string;
  focusType: string;
  isFollowing: boolean;
};

type NwtTrackingPropsArgs = {
  pageContext: PageContextType;
  isEditing: boolean;
};

type NwtTrackingProps = {
  flags: string;
  itemUrn: string;
  context: string;
};

const COMPANIES_FOCUS_TYPE = 'COMPANY';

const getPropContextDimension1 = (focusType: string, contractType: string) => {
  if (focusType === COMPANIES_FOCUS_TYPE) {
    return {
      PropContextDimension1: `entity_page_${contractType?.toLowerCase()}`,
    };
  }
  return {};
};
const getPropActorType = (isEditing: boolean) => ({
  [PROP_ACTOR_TYPE]: isEditing ? 'entity_page' : 'user',
});

const getPropContextDimension2 = (isEditing: boolean) => ({
  PropContextDimension2: `entity_page_edit_mode_${
    isEditing ? 'active' : 'passive'
  }`,
});

const getPropContextDimension4 = (isFollowing?: boolean) => {
  return {
    PropContextDimension4: `entity_page_${
      isFollowing ? 'following' : 'unfollowing'
    }`,
  };
};

//TODO: discuss if we can move this helper function to another file
export const getNwtTrackingProps = ({
  pageContext,
  isEditing,
}: NwtTrackingPropsArgs): NwtTrackingProps => {
  const trackEditMode = pageContext.isEditor
    ? `edit_mode_${isEditing ? 'active' : 'passive'}`
    : undefined;
  const trackFollowing = `following_${Boolean(pageContext.isFollowingPage)}`;
  const trackContractType = `entity_page_${pageContext.contractType}`;
  const trackContext = pageContext.isEditor
    ? 'entity_page_editor'
    : 'entity_page_user';

  return {
    flags: [trackEditMode, trackFollowing, trackContractType]
      .filter((value) => value !== undefined)
      .join(','),
    itemUrn: pageContext.globalId,
    context: trackContext,
  };
};

export const trackPageviewModules = ({
  isEditor,
  isEditing,
  modulesByType,
  id,
  contractType,
  focusType,
  isFollowing,
}: trackPageviewModulesType) => {
  const trackingModules = modulesByType
    .filter((type) => type !== 'header')
    .map((type, index) => modulesOrder(type, index + 1))
    .join();

  const trackingData = {
    [PROP_MODULES]: trackingModules,
    [PROP_ENTITY_PAGE_ID]: id,
    [PROP_CONTEXT_DIMENSION3]: isEditor
      ? 'entity_page_editor'
      : 'entity_page_user',
    ...getPropContextDimension1(focusType, contractType),
    ...getPropContextDimension2(isEditing),
    ...getPropContextDimension4(isFollowing),
    ...getPropActorType(isEditing),
  };

  trackPage(trackingData);
};

export const getIDfromURN = (urn: any) => {
  const items = urn.split(':');
  return items[items.length - 1];
};

export const trackPageDetail = ({
  pageId,
  subpage,
  isEditor,
  contractType,
  focusType,
  isFollowing,
}: trackPageDetailProps) => {
  const trackingData = {
    PropEntityPagesId: pageId,
    PropContextDimension3: isEditor ? 'entity_page_editor' : 'entity_page_user',
    ...getPropContextDimension1(focusType, contractType),
    ...getPropContextDimension4(isFollowing),
  };
  return trackSubpage(subpage, trackingData);
};
