import type React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';

export type SubmitButtonType = {
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
};

export const SubmitButton: React.FC<SubmitButtonType> = ({
  disabled = false,
  loading = false,
  onClick,
}) => (
  <Button {...{ disabled, loading, onClick }} variant="primary">
    <FormattedMessage id="SHARE_BOX_SUBMIT_SHARE" />
  </Button>
);
