import styled, { css } from 'styled-components';

import { motionTimeS, spaceM } from '@xing-com/tokens';

export const shareViewTransitionName = 'counters-animation';
export const shareViewAnimationDuration = motionTimeS;

// *** Base ***
const baseWrapper = css`
  display: flex;
  align-items: center;
`;

const baseDescription = css`
  overflow: hidden;
  margin-left: ${spaceM};
`;

// *** Components ***
const Wrapper = styled.div`
  ${baseWrapper}
`;

const Description = styled.div`
  ${baseDescription}
`;

export const Styled = {
  Wrapper,
  Description,
};
