import type React from 'react';

import {
  ShareErrorBoundary,
  TrackingPropsContextWrapper,
} from '@xing-com/crate-social-common-share';
import type {
  OnErrorType,
  OnSuccessType,
} from '@xing-com/crate-social-common-share';
import type { BackendTrackingMetaData } from '@xing-com/crate-social-utils';

import { LazyShareToFeed, LazyShareToMessenger } from '../../lazy-components';

import { Styled } from './share-views.styles';

export type ShareViewsType = {
  backendTrackingMetaData: BackendTrackingMetaData;
  entryPoint: string;
  inputHintClick?: boolean;
  interactionTargetUrn: string;
  onCancel: () => void;
  onError: OnErrorType;
  onSuccess: OnSuccessType;
  openWithShareTo: '' | 'FEED' | 'MESSENGER';
  propChannel: string;
  propSocialInteractionId: string;
  propSourceType: string;
  url: string;
  verb: 'SHARE' | 'POST';
};

export const ShareViews: React.FC<ShareViewsType> = ({
  backendTrackingMetaData,
  entryPoint,
  inputHintClick = false,
  interactionTargetUrn,
  openWithShareTo,
  propChannel,
  propSocialInteractionId,
  propSourceType,
  onCancel,
  onError,
  onSuccess,
  url,
  verb,
}) => (
  <ShareErrorBoundary>
    <TrackingPropsContextWrapper
      {...{
        backendTrackingMetaData,
        entryPoint,
        inputHintClick,
        interactionTargetUrn,
        propChannel,
        propSocialInteractionId,
        propSourceType,
      }}
    >
      <Styled.Grid>
        {openWithShareTo === 'FEED' && (
          <LazyShareToFeed {...{ onCancel, onError, onSuccess, url, verb }} />
        )}
        {openWithShareTo === 'MESSENGER' && (
          <LazyShareToMessenger {...{ onCancel, onError, onSuccess, url }} />
        )}
      </Styled.Grid>
    </TrackingPropsContextWrapper>
  </ShareErrorBoundary>
);
