import { useQuery } from '@apollo/client';

import {
  ModuleHeader,
  useEditContext,
  WarningMessageBox as Error,
} from '@xing-com/crate-entity-pages-common';

import { getBrandmanagerURL } from '../config/config';
import { IdealEmployerDocument } from '../graphql/queries/ideal-employer-query.gql-types';

import IdealEmployer from './ideal-employer';
import Skeleton from './skeleton';

type IdealEmployerContainerProps = {
  pageSlug: string;
  companyId: string;
};
const IdealEmployerContainer = ({
  pageSlug,
  companyId,
}: IdealEmployerContainerProps) => {
  const { isEditing } = useEditContext();
  const { data, loading, error, refetch } = useQuery(IdealEmployerDocument, {
    variables: { id: companyId },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });

  return (
    <>
      <ModuleHeader
        headlineCopyKey="EP_IDEAL_EMPLOYER_HEADLINE"
        externalEditInfo={
          !loading && isEditing
            ? {
                copyKey: 'EP_IDEAL_EMPLOYER_EDIT_HINT',
                ctaCopyKey: 'EP_IDEAL_EMPLOYER_EDIT_HINT_CTA',
                ctaUrl: getBrandmanagerURL(pageSlug),
                cyTestId: 'EDIT_HINT_IDEAL_EMPLOYER',
                ctaAvailableInMobile: false,
              }
            : null
        }
      />
      {error && (
        <div data-testid={'errorContainer'}>
          <Error
            headerText="EP_ERROR_HEADER"
            bodyText="EP_ERROR_BODY"
            buttonText="EP_ERROR_RELOAD_CTA"
            onClick={() => refetch()}
          />
        </div>
      )}
      {loading && <Skeleton />}
      {data && !error && (
        <IdealEmployer
          companyId={companyId}
          isIdealEmployer={!!data?.company?.proJobs?.idealEmployer}
        />
      )}
    </>
  );
};

export default IdealEmployerContainer;
