import cx from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useEditContext,
  usePageContext,
  ExternalEditInfoBanner,
} from '@xing-com/crate-entity-pages-common';
import { trackContentSwitcherFirstUse } from '@xing-com/crate-entity-pages-common/src/tracking';
import Observer from '@xing-com/intersection-observer';

import { BRANDMANAGER_URL } from '../../config/urls';

import * as Styled from './navigation.styles';

type NavigationProps = {
  overwriteIndex?: number;
  navigationRef: (node: any) => void;
  navigationOffset: number;
  frameSize: {
    width: number;
    height: number;
  };
  navigationBarHeight: number;
};

export const Navigation: FC<NavigationProps> = ({
  overwriteIndex,
  navigationOffset,
  frameSize,
  navigationBarHeight,
  navigationRef,
}) => {
  const { $t } = useIntl();
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext();

  const [activeIndex, setActiveIndex] = useState(0);
  const [isSticky, setSticky] = useState(false);
  const [firstNavClick, setFirstNavClick] = useState(true);

  useEffect(() => {
    if (overwriteIndex !== undefined) {
      setActiveIndex(overwriteIndex);
    }
  }, [overwriteIndex]);

  const scrollToModule = (type: string) => {
    const moduleOffset =
      window.pageYOffset +
      document.getElementById(`module-${type}`)!.getBoundingClientRect().top;

    const margin = 16;

    setActiveIndex(pageContext.pageModulesByType.indexOf(type) ?? 0);

    if (firstNavClick) {
      trackContentSwitcherFirstUse(type);
      setFirstNavClick(false);
    }

    window.scrollTo({
      top: moduleOffset - navigationOffset - margin,
      behavior: 'smooth',
    });
  };

  const data =
    pageContext.pageModulesByType.map((pageModuleType) => ({
      copy: $t({
        id: 'EP_' + pageModuleType.toUpperCase() + '_NAVIGATION_TITLE',
      }),
      ['data-testid']: `EP-${pageModuleType.toUpperCase()}-NAVIGATION`,
      onClick: () => module && scrollToModule(pageModuleType),
    })) ?? [];

  const showBrandManagerBanner =
    isEditing && !pageContext.isPageDraft && pageContext.isCompanyPage;

  const navigationHeigth =
    navigationBarHeight > 0 ? navigationBarHeight : 'inherit';
  const navigationWidth = frameSize.width > 0 ? frameSize.width : 'inherit';

  return (
    <Styled.NavigationWrapper $isEditing={isEditing}>
      <Observer
        onChange={({
          rootBounds,
          boundingClientRect,
        }: IntersectionObserverEntry) =>
          rootBounds && setSticky(boundingClientRect.y < rootBounds.y)
        }
        rootMargin={`-${frameSize.height}px 0px 0px 0px`}
        threshold={[1]}
      >
        <div className="navigationSentinal" />
      </Observer>
      <div style={{ height: navigationHeigth }}>
        <Styled.Navigation
          className={cx({
            navigationSticky: isSticky,
            isEditing: isEditing,
            isEditor: pageContext.isEditor,
            notDraft: !pageContext.isPageDraft,
          })}
          style={{ height: navigationHeigth, width: navigationWidth }}
        >
          <Styled.NavigationContainer ref={navigationRef}>
            <Styled.ContentSwitcherContainer>
              <Styled.ContentSwitcher
                activeItemIndex={activeIndex}
                variant="filled"
                size="small"
                isFixed={isSticky}
                data={data}
              />
            </Styled.ContentSwitcherContainer>
          </Styled.NavigationContainer>
          <Styled.NavigationBorder />
        </Styled.Navigation>
      </div>

      {showBrandManagerBanner ? (
        <Styled.InfoBanner>
          <ExternalEditInfoBanner
            copyKey="EP_NAVIGATION_EDIT_HINT"
            ctaCopyKey="EP_NAVIGATION_EDIT_HINT_CTA"
            ctaUrl={BRANDMANAGER_URL(pageContext.pageSlug)}
            cyTestId="EDIT_HINT_NAVIGATION"
            ctaAvailableInMobile={false}
          />
        </Styled.InfoBanner>
      ) : null}
    </Styled.NavigationWrapper>
  );
};
