import type { LikerUser } from '../..';
import type { Edge } from '../../types';

export type flattenUserProps = (edges: Edge[]) => LikerUser[];

export const flattenUser: flattenUserProps = (edges) =>
  edges.reduce((acc: LikerUser[], edge) => {
    if (edge.node.user === null) return acc;
    const {
      node: {
        isContactWithCurrentUser,
        user: {
          displayName,
          gender,
          id,
          occupations,
          pageName,
          profileImage: [{ url }],
          userFlags: { displayFlag },
        },
      },
    } = edge;

    const user = {
      userId: id,
      displayName,
      profileUrl: `/profile/${pageName}`,
      pageName,
      isContactWithCurrentUser,
      occupations,
      image: url,
      gender,
      userFlags: { displayFlag },
    };

    return [...acc, user];
  }, []);
