import ttt from '@xing-com/ticktricktrack';

export type trackContactRequestProps = (props: {
  propChannel: string;
  propActionOrigin: string;
}) => void;

export const trackContactRequest: trackContactRequestProps = ({
  propChannel,
  propActionOrigin,
}) =>
  ttt.event('EventContactsContactRequestSent', {
    EventContactsContactRequestSent: 1,
    PropActionOrigin: propActionOrigin,
    PropChannel: propChannel,
    PropContextDimension1: 'contacts_contactrequest_without_message',
  });
