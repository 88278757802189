import { useLocation, useNavigate } from '@reach/router';
import { useEffect } from 'react';

import type { Employee } from '@xing-com/crate-common-graphql-types';
import type { RecursivePartial } from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import {
  RELATIONSHIP,
  FOLLOW_EMPLOYEE_ACTION,
  FOLLOW_EMPLOYEE_ID_PARAM,
} from '../helpers';

import { useNetworkRequestMutation } from './use-network-request-mutation';

export type UseNetworkRequestLoggedOutActionProps = {
  employees: RecursivePartial<Employee>[];
  loading: boolean;
};
export const useNetworkRequestLoggedOutAction = ({
  employees,
  loading,
}: UseNetworkRequestLoggedOutActionProps) => {
  const { isLoggedOut } = useLoginState();
  const location = useLocation();
  const navigate = useNavigate();

  const [executeNetworkRequest] = useNetworkRequestMutation();

  const actionUrlParam = new URLSearchParams(location.search).get('action');
  const hasAction = actionUrlParam === FOLLOW_EMPLOYEE_ACTION;

  const followEmployeeId = new URLSearchParams(location.search).get(
    FOLLOW_EMPLOYEE_ID_PARAM
  );

  useEffect(() => {
    if (loading || !employees?.length || isLoggedOut || !hasAction) return;

    if (followEmployeeId && employees) {
      const employeeInList = employees.find(
        (employee) => employee?.profileDetails?.id === followEmployeeId
      );

      const isAlreadyContact =
        employeeInList?.networkRelationship?.relationship !== RELATIONSHIP.NONE;

      if ((employeeInList && !isAlreadyContact) || !employeeInList) {
        executeNetworkRequest(followEmployeeId);
      }
    }

    navigate(location.pathname);
  }, [followEmployeeId, hasAction, employees, loading, isLoggedOut]);

  return null;
};
