export type GetSuccessFeedbackConfigType = (props: { targetType: string }) => {
  message: string;
  button: string;
};

export const getSuccessFeedbackConfig: GetSuccessFeedbackConfigType = ({
  targetType,
}) => {
  const capitalizedTarget = targetType.toUpperCase();

  return {
    message: `SHARE_FEEDBACK_${capitalizedTarget}_MESSAGE`,
    button: `SHARE_FEEDBACK_${capitalizedTarget}_BUTTON`,
  };
};
