import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  EP_NwtEditViewAction,
  useEntityPageNwtEditViewAction,
  useErrorContext,
} from '@xing-com/crate-entity-pages-common';

import { UpdateAboutDocument } from '../graphql/update-about-mutation.gql-types';
import { goToMainPage } from '../utils';

type UseSaveDescription = {
  saveDescription: (aboutSummary: string) => void;
  isSaving: boolean;
};

export const useSaveDescription = (pageSlug: string): UseSaveDescription => {
  const { showError } = useErrorContext();
  const { trackEntityPageEditView } = useEntityPageNwtEditViewAction();

  const [updateAbout, { loading }] = useMutation(UpdateAboutDocument, {
    onCompleted: (data) => {
      const isSuccess =
        data?.entityPageUpdateAboutContent?.__typename ===
          'EntityPageMutationResult' &&
        !data.entityPageUpdateAboutContent.error;

      if (isSuccess) {
        goToMainPage(pageSlug);
      } else {
        showError({
          message: 'EP_GENERAL_FEEDBACK_ERROR',
          error: data?.entityPageUpdateAboutContent?.error,
        });
      }
    },
    onError: (error) => {
      showError({
        message: 'EP_GENERAL_FEEDBACK_ERROR',
        error,
      });
    },
  });

  const saveDescription = useCallback(
    (aboutSummary: string) => {
      updateAbout({ variables: { pageId: pageSlug, aboutSummary } });

      trackEntityPageEditView(EP_NwtEditViewAction.ABOUT_DESCRIPTION_SAVE);
    },
    [pageSlug]
  );

  return { saveDescription, isSaving: loading };
};
