import styled, { css } from 'styled-components';

import { IconArrowRight } from '@xing-com/icons';
import {
  motionTimeS,
  scale000,
  scale030,
  scale410,
  spaceL,
  spaceM,
  xdlColorText,
} from '@xing-com/tokens';

export const shareViewTransitionName = 'counters-animation';
export const shareViewAnimationDuration = motionTimeS;

// *** Base ***
const baseWrapper = css`
  margin: ${scale000} auto;
  max-width: calc(${scale410} + ${scale030});

  /* Share View Animation */
  &.${shareViewTransitionName}-enter {
    opacity: 0.1;
    transform: translateY(5vh);
  }

  &.${shareViewTransitionName}-enter-active {
    opacity: 1;
    transition:
      opacity ${shareViewAnimationDuration} ease-out,
      transform ${shareViewAnimationDuration} ease-out;
    transform: translateY(0);
  }

  &.${shareViewTransitionName}-enter-done {
    opacity: 1;
  }

  &.${shareViewTransitionName}-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.${shareViewTransitionName}-exit-active {
    opacity: 0.1;
    transition:
      opacity ${shareViewAnimationDuration} ease-out,
      transform ${shareViewAnimationDuration} ease-out;
    transform: translateY(5vh);
  }

  &.${shareViewTransitionName}-exit-done {
    opacity: 0;
  }
`;

const baseButton = css`
  color: ${xdlColorText};
  display: flex;
  align-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: ${spaceL};
  outline: none;
  padding: ${spaceL} ${spaceM} ${spaceL} ${scale000};
  width: 100%;
`;

const baseIconArrow = css`
  margin-left: auto;
`;

// *** Components ***
const Wrapper = styled.div`
  ${baseWrapper}
`;

const Button = styled.button`
  ${baseButton}
`;

const IconArrow = styled(IconArrowRight)`
  ${baseIconArrow}
`;

export const Styled = {
  Wrapper,
  Button,
  IconArrow,
};
