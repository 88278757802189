import type * as React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  EditButton,
  EP_NwtEditViewAction,
  useEditContext,
  useEntityPageNwtEditViewAction,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';

import * as Styled from './your-topics-visitor-view.styles';

export const YourTopicsVisitorView: React.FC = () => {
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext();
  const { trackEntityPageEditView } = useEntityPageNwtEditViewAction();
  const { pageSlug } = pageContext;

  const topics = pageContext.contentTopicCollection || [];
  const hasTopics = !!topics?.length;
  const hideDescription = !hasTopics && !isEditing;
  const editPath = `/pages/${pageSlug}/edit/yourTopics`;

  return (
    <Styled.TopicsContainer>
      <Styled.BodyCopy>
        <Styled.InsiderDescription
          $hideDescription={hideDescription}
          $showEllipsis={!hasTopics}
          aria-hidden={hideDescription}
          data-show-ellipsis={!hasTopics}
        >
          <FormattedMessage id="INSIDER_DESCRIPTION_INTRO" />
        </Styled.InsiderDescription>
        {topics.map((topic, index) => {
          return <Styled.Topic key={index}>{topic.name}</Styled.Topic>;
        })}
      </Styled.BodyCopy>
      {isEditing ? (
        <div>
          <EditButton
            showIcon
            size="small"
            to={editPath}
            onClick={() =>
              trackEntityPageEditView(EP_NwtEditViewAction.INSIDER_TOPICS_OPEN)
            }
          />
        </div>
      ) : null}
    </Styled.TopicsContainer>
  );
};
