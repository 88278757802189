import styled from 'styled-components';

import { Button as ButtonComponent } from '@xing-com/button';
import { spaceM, spaceXL, xdlColorInfoSoft } from '@xing-com/tokens';

export const BannerContainer = styled.div`
  align-items: center;
  background-color: ${xdlColorInfoSoft};
  display: flex;
  gap: ${spaceM};
  padding: ${spaceM} ${spaceXL};
  margin-top: ${spaceXL};
`;

export const IconContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
`;

export const Button = styled(ButtonComponent)`
  white-space: nowrap;
`;
