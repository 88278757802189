import { usePageContext } from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

export type ModulesLoadedState = {
  [key: string]: boolean;
};

type UseModulesLoadedValues = {
  canDisplayModule: (type: string) => boolean;
  setModuleLoaded: (type: string) => void;
};
export const useModulesLoaded = (): UseModulesLoadedValues => {
  const { pageContext, setPageContext } = usePageContext();
  const { isLoggedIn } = useLoginState();

  const setModuleLoaded = (type: string) =>
    setPageContext((prevState) => {
      const newState = prevState
        ? {
            ...prevState,
            modulesLoaded: {
              ...(prevState.modulesLoaded || {}),
              [type]: true,
            },
          }
        : {
            ...pageContext,
            modulesLoaded: {
              [type]: true,
            },
          };

      return newState;
    });

  const canDisplayModule = (type: string) => {
    if (!isLoggedIn) {
      return true;
    }

    if (!pageContext.pageModulesByType || !pageContext) {
      return false;
    }

    return pageContext.modulesLoaded?.[type] ?? false;
  };

  return { canDisplayModule, setModuleLoaded };
};
