import * as React from 'react';
import { useIntl } from 'react-intl';

import { IllustrationEmptyStateError } from '@xing-com/illustrations';

import type { ErrorStateProps } from '.';
import { EmptyState } from '.';

export const ErrorState = React.forwardRef<HTMLDivElement, ErrorStateProps>(
  ({ ...props }, forwardedRef): JSX.Element => {
    const intl = useIntl();

    return (
      <EmptyState
        bodyCopyText={intl.formatMessage({
          id: 'XDS_EMPTYSTATE_ERROR_DESCRIPTION',
          defaultMessage: 'Please try again.',
        })}
        buttonText={intl.formatMessage({
          id: 'XDS_EMPTYSTATE_ERROR_BUTTON',
          defaultMessage: 'Try again',
        })}
        headlineText={intl.formatMessage({
          id: 'XDS_EMPTYSTATE_ERROR_TITLE',
          defaultMessage: 'That didn’t work.',
        })}
        illustration={IllustrationEmptyStateError}
        ref={forwardedRef}
        {...props}
      />
    );
  }
);

ErrorState.displayName = 'ErrorState';
