import { useLocation, useNavigate } from '@reach/router';
import { useEffect } from 'react';

export const useLoggedoutAction = (
  toggle: () => void,
  action: string,
  currentValue = false,
  skip?: boolean
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const search = new URLSearchParams(location.search);

  const hasAction = search.get('action') === action;

  useEffect(() => {
    if (hasAction && !currentValue && !skip) {
      toggle();
    }

    if (hasAction && !skip) {
      navigate(location.pathname);
      // history.push({
      //   search: '',
      // });
    }
  }, [hasAction, skip]);

  return null;
};
