/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationEmptyStateArticle = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationEmptyStateArticle"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="#DBE2E6"
        fillRule="evenodd"
        d="M9.903 64.509 9.885 3.486h48.223v50.826L47.93 64.513z"
        clipRule="evenodd"
      />
      <path
        fill="#5E737D"
        d="M38.346 47.324v2H18.707v-2zm0-5.176v2H18.707v-2zm0-7.396v2H18.707v-2zm0-5.178v2H18.707v-2z"
      />
      <path
        fill="#BCC9D1"
        fillRule="evenodd"
        d="m47.976 64.479.004-10.177h10.136zm-7.36-27.67h7.22v-7.22h-7.22zm7.239 8.577a3.62 3.62 0 1 1-7.24 0 3.62 3.62 0 0 1 7.24 0M16.48 24.28h35.034V11.342H16.48z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default IllustrationEmptyStateArticle;
