import { useCallback, useMemo } from 'react';

import { useTracking } from '@xing-com/crate-communication-tracking';
import type { NWTEvent } from '@xing-com/crate-communication-tracking';

import { useEditContext } from '../../hooks/use-edit-context/use-edit-context';
import { usePageContext } from '../../hooks/use-page-context/use-page-context';
import { getNwtTrackingProps } from '../page-tracking';

type TrackingProps = {
  event:
    | 'entity_pages_editing_opened'
    | 'entity_pages_editing_saved'
    | 'opened';
  element: 'description' | 'insider_topics' | 'article_editor';
  moduleTitle: 'about' | 'page_header' | 'articles';
};

export enum EP_NwtEditViewAction {
  INSIDER_TOPICS_OPEN = 'INSIDER_TOPICS_OPEN',
  INSIDER_TOPICS_SAVE = 'INSIDER_TOPICS_SAVE',
  ABOUT_DESCRIPTION_OPEN = 'ABOUT_DESCRIPTION_OPEN',
  ABOUT_DESCRIPTION_SAVE = 'ABOUT_DESCRIPTION_SAVE',
  ARTICLES_MODULE_EDITOR_OPEN = 'ARTICLES_MODULE_EDITOR_OPEN',
}

export const useEntityPageNwtEditViewAction = () => {
  const { track: trackNWTEvent } = useTracking<NWTEvent>();
  const { pageContext } = usePageContext();
  const { isEditing } = useEditContext();

  const track = useCallback(
    (trackingProps: TrackingProps): void => {
      trackNWTEvent({
        type: 'nwt',
        application: 'wbm',
        eventSchema: 'extended',
        sentBy: 'entity_pages',
        page: 'entity_pages/overview',
        ...getNwtTrackingProps({ pageContext, isEditing }),
        ...trackingProps,
      });
    },
    [trackNWTEvent, pageContext, isEditing]
  );

  const actionMappings = useMemo<Record<EP_NwtEditViewAction, TrackingProps>>(
    () => ({
      [EP_NwtEditViewAction.INSIDER_TOPICS_OPEN]: {
        event: 'entity_pages_editing_opened',
        element: 'insider_topics',
        moduleTitle: 'page_header',
      },
      [EP_NwtEditViewAction.INSIDER_TOPICS_SAVE]: {
        event: 'entity_pages_editing_saved',
        element: 'insider_topics',
        moduleTitle: 'page_header',
      },
      [EP_NwtEditViewAction.ABOUT_DESCRIPTION_OPEN]: {
        event: 'entity_pages_editing_opened',
        element: 'description',
        moduleTitle: 'about',
      },
      [EP_NwtEditViewAction.ABOUT_DESCRIPTION_SAVE]: {
        event: 'entity_pages_editing_saved',
        element: 'description',
        moduleTitle: 'about',
      },
      [EP_NwtEditViewAction.ARTICLES_MODULE_EDITOR_OPEN]: {
        event: 'opened',
        element: 'article_editor',
        moduleTitle: 'articles',
      },
    }),
    []
  );

  const trackEntityPageEditView = useCallback(
    (action: EP_NwtEditViewAction) => {
      track(actionMappings[action]);
    },
    [track, actionMappings]
  );

  return { trackEntityPageEditView };
};
