import type { FC } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { linkRegEx } from '../../utils/utils';
import { LinkParser } from '../link-parser/link-parser';

import * as Styled from './truncated-text.styles';

export type TruncatedTextProps = {
  children: string;
  limit: number;
};

export const TruncatedText: FC<TruncatedTextProps> = ({
  limit = 200,
  children: text,
}) => {
  const [isToggled, setIsToggled] = useState(false);
  const textIsLongEnough = text.length > limit + 20;
  const Links = text
    .split(linkRegEx)
    .filter((textItem) => linkRegEx.exec(textItem) !== null);
  // we have to detect which link has been cut due to truncation
  const cutLink: any = text
    .substr(0, limit)
    .split(linkRegEx)
    .filter((textItem) => linkRegEx.exec(textItem) !== null)
    .find((element) => !Links.includes(element));
  // and pass the full link, so the LinkParser can replace the truncated link with the correct one
  const completeLink = Links.find((link) => link?.includes(cutLink));

  return (
    <>
      <LinkParser completeLink={completeLink} cutLink={cutLink}>
        {isToggled || !textIsLongEnough ? text : text.substr(0, limit) + '... '}
      </LinkParser>
      {!isToggled && textIsLongEnough && (
        <Styled.TextButton
          size="small"
          fontWeight="regular"
          onClick={() => setIsToggled(true)}
        >
          <FormattedMessage id="EP_READ_MORE_CTA" />
        </Styled.TextButton>
      )}
    </>
  );
};
