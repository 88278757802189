import styled, { css } from 'styled-components';

import {
  scale030,
  scale190,
  scale410,
  spaceXL,
  spaceL,
} from '@xing-com/tokens';

// *** Base ***
const baseGrid = css`
  margin: ${scale190} auto ${spaceXL};
  max-width: calc(${scale410} + ${scale030});
  padding-top: ${spaceL};
`;

// *** Components ***
const Grid = styled.div`
  ${baseGrid}
`;

export const Styled = {
  Grid,
};
