import ttt from '@xing-com/ticktricktrack';

import type { RecipientType } from '../../types';

import { getPropInteractionType } from './get-prop-interaction-type';

export type TrackShareToMessengerSuccessType = (props: {
  entryPoint: string;
  inputHintClick: boolean;
  interactionTargetUrn: string;
  message: string;
  propChannel: string;
  propSocialInteractionId: string;
  propSourceType: string;
  recipients: RecipientType[];
}) => void;

export const trackShareToMessengerSuccess: TrackShareToMessengerSuccessType = ({
  entryPoint,
  inputHintClick,
  interactionTargetUrn,
  message,
  propChannel,
  propSocialInteractionId,
  propSourceType,
  recipients = [],
}) => {
  ttt.event('EventShareSent', {
    EventMessengerMessageSent: 1,
    EventShareSent: 1,
    EventShareNumber: recipients.length,
    PropInteractionType: getPropInteractionType(
      'messenger',
      interactionTargetUrn,
      propSourceType
    ),
    PropSocialObjectId: interactionTargetUrn,
    PropChannel: propChannel,
    PropSocialInteractionId: propSocialInteractionId,
    PropContextDimension4: message ? 'social_text' : 'social_empty',
    PropMessengerContext: `text|1:1|social_share|${entryPoint || 'misc'}`,
    ...(inputHintClick && {
      PropContextDimension3: 'social_overview_comment_click',
    }),
  });
};
