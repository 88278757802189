import type { SocialCommentsData } from '../../types';

import { extractTotalEdgesAndCursor } from './extract-total-edges-and-cursor';

const defaultResponseData: SocialCommentsData = {
  viewer: {
    socialReactions: {
      total: 0,
      edges: [],
      pageInfo: { endCursor: '', hasNextPage: false },
    },
  },
};

export type mergeLikersListResponsesProps = (
  previous?: SocialCommentsData,
  next?: SocialCommentsData
) => SocialCommentsData;

export const mergeLikersListResponses: mergeLikersListResponsesProps = (
  previous = defaultResponseData,
  next = defaultResponseData
) => {
  const previousEdgesAndCursor = extractTotalEdgesAndCursor(previous);
  const nextEdgesAndCursor = extractTotalEdgesAndCursor(next);

  if (
    !previousEdgesAndCursor.hasNextPage ||
    next.viewer.socialReactions === null
  )
    return previous;

  return {
    viewer: {
      ...previous.viewer,
      socialReactions: {
        ...previous.viewer.socialReactions,
        total: nextEdgesAndCursor.total,
        edges: [...previousEdgesAndCursor.edges, ...nextEdgesAndCursor.edges],
        pageInfo: { ...next.viewer.socialReactions.pageInfo },
      },
    },
  };
};
