export const OLD_NEWS_NEW_ARTICLE_LINK = (cppId: string) =>
  `/news/pages/${cppId}/articles/new`;

export const OLD_NEWS_NEW_ARTICLE = (cppId: string) =>
  `/news/article/page/${cppId}/edit/new`;

export const CONTENT_SOURCE_XING = 'xing.com';

export const CONTENT_SOURCE_XING_PREVIEW = 'preview.xing.com';

export const CONTENT_ARTICLE_EDIT_LINK = (cppId: string, articleId: string) =>
  `/news/pages/${cppId}/articles/${articleId}/edit`;

export const ARTICLES_ARTICLE_EDIT_LINK = (articleId: string) =>
  `/news/article/${articleId}/edit`;

export const DISCOVERY_DETAIL_ACTIVITIES = (
  socialInteractionIdSlugged: string
) => `/discover/detail-activities/${socialInteractionIdSlugged}`;
