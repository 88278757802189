import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { getLoginLink } from '../utils';

const doRedirect = (): void => {
  const destUrl = window.location.href;
  const loginUrl = `${getLoginLink()}?dest_url=${encodeURIComponent(destUrl)}`;
  window.location.href = loginUrl;
};

export type useWrapRedirectLoggedOutUserOnClickProps = (
  onClick?: () => void,
  onLoggedOutClick?: () => void
) => { handleClick: () => void };

export const useWrapRedirectLoggedOutUserOnClick: useWrapRedirectLoggedOutUserOnClickProps =
  (onClick, onLoggedOutClick = () => undefined) => {
    const { isLoggedOut } = useLoginState();

    const handleClick = (): void => {
      if (isLoggedOut) {
        onLoggedOutClick();
        doRedirect();
      } else {
        onClick?.();
      }
    };

    return { handleClick };
  };
