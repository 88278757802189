import * as Styled from './about-edit-view.styles';

export const AboutEditViewSkeleton: React.FC = () => {
  return (
    <div data-qa="about-edit-view-skeleton">
      <Styled.SkeletonFormField size="medium" />
      <Styled.SkeletonButtonContainer>
        <Styled.SkeletonBodyCopy alignment="left" amountRows={1} />
        <Styled.SkeletonButton size="large" />
      </Styled.SkeletonButtonContainer>
    </div>
  );
};
