import type React from 'react';
import { FormattedMessage } from 'react-intl';

import { Checkbox } from '@xing-com/checkbox';
import { Dropdown, DropdownList } from '@xing-com/crate-entity-pages-common';

import type {
  Aggregation,
  ToggleCheckboxType,
  ClearType,
  FilterNameType,
} from '../../../types';

import * as Styled from './checkbox-filter.styles';

type CheckboxFilterProps = {
  items: Array<Aggregation> | undefined;
  selectedItems: Array<string>;
  label: string;
  filterName: FilterNameType;
  toggleCheckbox: ToggleCheckboxType;
  onClear: ClearType;
  className?: string;
};

export const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  items,
  selectedItems,
  label,
  filterName,
  toggleCheckbox,
  onClear,
  className,
}) => {
  const labelWithSelectedItems = `${label}${
    selectedItems.length > 0 ? ` ● (${selectedItems?.length})` : ''
  }`;
  return (
    <Dropdown
      isSelected={selectedItems?.length !== 0}
      label={labelWithSelectedItems}
      className={className}
    >
      <DropdownList>
        {items?.map(({ count, name, id, trackingKey }) => (
          <Styled.Item key={`${id}-${filterName}`}>
            <Checkbox
              data-testid={name}
              name={name}
              onChange={() => toggleCheckbox(filterName, id, trackingKey)}
              on={selectedItems.indexOf(id) >= 0}
            />
            <Styled.Label>
              {name} ({count})
            </Styled.Label>
          </Styled.Item>
        ))}
      </DropdownList>

      <Styled.ClearItems>
        <Styled.ClearButton
          disabled={selectedItems.length === 0}
          size="medium"
          onClick={() => onClear(filterName)}
        >
          <FormattedMessage id="EP_CLEAR_ALL" />
        </Styled.ClearButton>
      </Styled.ClearItems>
    </Dropdown>
  );
};
