import type { FC } from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { useHeaderContext } from '../../hooks/use-header-context/use-header-context';
import { EntityInfoContainer } from '../entity-info/entity-info-container';
import HeaderActions from '../header-actions';
import { SocialProof } from '../social-proof/social-proof';

import * as Styled from './secondary-area.styles';

export const SecondaryArea: FC = () => {
  const { isLoggedOut } = useLoginState();
  const { isSubpage, hasTertiaryArea } = useHeaderContext();

  return (
    <Styled.SecondaryArea className="secondaryArea">
      {!isSubpage ? (
        <div className="secondaryMainArea">
          <EntityInfoContainer />
        </div>
      ) : null}
      {isSubpage && !isLoggedOut ? (
        <div className="secondaryMainArea">
          <SocialProof />
        </div>
      ) : null}
      {!hasTertiaryArea ? (
        <Styled.HeaderActionsArea>
          <HeaderActions />
        </Styled.HeaderActionsArea>
      ) : null}
    </Styled.SecondaryArea>
  );
};
