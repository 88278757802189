/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SocialShareToStartpageMutationVariables = Types.Exact<{
  interactionTargetUrn: Types.Scalars['GlobalID']['input'];
  message?: Types.InputMaybe<Types.Scalars['String']['input']>;
  verb?: Types.InputMaybe<Types.SocialShareToStartpageVerb>;
  visibility?: Types.InputMaybe<Types.SocialShareToStartpageVisibility>;
  trackingMetadata: Types.SocialTrackingMetadataInput;
}>;

export type SocialShareToStartpageMutation = {
  __typename?: 'Mutation';
  socialShareToStartpage?: {
    __typename?: 'SocialShareMutationResult';
    error?: { __typename?: 'SocialShareError'; message?: string | null } | null;
    success?: {
      __typename?: 'SocialShareResult';
      targetType?: string | null;
      redirectUrl?: string | null;
      interactionTarget?: {
        __typename?: 'SocialInteractionTarget';
        commentsCount: number;
        reactionsCount: number;
        sharesCount: number;
        userReactionType?: Types.SocialReactionType | null;
        id: string;
      } | null;
    } | null;
  } | null;
};

export const SocialShareToStartpageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SocialShareToStartpage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'interactionTargetUrn' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GlobalID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'verb' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SocialShareToStartpageVerb' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'visibility' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SocialShareToStartpageVisibility' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'trackingMetadata' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SocialTrackingMetadataInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialShareToStartpage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'interactionTargetUrn' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'interactionTargetUrn' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'message' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'verb' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'verb' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingMetadata' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingMetadata' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'visibility' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'visibility' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'targetType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'redirectUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interactionTarget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SocialInteractionTargetShare',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialInteractionTargetShare' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocialInteractionTarget' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'urn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'commentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userReactionType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SocialShareToStartpageMutation,
  SocialShareToStartpageMutationVariables
>;
