import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import type { EntityMedia } from '@xing-com/crate-common-graphql-types';
import { VideoV2Status } from '@xing-com/crate-common-graphql-types';
import { IconVideo } from '@xing-com/icons';
import Video from '@xing-com/video';

import { VideoDocument } from '../../../../../graphql/queries/video-status-query.gql-types';

import disabledPlay from './disabled_play.svg';
import { ActionButtons } from './media-item-action-buttons';
import { InfoTitle } from './media-item-info-title';
import * as Styled from './media-item.styles';

type MediaItemVideoProps = {
  item: EntityMedia;
  onVideoEncodingFailure: () => void;
  onVideoEncodingSuccess: () => void;
};
const MediaItemVideo: FC<MediaItemVideoProps> = ({
  item,
  onVideoEncodingFailure,
  onVideoEncodingSuccess,
}) => {
  const videoMedia =
    item.media?.__typename === 'EntityVideo' ? item.media : undefined;

  const [videoFailed, setVideoFailed] = useState<boolean>(false);
  const [videoStatus, setVideoStatus] = useState<
    VideoV2Status | string | undefined
  >(videoMedia?.videoDetailsV2?.status);

  const { refetch } = useQuery(VideoDocument, {
    variables: { id: videoMedia?.videoReferenceV2 ?? '' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const status = data?.videoV2?.status;

      setVideoStatus(status);

      if (data?.videoV2?.status === VideoV2Status.Failed) {
        onVideoEncodingFailure();
        setVideoFailed(true);
        setTimeout(() => refetch(), 15000);
      } else {
        onVideoEncodingSuccess();
      }
    },
    onError: () => {
      setTimeout(() => refetch(), 15000);
      onVideoEncodingFailure();
    },
  });

  const isVideoEncoding = videoStatus === VideoV2Status.Encoding;

  if (videoFailed) {
    return null;
  }

  return (
    <Styled.MediaWrapper $videoWrapper={!!videoMedia?.videoReferenceV2}>
      <Video
        id={videoMedia?.videoReferenceV2}
        onError={() => undefined}
        previewModeAction={() => undefined}
        previewModeButtonSize={1}
      />
      {isVideoEncoding ? (
        <>
          <Styled.PreviewModeDimmer />
          <Styled.LoadingIndicator />
        </>
      ) : (
        <Styled.VideoPreviewIcon
          src={disabledPlay}
          loading="lazy"
          alt="Disabled play"
        />
      )}
    </Styled.MediaWrapper>
  );
};
type MediaItemImageProps = {
  item: EntityMedia;
};
const MediaItemImage: FC<MediaItemImageProps> = ({ item }) => {
  const imageMedia =
    item.media?.__typename === 'ScaledImage' ? item.media : undefined;
  const externalMedia =
    item.media?.__typename === 'EntityExternalVideo' ? item.media : undefined;

  return (
    <Styled.MediaWrapper>
      {externalMedia?.externalLink && (
        <Styled.ExternalIcon>
          <IconVideo width={15} height={15} />
        </Styled.ExternalIcon>
      )}
      <Styled.Media
        src={imageMedia?.url ?? externalMedia?.url ?? ''}
        alt="Media item"
        loading="lazy"
      />
    </Styled.MediaWrapper>
  );
};

type MediaItemProps = {
  item: EntityMedia;
  showDragAndDrop?: boolean;
  defaultVideoStatus?: VideoV2Status | string;
  onVideoEncodingSuccess?: () => void;
  onVideoEncodingFailure?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
};
export const MediaItem: FC<MediaItemProps> = ({
  item,
  showDragAndDrop,
  onVideoEncodingSuccess = () => undefined,
  onVideoEncodingFailure = () => undefined,
  onDelete = () => undefined,
  onEdit = () => undefined,
}) => {
  const { $t } = useIntl();
  const [deleted, setDeleted] = useState<boolean>(false);

  const [isVideoEncoding, setIsVideoEncoding] = useState<boolean>(
    item.media?.__typename === 'EntityVideo' &&
      item.media.videoDetailsV2?.status === VideoV2Status.Encoding
      ? true
      : false
  );

  const descriptionText =
    isVideoEncoding && !deleted
      ? $t({ id: 'EP_GM_VIDEO_ENCODING_MESSAGE' })
      : item.description;

  const mediaType = item.media?.__typename;

  return (
    <Styled.Tag
      as={deleted ? 'span' : 'div'}
      $deleted={!!deleted}
      $encoding={isVideoEncoding}
    >
      <>
        {mediaType === 'EntityVideo' && (
          <MediaItemVideo
            item={item}
            onVideoEncodingSuccess={() => {
              setIsVideoEncoding(false);
              onVideoEncodingSuccess();
            }}
            onVideoEncodingFailure={() => {
              setIsVideoEncoding(true);
              onVideoEncodingFailure();
            }}
          />
        )}
        {(mediaType === 'ScaledImage' ||
          mediaType === 'EntityExternalVideo') && (
          <MediaItemImage item={item} />
        )}
      </>

      <Styled.TextWrapper>
        {deleted && (
          <InfoTitle deleted={!!deleted} description={item.description ?? ''} />
        )}
        <Styled.Description $deleted={deleted}>
          {descriptionText}
        </Styled.Description>
      </Styled.TextWrapper>
      <Styled.ItemActionsWrapper>
        <ActionButtons
          id={item.id}
          encoding={isVideoEncoding}
          deleted={deleted}
          showDragAndDrop={showDragAndDrop}
          isExternalVideo={
            item.media?.__typename === 'EntityExternalVideo' &&
            item.media.externalLink
              ? true
              : false
          }
          onDelete={() => {
            onDelete();
            setDeleted(!deleted);
          }}
          onEdit={onEdit}
        />
      </Styled.ItemActionsWrapper>
    </Styled.Tag>
  );
};
