import { useQuery } from '@apollo/client';
import type { FC } from 'react';

import { WarningMessageBox } from '@xing-com/crate-entity-pages-common';

import { NUMBER_OF_REVIEWS_REQUESTED } from '../../config';
import { KununuDocument } from '../../graphql/kununu-query.gql-types';
import Header from '../header/header';
import Kununu from '../kununu/kununu';
import KununuSkeleton from '../kununu-skeleton/kununu-skeleton';

type KununuContainerProps = {
  companyId: string;
  pageSlug: string;
  isEditor: boolean;
};
const KununuContainer: FC<KununuContainerProps> = ({
  isEditor,
  companyId,
  pageSlug,
}) => {
  const { data, loading, error, refetch } = useQuery(KununuDocument, {
    variables: { id: companyId, reviewsLimit: NUMBER_OF_REVIEWS_REQUESTED },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });

  const company = data?.company;
  const kununuData = company?.kununuData;

  return (
    <>
      <Header
        loading={loading}
        profileUrl={kununuData?.companyProfileUrl ?? ''}
      />
      {error && (
        <div data-testid={'errorContainer'}>
          <WarningMessageBox
            headerText="EP_ERROR_HEADER"
            bodyText="EP_ERROR_BODY"
            buttonText="EP_ERROR_RELOAD_CTA"
            onClick={() => refetch()}
          />
        </div>
      )}
      {loading && <KununuSkeleton />}
      {kununuData && (
        <Kununu
          isEditor={isEditor}
          pageSlug={pageSlug}
          // @ts-expect-error TODO fix this type
          kununuReviews={company?.reviews}
          score={kununuData.ratingAverage ?? 0}
          recommendation={kununuData.recommendationRate ?? 0}
          companyProfileUrl={kununuData.companyProfileUrl ?? ''}
          // @ts-expect-error TODO fix this type
          benefits={kununuData.benefits}
          companyName={company?.companyName ?? ''}
          kununuCommentsUrl={
            kununuData.kununuCommentsUrl ?? kununuData.companyProfileUrl ?? ''
          }
          employerRatingsCount={kununuData.employerRatingsCount ?? 0}
          profileUuid={kununuData.profileUuid ?? ''}
          evaluateProfileUrl={kununuData.evaluateProfileUrl ?? ''}
        />
      )}
    </>
  );
};

export default KununuContainer;
