import { useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';

import { useDialogContext } from '@xing-com/crate-entity-pages-common';
import { IconCross, IconTick } from '@xing-com/icons';
import { ProfileImage } from '@xing-com/profile-image';
import { Meta } from '@xing-com/typography';

import { useContactsEditContext } from '../../hooks/use-contacts-edit-context/use-contacts-edit-context';
import type { ContactEdit } from '../../types/contacts-edit';

import * as Styled from './contact-edit-card-form.styles';

const MAXIMUM_LENGTH_LABEL = 50;

type ContactEditCardFormProps = {
  contactData: ContactEdit;
  onClose?: () => void;
  onChangeLabel?: () => void;
};
export const ContactEditCardForm: FC<ContactEditCardFormProps> = ({
  contactData,
  onClose = () => undefined,
  onChangeLabel = () => undefined,
}) => {
  const { $t } = useIntl();
  const { setDataChanged } = useDialogContext();
  const { contactDataList, setContactUsersList, setContactsTouched } =
    useContactsEditContext();

  const [contactLabel, setContactLabel] = useState<string>(
    contactData.contact.label || ''
  );

  const handleOnChangeInput = (ev: ChangeEvent<HTMLInputElement>) => {
    const hasMaximumLength = ev.target.value.length > MAXIMUM_LENGTH_LABEL;

    if (!hasMaximumLength) {
      setContactLabel(ev.target.value);
    }
  };

  const handleChangeLabel = () => {
    const newContactList = contactDataList.map((mappedContactData) => {
      if (mappedContactData.contact.id === contactData.contact.id) {
        return {
          ...mappedContactData,
          contact: {
            ...mappedContactData.contact,
            label: contactLabel?.trim().replace(/\s\s+/g, ' '),
          },
        };
      }
      return mappedContactData;
    });

    setContactUsersList(newContactList);
    setContactsTouched(true);
    setDataChanged(true);

    onChangeLabel();
  };

  return (
    <>
      <Styled.EditWrapper>
        <ProfileImage
          size="xxsmall"
          src={contactData.contact.xingId?.profileImage?.[0]?.url}
          profileName="user profile image"
        />
        <Styled.EditText fontWeight="bold" size="medium">
          {contactData.contact.xingId?.displayName}
        </Styled.EditText>
      </Styled.EditWrapper>
      <Styled.InputGuidance>
        <Meta size="xsmall">
          {$t({ id: 'EP_EDIT_CONTACTS_DESCRIPTION_INPUT_FIELD' })}
        </Meta>
      </Styled.InputGuidance>
      <div>
        <Styled.FormAndActionsWrapper>
          <Styled.FormField
            data-testid="CONTACTS-EDIT-CARD-FORM-INPUT"
            size="medium"
            value={contactLabel}
            onChange={handleOnChangeInput}
            label={$t({ id: 'EP_CONTACT_EDIT_LABEL' })}
            helperText={$t(
              { id: 'EP_INPUT_CHARS_LEFT' },
              { charactersNumber: 50 - contactLabel.length }
            )}
          />
          <Styled.EditActionsWrapper>
            <Styled.DefaultItemActionButton
              data-cy="ICON_TICK"
              data-testid="CONTACT-EDIT-CARD-FORM-TICK"
              icon={IconTick}
              onClick={handleChangeLabel}
              size="small"
              variant="primary"
              aria-label="Tick"
            />
            <Styled.DefaultItemActionButton
              data-cy="ICON_CROSS"
              icon={IconCross}
              size="small"
              variant="tertiary"
              onClick={onClose}
              aria-label="Cross"
            />
          </Styled.EditActionsWrapper>
        </Styled.FormAndActionsWrapper>
      </div>
    </>
  );
};
