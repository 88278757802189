import type React from 'react';

import { useUpdateEffect } from '@xing-com/crate-entity-pages-common';
import { IconHide } from '@xing-com/icons';

import { useDisableArticleMutation } from '../../../../hooks/use-disable-article-mutation';
import * as Styled from '../article-card-top.styles';

import type { ArticleControlButton } from './types';

export const ArticleHideButton: React.FC<ArticleControlButton> = ({
  article,
  disabled,
  onLoadingChange,
  onCompleted,
  onError,
}) => {
  const { handleDisableArticle, loading } = useDisableArticleMutation({
    onCompleted,
    onError,
  });

  useUpdateEffect(() => {
    onLoadingChange(loading);
  }, [loading]);

  const handleClick = () => {
    handleDisableArticle({ article });
  };

  return (
    <Styled.Button
      data-testid={`ARTICLE-HIDE-BUTTON-${article.id}`}
      icon={IconHide}
      size="small"
      variant="tertiary"
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
    />
  );
};
