import styled from 'styled-components';

import { Button as OriginalButton } from '@xing-com/button';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { spaceL, spaceXXL } from '@xing-com/tokens';
import { Headline as OriginalHeadline, BodyCopy } from '@xing-com/typography';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: ${spaceXXL} 0;
`;

export const Image = styled.img`
  width: 45%;
  @media ${mediaSmallOrTiny} {
    width: 70%;
  }
`;

export const Headline = styled(OriginalHeadline)`
  text-align: center;
  max-width: 500px;
  margin-top: ${spaceL};
  @media ${mediaSmallOrTiny} {
    max-width: 300px;
  }
`;

export const Text = styled(BodyCopy)`
  text-align: center;
  max-width: 600px;
  margin-bottom: ${spaceXXL};
`;

export const Button = styled(OriginalButton)`
    color: inherit;
    margin-top: ${spaceL};

    &:hover {
        text-decoration: none;
    }
}`;
