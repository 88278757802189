import styled, { css } from 'styled-components';

import { TextButton } from '@xing-com/button';
import { spaceL } from '@xing-com/tokens';

// *** Base ***
const baseTextButton = css`
  margin-right: ${spaceL};
`;

// *** Components ***
const StyledTextButton = styled(TextButton)`
  ${baseTextButton}
`;

export const Styled = {
  TextButton: StyledTextButton,
};
