import type * as React from 'react';

import * as Styled from './pagination-dots.styles';
const { dotSize } = Styled;

export type DotSize = keyof typeof dotSize;

export type DotProps = {
  /** Size of the dot */
  size: DotSize;
  /** Is the dot selected */
  isSelected: boolean;
  /** Custom color for the dot */
  paginationDotColor?: string;
};

export type PaginationDotProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Add custom CSS */
  className?: string;
  /** The amount of elements */
  length: number;
  /** The index of the element selected */
  selected: number;
  /** Add custom color for selected element */
  paginationDotColor?: string;
} & React.HTMLAttributes<HTMLDivElement>;
