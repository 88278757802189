import { ArticleCard } from '../components/article-card/article-card';

import * as Styled from './articles-module.styles';

export const ArticleListSkeleton: React.FC = () => {
  return (
    <Styled.ArticleListSkeleton data-testid="loading-state">
      <ArticleCard.Skeleton />
      <ArticleCard.Skeleton />
    </Styled.ArticleListSkeleton>
  );
};
