import * as React from 'react';

import { Button } from '@xing-com/button';
import { Headline, Hero, BodyCopy } from '@xing-com/typography';

import * as Styled from './empty-state.styles';
import type { EmptyStateProps } from './empty-state.types';

export const EmptyState = React.forwardRef<HTMLDivElement, EmptyStateProps>(
  (
    {
      bodyCopyText,
      buttonProps,
      buttonText,
      children,
      className,
      headlineText,
      illustration: Illustration,
      size = 'medium',
      variant = 'default',
      withBackground,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const isMediumSize = size === 'medium';
    const isEngageVariant = variant === 'engage';
    const hasTextContent = headlineText || bodyCopyText;

    const renderHeadline = (): JSX.Element => {
      if (isMediumSize) {
        return (
          <Headline size="xlarge" noMargin>
            {headlineText}
          </Headline>
        );
      } else {
        return (
          <Hero size="small" noMargin>
            {headlineText}
          </Hero>
        );
      }
    };

    const renderBodyCopy = (): JSX.Element => {
      if (isMediumSize) {
        return (
          <BodyCopy size="medium" lineHeight="increased" noMargin>
            {bodyCopyText}
          </BodyCopy>
        );
      } else {
        return (
          <BodyCopy size="xlarge" lineHeight="increased" noMargin>
            {bodyCopyText}
          </BodyCopy>
        );
      }
    };

    const renderButton = (): JSX.Element => {
      if (isEngageVariant) {
        return (
          <Button {...buttonProps} size="medium" variant="secondary">
            {buttonText}
          </Button>
        );
      } else {
        return (
          <Button {...buttonProps} size="medium" variant="tertiary">
            {buttonText}
          </Button>
        );
      }
    };

    return (
      <Styled.EmptyState
        $size={size}
        $withBackground={withBackground}
        className={className}
        data-xds="EmptyState"
        ref={forwardedRef}
        {...props}
      >
        {Illustration && (
          <Styled.IllustrationContainer>
            <Illustration width={92} height={92} aria-hidden="true" />
          </Styled.IllustrationContainer>
        )}
        {hasTextContent && (
          <Styled.TextContent $size={size} $variant={variant}>
            {headlineText && renderHeadline()}
            {bodyCopyText && renderBodyCopy()}
          </Styled.TextContent>
        )}
        {children && <Styled.CustomContent>{children}</Styled.CustomContent>}
        {buttonText && (
          <Styled.ButtonContainer>
            {buttonText && renderButton()}
          </Styled.ButtonContainer>
        )}
      </Styled.EmptyState>
    );
  }
);

EmptyState.displayName = 'EmptyState';
