import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  EP_NwtEditViewAction,
  useEntityPageNwtEditViewAction,
  useErrorContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { useViewerData } from '@xing-com/hub';

import { SaveYourTopicsDocument } from '../graphql/your-topics-mutation.gql-types';

import type { TopicsObject } from './use-your-topics';

type UseSaveYourTopicsResult = {
  isSaving: boolean;
  handleSaveTopics: (topics: TopicsObject[]) => void;
};

export const useSaveYourTopics = (): UseSaveYourTopicsResult => {
  const { data } = useViewerData();
  const { showError } = useErrorContext();
  const { pageContext } = usePageContext();
  const { trackEntityPageEditView } = useEntityPageNwtEditViewAction();

  const [saveYourTopics, { loading }] = useMutation(SaveYourTopicsDocument, {
    onCompleted: (data) => {
      if (data.contentUpdatePage?.success) {
        if (pageContext.goBackUrl) pageContext.goBackUrl();
      } else {
        throw new Error();
      }
    },
    onError: (error) => {
      showError({
        message: 'EP_GENERAL_FEEDBACK_ERROR',
        error,
      });
    },
  });

  const handleSaveTopics = useCallback(
    (topics: TopicsObject[]): void => {
      const transferableTopics = topics
        .map(({ __typename, ...rest }) => rest)
        .filter((item) => !!item.name.trim());

      saveYourTopics({
        variables: {
          globalId: `surn:x-xing:content:insider:${data?.user?.id}`,
          topics: transferableTopics,
        },
      });

      trackEntityPageEditView(EP_NwtEditViewAction.INSIDER_TOPICS_SAVE);
    },
    [data?.user?.id, saveYourTopics]
  );

  return { isSaving: loading, handleSaveTopics };
};
