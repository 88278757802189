import { type FC } from 'react';
import { useIntl } from 'react-intl';

import { EntityPageContractType } from '@xing-com/crate-common-graphql-types';
import {
  DialogContextProvider,
  UpsellBannerWithIcon,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { useFeatureSwitch } from '@xing-com/hub';
import { Row, Column } from '@xing-com/xing-grid';

import { HeaderContext } from '../../context/header-context/header-context';
import { CoverMediaContainer } from '../cover-media/cover-media-container';
import { ENTITY_PAGES_UNFENCE_HEADER } from '../edit/edit-cover/edit-cover-menu';
import { HeaderSkeleton } from '../header-skeleton/header-skeleton';
import { LogoArea } from '../logo-area/logo-area';
import { PrimaryArea } from '../primary-area/primary-area';
import { SecondaryArea } from '../secondary-area/secondary-area';
import { TertiaryArea } from '../tertiary-area/tertiary-area';

import * as Styled from './header.styles';

type HeaderProps = {
  pageType?: string;
  isPageLoading?: boolean;
  moduleType?: string;
  isSubpage?: boolean;
};
export const Header: FC<HeaderProps> & {
  Skeleton: typeof HeaderSkeleton;
} = ({ pageType, isPageLoading = false, moduleType, isSubpage = false }) => {
  const { $t } = useIntl();
  const { isLoggedOut } = useLoginState();
  const { pageContext } = usePageContext();
  const { socialProof, pageSlogan, pageTitle } = pageContext;

  const hasFenceFS = useFeatureSwitch(ENTITY_PAGES_UNFENCE_HEADER, false);

  const isEBP = pageContext.contractType === EntityPageContractType.Paid;

  let title = pageTitle ?? '';
  if (isSubpage && moduleType) {
    title = $t({ id: `EP_${moduleType.toUpperCase()}_DESCRIPTION` });
  }

  let subtitle = isEBP ? pageSlogan : '';
  if (isSubpage || !pageSlogan) {
    subtitle = pageTitle ?? '';
  }

  const hasTertiaryArea = !(
    isLoggedOut ||
    isSubpage ||
    socialProof?.total === 0
  );

  return (
    <>
      {!hasFenceFS && pageContext.upsellActive && (
        <Styled.UpsellLayout>
          <UpsellBannerWithIcon
            headerCopyKey="EP_UPSELL_HEADER_TOP_HEADER"
            bodyCopyKey="EP_UPSELL_BODY_TOP_HEADER"
            moduleName="headermodule"
          />
        </Styled.UpsellLayout>
      )}
      <DialogContextProvider>
        <HeaderContext.Provider
          value={{
            subtitle: subtitle,
            title: title,
            isSubpage: isSubpage,
            hasTertiaryArea: hasTertiaryArea,
            pageType: pageType,
          }}
        >
          <Styled.Container data-testid="HEADER-CONTAINER">
            <CoverMediaContainer isPageLoading={isPageLoading} />
            <Styled.GridContainer>
              <Row>
                <Column size={12} className="col">
                  <Styled.TopArea>
                    <LogoArea />
                    <Styled.SideArea
                      $isLoggedOut={isLoggedOut}
                      $isSubpage={isSubpage}
                      $noSocialProof={!(socialProof && socialProof?.total > 0)}
                    >
                      <PrimaryArea />
                      <SecondaryArea />
                    </Styled.SideArea>
                  </Styled.TopArea>
                  {hasTertiaryArea && <TertiaryArea />}
                </Column>
              </Row>
            </Styled.GridContainer>
          </Styled.Container>
        </HeaderContext.Provider>
      </DialogContextProvider>
    </>
  );
};

Header.Skeleton = HeaderSkeleton;
