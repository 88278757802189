import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@xing-com/button';

import { usePageContext } from '../../hooks/use-page-context/use-page-context';

import * as Styled from './upsell-banner.styles';
import { getMarketingPageUrl } from './utils';

const i18nKeys = {
  header: 'EP_NEWS_UPSELL_POPOVER_HEADER',
  body: 'EP_NEWS_UPSELL_POPOVER_BODY',
  btn: 'EP_NEWS_UPSELL_POPOVER_BUTTON',
};

type UpsellPopOverProps = {
  moduleName: string;
  popOver: {
    handleShow: () => void;
    handleHide: () => void;
    isInFlow?: boolean;
    triggerRef?: any;
    show: boolean;
  };
};
export const UpsellPopOver: FC<UpsellPopOverProps> = ({
  moduleName,
  popOver,
}) => {
  const { pageContext } = usePageContext();
  const intl = useIntl();

  const marketingPageUrl = getMarketingPageUrl({
    locale: intl.locale,
    focusType: pageContext.focusType,
    moduleName,
    component: 'tooltip',
  });

  return (
    <Styled.PopOver
      isInFlow={true}
      onOutsideClick={popOver.handleHide}
      show={popOver.show}
      triggerRef={popOver.triggerRef}
      data-testid={'UPSELL_POPOVER'}
      onMouseLeave={popOver.handleHide}
    >
      <h1>{intl.formatMessage({ id: i18nKeys.header })}</h1>
      <p>{intl.formatMessage({ id: i18nKeys.body })}</p>

      <Button
        variant="proBusiness"
        size="medium"
        href={marketingPageUrl}
        // @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: "proBusiness"; ... Remove this comment to see the full error message
        target="_blank"
        rel="noopener nofollow"
      >
        {intl.formatMessage({ id: i18nKeys.btn })}
      </Button>
    </Styled.PopOver>
  );
};
