import type { FC } from 'react';

import { SkeletonBodyCopy } from '@xing-com/skeleton';

import * as Styled from './article-card.styles';

export const ArticleCardSkeleton: FC = () => (
  <Styled.ArticleCardSkeletonContainer>
    <Styled.SkeletonTextContainer>
      <Styled.SkeletonHeadline alignment="left" amountRows={2} size="small" />
      <SkeletonBodyCopy alignment="left" amountRows={4} />
    </Styled.SkeletonTextContainer>
    <Styled.SkeletonImageContainer>
      <Styled.SkeletonImage />
    </Styled.SkeletonImageContainer>
  </Styled.ArticleCardSkeletonContainer>
);
