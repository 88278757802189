import styled, { css } from 'styled-components';

import { spaceS, xdlColorTextInvert } from '@xing-com/tokens';
import { BodyCopy as BodyCopyComponent } from '@xing-com/typography';

export const TopicsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BodyCopy = styled(BodyCopyComponent)`
  margin: 0;

  @media (prefers-color-scheme: light) {
    color: ${xdlColorTextInvert};
  }
`;

export const Topic = styled.em`
  font-style: normal;

  &:first-of-type {
    &:before {
      content: ' ';
    }
  }

  &:not(:first-of-type) {
    &:before {
      content: ', ';
    }
  }
`;

export const InsiderDescription = styled.span<{
  $hideDescription: boolean;
  $showEllipsis: boolean;
}>`
  ${({ $hideDescription }) => $hideDescription && `display: none;`}

  ${({ $showEllipsis }) =>
    $showEllipsis &&
    css`
      &:after {
        content: '...';
        display: inline-block;
        margin-left: ${spaceS};
      }
    `}
`;
