import React from 'react';

import { IconCross } from '@xing-com/icons';
import { BodyCopy } from '@xing-com/typography';

import { trackAction } from '../../tracking';
import type { CommboxImagesType } from '../../types';

import { DropZoneImage } from './drop-zone-image';
import { FilledDropZoneMessages } from './filled-drop-zone-messages';
import { Styled } from './filled-drop-zone.styles';

export type FilledDropZoneType = {
  clearDropzone: () => void;
  images: Array<CommboxImagesType>;
  isEditingPosting: boolean;
  onRemove: (index: number) => void;
};

export const FilledDropZone: React.VFC<FilledDropZoneType> = ({
  images,
  clearDropzone,
  isEditingPosting,
  onRemove,
}) => {
  return (
    <Styled.FilledDropZone>
      <Styled.DropZoneHeader>
        {!isEditingPosting && (
          <React.Fragment>
            <BodyCopy size="small" noMargin>
              <FilledDropZoneMessages imagesNumber={images.length} />
            </BodyCopy>
            <Styled.ButtonCross
              icon={IconCross}
              size="medium"
              sizeConfined="small"
              onClick={() => {
                trackAction({
                  PropTrackAction: 'social_share_commbox_images_delete',
                });
                clearDropzone();
              }}
              data-testid="dropzone-cross-icon"
            />
          </React.Fragment>
        )}
      </Styled.DropZoneHeader>
      <Styled.DropZoneImageContainer>
        {images.map(({ url }, index) => (
          <DropZoneImage
            key={index}
            {...{ index, url, isEditingPosting, onRemove }}
          />
        ))}
      </Styled.DropZoneImageContainer>
    </Styled.FilledDropZone>
  );
};
