import ttt from '@xing-com/ticktricktrack';

import { getPropInteractionType } from './get-prop-interaction-type';

export type TrackShareToFeedSuccessType = (props: {
  interactionTargetUrn: string;
  toChannel: string;
  propSocialInteractionId: string;
  message: string;
  propSourceType: string;
  propChannel: string;
  isQuickShare: boolean;
  audience: string;
  inputHintClick: boolean;
}) => void;

export const trackShareToFeedSuccess: TrackShareToFeedSuccessType = ({
  interactionTargetUrn,
  toChannel,
  propSocialInteractionId,
  message,
  propSourceType,
  propChannel,
  isQuickShare,
  audience = 'PUBLIC',
  inputHintClick = false,
}) => {
  ttt.event('EventShareSent', {
    EventShareSent: 1,
    EventShareNumber: 1,
    PropInteractionType: getPropInteractionType(
      toChannel,
      interactionTargetUrn,
      propSourceType
    ),
    PropSocialObjectId: interactionTargetUrn,
    PropChannel: propChannel,
    PropSocialInteractionId: propSocialInteractionId,
    PropActionOrigin:
      audience === 'PUBLIC'
        ? 'social_share_audience_public'
        : 'social_share_audience_my_contacts',
    PropContextDimension4: isQuickShare
      ? 'social_empty_quickshare'
      : message
        ? 'social_text'
        : 'social_empty',
    ...(inputHintClick && {
      PropContextDimension3: 'social_overview_comment_click',
    }),
  });
};
