import styled, { css } from 'styled-components';

import { zIndexStickyLayer3 } from '@xing-com/layout-tokens';
import {
  scale000,
  scale190,
  spaceL,
  xdlColorTextInvert,
} from '@xing-com/tokens';

// *** Base ***
const basePlacebo = css`
  height: ${scale190};
`;

const baseHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${scale000} ${spaceL};
  height: inherit;
  background: #007575;
  z-index: ${zIndexStickyLayer3};
  position: fixed;
  left: ${scale000};
  right: ${scale000};
  top: ${scale000};
`;

const baseLogo = css`
  color: ${xdlColorTextInvert};
`;

const baseLogout = css`
  color: ${xdlColorTextInvert};
  &:hover,
  &:focus {
    outline: ${scale000};
    text-decoration: none;
  }
`;

// *** Components ***
const Placebo = styled.div`
  ${basePlacebo}
`;

const Header = styled.header<{ $isPremium: boolean }>`
  ${baseHeader}
  ${({ $isPremium }) =>
    $isPremium &&
    css`
      background-image: radial-gradient(
        circle at 75% -50%,
        #00d296,
        #199980 50%,
        #007575
      );
      background-repeat: no-repeat;
      opacity: 1;
    `}
`;

const Logo = styled.a`
  ${baseLogo}
`;

const Logout = styled.a`
  ${baseLogout}
`;

export const Styled = {
  Placebo,
  Header,
  Logo,
  Logout,
};
