import { useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { OmView } from '@xing-com/platform-layout-om'; // eslint-disable-line

import type { ImageUploadOnPublishData } from '@xing-com/crate-entity-pages-common';
import {
  EntityPageDocument,
  getEntityPageQueryVariables,
  ExternalEditInfoBanner,
  EntitySubpageDocument,
  getEntitySubpageQueryVariables,
  ImageUpload,
  DialogContextProvider,
  useDialogContext,
  NotSavedDialog,
  SmallGrid,
  usePageContext,
  useErrorContext,
} from '@xing-com/crate-entity-pages-common';
import {
  trackEditOpening,
  trackEditSaving,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { IconImage, IconUpload } from '@xing-com/icons';
import { Menu } from '@xing-com/menu';
import { usePopOver } from '@xing-com/pop-over';

import { EntityPageUpdateLogoDocument } from '../../../graphql/mutations/update-logo.gql-types';
import { useHeaderContext } from '../../../hooks/use-header-context/use-header-context';

import * as Styled from './edit-logo-menu.styles';
import { UpdateLogo } from './update-logo';

type RenderEditLogoMenuProps = {
  onUploadLogo?: () => void;
};
const RenderEditLogoMenu: FC<RenderEditLogoMenuProps> = ({
  onUploadLogo = () => undefined,
}) => {
  const { locale, $t } = useIntl();
  const { pageType, isSubpage } = useHeaderContext();
  const popOver = usePopOver();
  const { showError } = useErrorContext();
  const { pageContext } = usePageContext();
  const {
    setDataChanged,
    dataChanged,
    dialogConfirmation,
    setDialogConfirmation,
  } = useDialogContext();

  const logoForCache = useRef<string | null>(null);
  const omViewHandle = useRef<any | undefined>(null);
  const closeOmView = useRef<any | undefined>(null);
  const [isEditCrop, setIsEditCrop] = useState<boolean>(false);

  const displayError = (error: any) =>
    showError({
      message: 'EP_GENERAL_FEEDBACK_ERROR',
      error,
    });

  const [updateLogo] = useMutation(EntityPageUpdateLogoDocument, {
    onError: (error) => displayError(error),
    update: (cache) => {
      if (!isSubpage) {
        const entityPageData: any = cache.readQuery({
          query: EntityPageDocument,
          // @ts-expect-error TODO: fix this types
          variables: getEntityPageQueryVariables(pageContext.pageSlug),
        });

        cache.writeQuery({
          query: EntityPageDocument,
          // @ts-expect-error TODO: fix this types
          variables: getEntityPageQueryVariables(pageContext.pageSlug),
          data: {
            entityPageEX: {
              ...entityPageData?.entityPageEX,
              logoImage: [
                {
                  ...entityPageData?.entityPageEX?.logoImage?.[0],
                  url: logoForCache.current,
                },
              ],
            },
          },
        });
      } else {
        const entitySubpageData: any = cache.readQuery({
          query: EntitySubpageDocument,
          // @ts-expect-error TODO: fix this types
          variables: getEntitySubpageQueryVariables(
            pageContext.pageSlug,
            pageType
          ),
        });

        cache.writeQuery({
          query: EntitySubpageDocument,
          // @ts-expect-error TODO: fix this types
          variables: getEntitySubpageQueryVariables(
            pageContext.pageSlug,
            pageType
          ),
          data: {
            entityPageEX: {
              ...entitySubpageData.entityPageEX,
              logoImage: [
                {
                  ...entitySubpageData.entityPageEX.logoImage[0],
                  url: logoForCache.current,
                },
              ],
            },
          },
        });
      }
    },
  });

  const handleOnClickMenuItem = (isEditCrop = false) => {
    if (pageContext.focusType && pageContext.pageId) {
      trackEditOpening({
        focusType: pageContext.focusType,
        itemId: pageContext.pageId,
        module: 'header',
        part: 'logo',
      });
    }

    setIsEditCrop(isEditCrop);
    popOver.handleHide();
    omViewHandle.current();
  };

  const handleOnUpdateLogo = async ({
    cropped,
    original,
  }: ImageUploadOnPublishData) => {
    if (pageContext.focusType && pageContext.pageId) {
      trackEditSaving({
        focusType: pageContext.focusType,
        itemId: pageContext.pageId,
        module: 'header',
        part: 'logo',
      });
    }

    logoForCache.current = URL.createObjectURL(cropped.file);

    const { data, errors } = await updateLogo({
      variables: {
        id: pageContext.pageSlug as string,
        logoUploadId: cropped.id,
        originalLogoUploadId: original?.id,
      },
    });

    const error = errors ?? data?.entityPageUpdateLogo?.error;

    if (error) {
      displayError(error);
    } else {
      setDataChanged(false);
      closeOmView.current?.();
      onUploadLogo();
    }
  };

  const handleDiscard = () => {
    if (dataChanged) {
      setDialogConfirmation({
        dialogAction: () => {
          setDialogConfirmation(null);
          setDataChanged(false);
          if (closeOmView.current) closeOmView.current();
        },
      });
    } else {
      if (closeOmView.current) closeOmView.current();
    }
  };

  useEffect(() => {
    return () => closeOmView.current?.();
  }, []);

  return (
    <>
      <Styled.LogoEditButton
        size="small"
        showIcon
        data-cy="EDIT_BUTTON_ON_LOGO"
        data-testid={'EDIT_BUTTON_ON_LOGO'}
        onClick={popOver.handleShow}
        innerRef={popOver.triggerRef}
      />
      <OmView
        interceptClose={() => handleDiscard()}
        trigger={(activateOmView: any) => {
          omViewHandle.current = activateOmView;
          return (
            <Menu
              dimmerTitle="close menu"
              onOutsideClick={popOver.handleHide}
              triggerRef={popOver.triggerRef}
              show={popOver.show}
            >
              <Styled.ListWrapper>
                <Styled.ListItem
                  data-cy="LOGO_UPLOAD"
                  data-testid="LOGO_UPLOAD"
                  onClick={() => handleOnClickMenuItem()}
                >
                  <IconUpload width={24} height={24} />
                  <Styled.Link noMargin size="small">
                    {$t({ id: 'EP_UPLOAD_LOGO' })}
                  </Styled.Link>
                </Styled.ListItem>
                {pageContext.logo && (
                  <Styled.ListItem
                    data-cy="LOGO_EDIT"
                    onClick={() => handleOnClickMenuItem(true)}
                  >
                    <IconImage width={24} height={24} />
                    <Styled.Link noMargin size="small">
                      {$t({ id: 'EP_EDIT_SECTION' })}
                    </Styled.Link>
                  </Styled.ListItem>
                )}
              </Styled.ListWrapper>
            </Menu>
          );
        }}
      >
        {({ handleClose }: any) => {
          closeOmView.current = handleClose;
          return (
            <SmallGrid>
              {isEditCrop ? (
                <UpdateLogo
                  imageType="logo"
                  onPublish={handleOnUpdateLogo}
                  onDiscard={handleDiscard}
                  onDragCrop={() => setDataChanged(true)}
                  onCancel={() => handleClose()}
                />
              ) : (
                <ImageUpload
                  onDiscard={() => handleDiscard()}
                  onFileSelected={() => setDataChanged(true)}
                  shouldUploadOriginal={true}
                  onPublish={handleOnUpdateLogo}
                  onCancel={handleClose}
                  imageUploadCopy={{
                    headline: $t({ id: 'EP_CHOOSE_LOGO' }),
                    hint: $t({ id: 'EP_UPLOAD_LOGO_HINT' }),
                    label: $t({ id: 'EP_UPLOAD_IMAGE_LABEL' }),
                    onDragLabel: $t({ id: 'EP_UPLOAD_IMAGE_DRAG_LABEL' }),
                  }}
                />
              )}
              <ExternalEditInfoBanner
                copyKey="EP_UPLOAD_LOGO_EDIT_INFO"
                ctaUrl={`https://faq.xing.com/${locale}/node/68249`}
                ctaCopyKey="EP_UPLOAD_LOGO_EDIT_INFO_CTA"
              />
            </SmallGrid>
          );
        }}
      </OmView>
      {dataChanged && dialogConfirmation?.dialogAction && <NotSavedDialog />}
    </>
  );
};

export const EditLogoMenu: FC<RenderEditLogoMenuProps> = (props) => (
  <DialogContextProvider>
    <RenderEditLogoMenu {...props} />
  </DialogContextProvider>
);
