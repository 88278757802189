import type * as React from 'react';

import * as Styled from './list-item.styles';
const { variants } = Styled;

export type ListItemVariant = keyof typeof variants;

export type ListItemProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Content of the list item */
  children?: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** The variant of the list item */
  variant?: ListItemVariant;
} & React.HTMLAttributes<HTMLLIElement>;
