import { useState, useEffect, useMemo } from 'react';
import type { FC, MutableRefObject } from 'react';
import { useInView } from 'react-intersection-observer';

import type * as Types from '@xing-com/crate-common-graphql-types';
import {
  trackNewsArticleClickAction,
  getIDfromURN,
} from '@xing-com/crate-entity-pages-common/src/tracking';
// eslint-disable-next-line monorepo/forbidden-imports
import Video from '@xing-com/video';

import {
  CONTENT_FEED_TYPE_ARTICLE,
  CONTENT_FEED_TYPE_NEW_ARTICLE,
  CONTENT_FEED_TYPE_GENERIC,
  CONTENT_FEED_TYPE_COMPANY_ARTICLE,
  ENTITY_PAGE_IMAGE_POSTING,
  VIDEOPOSTING,
  LINKSHAREPOSTING,
  IMAGEPOSTING,
} from '../../../config/constants';
import { useNewsItemContext } from '../../../hooks/use-news-item-context/use-news-item-context';

import * as Styled from './news-item-attachment.styles';

const trimCopyText = (title: string) => title.length > 80;

type NewsItemAttachmentProps = {
  videoPlaying: MutableRefObject<any>;
};
export const NewsItemAttachment: FC<NewsItemAttachmentProps> = ({
  videoPlaying,
}) => {
  const { post } = useNewsItemContext();

  const [playerActions, setPlayerActions] = useState<any>();

  const [ref, inView] = useInView({
    rootMargin: '0px 0px',
    threshold: 0.5,
  });

  useEffect(() => {
    if (playerActions && !inView) {
      playerActions.pause(true);
    }
  }, [inView]);

  // current types
  // linkshare
  // image posting
  // video posting
  // plain text

  // Normalize the Attachments to a single object with the same naming,
  // so its easier to render

  type attachmentType = Pick<
    Types.EntityPageContentFeedArticlePost,
    'globalId' | 'title' | 'description' | 'provider' | 'permalink' | 'comment'
  > & {
    type?: string;
    image?: string;
    url?: any;
    video?: Pick<Types.Video, 'id'>;
    videoV2?: Pick<Types.VideoV2, 'ref'>;
  };

  const attachment = useMemo(() => {
    let tempPostingAttachment: attachmentType | undefined = undefined;

    if (
      typeof post.object === 'undefined' ||
      post.object === null ||
      post.object.__typename === 'EntityPageContentFeedPlainTextPostingPost'
    ) {
      return;
    }

    if (
      (post.object?.__typename === 'EntityPageContentFeedCompanyArticlePost' ||
        post.object?.__typename === 'EntityPageContentFeedArticlePost') &&
      post.object.video
    ) {
      tempPostingAttachment = {
        globalId: post.object.globalId,
        type: VIDEOPOSTING,
        video: post.object.video,
        title: post.object.title,
        description: post.object.description,
        provider: post.object.provider
          ? post.object.provider
          : post.object.permalink,
        permalink: post.object.permalink,
        url: post.object.permalink,
      };
    }

    switch (post.object?.__typename) {
      // company updates
      case CONTENT_FEED_TYPE_COMPANY_ARTICLE:
        // if it is a company video we dont want to show the original url
        if (tempPostingAttachment?.type === VIDEOPOSTING) {
          tempPostingAttachment.url = null;
          tempPostingAttachment.permalink = null;
          tempPostingAttachment.provider = null;
        }

        // check for linkshare posting
        if (!tempPostingAttachment?.type && post.object?.permalink) {
          tempPostingAttachment = {
            globalId: post.object.globalId,
            type: LINKSHAREPOSTING,
            url: post.object.permalink,
            image: post.object.images
              ? (post.object.images?.[0].url ?? undefined)
              : (post.object.imageUrl ?? undefined),
            provider: post.object.provider
              ? post.object.provider
              : post.object.permalink,
            title: post.object.title,
            description: post.object.description,
          };
        }

        // check for image posting
        if (!tempPostingAttachment?.type && post.object?.images?.length) {
          tempPostingAttachment = {
            globalId: post.object.globalId,
            type: IMAGEPOSTING,
            image: post.object.images?.[0].url ?? undefined,
          };
        }
        break;

      // content article
      case CONTENT_FEED_TYPE_ARTICLE:
        if (!tempPostingAttachment?.type && post.object?.permalink) {
          tempPostingAttachment = {
            globalId: post.object.globalId,
            type: LINKSHAREPOSTING,
            url: post.object.permalink,
            image: post.isPinned
              ? (post.object.image?.['srcWidth1232'] ?? undefined)
              : (post.object.image?.['srcWidth516'] ?? undefined),
            title: post.object.title,
            description: post.object.description,
            provider: post.object.provider,
          };
        }

        break;

      // content new article
      case CONTENT_FEED_TYPE_NEW_ARTICLE:
        if (!tempPostingAttachment?.type && post.object?.visitUrl) {
          const videoThumbnail = post.object.coverVideo?.customThumbnails
            ?.length
            ? post.object.coverVideo?.customThumbnails?.[0]?.sources?.[0]
                ?.source
            : post.object.coverVideo?.defaultThumbnails?.[0]?.sources?.[2]
                ?.source;

          const articleCoverImage =
            videoThumbnail ||
            post.object.coverImageUrl ||
            post.object.images?.[0].url ||
            undefined;

          tempPostingAttachment = {
            globalId: post.object.globalId,
            type: LINKSHAREPOSTING,
            url: post.object.visitUrl,
            image: articleCoverImage,
            title: post.object.title,
            description: post.object.summary,
            videoV2: post.object.coverVideo
              ? { ref: post.object.coverVideo.ref }
              : undefined,
          };
        }

        break;

      // posting
      case CONTENT_FEED_TYPE_GENERIC:
        //
        if (
          !tempPostingAttachment?.type &&
          post.object?.attachment?.__typename === 'PostingsVideoAttachment' &&
          post.object?.attachment?.videoV2
        ) {
          tempPostingAttachment = {
            globalId: post.object.globalId,
            type: VIDEOPOSTING,
            videoV2: post.object.attachment.videoV2,
            title: '',
            url: null,
          };
        }

        // we skip all of this if post has video
        if (
          !tempPostingAttachment?.type &&
          post.object?.attachment?.__typename === 'PostingsLinkAttachment' &&
          !!post.object?.attachment?.link
        ) {
          const { link } = post.object.attachment;

          tempPostingAttachment = {
            globalId: post.object.globalId,
            type: LINKSHAREPOSTING,
            url: link.url,
            image: link.cachedImageUrl ?? undefined,
            title: link.title || '',
            description: link.description || '',
            comment: post.object.comment || '',
            provider: link.sourceDomain || link.url,
          };
        }

        if (
          !tempPostingAttachment?.type &&
          post.object.attachment?.__typename === ENTITY_PAGE_IMAGE_POSTING
        ) {
          tempPostingAttachment = {
            globalId: post.object.globalId,
            type: IMAGEPOSTING,
            image: post.object.attachment?.images?.[0].url ?? undefined,
            comment: post.object.comment,
          };
        }
        break;
    }

    return tempPostingAttachment;
  }, [
    post.object?.__typename !== 'EntityPageContentFeedPlainTextPostingPost' &&
      post.object?.globalId,
  ]);

  const onPlayHandler = () => {
    // stop previous one
    if (
      videoPlaying?.current &&
      videoPlaying.current.id !== attachment?.globalId
    )
      videoPlaying.current.api.pause(true);
    // add current one to News state
    videoPlaying.current = { api: playerActions, id: attachment?.globalId };
  };

  const renderVideo = (postingAttachment: attachmentType) => {
    const videoRef =
      postingAttachment.video?.id ?? postingAttachment.videoV2?.ref;

    return (
      <>
        {videoRef && (
          <Styled.Video ref={ref} style={{ opacity: inView ? 1 : 0.8 }}>
            <Video
              id={videoRef}
              section={'entity_pages_news_module'}
              actions={(api: any) => {
                setPlayerActions(api);
              }}
              onPlay={onPlayHandler}
            />
          </Styled.Video>
        )}
        {postingAttachment.url && renderLinkshare(postingAttachment)}
      </>
    );
  };

  const renderLinkshare = (postingAttachment: attachmentType) => {
    const articleHasImage =
      postingAttachment.image !== null && postingAttachment.image !== undefined;
    const articleHasTextContent =
      postingAttachment.description !== null ||
      postingAttachment.description !== undefined;
    const isExternalUrl = !postingAttachment.provider?.includes('xing.com');
    const externalUrlProps = isExternalUrl
      ? {
          target: '_blank',
          rel: 'noopener nofollow',
          onClick: () => {
            if (
              post.object?.__typename !==
              'EntityPageContentFeedPlainTextPostingPost'
            ) {
              trackNewsArticleClickAction(
                getIDfromURN(post.object?.globalId),
                getIDfromURN(post.globalId)
              );
            }
          },
        }
      : {};

    return (
      <Styled.Content>
        <Styled.GlobalPostingLink
          href={postingAttachment.url}
          {...externalUrlProps}
        />

        {articleHasImage && post.isPinned && (
          <Styled.ContentImg
            href={postingAttachment.url}
            {...externalUrlProps}
            $isVideo={!!postingAttachment.videoV2?.ref}
          >
            <Styled.TopImage
              alt={postingAttachment.title || 'News item main image'}
              src={postingAttachment.image}
              loading="lazy"
            />
          </Styled.ContentImg>
        )}

        {articleHasTextContent && (
          <Styled.ContentText $isPinned={post.isPinned}>
            <Styled.Headline as="h3">
              <Styled.HeadlineAnchor
                href={postingAttachment.url}
                {...externalUrlProps}
              >
                {postingAttachment.title}
              </Styled.HeadlineAnchor>
            </Styled.Headline>

            {!!postingAttachment.description && postingAttachment.title && (
              <Styled.Summary
                {...externalUrlProps}
                href={postingAttachment.url}
                willTrim={trimCopyText(postingAttachment.title)}
              >
                {postingAttachment.description}
              </Styled.Summary>
            )}
            <Styled.Provider>
              <a href={postingAttachment.url} {...externalUrlProps}>
                {postingAttachment.provider}
              </a>
            </Styled.Provider>
          </Styled.ContentText>
        )}

        {articleHasImage && !post.isPinned && (
          <Styled.ContentImg
            href={postingAttachment.url}
            {...externalUrlProps}
            $isVideo={!!postingAttachment.videoV2?.ref}
          >
            <img
              alt={postingAttachment.title || 'News item main image'}
              src={postingAttachment.image}
              loading="lazy"
            />
          </Styled.ContentImg>
        )}
      </Styled.Content>
    );
  };

  const renderImagePosting = (postingAttachment: attachmentType) => (
    <Styled.ImagePosting>
      <img src={postingAttachment.image} loading="lazy" alt="Posting" />
    </Styled.ImagePosting>
  );

  return (
    <>
      {attachment && (
        <>
          {attachment.type === VIDEOPOSTING && renderVideo(attachment)}
          {attachment.type === LINKSHAREPOSTING && renderLinkshare(attachment)}
          {attachment.type === IMAGEPOSTING && renderImagePosting(attachment)}
        </>
      )}
    </>
  );
};
