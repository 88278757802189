import type * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AutoComplete } from '@xing-com/auto-complete';
import { Button } from '@xing-com/button';
import { Headline } from '@xing-com/typography';

import { MAX_CHARACTERS_TOPIC_FIELD } from '../../constants/constants';
import { useSaveYourTopics } from '../../hooks/use-save-your-topics';
import { useYourTopics } from '../../hooks/use-your-topics';

import { YourTopicsEditViewSkeleton } from './your-topics-edit-view.skeleton';
import * as Styled from './your-topics-edit-view.styles';

export const YourTopicsEditView: React.FC = () => {
  const intl = useIntl();
  const {
    loading,
    topics,
    hasNoTopics,
    suggestions,
    handleChange,
    resetSuggestions,
    onCancel,
  } = useYourTopics();
  const { isSaving, handleSaveTopics } = useSaveYourTopics();

  return (
    <>
      <Headline size="xxlarge" noMargin>
        <FormattedMessage id="EP_YOUR_TOPICS_EDIT_VIEW_TITLE" />
      </Headline>
      <Styled.BodyCopy>
        <FormattedMessage id="EP_YOUR_TOPICS_EDIT_VIEW_COPY" />
      </Styled.BodyCopy>

      {loading ? (
        <YourTopicsEditViewSkeleton />
      ) : (
        <>
          <Styled.FormFieldContainer>
            {topics.map((topic, index) => {
              return (
                <li key={index}>
                  <AutoComplete
                    key={index}
                    label={intl.formatMessage({
                      id: 'EP_YOUR_TOPICS_EDIT_VIEW_LABEL',
                    })}
                    value={topic.name}
                    suggestions={suggestions}
                    onSelect={
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      (suggestion) => {
                        handleChange(suggestion.name, index);
                      }
                    }
                    onChange={(value): void => handleChange(value, index)}
                    onBlur={() => resetSuggestions()}
                    suggestionToString={(item) => (item ? item.name : '')}
                    characterCounter={
                      <Styled.CharacterCounter>
                        {topic.name.length}/{MAX_CHARACTERS_TOPIC_FIELD}
                      </Styled.CharacterCounter>
                    }
                  />
                </li>
              );
            })}
          </Styled.FormFieldContainer>

          <Styled.ButtonContainer>
            <Button onClick={onCancel} disabled={isSaving}>
              <FormattedMessage id="EP_YOUR_TOPICS_EDIT_VIEW_CANCEL" />
            </Button>
            <Button
              onClick={() => handleSaveTopics(topics)}
              variant="primary"
              disabled={isSaving || hasNoTopics}
            >
              <FormattedMessage id="EP_YOUR_TOPICS_EDIT_VIEW_SAVE" />
            </Button>
          </Styled.ButtonContainer>
        </>
      )}
    </>
  );
};
