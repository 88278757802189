import { useQuery } from '@apollo/client';

import type { SocialInteractionTargetQuery } from '@xing-com/crate-utils-social-interactions';
import { SocialInteractionTargetDocument } from '@xing-com/crate-utils-social-interactions';

import type { InteractionTarget } from '../types';

export type useCachedInteractionTargetProps = (
  urn: string
) => InteractionTarget;

export const useCachedInteractionTarget: useCachedInteractionTargetProps = (
  urn
) => {
  const { data } = useQuery<SocialInteractionTargetQuery>(
    SocialInteractionTargetDocument,
    {
      fetchPolicy: 'cache-only',
      variables: { urn },
    }
  );

  return (
    data?.viewer?.socialInteractionTarget || {
      commentsCount: 0,
      id: '',
      reactionsCount: 0,
      sharesCount: 0,
      userReactionType: null,
    }
  );
};
