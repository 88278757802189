import type { FC } from 'react';

import {
  usePageContext,
  useEditContext,
} from '@xing-com/crate-entity-pages-common';

import * as Styled from './gallery-edit-button.styles';

type GalleryEditButtonProps = {
  inFullWidth?: boolean;
};
export const GalleryEditButton: FC<GalleryEditButtonProps> = ({
  inFullWidth = false,
}) => {
  const { pageContext } = usePageContext();
  const { isEditing } = useEditContext();
  const isFreePage = pageContext.contractType === 'FREE';

  if (isFreePage || !isEditing) {
    return null;
  }

  return (
    <Styled.GalleryEditButton
      size={'small'}
      showIcon
      inFullWidth={inFullWidth}
      to={`${pageContext.basePath}/${pageContext.pageSlug}/edit/gallery`}
      data-testid="edit-gallery-preview"
    />
  );
};
