import { useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';

import {
  useDialogContext,
  usePageContext,
  useUpdateEffect,
} from '@xing-com/crate-entity-pages-common';

import {
  MAX_CHARACTERS_TOPIC_FIELD,
  TOPICS_ARRAY_LENGTH,
} from '../constants/constants';
import { YourTopicsContentCategoriesDocument } from '../graphql/your-topics-content-categories-query.gql-types';

export type TopicsObject = {
  name: string;
  __typename?: string;
};

type UserYourTopicsResult = {
  loading: boolean;
  topics: TopicsObject[];
  hasNoTopics: boolean;
  suggestions: TopicsObject[];
  handleChange: (value: string, index: number) => void;
  resetSuggestions: () => void;
  onCancel: () => void;
};

const createEmptyTopicsArray = (length: number): TopicsObject[] =>
  Array.from({ length }, () => ({ name: '' }));

export const useYourTopics = (): UserYourTopicsResult => {
  const { executeWithDialog, setDataChanged } = useDialogContext();
  const { pageContext } = usePageContext();
  const [topics, setTopics] = useState<TopicsObject[]>(
    createEmptyTopicsArray(TOPICS_ARRAY_LENGTH)
  );
  const [initialTopics, setInitialTopics] = useState<TopicsObject[]>(
    createEmptyTopicsArray(TOPICS_ARRAY_LENGTH)
  );
  const [suggestions, setSuggestions] = useState<TopicsObject[]>([]);

  const storedTopics = pageContext.contentTopicCollection || [];

  const { data, loading } = useQuery(YourTopicsContentCategoriesDocument, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const topicCategories = data?.contentCategories?.collection;

  useUpdateEffect(() => {
    if (loading) return;

    if (storedTopics.length) {
      const initialTopicsArray = [
        ...storedTopics,
        ...createEmptyTopicsArray(TOPICS_ARRAY_LENGTH - storedTopics.length),
      ];

      setTopics(initialTopicsArray);
      setInitialTopics(initialTopicsArray);
    }

    if (topicCategories?.length) {
      setSuggestions(topicCategories);
    }
  }, [loading, topicCategories, storedTopics]);

  useUpdateEffect(() => {
    const hasTopicsChanged = !initialTopics.every(
      (topic, index) => topic.name === topics[index].name
    );

    setDataChanged(hasTopicsChanged);
  }, [topics, initialTopics]);

  const handleChange = useCallback(
    (value: string, index: number): void => {
      if (value.length > MAX_CHARACTERS_TOPIC_FIELD) return;

      setTopics((prevTopics) =>
        prevTopics.map((topic, i) =>
          i === index ? { ...topic, name: value } : topic
        )
      );

      if (topicCategories?.length) {
        const searchValue = value.toLowerCase();
        const newSuggestions = topicCategories.filter((category) =>
          category.name.toLowerCase().includes(searchValue)
        );
        setSuggestions(newSuggestions);
      }
    },
    [topicCategories, setSuggestions]
  );

  const onCancel = useCallback((): void => {
    executeWithDialog(() => {
      if (pageContext.goBackUrl) pageContext.goBackUrl();
    });
  }, [executeWithDialog, pageContext.goBackUrl]);

  const resetSuggestions = useCallback((): void => {
    if (topicCategories?.length) {
      setSuggestions(topicCategories);
    }
  }, [topicCategories]);

  const hasNoTopics = useMemo(
    () => topics.every((topic) => !topic.name),
    [topics]
  );

  return {
    loading,
    topics,
    hasNoTopics,
    suggestions,
    handleChange,
    resetSuggestions,
    onCancel,
  };
};
