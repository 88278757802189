/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconTitle3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconTitle3"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.13 15.243a2 2 0 0 0-.926-.5q.484-.207.784-.563c.268-.318.403-.718.403-1.186 0-.372-.103-.713-.309-1.014-.204-.301-.504-.543-.889-.718-.382-.175-.847-.262-1.382-.262-.992 0-1.861.346-2.583 1.03l-.068.063.99 1.077.067-.066c.43-.415.898-.627 1.396-.627q.429 0 .669.205c.157.133.234.318.234.565 0 .286-.085.498-.257.65-.174.153-.418.232-.724.232h-.51l-.216 1.388h.726q.574.001.854.228.27.223.27.753c0 .314-.094.551-.29.727q-.294.263-.78.264-.411 0-.75-.161a2.7 2.7 0 0 1-.709-.531l-.064-.064L14 17.773l.053.065C14.688 18.61 15.575 19 16.69 19c.607 0 1.14-.107 1.581-.319.446-.213.789-.505 1.018-.868.229-.364.345-.77.345-1.205 0-.583-.17-1.042-.503-1.365M13.923 4 13.7 6.982l-3.7.012v12.005H7V7.005L3 7.02V4z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconTitle3;
