import styled from 'styled-components';

import {
  SkeletonFormField as SkeletonFormFieldComponent,
  SkeletonButton as SkeletonButtonComponent,
  SkeletonBodyCopy as SkeletonBodyCopyComponent,
} from '@xing-com/skeleton';
import {
  scale090,
  scale230,
  scale240,
  spaceM,
  spaceXL,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const AboutEditBodyCopy = styled(BodyCopy)`
  margin: ${spaceXL} 0 ${spaceM};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${spaceM};
  justify-content: flex-end;
`;

export const SkeletonButtonContainer = styled.div`
  display: flex;
  gap: ${spaceXL};
  justify-content: flex-end;
  margin-top: ${spaceXL};
  align-items: center;
`;

export const SkeletonFormField = styled(SkeletonFormFieldComponent)`
  height: ${scale240};
`;

export const SkeletonButton = styled(SkeletonButtonComponent)`
  width: ${scale230};
`;

export const SkeletonBodyCopy = styled(SkeletonBodyCopyComponent)`
  height: ${scale090};
  width: ${scale230};
`;
