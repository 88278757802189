import { useQuery } from '@apollo/client';
import type { FC } from 'react';

import {
  ModuleHeader,
  MetaData,
  useEditContext,
  usePageContext,
  WarningMessageBox as Error,
} from '@xing-com/crate-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-entity-pages-common/src/tracking';

import { DEFAULT_MEDIA_AMOUNT_FOR_ABOUTUS } from '../../config/media';
import { AboutUsDocument } from '../../graphql/queries/about-us-query.gql-types';

import { AboutUs } from './about-us';
import { AboutUsSkeleton } from './skeleton/skeleton';

export const AboutUsContainer: FC = () => {
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext();

  const { data, loading, refetch } = useQuery(AboutUsDocument, {
    variables: {
      id: pageContext.pageSlug,
      first: DEFAULT_MEDIA_AMOUNT_FOR_ABOUTUS,
      companyId: pageContext.companyId,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const aboutEntityPage =
    data?.pagesAboutUs?.__typename === 'AboutEntity' ? data.pagesAboutUs : null;

  const upsellActive = pageContext.upsellActive;

  const hasError = !loading && !aboutEntityPage?.summary;

  const hasSubpage = aboutEntityPage?.properties?.hasSubpage;

  const entityPageMediaEdges =
    aboutEntityPage?.media?.__typename === 'EntityMediaConnection'
      ? (aboutEntityPage?.media?.edges ?? [])
      : [];

  const entityPageMedia =
    entityPageMediaEdges?.map((edge) => edge?.node).filter((node) => !!node) ??
    [];

  const kununuBadges = aboutEntityPage?.companyData?.kununuData?.badges || [];

  const availableBadges = kununuBadges.filter(
    (badge) => badge?.image?.[0]?.url
  );

  const showMore =
    hasSubpage && !loading && !hasError
      ? {
          to: getTrackingUrl({
            to: `${pageContext.basePath}/${pageContext.pageSlug}/about_us`,
            trackingKey: 'learnMoreLink',
          }),
          copyKey: 'EP_ABOUT_US_DISCOVER_CTA',
          testId: 'moreAboutUsLink',
        }
      : null;

  return (
    <>
      <ModuleHeader
        headlineCopyKey="EP_ABOUT_US_HEADLINE"
        showMore={showMore}
      />

      {loading && <AboutUsSkeleton />}

      {hasError && (
        <div data-testid={'errorContainer'}>
          <Error
            headerText="EP_ERROR_HEADER"
            bodyText="EP_ERROR_BODY"
            buttonText="EP_ERROR_RELOAD_CTA"
            onClick={() => refetch()}
          />
        </div>
      )}

      {data && !loading && !hasError && (
        <>
          <MetaData description={aboutEntityPage?.summary} />
          <AboutUs
            pageSlug={pageContext.pageSlug}
            upsellActive={upsellActive}
            // @ts-expect-error TODO: fix this type
            headline={aboutEntityPage?.headline}
            // @ts-expect-error TODO: fix this type
            summary={aboutEntityPage?.summary}
            // @ts-expect-error TODO: fix this type
            galleryItems={entityPageMedia}
            // @ts-expect-error TODO: fix this type
            badges={availableBadges}
            hasSubpage={!!hasSubpage}
            isEditing={!!isEditing}
          />
        </>
      )}
    </>
  );
};

export default AboutUsContainer;
