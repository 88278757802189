import type { FC } from 'react';
import styled from 'styled-components';

import { Preview } from '@xing-com/link-preview';
import { scale150, scale380 } from '@xing-com/tokens';

import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxFormContext } from '../../hooks/use-commbox-form-context';
import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';

export const CommboxLinkPreview: FC = () => {
  const { setAttachmentType } = useCommboxContext();
  const {
    imagePreviewSrc: imageUrl,
    linkShareUrl: url,

    setImagePreviewSrc,
    setLinkShareUrl,
    setIsPreviewCompleted,
  } = useCommboxFormContext();
  const { isEditingPosting } = useCommboxPermissions();

  const handleOnRemove = () => {
    setAttachmentType('EMPTY');
    setLinkShareUrl('');
    setImagePreviewSrc('');
  };
  const handleOnComplete = () => {
    setIsPreviewCompleted(true);
  };

  return (
    <StyledLinkShareSection>
      <Preview
        imageUrl={imageUrl}
        url={url || ''}
        imageMaxHeight={scale380}
        onCompleted={({ viewer }) => {
          if (!viewer?.linkPreview?.success) handleOnRemove();
          handleOnComplete();
        }}
        onError={handleOnRemove}
        {...(!isEditingPosting && { onClose: handleOnRemove })}
      />
    </StyledLinkShareSection>
  );
};

const StyledLinkShareSection = styled.section`
  min-height: ${scale150};
  position: relative;
`;
