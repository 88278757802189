import type React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { useUpdateEffect } from '@xing-com/crate-entity-pages-common';
import { Dialog } from '@xing-com/dialog';
import { IconTrash } from '@xing-com/icons';

import { useRemoveArticleMutation } from '../../../../hooks/use-remove-article-mutation';
import * as Styled from '../article-card-top.styles';

import type { ArticleControlButton } from './types';

export const ArticleRemoveButton: React.FC<ArticleControlButton> = ({
  article,
  disabled,
  onLoadingChange,
  onCompleted,
  onError,
}) => {
  const intl = useIntl();

  const [show, setShow] = useState(false);

  const { handleRemoveArticle, loading } = useRemoveArticleMutation({
    onCompleted,
    onError,
  });

  useUpdateEffect(() => {
    onLoadingChange(loading);
  }, [loading]);

  const handleClick = () => {
    setShow(true);
  };

  function onCancelDialog() {
    setShow(false);
  }

  function onConfirmDialog() {
    handleRemoveArticle({ article });
    setShow(false);
  }

  return (
    <>
      <Styled.Button
        data-testid={`ARTICLE-REMOVE-BUTTON-${article.id}`}
        icon={IconTrash}
        size="small"
        variant="tertiary"
        disabled={disabled}
        loading={loading}
        onClick={handleClick}
      />
      <Dialog
        headline={intl.formatMessage({
          id: 'EP_ARTICLES_MODULE_DELETE_CONFIRMATION_DIALOG_TITLE',
        })}
        text={intl.formatMessage({
          id: 'EP_ARTICLES_MODULE_DELETE_CONFIRMATION_DIALOG_MESSAGE',
        })}
        cancelLabel={intl.formatMessage({
          id: 'EP_ARTICLES_MODULE_DELETE_CONFIRMATION_DIALOG_DISMISS_BUTTON',
        })}
        confirmLabel={intl.formatMessage({
          id: 'EP_ARTICLES_MODULE_DELETE_CONFIRMATION_DIALOG_CONFIRM_BUTTON',
        })}
        isDestructive={true}
        loading={loading}
        onCancel={onCancelDialog}
        onConfirm={onConfirmDialog}
        show={show}
      />
    </>
  );
};
