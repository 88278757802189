/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconShare"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4 21H2v-3.111c0-5.464 4.48-9.89 10-9.89V2.8l10.519 9.2L12 21.203V16c-3.682 0-8 2.776-8 5m8-7h2v2.795L19.481 12 14 7.205V10h-2c-4.16 0-7.575 3.13-7.963 7.127C6.019 15.3 9.14 14 12 14"
    />
  </svg>
);
export default IconShare;
