import { useQuery } from '@apollo/client';

import { EntityPageCoverImageDocument } from '../../graphql/queries/cover-image-query.gql-types';

import { NORMAL_DIMENSIONS } from './constants';

export const useCoverMedia = (pageSlug: string) => {
  const result = useQuery(EntityPageCoverImageDocument, {
    variables: { id: pageSlug, dimensions: NORMAL_DIMENSIONS },
    errorPolicy: 'all',
  });

  return result;
};

export default useCoverMedia;
