import { useNavigate } from '@reach/router';
import type { FC } from 'react';
import { FormattedPlural, FormattedMessage } from 'react-intl';

import { NetworkRelationshipType } from '@xing-com/crate-common-graphql-types';
import {
  capitalizeString,
  redirectToLogin,
} from '@xing-com/crate-entity-pages-common';
import {
  getTrackingUrl,
  trackEmployeesAddContactButtonAction,
  ENTITY_PAGE_SUB_EMPLOYEES,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import type { FlagSize } from '@xing-com/flag';
import { Flag } from '@xing-com/flag';
import { useViewerData } from '@xing-com/hub';
import { IconAddContact, IconMessages } from '@xing-com/icons';

import { BASIC_FLAG, EXECUTIVE } from '../../constants/employees-module';
import {
  addContactButtonDisabled,
  FOLLOW_EMPLOYEE_ACTION,
  FOLLOW_EMPLOYEE_ID_PARAM,
} from '../../helpers';
import { useNetworkRequestMutation } from '../../hooks/use-network-request-mutation';
import type { EmployeeCard } from '../../types/employee-card';

import { EmployeesCardHorizontalSkeleton } from './employees-card-horizontal.skeleton';
import * as Styled from './employees-card-horizontal.styles';

type EmployeesCardHorizontalProps = {
  card: EmployeeCard;
  trackingModuleType?: string;
  onAddContact?: () => void;
};
export const EmployeesCardHorizontal: FC<EmployeesCardHorizontalProps> & {
  Skeleton: typeof EmployeesCardHorizontalSkeleton;
} = ({ card, trackingModuleType, onAddContact = () => undefined }) => {
  const { isLoggedOut } = useLoginState();
  const { data: viewerData } = useViewerData();
  const currentUser = viewerData?.user;
  const navigate = useNavigate();

  const [executeNetworkRequest, { loading: mutationLoading }] =
    useNetworkRequestMutation({
      onCompleted: onAddContact,
    });

  const getFlag = (
    flag?: string
  ):
    | {
        size: FlagSize;
        variant: $TSFixMe;
        children: string | undefined;
      }
    | undefined => {
    if (!flag || flag === BASIC_FLAG || flag === EXECUTIVE) return;

    return {
      size: 'small',
      variant: flag.toLowerCase(),
      children: capitalizeString(flag),
    };
  };

  const handleOnClick = () => {
    if (isLoggedOut) {
      redirectToLogin(`${FOLLOW_EMPLOYEE_ACTION}`, {
        [FOLLOW_EMPLOYEE_ID_PARAM]: card.id,
      });
    } else if (card.relationship === NetworkRelationshipType.Contact) {
      navigate(
        `/chats/new/${card.pageName}?contextId=entity_pages&entryPoint=entity_pages_sub_employees_message`
      );
    } else {
      executeNetworkRequest(card.id);
      trackEmployeesAddContactButtonAction(ENTITY_PAGE_SUB_EMPLOYEES);
    }
  };

  const isCurrentUser = currentUser?.id === card.id;

  return (
    <Styled.Card data-testid={`EMPLOYEES-CARD-HORIZONTAL-${card.id}`}>
      <Styled.EmployeesCard
        alignment="horizontal"
        profileUrl={getTrackingUrl({
          to: card.profileUrl,
          trackingKey: `viewEmployeesProfile${trackingModuleType}`,
        })}
        profileImage={{
          size: 'large',
          src: card.profileImage.src,
          type: card.profileImage.type as any,
          profileName: card.profileImage.profileName,
          connectionDegree: !isLoggedOut
            ? card.profileImage?.connectionDegree
            : 0,
        }}
        headline={{
          size: 'medium',
          noMargin: true,
          children: (
            <Styled.HeadlineFlagWrapper>
              <Styled.ClampedText>{card.displayName}</Styled.ClampedText>
              {getFlag(card.flag) && (
                <Flag
                  variant={getFlag(card.flag)?.variant}
                  size={getFlag(card.flag)?.size}
                  {...getFlag(card.flag)}
                />
              )}
            </Styled.HeadlineFlagWrapper>
          ),
        }}
        textBody={[
          {
            size: 'medium',
            children: (
              <Styled.ClampedText>{card.occupationTitle}</Styled.ClampedText>
            ),
          },
        ]}
        textMeta={
          !isLoggedOut && !isCurrentUser && card.sharedContacts !== 0
            ? [
                {
                  size: 'medium',
                  children: (
                    <FormattedPlural
                      value={card.sharedContacts}
                      one={
                        <FormattedMessage
                          id="EP_EMPLOYEE_CARD_SHARED_CONTACTS_WEB.one"
                          values={{ value: card.sharedContacts }}
                        />
                      }
                      other={
                        <FormattedMessage
                          id="EP_EMPLOYEE_CARD_SHARED_CONTACTS_WEB.other"
                          values={{ value: card.sharedContacts }}
                        />
                      }
                    />
                  ),
                },
              ]
            : []
        }
        buttons={
          isCurrentUser
            ? []
            : [
                {
                  size: 'medium',
                  variant: 'tertiary',
                  disabled:
                    card.relationship &&
                    addContactButtonDisabled(card.relationship),
                  as: Styled.AddContactButton,
                  loading: mutationLoading,
                  icon:
                    !isLoggedOut &&
                    card.relationship === NetworkRelationshipType.Contact
                      ? IconMessages
                      : IconAddContact,
                  onClick: handleOnClick,
                },
              ]
        }
      />
    </Styled.Card>
  );
};

EmployeesCardHorizontal.Skeleton = EmployeesCardHorizontalSkeleton;
