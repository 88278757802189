import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { type TextButtonProps } from '@xing-com/button';
import { SkeletonBody } from '@xing-com/skeleton';

import { useEditContext } from '../../hooks/use-edit-context/use-edit-context';
import { useMainPageLoadModule } from '../../hooks/use-main-page-load-module/use-main-page-load-module';
import { ExternalEditInfoBanner } from '../external-edit-info-banner/external-edit-info-banner';

import { ModuleHeaderShowMore } from './module-header-show-more';
import * as Styled from './module-header.styles';

export type ShowMoreTypes = TextButtonProps & {
  target?: string;
  copyKey?: string;
  number?: number;
  isLoading?: boolean;
  testId?: string;
  externalLink?: boolean;
};

type ModuleHeaderProps = {
  headlineCopyKey?: string;
  showMore?: ShowMoreTypes | null;
  editTestId?: string;
  CustomEditButton?: React.ReactNode;
  CustomButtons?: React.ReactNode[];
  externalEditInfo?: any;
  editTo?: string | null;
  children?: React.ReactNode;
  small?: boolean;
  className?: string;
};
export const ModuleHeader: FC<ModuleHeaderProps> = ({
  headlineCopyKey,
  showMore,
  children,
  small,
  editTo,
  editTestId,
  externalEditInfo,
  CustomEditButton,
  CustomButtons,
  className,
}) => {
  useMainPageLoadModule();
  const { isEditing } = useEditContext();
  const showCustomEditButton = isEditing && Boolean(CustomEditButton);
  const showCustomButtons = CustomButtons && CustomButtons.length > 0;
  const showMultipleCustomButtons = CustomButtons && CustomButtons.length > 1;

  return (
    <>
      <Styled.Header
        $customButtons={showMultipleCustomButtons}
        $smallHeader={small}
        className={className}
      >
        <Styled.HeaderHeadlineContainer>
          <Styled.Headline
            $headLineSmall={small}
            $headlineWithCustomBtns={showMultipleCustomButtons}
            size="xxxlarge"
            noMargin
            data-testid="module-header-title"
          >
            <FormattedMessage
              id={headlineCopyKey}
              defaultMessage={headlineCopyKey}
            />
          </Styled.Headline>
          {isEditing && editTo && (
            <Styled.EditButton
              size="small"
              showIcon={true}
              data-cy={editTestId}
              data-testid={editTestId}
              to={editTo}
            />
          )}
        </Styled.HeaderHeadlineContainer>
        {showMore?.isLoading && (
          <Styled.SkeletonWrapper>
            <SkeletonBody amountRows={1} />
          </Styled.SkeletonWrapper>
        )}
        <Styled.ButtonWrapper $customButtons={showMultipleCustomButtons}>
          {(showCustomEditButton || showCustomButtons) && (
            <Styled.CustomButtonsWrapper>
              {showCustomEditButton && CustomEditButton && (
                <Styled.CustomButton>{CustomEditButton}</Styled.CustomButton>
              )}
              {CustomButtons?.map((button, i) => (
                <Styled.CustomButton key={i}>{button}</Styled.CustomButton>
              ))}
            </Styled.CustomButtonsWrapper>
          )}
          {!showMore?.isLoading && showMore?.copyKey && (
            <ModuleHeaderShowMore
              showMore={showMore}
              hasMultipleCustomButtons={showMultipleCustomButtons}
            />
          )}
        </Styled.ButtonWrapper>
        {children && <Styled.ShowMore>{children}</Styled.ShowMore>}
      </Styled.Header>
      {externalEditInfo && <ExternalEditInfoBanner {...externalEditInfo} />}
    </>
  );
};
