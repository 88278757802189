import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Portal } from '@xing-com/portal';

import { SideSheetPrimitive } from './side-sheet.primitive';
import * as Styled from './side-sheet.styles';
import type { SideSheetProps } from './side-sheet.types';

const {
  sideSheetDurationEnter,
  sideSheetTransitionName,
  dimmerDurationEnter,
  dimmerTransitionName,
} = Styled;

export const SideSheet = React.forwardRef<HTMLDivElement, SideSheetProps>(
  (
    {
      children,
      className,
      dimmerTitle,
      keepSideSheetInDom,
      onOutsideClick,
      onSwipeLeft,
      shouldDisableBodyScroll = true,
      show = false,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const disableScrolling = (): void => {
      document.body.style.overflow = 'hidden';
    };

    const enableScrolling = (): void => {
      document.body.style.overflow = 'visible';
    };

    const closeModal = (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      enableScrolling();
      if (onOutsideClick) onOutsideClick(event);
    };

    React.useEffect(() => {
      if (!shouldDisableBodyScroll) {
        return;
      }

      if (show) {
        disableScrolling();
      }

      return () => {
        enableScrolling();
      };
    }, [show, shouldDisableBodyScroll]);

    const sideSheetTransition = (
      <CSSTransition
        appear={show}
        classNames={sideSheetTransitionName}
        in={show}
        key="sideSheet"
        mountOnEnter={!keepSideSheetInDom}
        timeout={parseInt(sideSheetDurationEnter)}
        unmountOnExit={!keepSideSheetInDom}
      >
        <SideSheetPrimitive
          className={className}
          data-show={show}
          onSwipeLeft={onSwipeLeft}
          ref={forwardedRef}
          {...props}
        >
          {children}
        </SideSheetPrimitive>
      </CSSTransition>
    );

    const dimmerTransition = (
      <CSSTransition
        appear={show}
        classNames={dimmerTransitionName}
        in={show}
        key="dimmer"
        mountOnEnter
        onEnter={() => {
          if (shouldDisableBodyScroll) {
            disableScrolling();
          }
        }}
        onExited={() => {
          if (shouldDisableBodyScroll) {
            enableScrolling();
          }
        }}
        timeout={parseInt(dimmerDurationEnter)}
        unmountOnExit
      >
        <Styled.Dimmer
          $isNotClickable={!onOutsideClick}
          data-qa="side-sheet-dimmer"
          data-testid="side-sheet-dimmer"
          onClick={closeModal}
          title={dimmerTitle}
        />
      </CSSTransition>
    );

    return (
      <React.Fragment>
        <Portal>{sideSheetTransition}</Portal>
        {dimmerTransition}
      </React.Fragment>
    );
  }
);

SideSheet.displayName = 'SideSheet';
