import throttle from 'lodash/throttle';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import type { ButtonProps } from '@xing-com/button';
import { IconArrowLeft } from '@xing-com/icons';
import { IconWithDiscoverBadge } from '@xing-com/layout-icon-with-discover-badge';

import { SearchDropdown } from './old/search-dropdown';
import type { TopBarType } from './top-bar-types';
import {
  H2,
  H3,
  Placebo,
  PrimaryActions,
  SecondaryActions,
  SubHeaderButton,
  SubHeaderContainer,
  SubHeaderLink,
  TopBarContainer,
} from './top-bar.styles';

export type TopBarProps = {
  primaryActions?: React.ReactNode[];
  secondaryActions?: React.ReactNode[];
  /** @deprecated use skipAdditionalButtons instead */
  skipSearchAndMessenger?: boolean;
  skipAdditionalButtons?: boolean;
  skipBackButton?: boolean;
  showOnLargerScreens?: boolean;
  titleSectionOnClick?: (e: React.MouseEvent) => void;
  titleSectionUrl?: string;
  backButtonOnClick?: (e: React.MouseEvent) => void;
  backButtonUrl?: string;
  /** section name of custom link (e.g.: you) */
  backButtonUrlAriaLabelSection?: string;
  subTitle?: string;
  title?: string;
  type?: TopBarType;
};

type BackButtonType = Pick<
  ButtonProps,
  'aria-label' | 'to' | 'onClick' | 'size' | 'variant' | 'icon'
> & {
  size: 'medium';
  icon: typeof IconArrowLeft;
};

export const TopBar: React.VFC<TopBarProps> = ({
  primaryActions = [],
  secondaryActions = [],
  skipSearchAndMessenger,
  skipAdditionalButtons,
  skipBackButton,
  showOnLargerScreens = false,
  titleSectionOnClick,
  backButtonOnClick,
  titleSectionUrl,
  backButtonUrl,
  backButtonUrlAriaLabelSection,
  subTitle,
  title,
  type,
  ...props
}) => {
  const intl = useIntl();

  // Those 2 Copy variables needed to be created to prevent re-rendering of elements in props on every scroll. Checkout UseEffect.
  const primaryActionsCopy = [...primaryActions];
  const secondaryActionsCopy = [...secondaryActions];

  // Search Button Section
  const Search = (
    <SearchDropdown
      key="top-bar-search"
      icon={IconWithDiscoverBadge}
      size="medium"
    />
  );

  // considering the deprecated prop
  skipAdditionalButtons = skipAdditionalButtons || skipSearchAndMessenger;

  if (!skipAdditionalButtons) {
    primaryActionsCopy.push(Search);
  }

  // Title Section
  let titleSection: React.ReactNode;
  const _getDefaultTitleSection = (): React.ReactElement => {
    const subHeaderContent = (
      <React.Fragment>
        <H2 size="large">{title}</H2>
        {subTitle && <H3 size="small">{subTitle}</H3>}
      </React.Fragment>
    );
    if (titleSectionUrl) {
      return (
        <SubHeaderLink to={titleSectionUrl}>{subHeaderContent}</SubHeaderLink>
      );
    } else if (titleSectionOnClick) {
      return (
        <SubHeaderButton onClick={titleSectionOnClick}>
          {subHeaderContent}
        </SubHeaderButton>
      );
    }
    return <SubHeaderContainer>{subHeaderContent}</SubHeaderContainer>;
  };

  // Back Button Section
  let BackButton: React.ReactNode;

  if (!skipBackButton) {
    const backButtonProps: BackButtonType = {
      'aria-label': intl.formatMessage({
        id: 'TOP_BAR_BACK_BUTTON_ARIA_LABEL',
      }),
      icon: IconArrowLeft,
      size: 'medium',
      onClick: backButtonOnClick,
    };

    if (backButtonUrl) {
      if (backButtonUrlAriaLabelSection) {
        backButtonProps['aria-label'] = intl.formatMessage(
          {
            id: 'TOP_BAR_BACK_BUTTON_ARIA_LABEL_CUSTOM',
          },
          { section: backButtonUrlAriaLabelSection }
        );
      }
      backButtonProps.to = backButtonUrl;
    } else {
      backButtonProps.onClick = (e) => {
        if (backButtonOnClick) backButtonOnClick(e);
        if (global?.window?.history?.length > 1) {
          global.window.history.back();
          e.preventDefault();
        }
      };
      backButtonProps.to = '/';
    }

    BackButton = (
      <Button {...backButtonProps} key="top-bar-back" data-qa="top-bar-back" />
    );
    secondaryActionsCopy.push(BackButton);
    titleSection = _getDefaultTitleSection();
  } else {
    titleSection = _getDefaultTitleSection();
  }
  const [scrollTopPosition, setScrollTopPosition] = React.useState(true);

  React.useEffect(() => {
    const setLineOnTop = throttle(
      () => setScrollTopPosition(window.pageYOffset === 0),
      250
    ); // 250ms

    window.addEventListener('scroll', setLineOnTop);
    return () => {
      window.removeEventListener('scroll', setLineOnTop);
    };
  }, []);

  return (
    <React.Fragment>
      <TopBarContainer
        data-qa="top-bar-container"
        $showOnLargerScreens={showOnLargerScreens}
        $isScrolling={scrollTopPosition}
        {...props}
      >
        <SecondaryActions $type={type}>{secondaryActionsCopy}</SecondaryActions>
        {titleSection}
        <PrimaryActions data-testid="top-bar-primary-actions" $type={type}>
          {primaryActionsCopy}
        </PrimaryActions>
      </TopBarContainer>
      <Placebo $showOnLargerScreens={showOnLargerScreens} />
    </React.Fragment>
  );
};
