import type { FC } from 'react';

import { usePageContext } from '@xing-com/crate-entity-pages-common';

import { EntityInfoCompany } from './entity-info-company';
import { EntityInfoPublisherPage } from './entity-info-publisher-page';
import { EntityInfoTopicPage } from './entity-info-topic-page';

export const EntityInfoContainer: FC = () => {
  const { pageContext } = usePageContext();

  const headerContent = pageContext.headerModule?.content;

  if (headerContent?.__typename === 'EntityPageTopicPageHeaderContent') {
    return <EntityInfoTopicPage />;
  }
  if (headerContent?.__typename === 'EntityPageCompanyHeaderContent') {
    return <EntityInfoCompany />;
  }
  if (headerContent?.__typename === 'EntityPagePublisherHeaderContent') {
    return <EntityInfoPublisherPage />;
  }
  return null;
};
