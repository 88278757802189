import { useMutation, useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import { Formik } from 'formik';
import type { FC } from 'react';
import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import type { CompanyAboutUsFactsType } from '@xing-com/crate-entity-pages-common';
import {
  PagesField,
  PagesFormActions,
  usePageContext,
  useDialogContext,
  PagesFormSkeleton,
  SmallGrid,
  EditHeader,
  useErrorContext,
  WarningMessageBox as Error,
} from '@xing-com/crate-entity-pages-common';

import { EntityPageUpdateAboutContentDocument } from '../../../graphql/mutation/entity-page-update-about-content-mutation.gql-types';
import { UpdateCompanyDocument } from '../../../graphql/mutation/update-company.gql-types';
import type { CompanyBasicInfoQueryFileUploadQuery } from '../../../graphql/queries/company-basic-info-query.gql-types';
import { CompanyBasicInfoQueryFileUploadDocument } from '../../../graphql/queries/company-basic-info-query.gql-types';

import * as Styled from './facts-edit.styles';
import validate from './validation';

type FormikContentProps = {
  dirty: boolean;
  setDataChanged: (changed: boolean) => void;
  setValues: (values: any) => void;
  values: any;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  industryData: { industryId: string; segmentId: string };
  data?: CompanyBasicInfoQueryFileUploadQuery;
  noneSegmnet: { value: string; text: string };
  updateMetaLoading: boolean;
  updateCompanyLoading: boolean;
};
const FormikContent: FC<FormikContentProps> = ({
  dirty,
  setDataChanged,
  setValues,
  values,
  activeIndex,
  setActiveIndex,
  industryData,
  data,
  noneSegmnet,
  updateMetaLoading,
  updateCompanyLoading,
}) => {
  useEffect(() => {
    setDataChanged(dirty);
  }, [dirty]);

  useEffect(() => {
    setValues((prev: any) =>
      industryData?.industryId !== values.industry
        ? { ...prev, industrysegment: '' }
        : prev
    );
  }, [values.industry]);

  return (
    <>
      <EditHeader
        titleKey="EP_EDIT_COMPANY_FACTS_TITLE"
        contentSwitcher={{
          activeIndex,
          setActiveIndex,
          contentKeyList: [
            'EP_EDIT_ABOUT_DATA_TITLE',
            'EP_EDIT_INDUSTRY_FIELD_LABEL',
          ],
        }}
      />
      <SmallGrid inner>
        <Styled.ContentWrapper hidden={activeIndex !== 0}>
          <PagesField name="foundingYear" type="number" />
          <PagesField name="websiteUrl" type="url" />
          <PagesField
            name="companySizeId"
            optionList={data?.companyEmployeesNumbers?.map((item) => ({
              value: item?.id,
              text: item?.localizationValue,
            }))}
          />
          <PagesField name="imprint" maxAllowedChars={1000} />
        </Styled.ContentWrapper>
        <Styled.ContentWrapper hidden={activeIndex !== 1}>
          <Styled.IndustryField
            name="industry"
            optionList={data?.industries?.map((item) => ({
              value: item.id,
              text: item.localizationValue,
            }))}
          />
          <Styled.IndustryField
            name="industrysegment"
            optionList={[
              ...(data?.industries
                ?.find((item) => item.id === values.industry)
                ?.segments?.map((item) => ({
                  value: item.id,
                  text: item.localizationValue,
                })) || []),
              noneSegmnet,
            ]}
          />
        </Styled.ContentWrapper>
      </SmallGrid>
      <PagesFormActions loading={updateMetaLoading || updateCompanyLoading} />
    </>
  );
};

type FactsEditCompanyProps = {
  facts: CompanyAboutUsFactsType;
};
const FactsEditCompany: FC<FactsEditCompanyProps> = ({ facts }) => {
  const type = new URLSearchParams(useLocation().search).get('type');

  const intl = useIntl();
  const savingValues = useRef<CompanyAboutUsFactsType>();
  const [metaMutationSuccess, setMetaMutationSuccess] = useState<boolean>();
  const [industryData, setIndustryData] = useState<{
    industryId: string;
    segmentId: string;
  }>();
  const [companyMutationSuccess, setCompanyMutationSuccess] =
    useState<boolean>();
  const [activeIndex, setActiveIndex] = useState<number>(
    type === 'industries' ? 1 : 0
  );
  const { setDataChanged } = useDialogContext();
  const { pageContext } = usePageContext();
  const { showError } = useErrorContext();

  const { data, loading, error, refetch } = useQuery(
    CompanyBasicInfoQueryFileUploadDocument,
    {
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    }
  );

  // update meta information
  const [updateMeta, { loading: updateMetaLoading }] = useMutation(
    EntityPageUpdateAboutContentDocument,
    {
      onCompleted: (data) => {
        if (data?.entityPageUpdateAboutContent?.error) {
          showError({
            message: 'EP_GENERAL_FEEDBACK_ERROR',
            error: data.entityPageUpdateAboutContent.error,
          });
          setMetaMutationSuccess(false);
        } else {
          setMetaMutationSuccess(true);
        }
      },
      onError: (error) => {
        showError({
          message: 'EP_GENERAL_FEEDBACK_ERROR',
          error,
        });
        setMetaMutationSuccess(false);
      },
    }
  );

  // update company size
  const [updateCompany, { loading: updateCompanyLoading }] = useMutation(
    UpdateCompanyDocument,
    {
      onCompleted: (data) => {
        if (data?.companyUpdateInformation?.error) {
          showError({
            message: 'EP_GENERAL_FEEDBACK_ERROR',
            // @ts-expect-error TODO: fix this type
            error: data.companyUpdateInformation.error,
          });
          setCompanyMutationSuccess(false);
        } else {
          setCompanyMutationSuccess(true);
        }
      },
      onError: (error) => {
        showError({
          message: 'EP_GENERAL_FEEDBACK_ERROR',
          error,
        });
        setCompanyMutationSuccess(false);
      },
    }
  );

  useEffect(() => {
    if (
      metaMutationSuccess &&
      companyMutationSuccess &&
      pageContext.goBackUrl
    ) {
      pageContext.goBackUrl();
    }
  }, [metaMutationSuccess, companyMutationSuccess]);

  useEffect(() => {
    if (data?.industries && !industryData) {
      setIndustryData({
        industryId: '',
        segmentId: '',
      });
    }

    data?.industries?.map(({ id, segments }) => {
      if (id === facts.companyData?.industry?.id) {
        setIndustryData({
          industryId: id,
          segmentId: '',
        });
      }
      segments?.map(({ id: segId }) => {
        if (segId === facts.companyData?.industry?.id) {
          setIndustryData({
            industryId: id,
            segmentId: segId,
          });
        }
      });
    });
  }, [data]);

  const handleSubmit = (values: any) => {
    savingValues.current = values;

    updateMeta({
      variables: {
        pageId: pageContext.pageId,
        foundingYear: values.foundingYear
          ? parseInt(values.foundingYear)
          : null,
        imprint:
          values.imprint.trim().length > 0 ? values.imprint.trim() : null,
        websiteUrl: values.websiteUrl.length > 0 ? values.websiteUrl : null,
      },
    });
    updateCompany({
      variables: {
        // @ts-expect-error TODO: fix this type
        companyId: pageContext.companyId,
        sizeId: values.companySizeId,
        industryId: values.industrysegment || values.industry,
      },
    });
  };

  const noneSegmnet = {
    value: '',
    text: intl.formatMessage({
      id: `EP_SELECT_NONE`,
    }),
  };

  if (loading || typeof industryData?.industryId === 'undefined') {
    return (
      <>
        <EditHeader
          titleKey="EP_EDIT_COMPANY_FACTS_TITLE"
          contentSwitcher={{
            activeIndex,
            setActiveIndex,
            contentKeyList: [
              'EP_EDIT_ABOUT_DATA_TITLE',
              'EP_EDIT_INDUSTRY_FIELD_LABEL',
            ],
          }}
        />
        <PagesFormSkeleton />
      </>
    );
  }

  if (error) {
    return (
      <Error
        headerText="EP_ERROR_HEADER"
        bodyText="EP_ERROR_BODY"
        buttonText="EP_ERROR_RELOAD_CTA"
        onClick={() => refetch()}
      />
    );
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      <Formik
        initialValues={{
          foundingYear: facts.foundingYear || '',
          websiteUrl: facts.websiteUrl || '',
          companySizeId: facts.companyData.companySizeId || '',
          imprint: facts.imprint || '',
          industry: industryData?.industryId,
          industrysegment: industryData?.segmentId,
        }}
        onSubmit={handleSubmit}
        validate={(values) => validate(values, intl)}
      >
        {(props) => (
          <FormikContent
            dirty={props.dirty}
            setDataChanged={setDataChanged}
            setValues={props.setValues}
            values={props.values}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            industryData={industryData}
            data={data}
            noneSegmnet={noneSegmnet}
            updateMetaLoading={updateMetaLoading}
            updateCompanyLoading={updateCompanyLoading}
          />
        )}
      </Formik>
    </div>
  );
};

export default FactsEditCompany;
