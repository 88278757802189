import type { AppcuesCompanyData } from '../types/appcues';

const APPCUES_ID = '100199';
const APPCUES_LINK = `//fast.appcues.com/${APPCUES_ID}.js`;
const APPUES_LINK_REGEXP = new RegExp(
  // eslint-disable-next-line no-useless-escape
  `http(s?):\/\/fast\.appcues\.com\/${APPCUES_ID}\.js`
);

export const isLoaded = (): boolean => !!window.Appcues;

export const resetResources = (): void => {
  if (window.Appcues) window.Appcues.reset();
};

// Configure appcues based on wiki https://docs.appcues.com/article/464-installation-overview-developer-detail
export const loadResources = (id: string, data: AppcuesCompanyData): void => {
  if (window.Appcues) window.Appcues.identify(id, data);
};

// Create the element with the required info based on wiki https://docs.appcues.com/article/464-installation-overview-developer-detail
export const createElement = (
  id: string,
  data: AppcuesCompanyData
): HTMLScriptElement => {
  const element = document.createElement('script');
  element.src = APPCUES_LINK;
  element.async = true;
  element.onload = () => {
    loadResources(id, data);
  };

  // Load element
  document.body.appendChild(element);

  return element;
};
// Test if the element exist on document, check if is the same as creates
export const getElement = (): HTMLScriptElement | null => {
  const elements = document.getElementsByTagName('script');
  for (let i = elements.length; i--; ) {
    if (APPUES_LINK_REGEXP.test(elements[i].src)) return elements[i];
  }
  return null;
};

export const deleteElement = () => {
  const appcuesElement = getElement();
  if (appcuesElement?.remove) appcuesElement.remove();
};
