export const URL_COMPLAINTS_API_REPORT = '/complaints/report';
export const URL_COMPLAINTS_API_REPORT_NEW = '/complaints/report/new';
export const URL_COMPLAINTS_API_REPORTS = '/complaints/api/reports';
export const URL_SOCIAL_BAR = '/pages/social/shares';
export const MARKETING_URL_EN =
  'https://recruiting.xing.com/en/products/xing-360';
export const MARKETING_URL_DE =
  'https://recruiting.xing.com/de/products/xing-360';
export const PAGES_NEWS_PATH = (pageSlug: string) => `/pages/${pageSlug}/news`;
export const PAGES_PATH = (pageSlug: string) => `/pages/${pageSlug}`;
export const PROMOTE_URL = (globalId: string) =>
  `/xam/aac/?entity_urn=${globalId}&asset_urn=${globalId}`;
export const XBM_STATISTICS_URL = (pageSlug: string) =>
  `/brandmanager${pageSlug ? `/${pageSlug}/page-reach` : ''}`;
export const XBM_URL = (pageSlug: string) =>
  `/brandmanager${pageSlug ? `/${pageSlug}/admin` : ''}`;
export const PAGE_INFO_HELP_URL = () => 'https://community.xing.com/de/s/';
