import type { FC } from 'react';
import { useState, useLayoutEffect } from 'react';
import { ReactSortable } from 'react-sortablejs';

import {
  useDialogContext,
  getImageGender,
} from '@xing-com/crate-entity-pages-common';
import { ProfileImage } from '@xing-com/profile-image';

import { useContactsEditContext } from '../../hooks/use-contacts-edit-context/use-contacts-edit-context';
import { ContactsEditCard } from '../contacts-edit-card/contacts-edit-card';

import * as Styled from './contacts-edit-list-cards.style';

export const ContactsEditListCards: FC = () => {
  const { setDataChanged } = useDialogContext();
  const { contactDataList, setContactsTouched, setContactUsersList } =
    useContactsEditContext();
  const [itemChoosed, setItemChoosed] = useState(false);

  useLayoutEffect(() => {
    setItemChoosed(!itemChoosed);
  }, []);

  return (
    <>
      {/** *** Hack in order to display svgs on ProfileInfo images on re-order components*****/}
      <Styled.LoadableSVGs>
        {contactDataList?.map(({ contact }) => (
          <ProfileImage
            key={contact.xingId?.id}
            size="xxsmall"
            src={contact.xingId?.profileImage?.[0]?.url}
            type={getImageGender(contact.xingId?.gender)}
            profileName={'user profile image'}
          />
        ))}
      </Styled.LoadableSVGs>
      {/** *** Hack in order to display svgs on ProfileInfo images on re-order components*****/}
      <Styled.ListContainer data-testid="CONTACTS-EDIT-LIST-CARDS">
        <Styled.SortableWrapper>
          <ReactSortable
            // @ts-expect-error react-sortable list
            list={contactDataList}
            setList={setContactUsersList}
            handle={"[name*='dragButton']"}
            animation={200}
            ghostClass="ghost"
            forceFallback={true}
            fallbackClass="sortableFallback"
            onUpdate={() => {
              setDataChanged(true);
              setContactsTouched(true);
            }}
            onChoose={() => {
              document.body.style.cursor = 'grabbing';
              setItemChoosed(true);
            }}
            onUnchoose={() => {
              document.body.style.cursor = 'default';
              setItemChoosed(false);
            }}
          >
            {contactDataList?.map((contactData, index) => (
              <ContactsEditCard
                key={`CONTACT-EDIT-CARD-${contactData.contact.id}-${index}`}
                itemChoosed={itemChoosed}
                contactData={contactData}
              />
            ))}
          </ReactSortable>
        </Styled.SortableWrapper>
      </Styled.ListContainer>
    </>
  );
};
