import { useQuery } from '@apollo/client';
import { useMemo, useState, type FC } from 'react';

import {
  ModuleHeader,
  useEditContext,
  usePageContext,
  WarningMessageBox,
} from '@xing-com/crate-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-entity-pages-common/src/tracking';

import { JobsModuleCompanyJobsDocument } from '../../graphql/queries/jobs-module-company-jobs.gql-types';
import { createJobsCards } from '../../models/jobs-card';
import { JobsCardsCarousel } from '../jobs-cards-carousel/jobs-cards-carousel';
import { JobsEmptyState } from '../jobs-empty-state/jobs-empty-state';
import { JobsSkeleton } from '../jobs-skeleton/jobs-skeleton';

const MINIMUM_JOBS_FOR_DETAIL_LINK = 4;

export const SectionCompanyJobs: FC = () => {
  const { pageContext } = usePageContext();
  const companyId = pageContext.companyId;
  const pageSlug = pageContext.pageSlug;
  const { isEditing } = useEditContext();

  const [firstLoad, setFirstLoad] = useState(true);

  const { data, loading, error, refetch } = useQuery(
    JobsModuleCompanyJobsDocument,
    {
      variables: {
        jobsLimit: 12,
        sort: 'date/desc',
        returnAggregations: false,
        jobsQuery: {
          filter: {
            company: { id: [companyId as string] },
            publishToCompany: null,
          },
        },
      },
      errorPolicy: 'ignore',
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        setFirstLoad(false);
      },
    }
  );

  const totalJobs = data?.jobSearchByQuery?.total ?? 0;

  const pageJobsLink = `${pageContext.basePath}/${pageSlug}/jobs`;

  const handleShowMoreClick = () => {
    const trakingLink = getTrackingUrl({
      to: pageJobsLink,
      trackingKey: 'trackingKey',
    });
    window.open(trakingLink);
  };

  const jobsCards = useMemo(() => {
    const similarJobs = data?.jobSearchByQuery?.collection ?? [];

    // @ts-expect-error query generated as null
    return createJobsCards(similarJobs);
  }, [data]);

  return (
    <>
      <ModuleHeader
        headlineCopyKey="EP_JOBS_HEADLINE"
        showMore={{
          loading: loading,
          ...(totalJobs > MINIMUM_JOBS_FOR_DETAIL_LINK
            ? {
                'data-testid': 'JOBS_SHOWMORE_LINK',
                to: pageJobsLink,
                onClick: (ev) => {
                  ev.preventDefault();
                  handleShowMoreClick();
                },
                copyKey: 'EP_JOBS_LINK',
                number: totalJobs,
              }
            : {}),
        }}
        externalEditInfo={
          !loading && isEditing
            ? {
                copyKey: 'EP_JOBS_EDIT_HINT',
                cyTestId: 'EDIT_HINT_JOBS',
              }
            : null
        }
      />
      {firstLoad && <JobsSkeleton />}
      {totalJobs > 0 && (
        <div data-testid="JOBS_MODULE">
          <JobsCardsCarousel
            cards={jobsCards}
            onCardBookmarkUpdate={() => refetch()}
          />
        </div>
      )}
      {error && (
        <div data-testid="errorContainer">
          <WarningMessageBox
            headerText="EP_ERROR_HEADER"
            bodyText="EP_ERROR_BODY"
            buttonText="EP_ERROR_RELOAD_CTA"
            onClick={() => refetch()}
          />
        </div>
      )}
      {totalJobs === 0 && (
        <div data-testid="JOBS_MODULE">
          <JobsEmptyState />
        </div>
      )}
    </>
  );
};
