import styled, { css } from 'styled-components';

import { TextButton } from '@xing-com/button';
import {
  scale030,
  scale190,
  scale410,
  spaceL,
  spaceXL,
} from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

// *** Base ***
const baseLayout = css`
  margin: ${scale190} auto;
  max-width: calc(${scale410} + ${scale030});
`;

const baseHeadline = css`
  margin-bottom: ${spaceXL};
  text-align: center;
`;

const baseButtonBar = css`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spaceXL};
`;

const baseTextButton = css`
  margin-right: ${spaceL};
`;

// *** Components ***
const Layout = styled.div`
  ${baseLayout}
`;

const StyledHeadline = styled(Headline)`
  ${baseHeadline}
`;

const ButtonBar = styled.div`
  ${baseButtonBar}
`;

const StyledTextButton = styled(TextButton)`
  ${baseTextButton}
`;

export const Styled = {
  Layout,
  Headline: StyledHeadline,
  ButtonBar,
  TextButton: StyledTextButton,
};
