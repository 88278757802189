import { useEffect, useState, type FC } from 'react';

import { Carousel } from '@xing-com/carousel';
import { trackPageEmployeesChange } from '@xing-com/crate-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import { spaceXL } from '@xing-com/tokens';

import {
  NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_IN,
  NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT,
} from '../../constants/employees-module';
import { generateDots } from '../../helpers/employees-carousel';
import type { EmployeeCard } from '../../types/employee-card';
import { EmployeeCardFencing } from '../employees-card-vertical/employees-card-fencing';
import { EmployeesCardVertical } from '../employees-card-vertical/employees-card-vertical';

import { EmployeesCarouselSkeleton } from './employees-carousel.skeleton';
import * as Styled from './employees-carousel.styles';

type EmployeesCarouselProps = {
  employeesCards: EmployeeCard[];
  totalEmployees: number;
  onAddContact?: () => void;
};
export const EmployeesCarousel: FC<EmployeesCarouselProps> & {
  Skeleton: typeof EmployeesCarouselSkeleton;
} = ({ employeesCards, totalEmployees, onAddContact = () => undefined }) => {
  const [selected, setSelected] = useState(0);
  const [numberOfItemsPerChunk, setNumberOfItemsPerChunk] = useState(5);

  const { isLoggedIn } = useLoginState();

  const itemsToSlice = isLoggedIn
    ? NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_IN
    : NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT;
  const employeeCardSliced = employeesCards.slice(0, itemsToSlice);

  const showFencing =
    !isLoggedIn && totalEmployees > NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT;

  const dots = generateDots(employeeCardSliced, numberOfItemsPerChunk);

  useEffect(() => {
    window.matchMedia(mediaConfined).addEventListener('change', (ev) => {
      if (ev.matches) {
        setNumberOfItemsPerChunk(2);
      } else {
        setNumberOfItemsPerChunk(2);
      }
    });
    window.matchMedia(mediaWide).addEventListener('change', (ev) => {
      if (ev.matches) {
        setNumberOfItemsPerChunk(3);
      }
    });
  }, []);

  return (
    <Styled.CarouselWrapper data-testid="EMPLOYEES-CAROUSEL">
      <Carousel
        itemWidth="300px"
        itemWidthConfined="33%"
        itemWidthWide="20%"
        paddingBottom={spaceXL}
        onItemEnter={({ direction, itemIndex }) => {
          setSelected(parseInt((itemIndex / numberOfItemsPerChunk).toString()));
          if (direction) {
            trackPageEmployeesChange({ direction, page: itemIndex });
          }
        }}
      >
        {employeeCardSliced.map((employeeCard) => (
          <EmployeesCardVertical
            key={employeeCard.id}
            card={employeeCard}
            onAddContact={onAddContact}
          />
        ))}
        {showFencing && (
          <EmployeeCardFencing employeesNumber={totalEmployees} />
        )}
      </Carousel>
      {dots > 0 && <Styled.PaginationDots length={dots} selected={selected} />}
    </Styled.CarouselWrapper>
  );
};

EmployeesCarousel.Skeleton = EmployeesCarouselSkeleton;
