import { MULTI_IMAGE_POST_MAX_IMAGES } from '../constants';
import type { CommboxErrorMessageType } from '../types';

import { useCommboxContext } from './use-commbox';
import { useCommboxFormContext } from './use-commbox-form-context';
import { useUploadFile } from './use-upload-file';

export const useOnInputFiles = () => {
  const {
    attachmentType,
    crateImageUploader,
    setAttachmentType,
    setShouldOpenFilePicker,
  } = useCommboxContext();
  const {
    images,
    setErrorMessage,
    setImageIsUploading,
    setImagePreviewSrc,
    setImages,
    setInfoMessage,
    setUploadedFileId,
  } = useCommboxFormContext();

  const { uploadFile } = useUploadFile({ crateImageUploader, setErrorMessage });

  const onInputFiles = async (files: File[]) => {
    const errors: CommboxErrorMessageType[] = [];
    const uploadedImages: any[] = [];
    setImageIsUploading(true);

    // Processing the files
    for (const file of files) {
      // Max Image reached break
      if (
        images.length + uploadedImages.length ===
        MULTI_IMAGE_POST_MAX_IMAGES
      ) {
        setInfoMessage('COMMBOX_UPLOADED_IMAGES_LIMIT_REACHED');
        break;
      }

      // Push to the uploadedImages array
      const { fileId, error } = await uploadFile(file);

      if (error) {
        errors.push(error as CommboxErrorMessageType);
      } else {
        uploadedImages.push({ file, fileId });
      }
    }

    // After proccessing the files, when 1 or more files succeded
    if (uploadedImages.length) {
      const { uploadedFileId } = uploadedImages[0];

      for (const [index, image] of uploadedImages.entries()) {
        // Generates image preview when a file is uploaded and added to the state
        const simpleFileReader = new FileReader();
        simpleFileReader.onload = (e) => {
          const fileReadResult = (e?.target?.result || '') as string;
          // for editing image in link preview
          if (attachmentType === 'LINK_PREVIEW') {
            setImagePreviewSrc(fileReadResult);
          } else {
            setImages((prevImages) => {
              return [
                ...prevImages,
                {
                  url: fileReadResult,
                  type: uploadedImages[index].file.type,
                  uploadedFileId: uploadedImages[index].fileId,
                },
              ];
            });
          }
        };
        simpleFileReader.onerror = () => setErrorMessage('FILE_UPLOAD_ERROR');
        simpleFileReader.readAsDataURL(image.file);
      }
      setUploadedFileId(uploadedFileId);

      // Valid when not uploading a file for an editable link preview
      if (attachmentType === 'EMPTY') setAttachmentType('IMAGE');
    }

    // After proccessing the files, when there is an error
    if (errors.length) {
      setErrorMessage(errors.length > 1 ? 'GENERIC_ERROR' : errors[0]);
      setUploadedFileId(null);
      setImagePreviewSrc('');
    }

    setImageIsUploading(false);
    setShouldOpenFilePicker(false);
  };

  return { onInputFiles };
};
