import styled, { css } from 'styled-components';

import { mediaSmallOnly, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  scale005,
  scale070,
  scale090,
  scale100,
  scale110,
  scale120,
  scale130,
  scale160,
  spaceL,
  spaceS,
  spaceXS,
  spaceXXL,
  spaceXXS,
  xdlColorTextSecondary,
  xdlPalettePetrol08,
  xdlPaletteWhite,
} from '@xing-com/tokens';
import { Headline as OriginalHeadline } from '@xing-com/typography';

const colorCopy = 'rgb(51, 51, 51)';

const videoIconDimension = (size: string) => {
  return css`
    width: calc(${size} * 2);
    height: calc(${size} * 2);
    font-size: ${size};
    line-height: calc(${size} * 2);
    left: calc(50% - ${size});
    top: calc(50% - ${size});
  `;
};

export const Video = styled.section`
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  margin: ${spaceL} 0;
`;

export const ContentText = styled.section<{ $isPinned?: boolean }>`
  margin: ${({ $isPinned }) =>
    $isPinned ? `0 0 0  ${spaceXXL}` : `0  ${spaceXXL} 0 0`};
  @media ${mediaSmallOrTiny} {
    margin: 0;
  }
`;

export const Provider = styled.span`
  color: ${xdlColorTextSecondary};
  font-size: ${scale070};
  line-height: ${scale100};

  & a {
    display: -webkit-box;
    max-height: calc(${scale100} * 4);
    color: ${xdlColorTextSecondary};
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

export const Content = styled.section`
  display: flex;
  position: relative;
  & ${ContentText} {
    overflow-x: hidden;
  }
  @media ${mediaSmallOrTiny} {
    flex-direction: column-reverse;
  }
`;

export const Summary = styled.a<{ willTrim: boolean }>`
  font-size: ${scale070};
  line-height: ${scale110};
  margin-bottom: ${spaceXS};
  display: -webkit-box;
  -webkit-line-clamp: ${({ willTrim }) => (willTrim ? 3 : 4)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;

  &:hover {
    text-decoration: none;
  }

  color: ${xdlPaletteWhite};
  @media (prefers-color-scheme: light) {
    color: ${colorCopy};
  }

  @media ${mediaSmallOrTiny} {
    font-size: ${scale070};
    line-height: ${scale100};
    max-height: calc(${scale100} * ${({ willTrim }) => (willTrim ? 3 : 4)});
  }
`;

export const ContentImg = styled.a<{ $isVideo: boolean }>`
  width: 300px;
  object-fit: contain;
  margin-left: auto;
  flex-shrink: 0;
  margin-top: ${scale005};
  position: relative;

  ${({ $isVideo }) =>
    $isVideo
      ? css`
          &:after {
            border: 0;
            border-radius: 50%;
            color: ${xdlPaletteWhite};
            font-family: VideoJS;
            content: '';
            position: absolute;
            background: ${xdlPalettePetrol08};
            text-align: center;
            opacity: 1;
            padding-left: ${spaceXS};
            ${videoIconDimension(scale130)};
          }

          @media ${mediaSmallOnly} {
            &:after {
              padding-left: ${spaceS};
              ${videoIconDimension(scale160)};
            }
          }
        `
      : null};

  & img {
    margin-left: 0;
    height: auto;
    flex-basis: auto;
    width: 100%;
  }

  @media ${mediaSmallOrTiny} {
    height: auto;
    flex-basis: 100%;
    width: 100%;
    max-width: none;
    max-height: none;
    margin: 0 0 ${spaceL};
  }
`;

export const TopImage = styled.img`
  width: 100%;
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: ${spaceL};
`;

export const Headline = styled(OriginalHeadline)`
  font-size: ${scale090};
  line-height: ${scale120};
  margin: 0;
  margin-bottom: ${spaceXXS};
  font-weight: 700;

  @media ${mediaSmallOrTiny} {
    font-size: ${scale090};
    line-height: ${scale110};
  }
`;

export const HeadlineAnchor = styled.a`
  color: ${xdlPaletteWhite};

  @media (prefers-color-scheme: light) {
    color: ${colorCopy};
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const ImagePosting = styled.section`
  margin-top: ${spaceL};
  max-width: 100%;

  & img {
    max-width: 100%;
    height: auto;
  }
`;

export const GlobalPostingLink = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;
