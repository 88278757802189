import type { FC } from 'react';

import { EntityPageContractType } from '@xing-com/crate-common-graphql-types';
import {
  useEditContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { YourTopicsVisitorView } from '@xing-com/crate-entity-pages-your-topics';
import { Flag } from '@xing-com/flag';

import { useHeaderContext } from '../../hooks/use-header-context/use-header-context';
import { EditPageInfoButton } from '../edit-page-info-button/edit-page-info-button';
import { TitleTooltip } from '../title-tooltip/title-tooltip';

import * as Styled from './primary-area.styles';

export const PrimaryArea: FC = () => {
  const { subtitle, title, isSubpage } = useHeaderContext();
  const { pageContext } = usePageContext();

  const isFreePage = pageContext.contractType === EntityPageContractType.Free;
  const isInsiderPage = pageContext.isInsiderPage;

  const { isEditing } = useEditContext();

  return (
    <Styled.PrimaryArea>
      <Styled.TitleContainer>
        <Styled.Hero
          $isSubpage={!!isSubpage}
          size="small"
          className="title"
          data-cy="entityPageTitle"
          data-testid={!isSubpage ? 'PAGE_TITLE' : undefined}
        >
          {isSubpage && subtitle ? (
            <>
              <Styled.Subtitle>{subtitle}</Styled.Subtitle>
              <Styled.WhiteSpace>&nbsp;</Styled.WhiteSpace>
            </>
          ) : null}
          {title}
          {!isSubpage ? <TitleTooltip /> : null}
        </Styled.Hero>
        {isInsiderPage ? (
          <Flag gender="neutral" size="medium" variant="insider" />
        ) : null}
      </Styled.TitleContainer>
      {!isSubpage && (subtitle || isEditing || isInsiderPage) ? (
        isInsiderPage ? (
          <YourTopicsVisitorView />
        ) : (
          <Styled.SubtitleContainer data-cy="ENTITY_PAGE_SLOGAN">
            <Styled.HeaderSubtitle
              size="small"
              noMargin
              sizeWide="medium"
              data-testid="PAGE_SLOGAN"
            >
              {subtitle}
            </Styled.HeaderSubtitle>
            {!subtitle && isEditing && !isFreePage ? (
              <EditPageInfoButton isLabel />
            ) : null}
            {isEditing && !isSubpage && !isFreePage ? (
              <EditPageInfoButton />
            ) : null}
          </Styled.SubtitleContainer>
        )
      ) : null}
    </Styled.PrimaryArea>
  );
};
