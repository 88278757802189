import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ModuleHeader,
  useEditContext,
  usePageContext,
  WarningMessageBox as Error,
} from '@xing-com/crate-entity-pages-common';
import { getTrackingUrl } from '@xing-com/crate-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { EmployeesCarousel } from '../components/employees-carousel/employees-carousel';
import {
  NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_IN,
  NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT,
  EMPLOYEES_OFFSET_LOGGED_OUT,
  EMPLOYEES_OFFSET_LOGGED_IN,
} from '../constants/employees-module';
import { EmployeesDocument } from '../graphql/queries/employees-query.gql-types';
import { QUERY_CONSUMER_CAROUSEL, EMPLOYEES_SORTING } from '../helpers';
import { useNetworkRequestLoggedOutAction } from '../hooks/use-network-request-logged-out-action';
import { createEmployeeCards } from '../model/employee-card';

import * as Styled from './employees-module.styles';

const DEFAULT_ERROR_POLICY = 'all';
const MINIMUM_EMPLOYEES_FOR_DETAIL_LINK = 4;

export const trackingKey = 'viewAllEmployees';

type RenderErrorProps = {
  refetch: () => void;
  isEditing: boolean;
};
const RenderError: FC<RenderErrorProps> = ({ refetch, isEditing }) => (
  <div data-testid="errorContainer">
    <ModuleHeader
      headlineCopyKey="EP_EMPLOYEES_HEADLINE"
      externalEditInfo={
        isEditing
          ? {
              copyKey: 'EP_EMPLOYEES_EDIT_HINT',
              cyTestId: 'EDIT_HINT_EMPLOYEES',
            }
          : null
      }
    />
    <Error
      headerText="EP_ERROR_HEADER"
      bodyText="EP_ERROR_BODY"
      buttonText="EP_ERROR_RELOAD_CTA"
      onClick={() => refetch()}
    />
  </div>
);

type EmployeesModuleProps = {
  companyId: string;
  pageSlug: string;
};
export const EmployeesModule: FC<EmployeesModuleProps> = ({
  pageSlug,
  companyId,
}) => {
  const { pageContext } = usePageContext();
  const { isEditing } = useEditContext();
  const { isLoggedIn } = useLoginState();

  const numberOfEmployeesToShow = isLoggedIn
    ? NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_IN * EMPLOYEES_OFFSET_LOGGED_IN
    : NUMBER_OF_EMPLOYEES_TO_SHOW_LOGGED_OUT * EMPLOYEES_OFFSET_LOGGED_OUT;

  const {
    data: employeesWithProfilePicture,
    loading: loadingEmployeesWithProfilePicture,
    refetch: refetchEmployeesWithProfilePicture,
  } = useQuery(EmployeesDocument, {
    variables: {
      id: companyId,
      first: numberOfEmployeesToShow,
      includeTotalQuery: true,
      consumer: QUERY_CONSUMER_CAROUSEL,
      query: {
        consumer: QUERY_CONSUMER_CAROUSEL,
        sort: EMPLOYEES_SORTING,
        filters: {
          hasPhoto: true,
        },
      },
    },
    errorPolicy: DEFAULT_ERROR_POLICY,
  });

  const {
    data: employeesWithoutProfilePicture,
    loading: loadingEmployeesWithoutProfilePicture,
    refetch: refetchEmployeesWithoutProfilePicture,
  } = useQuery(EmployeesDocument, {
    skip:
      loadingEmployeesWithProfilePicture ||
      (employeesWithProfilePicture?.company?.employees?.total ?? 0) >= // hotfix if undefined
        numberOfEmployeesToShow,
    variables: {
      id: companyId,
      first:
        numberOfEmployeesToShow -
        (employeesWithProfilePicture?.company?.employees?.edges?.length ?? 0),
      query: {
        consumer: QUERY_CONSUMER_CAROUSEL,
        sort: EMPLOYEES_SORTING,
        filters: {
          hasPhoto: false,
        },
      },
    },
    errorPolicy: DEFAULT_ERROR_POLICY,
  });

  const loading =
    loadingEmployeesWithProfilePicture || loadingEmployeesWithoutProfilePicture;

  const totalEmployees =
    employeesWithProfilePicture?.company?.totalEmployees?.total;

  const employeeWithPictureEdges =
    employeesWithProfilePicture?.company?.employees?.edges ?? [];
  const employeeWithPictureNodes = employeeWithPictureEdges
    .map((employee) => employee?.node)
    .filter((employee) => !!employee);
  const employeesWithoutPictureEdges =
    employeesWithoutProfilePicture?.company?.employees?.edges ?? [];
  const employeesWithoutPictureNodes = employeesWithoutPictureEdges
    .map((employee) => employee?.node)
    .filter((employee) => !!employee);

  const employeesList = [
    ...employeeWithPictureNodes,
    ...employeesWithoutPictureNodes,
  ];

  useNetworkRequestLoggedOutAction({
    employees: employeesList,
    loading,
  });

  if (loading) {
    return (
      <>
        <ModuleHeader
          headlineCopyKey="EP_EMPLOYEES_HEADLINE"
          showMore={{
            isLoading: true,
          }}
        />
        <EmployeesCarousel.Skeleton />
      </>
    );
  }

  const hasError =
    !employeesWithProfilePicture &&
    !loadingEmployeesWithProfilePicture &&
    !employeesWithoutProfilePicture &&
    !loadingEmployeesWithoutProfilePicture;

  if (hasError) {
    return (
      <RenderError
        refetch={() => {
          refetchEmployeesWithoutProfilePicture();
          refetchEmployeesWithProfilePicture();
        }}
        isEditing={isEditing}
      />
    );
  }

  if (totalEmployees === 0) {
    return (
      <>
        <ModuleHeader headlineCopyKey="EP_EMPLOYEES_HEADLINE" />
        <Styled.EmptyState>
          <FormattedMessage id="EP_EMPLOYEES_EMPTY_STATE" />
        </Styled.EmptyState>
      </>
    );
  }

  if (totalEmployees === undefined || employeesList === undefined) {
    return (
      <RenderError
        refetch={() => {
          refetchEmployeesWithProfilePicture();
          refetchEmployeesWithoutProfilePicture();
        }}
        isEditing={isEditing}
      />
    );
  }

  const employeesCards = createEmployeeCards(employeesList);

  const link = `${pageContext.basePath}/${pageSlug}/employees`;
  const trackingLink = getTrackingUrl({
    to: link,
    trackingKey,
  });

  const showMore =
    totalEmployees > MINIMUM_EMPLOYEES_FOR_DETAIL_LINK
      ? {
          'data-testid': 'EMPLOYEES_SHOWMORE_LINK',
          to: link,
          onClick: (ev: MouseEvent) => {
            ev.preventDefault();
            window.open(trackingLink);
          },
          copyKey: 'EP_EMPLOYEES_LINK',
          number: totalEmployees,
        }
      : null;

  return (
    <div data-testid={'EMPLOYEES_MODULE'}>
      <ModuleHeader
        headlineCopyKey="EP_EMPLOYEES_HEADLINE"
        // @ts-expect-error FIXME: SC6
        showMore={showMore}
        externalEditInfo={
          !(
            loadingEmployeesWithProfilePicture ||
            loadingEmployeesWithoutProfilePicture
          ) && isEditing
            ? {
                copyKey: 'EP_EMPLOYEES_EDIT_HINT',
                cyTestId: 'EDIT_HINT_EMPLOYEES',
              }
            : null
        }
      />
      <EmployeesCarousel
        employeesCards={employeesCards}
        totalEmployees={totalEmployees}
        onAddContact={() => {
          refetchEmployeesWithProfilePicture();
          refetchEmployeesWithoutProfilePicture();
        }}
      />
    </div>
  );
};
