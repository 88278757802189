import * as React from 'react';

import type { ContentBannerAutoHideProps } from '.';
import { ContentBanner } from '.';

export const ContentBannerAutoHide = ({
  handleOnClose,
  show,
  timeout = 20000,
  ...props
}: ContentBannerAutoHideProps): JSX.Element => {
  const [hideByTimeout, showWithTimeout] = React.useState(show);
  const handleOnCloseForTimeOut = React.useCallback(() => {
    if (handleOnClose) handleOnClose();
    showWithTimeout(false);
  }, [handleOnClose, showWithTimeout]);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (timeout && show) {
      showWithTimeout(true);
      timeoutId = setTimeout(() => {
        handleOnCloseForTimeOut();
      }, timeout);
    } else {
      showWithTimeout(show);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [show, timeout, handleOnCloseForTimeOut]);

  return (
    <ContentBanner
      {...props}
      handleOnClose={handleOnClose}
      onExited={handleOnCloseForTimeOut}
      show={hideByTimeout}
    />
  );
};
