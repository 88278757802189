import type React from 'react';
import { useState, useEffect } from 'react';

import { usePageContext } from '@xing-com/crate-entity-pages-common';

import bookmarkIcon from '../../assets/bookmark.svg'; // eslint-disable-line
import bookmarkFillIcon from '../../assets/bookmark-fill.svg'; // eslint-disable-line

import * as Styled from './jobs-bookmark.styles';

type JobsBookmarkProps = {
  trackingAction?: (isEditor: boolean, bookmarked: boolean) => void;
  initialBookmark: boolean;
  deleteBookmark?: () => void;
  createBookmark?: () => void;
  fixedValue?: boolean;
  error?: {
    showError: boolean;
    onError: () => void;
  };
  loading?: boolean;
};

export const JobsBookmark: React.FC<JobsBookmarkProps> = ({
  trackingAction,
  initialBookmark,
  deleteBookmark,
  createBookmark,
  fixedValue = false,
  error,
  loading,
}) => {
  const [bookmarked, setBookmarked] = useState(initialBookmark);
  const { pageContext } = usePageContext();
  const isEditor = pageContext.isEditor;

  const showError = error?.showError || false;
  const onError = error?.onError;

  const toggleBookmark = () => {
    if (bookmarked) {
      if (deleteBookmark) deleteBookmark();
    } else {
      if (createBookmark) createBookmark();
    }

    if (!fixedValue) {
      if (trackingAction) trackingAction(isEditor, !bookmarked);
      setBookmarked(!bookmarked);
    }
  };

  useEffect(() => {
    if (showError) {
      setBookmarked(!bookmarked);
      if (onError) onError();
    }
  }, [showError]);

  return (
    <Styled.BookmarkButton
      onClick={(e) => {
        e.stopPropagation();

        if (!loading) {
          toggleBookmark();
        }
      }}
      data-cy={'bookmark'}
      aria-label={bookmarked ? 'Bookmark fill' : 'Bookmark'}
    >
      <Styled.Bookmark
        src={bookmarked ? bookmarkFillIcon : bookmarkIcon}
        alt="Bookmark"
      />
    </Styled.BookmarkButton>
  );
};
