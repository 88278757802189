/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconTitle1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconTitle1"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.923 4 13.7 6.982l-3.7.012v12.005H7V7.005L3 7.02V4zM16.263 11 13.5 12.673l.945 1.437 1.556-.954V19H18v-8z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconTitle1;
