import type { FC } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { IconImage, IconCross } from '@xing-com/icons';
import { OmView } from '@xing-com/platform-layout-om';
import { Headline, BodyCopy } from '@xing-com/typography';

import { FileUpload } from '../file-upload/file-upload';

import { CropImage } from './crop-image/crop-image';
import * as Styled from './preview-image.styles';

type PreviewImageProps = {
  previewImageDisplay: string;
  onDelete: () => void;
  onUpload: (file: File | undefined) => void;
  onError?: (error: any) => void;
};
export const PreviewImage: FC<PreviewImageProps> = ({
  previewImageDisplay,
  onDelete = () => undefined,
  onUpload = () => undefined,
  onError = () => undefined,
}) => {
  const [file, setFile] = useState<File | undefined>();

  const deletePreviewImage = () => {
    onDelete();
    setFile(undefined);
  };
  const handleUpload = (file: File) => {
    onUpload(file);
    setFile(file);
  };

  const prepare = (file: File, activateOmView: () => void) => {
    if (!file) return;

    try {
      setFile(file);
      activateOmView();
    } catch (e) {
      setFile(file);
    }

    onUpload(undefined);
  };

  return (
    <Styled.PreviewImageContainer>
      {/* @ts-expect-error FIXME: SC6 */}
      <Headline size="xxlarge" PreviewImageContainernoMargin>
        <FormattedMessage id="VIDEO_UPLOAD_PREVIEW_IMAGE_TITLE" />
      </Headline>
      <BodyCopy size="medium">
        <FormattedMessage id="VIDEO_UPLOAD_PREVIEW_IMAGE_DESCRIPTION" />
      </BodyCopy>
      <Styled.PreviewImageButtonContainer>
        <Styled.UploadCtaContainer>
          <OmView
            trigger={(activateOmView) => (
              <FileUpload
                accept="image/jpeg, image/png"
                maxSize={10000000}
                onError={onError}
                // @ts-expect-error TS(2345) FIXME: Argument of type 'File | FormEvent<HTMLAnchorEleme... Remove this comment to see the full error message
                onChange={(file) => file && prepare(file, activateOmView)}
                variant="tertiary"
                size="medium"
                icon={IconImage}
              >
                <FormattedMessage id="VIDEO_UPLOAD_PREVIEW_IMAGE_CTA" />
              </FileUpload>
            )}
          >
            {({ handleClose }) => (
              <>
                {file && (
                  <Styled.CropperContainer>
                    <CropImage
                      onDiscard={close}
                      onSave={() => {
                        handleClose();
                      }}
                      isUploading={false}
                      image={URL.createObjectURL(file)}
                      imageName={file.name}
                      onChange={({ file }) => handleUpload(file)}
                      aspectRatio={16 / 9}
                      discardCopy={'VIDEO_UPLOAD_CROP_CANCEL'}
                      publishCopy={'VIDEO_UPLOAD_CROP_SAVE'}
                    />
                  </Styled.CropperContainer>
                )}
              </>
            )}
          </OmView>
        </Styled.UploadCtaContainer>
        {previewImageDisplay && (
          <Styled.FileAddedContainer>
            <Styled.FileName>{previewImageDisplay}</Styled.FileName>
            <Button onClick={() => deletePreviewImage()} icon={IconCross} />
          </Styled.FileAddedContainer>
        )}
      </Styled.PreviewImageButtonContainer>
    </Styled.PreviewImageContainer>
  );
};
