import { useLazyQuery } from '@apollo/client';
import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Autocomplete,
  AutocompleteListItem,
  AutocompleteList,
  AutocompleteInput,
} from '@xing-com/crate-entity-pages-common';
import { Headline } from '@xing-com/typography';

import { AffiliatesCompaniesSuggestionsDocument } from '../../../../graphql/queries/affiliates-companies-suggestions.gql-types';

import * as Styled from './companies-autocomplete.styles';
import type { SuggestedCompany, Company } from './types';

const TIMEOUT_COMPANIES = 400;

const NoSuggestionItem: FC = () => (
  <Styled.NoSuggestionItem>
    <Headline size={'small'}>
      <FormattedMessage id="EP_COMPANY_NOT_FOUND" />
    </Headline>
  </Styled.NoSuggestionItem>
);

type CompanyCardProps = {
  displayName: string;
  logo?: string;
};

const CompanyCard = ({ displayName, logo }: CompanyCardProps) => (
  <Styled.ProfileInfo
    profileImage={{
      size: 'xsmall',
      profileName: displayName,
      src: logo,
    }}
    headline={{
      size: 'small',
      children: <div>{displayName}</div>,
    }}
    textBody={[{ size: 'xsmall', children: 'Employer' }]}
  />
);

type CompaniesAutocompleteProps = {
  company: Company;
  onChange(company: Company): void;
  allowFreeText?: boolean;
  error?: any;
};
const CompaniesAutocomplete: FC<CompaniesAutocompleteProps> = ({
  company,
  onChange,
  allowFreeText,
  error,
}) => {
  const [items, setItems] = useState<Array<SuggestedCompany>>([]);
  const [noSuggestions, setNoSuggestions] = useState(false);
  const [fetchSuggestions, { data }] = useLazyQuery(
    AffiliatesCompaniesSuggestionsDocument,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (data?.autocompletionCompanyName?.collection) {
      // @ts-expect-error TODO: fix this type
      setItems(data.autocompletionCompanyName.collection);
      if (data.autocompletionCompanyName.collection.length === 0) {
        setNoSuggestions(true);
      } else {
        setNoSuggestions(false);
      }
    }
  }, [data]);

  const onBlur = () => {
    setItems([]);
    setNoSuggestions(false);
  };

  return (
    <Autocomplete
      onBlur={onBlur}
      selectedValue={company}
      items={items}
      callback={(text: string) => {
        fetchSuggestions({
          variables: {
            text,
          },
        });
      }}
      onChange={onChange}
      minChars={0}
      timeout={TIMEOUT_COMPANIES}
      selectedPropertyName="companyName"
      allowFreeText={allowFreeText}
    >
      <AutocompleteInput>
        <Styled.Input
          // @ts-expect-error FIXME: SC6
          small
          label="Company Name"
          helperText={error}
          error={error}
        />
      </AutocompleteInput>
      <AutocompleteList>
        {noSuggestions && (
          <AutocompleteListItem index={0}>
            <NoSuggestionItem />
          </AutocompleteListItem>
        )}
        {items.map((item, index) => (
          <AutocompleteListItem
            key={item.company?.id}
            id={item.company?.id}
            index={index}
            item={item.company}
          >
            <CompanyCard
              displayName={item.company?.companyName}
              logo={item.company?.logos?.logo32px}
            />
          </AutocompleteListItem>
        ))}
      </AutocompleteList>
    </Autocomplete>
  );
};

export { CompaniesAutocomplete };
