import type { FC } from 'react';

import { DotLoadingIndicator } from '@xing-com/dot-loading-indicator';
import { IconTrash } from '@xing-com/icons';

import { isImageUploadDisabled } from '../../helpers/image-upload';
import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxFormContext } from '../../hooks/use-commbox-form-context';
import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';
import { useDragEvent } from '../../hooks/use-drag-event';
import { useOnInputFiles } from '../../hooks/use-on-input-files';

import { DropZone } from './drop-zone';
import * as Styled from './image-handler.styles';

export const ImageHandler: FC = () => {
  const { attachmentType, setAttachmentType, setShouldOpenFilePicker } =
    useCommboxContext();
  const {
    imageIsUploading,
    images,
    setImageIsUploading,
    setImagePreviewSrc,
    setUploadedFileId,
    setImages,
  } = useCommboxFormContext();
  const { isEditingPosting, enableMultiImage } = useCommboxPermissions();
  const { isDragging } = useDragEvent();
  const { onInputFiles } = useOnInputFiles();

  const handleRemoveImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    if (!newImages.length) {
      setAttachmentType('EMPTY');
      setImageIsUploading(false);
      setImagePreviewSrc('');
      setShouldOpenFilePicker(false);
      setUploadedFileId(null);
    }
    setImages(newImages);
  };
  const handleClearDropZone = () => {
    setImages([]);
    setAttachmentType('EMPTY');
    setImagePreviewSrc('');
  };

  if (enableMultiImage) {
    return (
      <DropZone
        canUploadImages={
          !isImageUploadDisabled({
            images,
            enableMultiImage,
            attachmentType,
            isEditingPosting,
          })
        }
        clearDropzone={handleClearDropZone}
        images={images}
        isEditingPosting={isEditingPosting}
        isDragging={isDragging}
        onRemove={(index) => handleRemoveImage(index)}
        onClick={() => setShouldOpenFilePicker(true)}
        onDrop={onInputFiles}
      />
    );
  }

  const showImageContainer = !!images?.length && attachmentType === 'IMAGE';

  return (
    <>
      {showImageContainer && (
        <Styled.ImageContainer>
          {imageIsUploading && (
            <Styled.ImageLoadingIndicator>
              <DotLoadingIndicator />
            </Styled.ImageLoadingIndicator>
          )}
          <Styled.Image src={images[0].url} />
          <Styled.Button
            variant="overlay"
            size="small"
            icon={IconTrash}
            disabled={isEditingPosting}
            onClick={() => {
              setAttachmentType('EMPTY');
              setImageIsUploading(false);
              setImagePreviewSrc('');
              handleRemoveImage(0);
              setShouldOpenFilePicker(false);
              setUploadedFileId(null);
            }}
          />
        </Styled.ImageContainer>
      )}
    </>
  );
};
