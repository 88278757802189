/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconGroups = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconGroups"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.811 14c1.488 0 2.67.76 3.395 1.75a3.5 3.5 0 0 1 2.186-.75h1.216c.902 0 1.674.3 2.275.828C22.568 16.43 23 17.339 23 18h-2c0-.071-.175-.44-.438-.67-.24-.212-.545-.33-.954-.33h-1.216c-.84 0-1.392.66-1.392 1h-2c0-.788-.903-2-2.189-2H11.19C9.903 16 9 17.212 9 18H7c0-.071-.175-.44-.438-.67-.24-.212-.545-.33-.954-.33H4.392C3.552 17 3 17.66 3 18H1c0-1.375 1.362-3 3.392-3h1.216c.858 0 1.598.27 2.185.751C8.516 14.761 9.7 14 11.19 14zM19.5 9c.69 0 1.315.28 1.768.732.452.453.732 1.078.732 1.768s-.28 1.315-.732 1.768A2.5 2.5 0 0 1 19.5 14c-.69 0-1.315-.28-1.768-.732A2.5 2.5 0 0 1 17 11.5c0-.69.28-1.315.732-1.768A2.5 2.5 0 0 1 19.5 9m-15 0c.69 0 1.315.28 1.768.732C6.72 10.185 7 10.81 7 11.5s-.28 1.315-.732 1.768A2.5 2.5 0 0 1 4.5 14c-.69 0-1.315-.28-1.768-.732A2.5 2.5 0 0 1 2 11.5c0-.69.28-1.315.732-1.768A2.5 2.5 0 0 1 4.5 9M12 5c1.105 0 2.105.448 2.829 1.172A4 4 0 0 1 16 9a4 4 0 0 1-1.171 2.829A4 4 0 0 1 12 13a4 4 0 0 1-2.828-1.171A4 4 0 0 1 8 9c0-1.105.448-2.105 1.172-2.828A3.99 3.99 0 0 1 12 5m7.5 5.5c-.27 0-.52.106-.707.293a1 1 0 0 0-.293.707c0 .27.106.52.293.707a1 1 0 0 0 .707.293c.27 0 .52-.106.707-.293a1 1 0 0 0 .293-.707c0-.27-.106-.52-.293-.707a1 1 0 0 0-.707-.293m-15 0c-.27 0-.52.106-.707.293a1 1 0 0 0-.293.707c0 .27.106.52.293.707a1 1 0 0 0 .707.293c.27 0 .52-.106.707-.293A1 1 0 0 0 5.5 11.5c0-.27-.106-.52-.293-.707A1 1 0 0 0 4.5 10.5M12 7c-.54 0-1.04.212-1.414.586A1.99 1.99 0 0 0 10 9c0 .54.212 1.04.586 1.414S11.46 11 12 11s1.04-.212 1.414-.586S14 9.54 14 9s-.212-1.04-.586-1.414A1.99 1.99 0 0 0 12 7"
    />
  </svg>
);
export default IconGroups;
