import times from 'lodash/times';
import * as React from 'react';

import * as Styled from './pagination-dots.styles';
import type {
  DotProps,
  PaginationDotProps,
  DotSize,
} from './pagination-dots.types';

const MAX_DOTS_VISIBLE = 7;
const CENTER_INDEX = Math.floor(MAX_DOTS_VISIBLE / 2);

const Dot = ({
  size,
  paginationDotColor,
  isSelected,
}: DotProps): JSX.Element => (
  <Styled.DotContainer>
    <Styled.Dot
      $size={size}
      $isSelected={isSelected}
      $paginationDotColor={paginationDotColor}
    />
  </Styled.DotContainer>
);

export const PaginationDots = React.forwardRef<
  HTMLDivElement,
  PaginationDotProps
>(
  (
    { className, length, selected, paginationDotColor },
    forwardedRef
  ): JSX.Element => {
    const getDotType = (index: number): DotSize => {
      if (length < 5) {
        return 'big';
      }

      const diff = Math.abs(index - selected);

      switch (diff) {
        case 0:
          return 'big';
        case 1:
          return 'big';
        case 2:
          return 'medium';
        default:
          return 'small';
      }
    };

    if (length < 2) {
      return <Styled.PaginationDots />;
    }

    const getTrackPosition = (): number => {
      if (length <= MAX_DOTS_VISIBLE) return 0;

      if (selected < CENTER_INDEX) return 0;
      if (selected >= length - CENTER_INDEX) return length - MAX_DOTS_VISIBLE;
      return selected - CENTER_INDEX;
    };

    return (
      <Styled.PaginationDots
        className={className}
        data-xds="PaginationDots"
        ref={forwardedRef}
      >
        <Styled.Track
          $centered={length < MAX_DOTS_VISIBLE}
          $index={getTrackPosition()}
        >
          {times(length, (i: number) => (
            <Dot
              isSelected={selected === i}
              key={i}
              paginationDotColor={paginationDotColor}
              size={getDotType(i)}
            />
          ))}
        </Styled.Track>
      </Styled.PaginationDots>
    );
  }
);

PaginationDots.displayName = 'PaginationDots';
