import { useLocation } from '@reach/router';
import type React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useState } from 'react';

import { useExperiment } from '@xing-com/hub';

import { ENABLE_NEW_ENTITY_PAGES_ARTICLES_MODULE } from '../../config/experiments';
import { usePageContext } from '../../hooks/use-page-context/use-page-context';
import type { EditItemMapTrackingProps } from '../../types/types';

export type EditContextType = {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  trackingData: EditItemMapTrackingProps | null;
  setTrackingData: React.Dispatch<
    React.SetStateAction<EditItemMapTrackingProps | null>
  >;
};

export const EditContext = createContext<EditContextType | undefined>(
  undefined
);

export const EditContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { pageContext } = usePageContext();

  const isNewArticleModuleEnabled =
    useExperiment(ENABLE_NEW_ENTITY_PAGES_ARTICLES_MODULE) === 'B';

  const isSearchParamEditOn =
    new URLSearchParams(location.search).get('edit') === 'true';
  const isEditable =
    (pageContext.isCompanyPage ||
      pageContext.isInsiderPage ||
      isNewArticleModuleEnabled) &&
    pageContext.isEditor;

  const defaultIsEditing = isEditable && isSearchParamEditOn;

  const [isEditing, setIsEditing] = useState<boolean>(defaultIsEditing);
  const [trackingData, setTrackingData] =
    useState<EditItemMapTrackingProps | null>(null);

  const updateIsEditing = useCallback(
    (value: boolean | ((prev: boolean) => boolean)) => {
      if (isEditable) {
        setIsEditing(value);
      }
    },
    [isEditable]
  );

  return (
    <EditContext.Provider
      value={{
        isEditing,
        setIsEditing: updateIsEditing,
        trackingData,
        setTrackingData,
      }}
    >
      {children}
    </EditContext.Provider>
  );
};
