import styled, { css } from 'styled-components';

import { lineClamp } from '@xing-com/crate-utils-line-clamp';
import { IconPlay } from '@xing-com/icons';
import { mediaSmallOrTiny, mediaConfined } from '@xing-com/layout-tokens';
import {
  SkeletonImage as SkeletonImageComponent,
  SkeletonHeadline as SkeletonHeadlineComponent,
} from '@xing-com/skeleton';
import {
  scale070,
  scale090,
  scale110,
  scale120,
  spaceXS,
  spaceXXS,
  xdlColorTextSecondary,
  scale100,
  spaceL,
  spaceM,
  spaceXXL,
  xdlColorFocussed,
  xdlPaletteWhite,
} from '@xing-com/tokens';
import { Headline as HeadlineComponent } from '@xing-com/typography';

const linkStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const Content = styled.section<{ $isFeatured: boolean }>`
  display: flex;
  gap: ${spaceXXL};
  margin: ${spaceM} 0;
  position: relative;

  ${({ $isFeatured }) => ($isFeatured ? 'flex-direction: row-reverse;' : '')};

  @media ${mediaSmallOrTiny} {
    flex-direction: column-reverse;
    gap: ${spaceL};
  }
`;

export const TextContainer = styled.div`
  flex: 1 1 auto;
`;

export const Headline = styled(HeadlineComponent)`
  font-size: ${scale090};
  line-height: ${scale120};
  margin: 0 0 ${spaceXXS};
  font-weight: 700;
  word-break: break-word;

  ${lineClamp(2)}

  @media ${mediaSmallOrTiny} {
    line-height: ${scale110};
  }
`;

export const Summary = styled.p`
  font-size: ${scale070};
  line-height: ${scale110};
  margin: 0 0 ${spaceXS};

  ${lineClamp(4)}
`;

export const ArticlePage = styled.span`
  color: ${xdlColorTextSecondary};
  font-size: ${scale070};
  line-height: ${scale100};
`;

export const ImageContainer = styled.span`
  position: relative;
  flex: 0 0 300px;

  & img {
    width: 100%;
  }

  @media ${mediaSmallOrTiny} {
    flex: 0 1 100%;
  }
`;

export const Link = styled.a`
  ${linkStyles}
`;

export const Button = styled.button`
  cursor: pointer;
  ${linkStyles}
`;

export const PlayIconWrapper = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    width: 64px;
    height: 64px;
    background: ${xdlColorFocussed};
    border-radius: 50%;
  }
`;
export const PlayIcon = styled(IconPlay).attrs({
  width: 32,
})`
  color: ${xdlPaletteWhite};
  z-index: 1;
`;

export const ArticleCardSkeletonContainer = styled.div`
  display: flex;
  gap: ${spaceXXL};
  margin: ${spaceM} 0;

  @media ${mediaSmallOrTiny} {
    flex-direction: column-reverse;
    gap: ${spaceL};
  }
`;

export const SkeletonTextContainer = styled.div`
  flex: 1 1 100%;
`;

export const SkeletonHeadline = styled(SkeletonHeadlineComponent)`
  margin-bottom: ${spaceM};

  @media ${mediaConfined} {
    margin-bottom: 0;

    & :not(:first-child) {
      display: none;
    }
  }
`;

export const SkeletonImageContainer = styled.div`
  flex: 0 0 300px;

  @media ${mediaSmallOrTiny} {
    flex: 0 1 100%;
  }
`;

export const SkeletonImage = styled(SkeletonImageComponent)`
  aspect-ratio: 16/9;
`;
