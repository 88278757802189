import styled from 'styled-components';

import * as tokens from '@xing-com/tokens';

import { InfoState as DefaultInfoState } from '../info-state/info-state';

export const InfoState = styled(DefaultInfoState)`
  padding: ${tokens.spaceM};
  margin: ${tokens.spaceM} 0;
`;
