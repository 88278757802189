/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconFileImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconFileImage"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m16 2 4 4v16H4V2zm2 9.905-6.258 6.26-2.25-2.25L6 19.404V20h12zM15.172 4H6v12.576l3.492-3.49 2.25 2.25L18 9.076V6.828zM12 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4"
    />
  </svg>
);
export default IconFileImage;
