import styled, { css } from 'styled-components';

import { Button } from '@xing-com/button';
import { IconLikeFilled } from '@xing-com/icons';
import {
  xdlColorTextSecondary,
  scale020,
  scale090,
  xdlColorTextOnDark,
  xdlColorTextTertiary,
} from '@xing-com/tokens';

// *** Base ***
const baseLikeButton = css`
  color: ${xdlColorTextSecondary};
  padding: 0;
`;

const baseLikeCounter = css`
  align-items: center;
  display: flex;
  font-weight: normal;
  line-height: 1;
`;

const baseLikeBadge = css`
  align-items: center;
  background: ${xdlColorTextTertiary};
  border-radius: 100%;
  color: ${xdlColorTextOnDark};
  display: flex;
  height: ${scale090};
  justify-content: center;
  justify-items: center;
  margin-right: ${scale020};
  padding: 3px;
  width: ${scale090};
`;

// *** Components ***
const LikeButton = styled(Button)`
  ${baseLikeButton}
`;

const LikeCounter = styled.div`
  ${baseLikeCounter}
`;

const LikeBadge = styled(IconLikeFilled)`
  ${baseLikeBadge}
`;

export const Styled = {
  LikeButton,
  LikeCounter,
  LikeBadge,
};
