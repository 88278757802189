import type { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { getMentionsCount } from '@xing-com/content-editor-plugin-mention';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { TagManagerId } from '@xing-com/winery-tag-manager';

import {
  audienceSurnFor,
  getPostTarget,
  getPostTypeTracking,
  trimArticleParagraph,
} from '../../helpers/commbox';
import { isPostButtonDisabled } from '../../helpers/commbox-footer';
import { isPublishAtValid } from '../../helpers/commbox-submit-button';
import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxFormContext } from '../../hooks/use-commbox-form-context';
import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';
import { trackAction, trackEvent } from '../../tracking';

const SubmitButtonTagManager: FC<PropsWithChildren> = ({ children }) => {
  const { tagManagerId } = useCommboxContext();

  if (tagManagerId) {
    return (
      <TagManagerId data-testid="commboxTagManagerId" id={tagManagerId}>
        {children}
      </TagManagerId>
    );
  }

  return <>{children}</>;
};

export type CommboxSubmitButtonProps = {
  selectedAudience: string;
  publishAt?: any;
  scheduled: 0 | 1;
};
export const CommboxSubmitButton: FC<CommboxSubmitButtonProps> = ({
  selectedAudience,
  scheduled,
  publishAt,
}) => {
  const {
    application,
    attachmentType,
    globalId = '',
    pollData,
    posting,
    selectableAudiences,
    setIsAllowedPost,
    postMutation,
    onSuccess,
    pageName,
  } = useCommboxContext();
  const {
    images,
    linkShareUrl,
    selectedActorGlobalId,
    setErrorMessage,
    slateMessage,
    videoState,
    imageIsUploading,
    isPreviewCompleted,
    plainTextMessage,
  } = useCommboxFormContext();

  const { isCreatingPosting, isEditingPosting, isAllowedToPost } =
    useCommboxPermissions();

  const { track: trackNWT } = useTracking();

  const disabled = isPostButtonDisabled({
    attachmentType,
    imageIsUploading,
    isAllowedToPost,
    isCreatingPosting,
    isEditingPosting,
    isPreviewCompleted,
    text: plainTextMessage,
  });

  const handleOnClick = async () => {
    if (!isPublishAtValid({ publishAt, setErrorMessage })) {
      return;
    }

    setIsAllowedPost(false);

    const audienceObject = selectableAudiences.find(
      (audience) => audience.id === selectedAudience
    ) || { id: 'PUBLIC', visibility: 'PUBLIC' };

    const userId = selectedActorGlobalId.split(':').pop() || '';

    const submitData = isEditingPosting
      ? {
          id: posting?.id,
          publishAt,
          commentArticleV1: trimArticleParagraph(slateMessage),
        }
      : {
          actorGlobalId: selectedActorGlobalId,
          commentArticleV1: slateMessage
            ? trimArticleParagraph(slateMessage)
            : undefined,
          publicationStrategy: publishAt ? 'LATER' : 'NOW',
          visibility: audienceObject.visibility,
          publishAt: publishAt,
          images:
            attachmentType === 'IMAGE'
              ? images.map(({ uploadedFileId: uploadId }) => ({ uploadId }))
              : undefined,
          links:
            attachmentType === 'LINK_PREVIEW' && linkShareUrl
              ? [{ url: linkShareUrl }]
              : undefined,
          videos:
            attachmentType === 'VIDEO' && videoState?.id
              ? [{ videoId: videoState.id }]
              : undefined,
          polls:
            attachmentType === 'POLLS' && pollData ? [pollData] : undefined,
          audience: audienceSurnFor(audienceObject.id, userId),
        };

    const isGifPost =
      images.find((image) => image.type === 'image/gif') !== undefined;
    const postType = getPostTypeTracking(attachmentType, isGifPost);

    const { error, success } = await postMutation(submitData);

    if (!success || error) {
      setErrorMessage(error?.message || 'GENERIC_ERROR');
    }
    if (success) {
      setErrorMessage(null);

      if (isEditingPosting) {
        trackAction({ PropTrackAction: 'social_share_commbox_edit_post_save' });
      }

      const postTarget = getPostTarget(application);
      const mentionsCount = getMentionsCount(slateMessage);

      trackEvent('EventPostCreate', {
        EventPostCreate: 1,
        PropContextDimension2: `${selectedAudience.toLowerCase()}|comment_1|scheduled_${scheduled}|images_${
          images.length
        }`,
        PropContextDimension4: `social_${postType}`,
        PropInteractionType: `social_commbox_share|startpage|${postTarget}_post`,
        PropSocialObjectId: globalId,
        ...(mentionsCount && { EventUserMention: mentionsCount }),
      });

      trackNWT({
        type: 'nwt',
        application: 'wbm',
        eventSchema: 'extended',
        event: isEditingPosting ? 'edited' : 'created',
        itemUrn: success.globalId,
        itemActorUrn: success?.actorGlobalId,
        trackingToken: success?.opTrackingTokens?.[0] ?? '',
        moduleTitle: 'postings',
        activityId: success?.activityId ?? '',
        visibility: success?.visibility?.toLowerCase(),
        flags: `allow_commenting_true,scheduled_${Boolean(
          publishAt
        ).toString()}`,
        actionCount: images?.length ?? 0,
        actionCountContext: 'image_count',
        page: pageName,
        sentBy: 'commbox',
      });

      onSuccess();
    }

    // Regardless of the result of the query,the user should be able to post again.
    setIsAllowedPost(true);
  };

  return (
    <SubmitButtonTagManager>
      <Button
        {...{ disabled, loading: isCreatingPosting }}
        data-testid="commboxPost"
        variant="primary"
        onClick={handleOnClick}
      >
        <FormattedMessage
          id={
            isEditingPosting
              ? 'COMMBOX_UPDATE_BTN'
              : publishAt
                ? 'COMMBOX_SCHEDULING_SCHEDULE'
                : 'COMMBOX_POST'
          }
        />
      </Button>
    </SubmitButtonTagManager>
  );
};
