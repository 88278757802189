import { FormattedMessage } from 'react-intl';

import { useEditContext } from '@xing-com/crate-entity-pages-common';
import { useViewerData } from '@xing-com/hub';
import { IconInfo } from '@xing-com/icons';

import * as Styled from './insider-edit-profile-banner.styles';

export const InsiderEditProfileBanner: React.FC = () => {
  const { isEditing } = useEditContext();
  const { data } = useViewerData();
  const profileSlug = data?.user?.pageName;

  return isEditing ? (
    <Styled.BannerContainer>
      <Styled.IconContainer>
        <IconInfo height={24} width={24} />
      </Styled.IconContainer>
      <span>
        <FormattedMessage id="EP_INSIDER_CHANGE_PROFILE_INFO_TEXT" />
      </span>
      {profileSlug ? (
        <Styled.ButtonContainer>
          <Styled.Button
            variant="secondary"
            size="small"
            to={`/profile/${profileSlug}/web_profiles`}
          >
            <FormattedMessage id="EP_INSIDER_CHANGE_PROFILE_INFO_BUTTON" />
          </Styled.Button>
        </Styled.ButtonContainer>
      ) : null}
    </Styled.BannerContainer>
  ) : null;
};
