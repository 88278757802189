import { useRef } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { FormattedMessage } from 'react-intl';

import {
  FormattedTimestamp,
  ModuleHeader,
} from '@xing-com/crate-entity-pages-common';
import {
  trackClickKununuReview,
  trackAllReviewsClick,
  trackPageChange,
  getTrackingUrl,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { StarRating } from '@xing-com/star-rating';

import { NUMBER_OF_REVIEWS_REQUESTED } from '../../config';
import type { KununuData, KununuReviews, Review } from '../../types';

import * as Styled from './reviews.styles';

type CardProps = {
  score: number;
  quote: string;
  date: string;
  link: string;
  jobStatus: string;
  position: number;
  isEditor: boolean;
};

type ReviewsProps = {
  kununuReviews: KununuReviews;
  isEditor?: boolean;
} & Pick<KununuData, 'kununuCommentsUrl'>;

const getJobStatusCopyKey = (jobStatus: string) => {
  switch (jobStatus) {
    case 'CURRENT':
      return 'EP_KUNUNU_REVIEWER_CURRENT_JOB';
    case 'INTERNSHIP':
      return 'EP_KUNUNU_REVIEWER_INTERNSHIP_JOB';
    case 'PREVIOUS':
      return 'EP_KUNUNU_REVIEWER_FORMER_JOB';
    // not specified
    default:
      return 'EP_KUNUNU_REVIEWER_NS_JOB';
  }
};

const reviewClick = (href: string, position: number, isEditor: boolean) => {
  const pageDevider =
    window.innerWidth < 740 ? 1 : window.innerWidth < 1024 ? 2 : 3;
  const page = Math.ceil((position + 1) / pageDevider);
  trackClickKununuReview({ isEditor, page });
  window.open(href, '_blank');
};

const onAllReviewsClick = (isEditor = false) => {
  trackAllReviewsClick({ isEditor });
};

const Card = ({
  quote,
  score,
  date,
  link,
  jobStatus,
  position,
  isEditor = false,
}: CardProps) => {
  const currentJobMessage = getJobStatusCopyKey(jobStatus);

  return (
    <Styled.CardWrapper onClick={() => reviewClick(link, position, isEditor)}>
      <Styled.CardReviewHeader>
        <Styled.ScoreContainer>
          <StarRating rating={parseFloat(score.toFixed(1))} />
        </Styled.ScoreContainer>
        <Styled.Date fontWeight="bold" lineHeight="dense" size={'small'}>
          {date && (
            <FormattedTimestamp
              date={new Date(date)}
              onlyMonthAndYear
              isShortVersion
            />
          )}
        </Styled.Date>
      </Styled.CardReviewHeader>
      <Styled.Quote fontWeight="bold" lineHeight="dense" size={'small'}>
        <Dotdotdot clamp={3}>{quote}</Dotdotdot>
      </Styled.Quote>
      <Styled.ReviewsFooter>
        <Styled.EmployeeStatus
          fontWeight="bold"
          lineHeight="dense"
          size={'small'}
        >
          <FormattedMessage
            id={currentJobMessage}
            defaultMessage={currentJobMessage}
          />
        </Styled.EmployeeStatus>
      </Styled.ReviewsFooter>
    </Styled.CardWrapper>
  );
};

const getCarouselItems = (
  reviews: Array<{ node: Review }>,
  isEditor: boolean
) =>
  reviews.map((review, index) => (
    <Card
      isEditor={isEditor}
      key={index}
      position={index}
      score={review.node.ratingAvg}
      quote={review.node.title}
      jobStatus={review.node.jobStatus}
      date={review.node.created}
      link={getTrackingUrl({
        to: review.node.url,
        trackingKey: 'viewSingleReviews',
      })}
    />
  ));

const Reviews = ({
  kununuReviews,
  kununuCommentsUrl,
  isEditor = false,
}: ReviewsProps) => {
  const firstRender = useRef(true);
  const reviews = kununuReviews?.edges || [];
  const totalReviews = kununuReviews?.total;
  const showMore =
    totalReviews > NUMBER_OF_REVIEWS_REQUESTED
      ? {
          href: getTrackingUrl({
            to: kununuCommentsUrl,
            trackingKey: 'viewAllReviews',
          }),
          target: '_blank',
          copyKey: 'EP_KUNUNU_ALL_REVIEWS',
          number: totalReviews,
          onClick: () => onAllReviewsClick(isEditor),
          externalLink: true,
        }
      : null;

  const handlePageChange = (pageChange: {
    direction: string;
    page: number;
  }) => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      trackPageChange(pageChange);
    }
  };

  return (
    <div>
      <ModuleHeader
        headlineCopyKey="EP_KUNUNU_REVIEW_TITLE"
        showMore={showMore}
        small
      />
      <Styled.Carousel onPageChange={handlePageChange}>
        {getCarouselItems(reviews, isEditor)}
      </Styled.Carousel>
    </div>
  );
};

export default Reviews;
