/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SocialInteractionTargetQueryVariables = Types.Exact<{
  urn: Types.Scalars['GlobalID']['input'];
}>;

export type SocialInteractionTargetQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    socialInteractionTarget?: {
      __typename: 'SocialInteractionTarget';
      urn: string;
      commentsCount: number;
      repliesCount: number;
      reactionsCount: number;
      sharesCount: number;
      userReactionType?: Types.SocialReactionType | null;
      id: string;
      permissions: {
        __typename: 'SocialPermissions';
        reactions: {
          __typename: 'SocialReactionPermissions';
          canCreate: boolean;
          canView: boolean;
          canDelete: boolean;
        };
        comments: {
          __typename: 'SocialCommentPermissions';
          canCreate: boolean;
          canUpdate: boolean;
          canView: boolean;
          canDelete: boolean;
          canDeleteMentions: boolean;
        };
        shares: {
          __typename: 'SocialSharePermissions';
          canCreate: boolean;
          canView: boolean;
          canDelete: boolean;
        };
        mentions: {
          __typename: 'SocialMentionPermissions';
          canDelete: boolean;
        };
      };
    } | null;
  } | null;
};

export type SocialInteractionTargetsQueryVariables = Types.Exact<{
  urns?: Types.InputMaybe<
    | Array<Types.Scalars['GlobalID']['input']>
    | Types.Scalars['GlobalID']['input']
  >;
}>;

export type SocialInteractionTargetsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    socialInteractionTargets?: Array<{
      __typename: 'SocialInteractionTarget';
      urn: string;
      commentsCount: number;
      repliesCount: number;
      reactionsCount: number;
      sharesCount: number;
      userReactionType?: Types.SocialReactionType | null;
      id: string;
      permissions: {
        __typename: 'SocialPermissions';
        reactions: {
          __typename: 'SocialReactionPermissions';
          canCreate: boolean;
          canView: boolean;
          canDelete: boolean;
        };
        comments: {
          __typename: 'SocialCommentPermissions';
          canCreate: boolean;
          canUpdate: boolean;
          canView: boolean;
          canDelete: boolean;
          canDeleteMentions: boolean;
        };
        shares: {
          __typename: 'SocialSharePermissions';
          canCreate: boolean;
          canView: boolean;
          canDelete: boolean;
        };
        mentions: {
          __typename: 'SocialMentionPermissions';
          canDelete: boolean;
        };
      };
    } | null> | null;
  } | null;
};

export type SocialCreateReactionMutationVariables = Types.Exact<{
  urn: Types.Scalars['GlobalID']['input'];
  trackingMetadata?: Types.InputMaybe<Types.SocialTrackingMetadataInput>;
}>;

export type SocialCreateReactionMutation = {
  __typename?: 'Mutation';
  socialCreateReaction?: {
    __typename?: 'SocialReactionMutationResult';
    success?: {
      __typename?: 'SocialReactionResult';
      interactionTarget?: {
        __typename: 'SocialInteractionTarget';
        urn: string;
        commentsCount: number;
        repliesCount: number;
        reactionsCount: number;
        sharesCount: number;
        userReactionType?: Types.SocialReactionType | null;
        id: string;
        permissions: {
          __typename: 'SocialPermissions';
          reactions: {
            __typename: 'SocialReactionPermissions';
            canCreate: boolean;
            canView: boolean;
            canDelete: boolean;
          };
          comments: {
            __typename: 'SocialCommentPermissions';
            canCreate: boolean;
            canUpdate: boolean;
            canView: boolean;
            canDelete: boolean;
            canDeleteMentions: boolean;
          };
          shares: {
            __typename: 'SocialSharePermissions';
            canCreate: boolean;
            canView: boolean;
            canDelete: boolean;
          };
          mentions: {
            __typename: 'SocialMentionPermissions';
            canDelete: boolean;
          };
        };
      } | null;
    } | null;
  } | null;
};

export type SocialDeleteReactionMutationVariables = Types.Exact<{
  urn: Types.Scalars['GlobalID']['input'];
}>;

export type SocialDeleteReactionMutation = {
  __typename?: 'Mutation';
  socialDeleteReaction?: {
    __typename?: 'SocialReactionMutationResult';
    success?: {
      __typename?: 'SocialReactionResult';
      interactionTarget?: {
        __typename: 'SocialInteractionTarget';
        urn: string;
        commentsCount: number;
        repliesCount: number;
        reactionsCount: number;
        sharesCount: number;
        userReactionType?: Types.SocialReactionType | null;
        id: string;
        permissions: {
          __typename: 'SocialPermissions';
          reactions: {
            __typename: 'SocialReactionPermissions';
            canCreate: boolean;
            canView: boolean;
            canDelete: boolean;
          };
          comments: {
            __typename: 'SocialCommentPermissions';
            canCreate: boolean;
            canUpdate: boolean;
            canView: boolean;
            canDelete: boolean;
            canDeleteMentions: boolean;
          };
          shares: {
            __typename: 'SocialSharePermissions';
            canCreate: boolean;
            canView: boolean;
            canDelete: boolean;
          };
          mentions: {
            __typename: 'SocialMentionPermissions';
            canDelete: boolean;
          };
        };
      } | null;
    } | null;
  } | null;
};

export const SocialInteractionTargetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'socialInteractionTarget' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GlobalID' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'socialInteractionTarget' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'urn' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urn' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SocialInteractionTarget',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialPermissions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocialPermissions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canUpdate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'canDeleteMentions' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shares' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialInteractionTarget' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocialInteractionTarget' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'urn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repliesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userReactionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SocialPermissions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SocialInteractionTargetQuery,
  SocialInteractionTargetQueryVariables
>;
export const SocialInteractionTargetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SocialInteractionTargets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'urns' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'GlobalID' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'socialInteractionTargets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'urns' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urns' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SocialInteractionTarget',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialPermissions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocialPermissions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canUpdate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'canDeleteMentions' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shares' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialInteractionTarget' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocialInteractionTarget' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'urn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repliesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userReactionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SocialPermissions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SocialInteractionTargetsQuery,
  SocialInteractionTargetsQueryVariables
>;
export const SocialCreateReactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SocialCreateReaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GlobalID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'trackingMetadata' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SocialTrackingMetadataInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialCreateReaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'interactionTargetUrn' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urn' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reactionType' },
                      value: { kind: 'EnumValue', value: 'LIKE' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'trackingMetadata' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackingMetadata' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interactionTarget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SocialInteractionTarget',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialPermissions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocialPermissions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canUpdate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'canDeleteMentions' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shares' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialInteractionTarget' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocialInteractionTarget' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'urn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repliesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userReactionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SocialPermissions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SocialCreateReactionMutation,
  SocialCreateReactionMutationVariables
>;
export const SocialDeleteReactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SocialDeleteReaction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GlobalID' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialDeleteReaction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'interactionTargetUrn' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urn' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'interactionTarget' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SocialInteractionTarget',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialPermissions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocialPermissions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reactions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canUpdate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'canDeleteMentions' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shares' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canCreate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SocialInteractionTarget' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SocialInteractionTarget' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'urn' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commentsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repliesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reactionsCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sharesCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userReactionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SocialPermissions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SocialDeleteReactionMutation,
  SocialDeleteReactionMutationVariables
>;
