import type { FC } from 'react';
import React from 'react';

import type { CompanyKununuReviewConnection } from '@xing-com/crate-common-graphql-types';

import type { KununuData } from '../../types';
import Benefits from '../benefits/benefits';
import Rating from '../rating/rating';
import Reviews from '../reviews/reviews';
import SchemaOrg from '../schema-org/schema-org';

import * as Styled from './kununu.styles';

type KununuProps = {
  pageSlug: string;
  companyName: string;
  recommendation?: number;
  score?: number;
  kununuReviews?: Partial<CompanyKununuReviewConnection>;
  isEditor: boolean;
} & Pick<
  KununuData,
  | 'benefits'
  | 'employerRatingsCount'
  | 'kununuCommentsUrl'
  | 'profileUuid'
  | 'companyProfileUrl'
  | 'evaluateProfileUrl'
>;
const Kununu: FC<KununuProps> = ({
  isEditor,
  pageSlug,
  companyProfileUrl,
  score,
  recommendation,
  benefits,
  companyName,
  kununuCommentsUrl,
  employerRatingsCount,
  kununuReviews,
  profileUuid,
  evaluateProfileUrl,
}) => (
  <Styled.Wrapper data-testid={'KUNUNU_MODULE'}>
    <Rating
      isEditor={isEditor}
      score={score}
      recommendation={recommendation}
      profileUrl={companyProfileUrl}
    />
    {kununuReviews && (kununuReviews?.total ?? 0) > 0 && (
      <Reviews
        isEditor={isEditor}
        // @ts-expect-error TODO fix this type
        kununuReviews={kununuReviews}
        kununuCommentsUrl={kununuCommentsUrl}
      />
    )}
    {benefits.length > 0 && (
      <Benefits
        benefits={benefits}
        companyProfileUrl={companyProfileUrl}
        employerRatingsCount={employerRatingsCount}
        profileUuid={profileUuid}
        evaluateProfileUrl={evaluateProfileUrl}
      />
    )}
    {companyName &&
      kununuReviews &&
      (kununuReviews.total ?? 0) > 0 &&
      score && (
        <SchemaOrg
          pageSlug={pageSlug}
          companyName={companyName}
          // @ts-expect-error TODO fix this type
          reviewsCount={kununuReviews?.total}
          ratingValue={score}
        />
      )}
  </Styled.Wrapper>
);

export default React.memo(Kununu);
