import type React from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import type { FileUploaded } from '@xing-com/crate-entity-pages-common';
import { FileUploadButton as DefaultFileUploadButton } from '@xing-com/crate-entity-pages-common';
import { IconUpload } from '@xing-com/icons';

import * as Styled from './documents-editor.styles';

type FileUploadButtonProps = {
  onSuccess: (fileData: FileUploaded) => void;
  isUploading?: boolean;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  disableUpload?: boolean;
};
export const FileUploadButton: FC<FileUploadButtonProps> = ({
  onSuccess,
  setIsUploading,
  isUploading,
  disableUpload,
}) => {
  const handleOnPublish = ({ file, id }: FileUploaded) => {
    onSuccess({ file, id });
  };

  return (
    <Styled.FileUploadContainer>
      <DefaultFileUploadButton
        disableUpload={disableUpload}
        onError={() => setIsUploading(false)}
        onChange={() => setIsUploading(true)}
        onPublish={handleOnPublish}
      >
        <Styled.FileUploadButton
          disabled={disableUpload}
          loading={isUploading}
          variant={'secondary'}
          size={'small'}
          icon={IconUpload}
        >
          <FormattedMessage
            id={'EP_UPLOAD_DOCUMENT'}
            defaultMessage={'EP_UPLOAD_DOCUMENT'}
          />
        </Styled.FileUploadButton>
      </DefaultFileUploadButton>
    </Styled.FileUploadContainer>
  );
};
