/* eslint-disable @typescript-eslint/consistent-type-imports */
import { useQuery } from '@apollo/client';
import React from 'react';

import { Carousel } from '@xing-com/carousel';
import {
  ModuleHeader,
  UpsellBanner,
  usePageContext,
  WarningMessageBox,
} from '@xing-com/crate-entity-pages-common';
import {
  SkeletonProfileImage,
  SkeletonHeadline,
  SkeletonBody,
} from '@xing-com/skeleton';

import { RecommendedCompaniesDocument } from '../../graphql/company-recommendations.gql-types';
import type { CompanyRecommendationsQuery } from '../../types';

import CompanyRecommendationCard from './company-recommendation-card';
import * as Styled from './company-recommendations.styles';

type Props = {
  companyId: string;
};

const CompanyRecommendationCardSkeleton: React.FC = () => (
  <Styled.Card>
    <Styled.ProfileBackground>
      <Styled.SkeletonProfileImage>
        <SkeletonProfileImage size="large" />
      </Styled.SkeletonProfileImage>
    </Styled.ProfileBackground>
    <Styled.SkeletonHeadline>
      <SkeletonHeadline size="medium" amountRows={1} />
    </Styled.SkeletonHeadline>
    {/* @ts-expect-error FIXME: SC6 */}
    <SkeletonBody size="medium" amountRows={2} />
  </Styled.Card>
);

const CompanyRecommendationCarouselSkeleton: React.FC = () => (
  <Carousel
    itemWidthConfined="50%"
    itemWidth="80%"
    itemWidthWide="280px"
    itemSpacingConfined="16px"
  >
    <CompanyRecommendationCardSkeleton />
    <CompanyRecommendationCardSkeleton />
    <CompanyRecommendationCardSkeleton />
  </Carousel>
);

const CompanyRecommendations: React.FC<Props> = ({ companyId }) => {
  const { pageContext } = usePageContext();
  const { data, loading, refetch } = useQuery<CompanyRecommendationsQuery>(
    RecommendedCompaniesDocument,
    {
      variables: {
        id: companyId,
      },
      errorPolicy: 'all',
    }
  );

  if (!loading && !data?.company?.relatedCompanies) {
    return (
      <div data-testid="errorContainer">
        <ModuleHeader headlineCopyKey="CP_RECOMMENDS_ADVERTISEMENT_ABOUT_US" />
        <WarningMessageBox
          headerText="EP_ERROR_HEADER"
          bodyText="EP_ERROR_BODY"
          buttonText="EP_ERROR_RELOAD_CTA"
          onClick={() => refetch()}
        />
      </div>
    );
  }

  if (loading) {
    return (
      <div data-testid="loadingContainer">
        <ModuleHeader headlineCopyKey="CP_RECOMMENDS_ADVERTISEMENT_ABOUT_US" />
        <CompanyRecommendationCarouselSkeleton />
      </div>
    );
  }

  const upsellActive = pageContext.upsellActive;

  const recommendedCompanies = data?.company?.relatedCompanies?.collection;

  if (recommendedCompanies?.length === 0) return null;

  return (
    <div data-testid={'COMPANY_RECOMMENDATIONS_MODULE'}>
      <ModuleHeader headlineCopyKey="CP_RECOMMENDS_ADVERTISEMENT_ABOUT_US" />
      {upsellActive ? (
        <Styled.UpsellLayout>
          <UpsellBanner
            headerCopyKey="EP_UPSELL_COMPANY_RECOMMENDED_HEADER"
            bodyCopyKey="EP_UPSELL_COMPANY_RECOMMENDED_BODY"
            moduleName="companyrecommendationsmodule"
          />
        </Styled.UpsellLayout>
      ) : null}
      <Carousel
        itemWidthConfined="50%"
        itemWidth="80%"
        itemWidthWide="280px"
        itemSpacingConfined="16px"
      >
        {recommendedCompanies?.map(
          ({
            company: { id, companyName, kununuData, logos, links, entityPage },
          }) => (
            <CompanyRecommendationCard
              key={id}
              profileImage={logos?.logo128px}
              profileName={companyName}
              numberOfRatings={kununuData?.ratingCount}
              kununuRating={kununuData?.ratingAverage}
              link={links?.public}
              backgroundImageUrl={entityPage?.coverImage?.[0]?.url}
            />
          )
        )}
      </Carousel>
    </div>
  );
};

export default CompanyRecommendations;
