import { FormattedMessage } from 'react-intl';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { PanoramicSvg } from '../../images/panoramic';
import type { ArticleCardView } from '../../types/types';

import { ArticleCardBottom } from './article-card-bottom/article-card-bottom';
import { ArticleCardTop } from './article-card-top/article-card-top';
import { ArticleCardSkeleton } from './article-card.skeleton';
import * as Styled from './article-card.styles';

type Props = {
  article: ArticleCardView;
  onButtonActionCompleted?: () => void;
};
export const ArticleCard: React.FC<Props> & {
  Skeleton: typeof ArticleCardSkeleton;
} = ({ article, onButtonActionCompleted = () => undefined }) => {
  const { content, isFeatured, url, isExternalSource } = article;
  const { isLoggedOut } = useLoginState();

  return (
    <article data-testid={`ARTICLE-CARD-${article.id}`}>
      <ArticleCardTop
        article={article}
        onButtonActionCompleted={onButtonActionCompleted}
      />
      <Styled.Content $isFeatured={isFeatured}>
        <Styled.TextContainer>
          <Styled.Headline as={'h3'}>{content.title}</Styled.Headline>
          <Styled.Summary>{content.summary}</Styled.Summary>
          <Styled.ArticlePage>{content.source}</Styled.ArticlePage>
          {isExternalSource && isLoggedOut ? (
            <Styled.Button
              onClick={() => window.open(url, '_blank', 'noopener')}
            >
              <FormattedMessage id="EP_READ_MORE" />
            </Styled.Button>
          ) : (
            <Styled.Link href={url}>
              <FormattedMessage id="EP_READ_MORE" />
            </Styled.Link>
          )}
        </Styled.TextContainer>
        <Styled.ImageContainer>
          {content.cover.isVideo ? (
            <Styled.PlayIconWrapper>
              <Styled.PlayIcon />
            </Styled.PlayIconWrapper>
          ) : null}
          {content.cover.url ? (
            <img
              alt={content.cover.caption}
              src={content.cover.url}
              loading="lazy"
            />
          ) : (
            <PanoramicSvg />
          )}
        </Styled.ImageContainer>
      </Styled.Content>
      <ArticleCardBottom article={article} />
    </article>
  );
};

ArticleCard.Skeleton = ArticleCardSkeleton;
