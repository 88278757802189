import type { FC, PropsWithChildren } from 'react';
import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';

import getCroppedImg from '../../utils';

import * as Styled from './crop-image.styles';

type CropImageProps = {
  onCropReady?: () => void;
  image: string;
  imageName?: string;
  onChange: (croppedImage: { file: File; croppedImageUrl: string }) => void;
  onDiscard: () => void;
  onSave: () => void;
  isUploading?: boolean;
  aspectRatio?: number;
  discardCopy?: string;
  publishCopy?: string;
};
export const CropImage: FC<PropsWithChildren<CropImageProps>> = ({
  isUploading,
  image,
  imageName,
  onCropReady = () => undefined,
  onChange,
  onDiscard,
  onSave,
  aspectRatio,
  discardCopy,
  publishCopy,
  children,
}) => {
  const rotation = 0;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const cropImage = useCallback(async () => {
    const croppedImage = await getCroppedImg(
      image,
      croppedAreaPixels,
      rotation,
      imageName
    );

    if (croppedImage) onChange(croppedImage);
  }, [croppedAreaPixels, rotation]);

  return (
    <React.Fragment>
      <div>
        <Styled.CropContainer data-cy="CROP_IMAGE_SREEN">
          {Cropper && (
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio || 1 / 1}
              zoomWithScroll={false}
              onCropAreaChange={onCropReady}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={false}
            />
          )}
        </Styled.CropContainer>
        <Styled.Controls>
          <Styled.SliderText fontWeight="bold" size="small">
            <FormattedMessage
              id="VIDEO_UPLOAD_CROP_MIN"
              defaultMessage="VIDEO_UPLOAD_CROP_MIN"
            />
          </Styled.SliderText>
          <Styled.Slider
            value={zoom}
            min={1}
            max={2}
            step={0.01}
            showValueLabel={false}
            onChange={(event: any, value: any) => {
              if (value) {
                setZoom(value);
              }
            }}
            direction="left"
            type="continuous"
          />
          <Styled.SliderText fontWeight="bold" size="small">
            <FormattedMessage
              id="VIDEO_UPLOAD_CROP_MAX"
              defaultMessage="VIDEO_UPLOAD_CROP_MAX"
            />
          </Styled.SliderText>
        </Styled.Controls>
        {children && (
          <Styled.ChildrenContainer>{children}</Styled.ChildrenContainer>
        )}
        <Styled.CropCtaContainer $noChildren={!children}>
          <Styled.CancelCta
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            variant="text"
            size="medium"
            disabled={isUploading}
            onClick={() => onDiscard()}
            data-cy="DISCARD_IMAGE_BUTTON"
          >
            <FormattedMessage id={discardCopy} defaultMessage={discardCopy} />
          </Styled.CancelCta>
          <Button
            variant="primary"
            size="medium"
            loading={isUploading}
            onClick={() => {
              cropImage();
              onSave();
            }}
            data-cy="SAVE_IMAGE_BUTTON"
          >
            <FormattedMessage id={publishCopy} defaultMessage={publishCopy} />
          </Button>
        </Styled.CropCtaContainer>
      </div>
    </React.Fragment>
  );
};
