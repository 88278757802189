import { IconPublic } from '@xing-com/icons';

export const COMMBOX_SELECTABLE_AUDIENCES = [
  {
    id: 'PUBLIC',
    Icon: IconPublic,
    audienceName: 'COMMBOX_AUDIENCE_PUBLIC',
    audienceDescription: 'COMMBOX_AUDIENCE_PUBLIC_DESC',
  },
];
