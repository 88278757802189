import { useContext } from 'react';

import { PageContext } from '../../context/page-context/page-context';

export const usePageContext = () => {
  const context = useContext(PageContext);

  if (!context || !context.pageContext) {
    throw new Error('No host context found. Please setup the PageContext');
  }

  return context;
};
