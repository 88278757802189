import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { Flag, type FlagVariant } from '@xing-com/flag';

import type { SearchCard } from '../../types/search-card';

import { SearchInputCardSkeleton } from './search-input-card.skeleton';
import * as Styled from './search-input-card.styles';

type SearchInputCardProps = {
  variant?: 'A' | 'B';
  cardData: SearchCard;
  isLoading?: boolean;
  isDisabled?: boolean;
  onAddContact: () => void;
};
export const SearchInputCard: FC<SearchInputCardProps> & {
  Skeleton: typeof SearchInputCardSkeleton;
} = ({ variant = 'A', cardData, isLoading, isDisabled, onAddContact }) => {
  const { $t } = useIntl();

  return (
    <Styled.ProfileInfo
      data-testid={`SEARCH-INPUT-CARD-${cardData.xingId}`}
      profileUrl={cardData.profileUrl}
      profileImage={{
        size: 'small',
        src: cardData.profileImage.src,
        profileName: 'user profile image',
      }}
      headline={{
        size: 'small',
        children:
          variant === 'A' ? (
            <div>
              <strong>{cardData?.displayName}</strong>
              {cardData.flag && (
                <Flag
                  size="small"
                  variant={cardData.flag.toLowerCase() as FlagVariant}
                />
              )}
            </div>
          ) : (
            cardData.displayName
          ),
      }}
      flag={
        variant === 'A'
          ? undefined
          : cardData.flag && {
              size: 'small',
              variant: cardData.flag.toLowerCase() as FlagVariant,
            }
      }
      textBody={[
        {
          size: 'xsmall',
          children: cardData?.occupation,
        },
      ]}
      buttons={[
        {
          variant: 'primary',
          size: 'small',
          // @ts-expect-error test case
          'data-testid': 'ADD_CONTACT_BUTTON',
          children: $t({ id: 'EP_ADD_CTA' }),
          loading: isLoading,
          disabled: isDisabled,
          onClick: onAddContact,
        },
      ]}
    />
  );
};

SearchInputCard.Skeleton = SearchInputCardSkeleton;
