import type * as React from 'react';

import { FormField } from '@xing-com/text-field';

export type ShareTextAreaType = {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  [props: string]: unknown;
};

export const ShareTextArea: React.FC<ShareTextAreaType> = ({
  autoFocus,
  disabled,
  errorMessage = '',
  label = '',
  value,
  onChange,
  ...props
}) => (
  // @ts-expect-error FIXME: SC6
  <FormField
    {...{ label, autoFocus, disabled, value, onChange }}
    data-qa="share-text-area"
    helperText={errorMessage}
    error={!!errorMessage}
    multiline
    size="large"
    {...props}
  />
);
