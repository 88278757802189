import { useEffect } from 'react';

import ttt from '@xing-com/ticktricktrack';

export type useTrackOnRenderProps = (
  PropChannel: string,
  PropTrackAction: string,
  urn: string
) => void;

export const useTrackOnRender: useTrackOnRenderProps = (
  PropChannel,
  PropTrackAction,
  urn
) => {
  useEffect(() => {
    ttt.event('PropTrackAction', { PropTrackAction, PropChannel });
  }, [urn]);
};
