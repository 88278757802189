import type { BackendTrackingMetaData } from '..';

type getSocialTrackingMetaDataProps = (props: {
  backendTrackingMetaData?: BackendTrackingMetaData;
  propSocialInteractionId?: string;
  referrerUrl?: string;
}) => {
  trackingMetadata: {
    channel: 'web';
    deliveryTimestamp: string;
    page?: string;
    position?: string;
    referrerUrl: string;
    trackingTokens?: string[] | [];
    trackingUUID: string;
    userAgent: string;
  };
};

export const getSocialTrackingMetaData: getSocialTrackingMetaDataProps = ({
  backendTrackingMetaData = { page: '', position: '', trackingTokens: [''] },
  propSocialInteractionId = '',
  referrerUrl,
}) => ({
  trackingMetadata: {
    channel: 'web',
    deliveryTimestamp: new Date(Date.now()).toISOString(),
    referrerUrl: referrerUrl || global?.top?.location?.href || '',
    trackingUUID: propSocialInteractionId,
    userAgent: global?.navigator?.userAgent || '',
    ...backendTrackingMetaData,
  },
});
