import { useState, useRef } from 'react';
import type { FC } from 'react';

import {
  type PageViewTrackingEvent,
  useTracking,
} from '@xing-com/crate-communication-tracking';
import {
  ModuleContextProvider,
  useEditContext,
  usePageContext,
  ErrorBoundary,
} from '@xing-com/crate-entity-pages-common';
import {
  trackPageviewModules,
  trackModuleVisibleAction,
  getNwtTrackingProps,
} from '@xing-com/crate-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import Observer from '@xing-com/intersection-observer';
import { GridContainer } from '@xing-com/xing-grid';

import { modulesMap as MODULES } from '../../config/modules-map';
import { useModulesLoaded } from '../../hooks/use-modules-loaded/use-modules-loaded';

import * as Styled from './modules-renderer.styles';

export type IntersectUseState = {
  [key: string]: boolean;
};

export type TrackedRef = {
  [key: string]: any;
  pageview?: boolean;
};
type Ratio = {
  [type: string]: number;
};

type ModulesRendererProps = {
  onEnterModule?: (type: string) => void;
  navigationOffset: number;
};
export const ModulesRenderer: FC<ModulesRendererProps> = ({
  onEnterModule = () => undefined,
  navigationOffset,
}) => {
  const { isEditing } = useEditContext();
  const { isLoggedIn } = useLoginState();
  const { setModuleLoaded } = useModulesLoaded();
  const { pageContext } = usePageContext();
  const { track: trackPageView } =
    useTracking<PageViewTrackingEvent<Record<string, unknown>>>();

  const ratio = useRef<Ratio>({});
  const tracked = useRef<TrackedRef>({});
  const [intersected, setIntersected] = useState<IntersectUseState>({});

  const handleModuleIntersect = (event: any, type: string, index: number) => {
    ratio.current = { ...ratio.current, [type]: event.intersectionRatio };

    setTimeout(() => {
      const maxValue = Math.max(...Object.values(ratio.current));
      const index =
        maxValue === 0
          ? -1
          : pageContext.pageModulesByType.findIndex(
              (type) => ratio.current[type] === maxValue
            );

      onEnterModule(pageContext.pageModulesByType[index]);
    }, 400);

    if (!tracked.current[type] && event.isIntersecting) {
      tracked.current = {
        ...tracked.current,
        [type]: true,
      };

      setIntersected({ ...intersected, [type]: true });
      trackModuleVisibleAction(type, index + 1);

      // NWT module in viewport
      trackPageView({
        type: 'nwt',
        application: 'wbm',
        event: 'entered_viewport',
        eventSchema: 'extended',
        sentBy: 'entity_pages',
        moduleTitle: type,
        modulePosition: index,
        ...getNwtTrackingProps({ pageContext, isEditing }),
      });
    }
  };

  if (!tracked.current['pageview'] && isEditing !== null) {
    tracked.current = {
      ...tracked.current,
      pageview: true,
    };

    trackPageviewModules({
      isLoggedIn: isLoggedIn,
      isEditor: pageContext.isEditor,
      isEditing: isEditing,
      modulesByType: pageContext.pageModulesByType,
      id: pageContext.pageId?.split('.')?.[0],
      focusType: pageContext.focusType as string,
      contractType: pageContext.contractType,
      isFollowing: !!pageContext.isFollowingPage,
    });

    // nwt
    trackPageView({
      type: 'nwt',
      page: 'entity_pages/overview',
      application: 'wbm',
      event: 'viewed_screen',
      eventSchema: 'basic',
      sentBy: 'entity_pages',
      ...getNwtTrackingProps({ pageContext, isEditing }),
    });
  }

  return (
    <GridContainer data-testid="ENTITY-PAGES-MODULES-LIST">
      <Styled.Row>
        <Styled.Column size={12}>
          {pageContext.pageModules?.map((pageModulesItem, i) => {
            const { globalId, properties, type } = pageModulesItem;
            const hasInsiderAboutModule =
              pageContext.isInsiderPage && type === 'about_us';

            const Module = hasInsiderAboutModule
              ? MODULES['about_insider'].module
              : type && MODULES[type]
                ? MODULES[type].module
                : null;

            if (!Module || !type) return null;

            const moduleContext = {
              moduleProperties: properties,
              intersected: type ? intersected[type] : null,
              moduleType: type,
              moduleGlobalId: globalId,
            };

            return (
              <ModuleContextProvider value={moduleContext} key={i}>
                <Observer
                  onChange={(event: any) => {
                    handleModuleIntersect(event, type, i);
                  }}
                  rootMargin={`-${navigationOffset}px 0px 0px 0px`}
                  threshold={[0, 0.2, 0.4, 0.6, 0.7, 1]}
                >
                  <Styled.ModuleWrapper
                    id={`module-${type}`}
                    data-testid={`ENTITY-PAGE-${type.toUpperCase()}-MODULE`}
                    data-cy={`${type}Module`}
                  >
                    <ErrorBoundary onError={() => setModuleLoaded(type)}>
                      <Module key={i} {...pageContext} />
                    </ErrorBoundary>
                  </Styled.ModuleWrapper>
                </Observer>
              </ModuleContextProvider>
            );
          })}
        </Styled.Column>
      </Styled.Row>
    </GridContainer>
  );
};
