/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type EntityPagesArticleModuleArticlesArticleDisableMutationVariables =
  Types.Exact<{
    input: Types.ArticlesDisableArticleInput;
  }>;

export type EntityPagesArticleModuleArticlesArticleDisableMutation = {
  __typename?: 'Mutation';
  articlesDisableArticle?:
    | {
        __typename?: 'ArticlesArticle';
        globalId: string;
        publicationState: Types.ArticlePublicationState;
      }
    | {
        __typename?: 'ArticlesError';
        message?: string | null;
        details?: Array<string> | null;
      }
    | null;
};

export const EntityPagesArticleModuleArticlesArticleDisableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'entityPagesArticleModuleArticlesArticleDisable',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ArticlesDisableArticleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesDisableArticle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ArticlesArticle' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicationState' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ArticlesError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'details' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EntityPagesArticleModuleArticlesArticleDisableMutation,
  EntityPagesArticleModuleArticlesArticleDisableMutationVariables
>;
