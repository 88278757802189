import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentArticleStatus } from '@xing-com/crate-common-graphql-types';
import { useEditContext } from '@xing-com/crate-entity-pages-common';

import type { ArticleCardView } from '../../../types/types';
import { ArticleState } from '../article-state/article-state';

import {
  ArticleCardTopWrapper,
  ButtonsContainer,
  InfoContainer,
} from './article-card-top.styles';
import { ArticleEditButton } from './article-controls/article-edit-button';
import { ArticleFeatureButton } from './article-controls/article-feature-button';
import { ArticleHideButton } from './article-controls/article-hide-button';
import { ArticleRemoveButton } from './article-controls/article-remove-button';
import { ArticleShowButton } from './article-controls/article-show-button';
import { ArticleUnfeatureButton } from './article-controls/article-unfeature-button';

type Props = {
  article: ArticleCardView;
  onButtonActionCompleted?: () => void;
};
export const ArticleCardTop: React.FC<Props> = ({
  article,
  onButtonActionCompleted = () => undefined,
}) => {
  const { formatDate } = useIntl();
  const { isEditing } = useEditContext();
  const { publicationState, publishedAt, updatedAt, isFeatured } = article;

  const [actionLoading, setActionLoading] = useState(false);

  const isPublished = publicationState === ContentArticleStatus.Published;
  const isHidden = publicationState === ContentArticleStatus.Disabled;
  const articleDate = publishedAt ?? updatedAt;

  return (
    <ArticleCardTopWrapper>
      <InfoContainer>
        {isEditing ? (
          <span>
            <ArticleState state={publicationState} />
          </span>
        ) : null}
        {articleDate ? (
          <span>
            {formatDate(articleDate, {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </span>
        ) : null}
        {isFeatured ? (
          <strong>
            <FormattedMessage id="EP_ARTICLES_MODULE_PINNED_LABEL" />
          </strong>
        ) : null}
      </InfoContainer>
      {isEditing ? (
        <ButtonsContainer>
          {isPublished ? (
            isFeatured ? (
              <ArticleUnfeatureButton
                article={article}
                disabled={actionLoading}
                onLoadingChange={setActionLoading}
                onCompleted={onButtonActionCompleted}
              />
            ) : (
              <ArticleFeatureButton
                article={article}
                disabled={actionLoading}
                onLoadingChange={setActionLoading}
                onCompleted={onButtonActionCompleted}
              />
            )
          ) : null}
          <ArticleEditButton article={article} disabled={actionLoading} />
          {isPublished ? (
            <ArticleHideButton
              article={article}
              disabled={actionLoading}
              onLoadingChange={setActionLoading}
              onCompleted={onButtonActionCompleted}
            />
          ) : null}
          {isHidden ? (
            <ArticleShowButton
              article={article}
              disabled={actionLoading}
              onLoadingChange={setActionLoading}
              onCompleted={onButtonActionCompleted}
            />
          ) : null}

          <ArticleRemoveButton
            article={article}
            disabled={actionLoading}
            onLoadingChange={setActionLoading}
            onCompleted={onButtonActionCompleted}
          />
        </ButtonsContainer>
      ) : null}
    </ArticleCardTopWrapper>
  );
};
