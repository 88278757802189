import type { FC } from 'react';

import { usePageContext } from '@xing-com/crate-entity-pages-common';
// eslint-disable-next-line monorepo/forbidden-imports
import { TopBar as DefaultTopBar } from '@xing-com/top-bar';

import { CreateButton } from './create-button';

export const TopBar: FC = () => {
  const { pageContext } = usePageContext();

  const showAction = pageContext.hasNewsModule && pageContext.isEditor;

  return (
    <DefaultTopBar
      title={pageContext.pageTitle ?? 'XING'}
      subTitle={undefined}
      type="subheader"
      action={showAction && <CreateButton />}
      skipAdditionalButtons
    />
  );
};
