import type * as React from 'react';

import { useHost } from '@xing-com/crate-xinglet';

import type { SocialBarProps } from '../../types';
import { SocialBarSkeleton } from '../social-bar-skeleton/social-bar-skeleton';

export type { SocialBarProps };

export const SocialBar: React.VFC<SocialBarProps> = (props) => {
  const { XingletLoader } = useHost();

  return (
    <XingletLoader
      name="@xing-com/crate-social-bar-xinglet"
      fallback={<SocialBarSkeleton />}
      {...props}
    />
  );
};
