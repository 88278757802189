import styled, { css } from 'styled-components';

import { mediaSmallOrTiny, mediaWideNavless } from '@xing-com/layout-tokens';
import {
  scale070,
  scale080,
  scale130,
  spaceL,
  spaceM,
  spaceS,
  xdlColorTextInvert,
} from '@xing-com/tokens';
import { Hero as InnerHero, BodyCopy } from '@xing-com/typography';

const logoOffsetDesktop = '-95px';

export const PrimaryArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  @media ${mediaSmallOrTiny} {
    margin-top: ${spaceM};
  }
  @media (min-width: 740px) and (max-width: 1024px) {
    margin-top: ${spaceL};
  }

  @media (prefers-color-scheme: light) and ${mediaWideNavless} {
    color: ${xdlColorTextInvert};
  }
  @media ${mediaWideNavless} {
    height: calc(-1 * ${logoOffsetDesktop});
    padding-bottom: ${spaceM};
  }
`;
export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${spaceS};
`;
export const Hero = styled(InnerHero)<{ $isSubpage: boolean }>`
  display: flex;
  ${({ $isSubpage }) =>
    $isSubpage &&
    css`
      flex-direction: column-reverse;
    `}
  @media (prefers-color-scheme: light) {
    color: ${xdlColorTextInvert};
  }

  &.title {
    margin: 0;
    hyphens: auto;
  }

  @media ${mediaWideNavless} {
    ${({ $isSubpage }) =>
      $isSubpage &&
      css`
        font-size: ${scale130};
      `}
  }
`;
export const Subtitle = styled.span`
  display: block;
  margin: 0;
  hyphens: auto;
  font-size: ${scale080};
  font-weight: 400;
  width: fit-content;
  @media ${mediaSmallOrTiny} {
    font-size: ${scale070};
  }
  @media ${mediaSmallOrTiny} {
    line-height: 17px;
  }
`;
export const WhiteSpace = styled.span`
  display: none;
`;
export const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const HeaderSubtitle = styled(BodyCopy)`
  margin: 0;
  hyphens: auto;
  @media ${mediaSmallOrTiny} {
    line-height: 17px;
  }
  @media (prefers-color-scheme: light) {
    color: ${xdlColorTextInvert};
  }
`;
