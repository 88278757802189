import * as React from 'react';
import { useIntl } from 'react-intl';

import { StarList } from './star-list';
import * as Styled from './star-rating.styles';
import type { StarRatingProps } from './star-rating.types';

export const StarRating = React.forwardRef<HTMLDivElement, StarRatingProps>(
  ({ className, noValue, rating, ...props }, forwardedRef): JSX.Element => {
    const intl = useIntl();
    const ratingFixed = Number(rating).toFixed(1);

    return (
      <Styled.Rating
        className={className}
        data-xds="StarRating"
        aria-label={intl.formatMessage(
          {
            id: 'XDS_STAR_RATING_A11Y',
            defaultMessage: 'Rating: {rating} out of 5 stars',
          },
          { rating: ratingFixed }
        )}
        ref={forwardedRef}
        role="img"
        {...props}
      >
        {rating && !noValue ? (
          <Styled.Text forwardedAs="span" size="small" noMargin>
            {ratingFixed}
          </Styled.Text>
        ) : null}
        <StarList rating={rating} emptyState={typeof rating === 'undefined'} />
      </Styled.Rating>
    );
  }
);

StarRating.displayName = 'StarRating';
