import type * as React from 'react';
import styled from 'styled-components';

import {
  spaceM,
  spaceS,
  spaceXS,
  xdlColorTextSecondary,
} from '@xing-com/tokens';

export type DotProps = {
  margin?: 'xsmall' | 'small' | 'medium';
  isGrey?: boolean;
};

export const Dot: React.FC<DotProps> = ({ margin, isGrey = false }) => (
  <StyledDot {...{ $isGrey: isGrey, $margin: margin }}>{'•'}</StyledDot>
);

const xsmall = `margin: 0 ${spaceXS}`;
const small = `margin: 0 ${spaceS}`;
const medium = `margin: 0 ${spaceM}`;

const StyledDot = styled.span<{ $margin?: string; $isGrey: boolean }>`
  ${({ $isGrey }) => $isGrey && `color: ${xdlColorTextSecondary};`};
  ${({ $margin }) => $margin === 'xsmall' && xsmall};
  ${({ $margin }) => $margin === 'small' && small};
  ${({ $margin }) => $margin === 'medium' && medium};
`;
