import type React from 'react';

import { usePageContext } from '@xing-com/crate-entity-pages-common';
import { IconEdit } from '@xing-com/icons';

import {
  ARTICLES_ARTICLE_EDIT_LINK,
  CONTENT_ARTICLE_EDIT_LINK,
} from '../../../../constants/urls';
import * as Styled from '../article-card-top.styles';

import type { ArticleControlButton } from './types';

type Props = Omit<
  ArticleControlButton,
  'onLoadingChange' | 'onCompleted' | 'onError'
>;

export const ArticleEditButton: React.FC<Props> = ({ article, disabled }) => {
  const { pageContext } = usePageContext();

  const cppIdSlugged = pageContext.cppId ?? '';
  const cppId = cppIdSlugged.split('.')[0];
  const articleIdSlugged = article.id;
  const articleId = articleIdSlugged.split('.')[0];

  const handleOnClick = () => {
    const editUrl =
      article.typename === 'ContentArticle'
        ? CONTENT_ARTICLE_EDIT_LINK(cppId, articleId)
        : ARTICLES_ARTICLE_EDIT_LINK(articleIdSlugged);

    window.open(editUrl, '_blank');
  };

  return (
    <Styled.Button
      icon={IconEdit}
      size="small"
      variant="tertiary"
      disabled={disabled}
      onClick={handleOnClick}
    />
  );
};
