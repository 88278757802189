import type { FC } from 'react';

import { usePageContext } from '@xing-com/crate-entity-pages-common';

import HeaderActions from '../header-actions';
import { SocialProof } from '../social-proof/social-proof';

import * as Styled from './tertiary-area.styles';

export const TertiaryArea: FC = () => {
  const { pageContext } = usePageContext();
  const { socialProof } = pageContext;

  return (
    <Styled.TertiaryArea className="tertiaryArea">
      {socialProof && socialProof.total > 0 && (
        <Styled.TertiaryMainArea>
          <SocialProof />
        </Styled.TertiaryMainArea>
      )}
      <Styled.HeaderActionsArea>
        <HeaderActions />
      </Styled.HeaderActionsArea>
    </Styled.TertiaryArea>
  );
};
