import type { Edge, SocialCommentsData } from '../../types';

export type extractTotalEdgesAndCursorProps = (props?: SocialCommentsData) => {
  cursor: string;
  edges: Edge[];
  hasNextPage: boolean;
  total: number;
};

export const extractTotalEdgesAndCursor: extractTotalEdgesAndCursorProps = (
  data
) => {
  if (!data?.viewer?.socialReactions)
    return { total: 0, edges: [], cursor: '', hasNextPage: false };

  const {
    viewer: {
      socialReactions: {
        edges,
        pageInfo: { endCursor, hasNextPage },
        total,
      },
    },
  } = data;
  return { total, edges, cursor: endCursor, hasNextPage };
};
