import { useMutation } from '@apollo/client';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { StatusBanner } from '@xing-com/banner';
import { Button } from '@xing-com/button';
import { IconAddContact, IconMessages } from '@xing-com/icons';
import { Link } from '@xing-com/link';
import { ProfileImage } from '@xing-com/profile-image';
import { ProfileInfo } from '@xing-com/profile-info';
import { Headline, Meta } from '@xing-com/typography';
import { UserFlag } from '@xing-com/user-flag';

import { CreateSocialContactRequestDocument } from '../../graphql';
import { useIsSmallScreen } from '../../hooks';
import type { LikerUser } from '../../types';
import { trackContactRequest } from '../../utils';

export type LikersListItemsProps = {
  propChannel: string;
  hasError: boolean;
  userPageName: string | unknown;
  likers: LikerUser[];
};

export const LikersListItems: React.FC<LikersListItemsProps> = ({
  propChannel,
  userPageName,
  likers,
  hasError,
}) => {
  const isSmallScreen = useIsSmallScreen();

  const [addContact, { loading, error: contactRequestError }] = useMutation(
    CreateSocialContactRequestDocument,
    {
      onCompleted: (data) => {
        const error = data.networkContactRequestsSend?.error;
        if (!error) {
          trackContactRequest({
            propChannel,
            propActionOrigin: 'social_sharers_list',
          });
        }
      },
      errorPolicy: 'all',
    }
  );

  const error = hasError || contactRequestError;

  return (
    <React.Fragment>
      {likers.map(
        ({
          userId,
          image,
          pageName,
          displayName,
          profileUrl,
          userFlags: { displayFlag },
          occupations,
          isContactWithCurrentUser,
        }) => {
          const buttonAddContact = {
            ...(!isSmallScreen && {
              children: <FormattedMessage id="SOCIAL_ADD_CONTACT" />,
            }),
            'data-testid': 'social-add-contact',
            icon: IconAddContact,
            loading,
            size: 'small',
            onClick: () =>
              addContact({ variables: { input: { recipientId: userId } } }),
            variant: 'tertiary',
          };

          const buttonSendMessage = {
            ...(!isSmallScreen && {
              children: <FormattedMessage id="SOCIAL_SEND_MESSAGE" />,
            }),
            'data-testid': 'social-send-message',
            href: `/messenger/chats/new/${pageName}`,
            icon: IconMessages,
            size: 'small',
            variant: 'secondary',
          };

          const button = isContactWithCurrentUser
            ? buttonSendMessage
            : buttonAddContact;

          return (
            <ProfileInfo
              key={userId}
              profileImage={
                <Link to={profileUrl}>
                  <ProfileImage
                    profileName={displayName}
                    size="small"
                    src={image}
                  />
                </Link>
              }
              actions={
                userPageName !== pageName ? (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  <Button {...[button]} />
                ) : undefined
              }
            >
              <Link to={profileUrl}>
                <Headline size="medium">
                  {displayName}&ensp;
                  <UserFlag
                    {...{ displayFlag, userId }}
                    showBasicFlag
                    size="medium"
                  />
                </Headline>
                <Meta size="small">{occupations[0]?.subline || ''}</Meta>
              </Link>
            </ProfileInfo>
          );
        }
      )}
      {error && (
        <StatusBanner variant="error" display="inline">
          <FormattedMessage id="SHARE_FETCH_ERROR_RETRY" />
        </StatusBanner>
      )}
    </React.Fragment>
  );
};
