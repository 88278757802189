import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import type { PostingPublicationState } from '@xing-com/crate-common-graphql-types';

import { PINNED } from '../../../config/constants';

import * as Styled from './news-status-flag.styles';

const STATE_DRAFT = 'DRAFT';
const STATE_PUBLISHED = 'PUBLISHED';
const STATE_SCHEDULED = 'SCHEDULED';
const STATE_HIDDEN = 'HIDDEN';

type NewsStatusFlagProps = {
  state: PostingPublicationState | 'PINNED';
};
export const NewsStatusFlag: FC<NewsStatusFlagProps> = ({ state }) => {
  let text;
  switch (state) {
    case STATE_PUBLISHED:
      text = 'NEWS_MODULE_PUBLISHED_STORY_LABEL';
      break;
    case STATE_DRAFT:
      text = 'NEWS_MODULE_DRAFT_LABEL';
      break;
    case STATE_SCHEDULED:
      text = 'NEWS_MODULE_SCHEDULE_LABEL';
      break;
    case STATE_HIDDEN:
      text = 'NEWS_MODULE_HIDDEN_LABEL';
      break;
    case PINNED:
      text = 'NEWS_MODULE_TOP_LABEL';
      break;
  }
  return (
    <Styled.StatusFlag state={state}>
      <FormattedMessage id={text} />
    </Styled.StatusFlag>
  );
};
