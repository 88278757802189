import styled, { css } from 'styled-components';

import { spaceL, spaceS, xdlColorTextSecondary } from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

// *** Base ***
const baseHeadline = css`
  margin-bottom: ${spaceS};
`;

const baseBodyCopy = css`
  color: ${xdlColorTextSecondary};
  margin-bottom: ${spaceL};
`;

// *** Components ***
const StyledHeadline = styled(Headline)`
  ${baseHeadline}
`;

const StyledBodyCopy = styled(BodyCopy)`
  ${baseBodyCopy}
`;

export const Styled = {
  Headline: StyledHeadline,
  BodyCopy: StyledBodyCopy,
};
