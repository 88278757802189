/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconExternalLink"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.2 6.6V9l-4.801-.001v9.6h9.6L15 13.8h2.4V21H3V6.6zM21 3v7.2h-2.4l-.001-3.052-7.268 7.268-1.697-1.697L16.953 5.4H13.8V3z"
    />
  </svg>
);
export default IconExternalLink;
