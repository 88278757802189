import { useMediaListener, BREAKPOINTS } from '@xing-com/util';

export type useIsSmallScreenProps = (
  breakpoint?: keyof typeof BREAKPOINTS
) => boolean;

export const useIsSmallScreen: useIsSmallScreenProps = (
  breakpoint = 'handheld'
) => {
  const device = useMediaListener();

  return device === BREAKPOINTS[breakpoint];
};
