import type { FC } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import type { EntityDocument } from '@xing-com/crate-common-graphql-types';
import { usePageContext } from '@xing-com/crate-entity-pages-common';
import {
  IconEdit,
  IconTrash,
  IconFilePDF,
  IconCross,
  IconTick,
} from '@xing-com/icons';
import { FormField } from '@xing-com/text-field';

import * as Styled from './documents-editor.styles';

type DocumentCommonTypes = {
  setEditDocumentId: (param: string | null) => void;
  setDeleteDocumentId: (param: string) => void;
  editDocumentId?: string | null;
};

type RenderEditItemProps = DocumentCommonTypes & {
  document: Partial<EntityDocument>;
  setEditDocumentDescription: (param: string) => void;
};
const RenderEditItem: FC<RenderEditItemProps> = ({
  document,
  setEditDocumentDescription,
  setEditDocumentId,
}) => {
  const [formError, setFormError] = useState<string | null>(null);
  const [description, setDescription] = useState(
    document.description || document.filename
  );
  const MAX_DESCRIPTION_CHARS = 35;
  const intl = useIntl();

  const handleDescriptionChange = (newDescription: string) => {
    if (newDescription.length <= MAX_DESCRIPTION_CHARS) {
      setDescription(newDescription);
    }
    if (formError) {
      setFormError(null);
    }
  };

  const submitDescription = (description: string) => {
    const newDescription = description.replace(/\s+/g, ' ').trim();

    if (newDescription.length > 0) {
      setEditDocumentDescription(newDescription);
    } else {
      setFormError('CANNOT_BE_EMPTY');
    }
  };

  return (
    <>
      <Styled.InputWrapper>
        <FormField
          // @ts-expect-error FIXME: SC6
          medium
          value={description}
          error={!!formError}
          onBlur={() =>
            description?.length === 0 ? setFormError('CANNOT_BE_EMPTY') : null
          }
          onChange={(e: any) => handleDescriptionChange(e.target.value)}
          label={intl.formatMessage({
            id: 'EP_DOCUMENT_TITLE_EDIT_LABEL',
            defaultMessage: 'EP_DOCUMENT_TITLE_EDIT_LABEL',
          })}
          helperText={
            formError
              ? intl.formatMessage({
                  id: 'EP_DOCUMENT_TITLE_EDIT_ERROR',
                  defaultMessage: 'EP_DOCUMENT_TITLE_EDIT_ERROR',
                })
              : intl.formatMessage(
                  {
                    id: 'EP_INPUT_CHARS_LEFT',
                    defaultMessage: 'EP_INPUT_CHARS_LEFT',
                  },
                  {
                    charactersNumber:
                      MAX_DESCRIPTION_CHARS - (description?.length ?? 0),
                  }
                )
          }
        />
      </Styled.InputWrapper>
      <Styled.ItemActionsWrapper>
        <Styled.ItemActionButton
          icon={IconTick}
          onClick={() => description && submitDescription(description)}
          size={'small'}
          variant={'primary'}
          aria-label="Tick"
        />
        <Styled.ItemActionButton
          icon={IconCross}
          size={'small'}
          variant={'tertiary'}
          aria-label="Cross"
          onClick={() => setEditDocumentId(null)}
        />
      </Styled.ItemActionsWrapper>
    </>
  );
};

type RenderItemProps = DocumentCommonTypes & {
  document: Partial<EntityDocument>;
};
const RenderItem: FC<RenderItemProps> = ({
  document,
  setDeleteDocumentId,
  editDocumentId,
  setEditDocumentId,
}) => {
  return (
    <>
      <Styled.TextWrapper>
        <Styled.Icon>
          <IconFilePDF width={24} height={24} />
        </Styled.Icon>
        <Styled.Description sizeWide={'small'}>
          <>{document.description || document.filename}</>
        </Styled.Description>
      </Styled.TextWrapper>
      <Styled.ItemActionsWrapper>
        <Styled.ItemActionButton
          icon={IconEdit}
          onClick={() => document?.id && setEditDocumentId(document?.id)}
          size={'small'}
          variant={'tertiary'}
          disabled={!!editDocumentId}
          aria-label="Edit"
        />
        <Styled.ItemActionButton
          icon={IconTrash}
          size={'small'}
          variant={'tertiary'}
          onClick={() => document?.id && setDeleteDocumentId(document?.id)}
          disabled={!!editDocumentId}
          aria-label="Trash"
        />
      </Styled.ItemActionsWrapper>
    </>
  );
};

type DocumentsEditorProps = DocumentCommonTypes & {
  documents: Partial<EntityDocument>[];
  setEditDocumentDescription: (param: any) => void;
};
export const DocumentsEditor: FC<DocumentsEditorProps> = ({
  documents,
  setEditDocumentDescription,
  setDeleteDocumentId,
  editDocumentId,
  setEditDocumentId,
}) => {
  const editItemIndex = documents.findIndex(
    (item) => item.id === editDocumentId
  );
  const { pageContext } = usePageContext();

  return (
    <>
      <div>
        {documents.map((document, index) => (
          <Styled.ListItemsWrapper
            key={document.id}
            $disabled={!!editDocumentId && document.id !== editDocumentId}
            $beforeEdit={!!editDocumentId && index === editItemIndex - 1}
            $edit={!!editDocumentId && document.id === editDocumentId}
          >
            <>
              {document.id !== editDocumentId ? (
                <RenderItem
                  document={document}
                  setDeleteDocumentId={setDeleteDocumentId}
                  editDocumentId={editDocumentId}
                  setEditDocumentId={setEditDocumentId}
                />
              ) : (
                <RenderEditItem
                  document={document}
                  setEditDocumentDescription={setEditDocumentDescription}
                  setDeleteDocumentId={setDeleteDocumentId}
                  editDocumentId={editDocumentId}
                  setEditDocumentId={setEditDocumentId}
                />
              )}
            </>
          </Styled.ListItemsWrapper>
        ))}
      </div>
      <Styled.ActionsWrapper>
        <Button
          disabled={!!editDocumentId}
          size={'medium'}
          variant={'primary'}
          onClick={pageContext.goBackUrl}
        >
          <FormattedMessage id="EP_DONE_CTA" defaultMessage="EP_DONE_CTA" />
        </Button>
      </Styled.ActionsWrapper>
    </>
  );
};
