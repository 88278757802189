import { useQuery, useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useState, useEffect } from 'react';

import type { FileUploaded } from '@xing-com/crate-entity-pages-common';
import {
  usePageContext,
  useEditContext,
  EmptyState,
  useErrorContext,
  WarningMessageBox as WarningBox,
} from '@xing-com/crate-entity-pages-common';
import { trackEditSaving } from '@xing-com/crate-entity-pages-common/src/tracking';

import { EntityPageCreateDocumentDocument } from '../../../graphql/mutation/entity-page-create-document.gql-types';
import { EntityPageDeleteDocumentDocument } from '../../../graphql/mutation/entity-page-delete-document.gql-types';
import { EntityPageUpdateDocumentDocument } from '../../../graphql/mutation/entity-page-update-document.gql-types';
import { GetDocumentsDocument } from '../../../graphql/queries/documents-query.gql-types';

import { DeleteDialog } from './delete-dialog';
import { DocumentsEditor } from './documents-editor';
import { DocumentsEditorHeader } from './documents-editor-header';
import { handleCache } from './handle-cache';
import { GalleryManagerSkeleton } from './skeleton/skeleton';

const DocumentsEditorContainer: FC = () => {
  const { pageContext } = usePageContext();
  const { pageSlug } = pageContext ?? {};
  const { showError } = useErrorContext();
  const { trackingData } = useEditContext();
  const [isUploading, setIsUploading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState<string>('');
  const [editDocumentId, setEditDocumentId] = useState<string | null>(null);
  const [editDocumentDescription, setEditDocumentDescription] = useState<
    string | null
  >(null);

  const { data, loading, error, refetch } = useQuery(GetDocumentsDocument, {
    variables: {
      id: pageSlug ?? '',
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const trackDocumentSaving = (isCreate: boolean) => {
    if (
      pageContext.focusType &&
      pageContext.pageId &&
      trackingData?.module &&
      trackingData?.part
    ) {
      trackEditSaving({
        focusType: pageContext.focusType,
        itemId: pageContext.pageId,
        isCreate,
        module: trackingData?.module,
        part: trackingData?.part,
      });
    }
  };

  const displayError = (error: any) =>
    showError({
      message: 'EP_GENERAL_FEEDBACK_ERROR',
      error,
    });

  const [createDocument] = useMutation(EntityPageCreateDocumentDocument, {
    onCompleted: (data) =>
      data?.entityPageCreateDocument?.error &&
      displayError(data.entityPageCreateDocument.error),
    onError: (error) => displayError(error),
    update: (cache, { data: createDocumentData }) => {
      handleCache(
        cache,
        GetDocumentsDocument,
        { id: pageSlug },
        'CREATE',
        createDocumentData
      );
    },
  });

  const [updateDocument] = useMutation(EntityPageUpdateDocumentDocument, {
    onCompleted: (data) => {
      if (data?.entityPageUpdateDocument?.error) {
        displayError(data.entityPageUpdateDocument.error);
      } else {
        setEditDocumentId('');
      }
      setEditDocumentDescription(null);
      trackDocumentSaving(false);
    },
    onError: (error) => {
      displayError(error);
    },
    update: (cache) => {
      handleCache(cache, GetDocumentsDocument, { id: pageSlug }, 'UPDATE', {
        id: editDocumentId,
        description: editDocumentDescription,
      });
    },
  });

  const [deleteDocument] = useMutation(EntityPageDeleteDocumentDocument, {
    onCompleted: (data) =>
      data?.entityPageDeleteDocument?.error &&
      displayError(data.entityPageDeleteDocument.error),
    onError: (error) => displayError(error),
    update: (cache) => {
      handleCache(cache, GetDocumentsDocument, { id: pageSlug }, 'DELETE', {
        id: deleteDocumentId,
      });
    },
  });

  const uploadNewDocument = async ({ file, id }: FileUploaded) => {
    // replaces double white spaces for single and then trims the string
    const description = file.name.split('.pdf')[0].replace(/\s+/g, ' ').trim();

    await createDocument({
      variables: {
        pageId: pageSlug,
        uploadId: id,
        filename: file.name,
        description:
          description.length > 35 ? description.substring(0, 35) : description,
      },
    });
    setIsUploading(false);
    trackDocumentSaving(true);
  };

  useEffect(() => {
    if (deleteDocumentId) {
      setShowDialog(true);
    }
  }, [deleteDocumentId]);

  useEffect(() => {
    if (editDocumentId && editDocumentDescription) {
      updateDocument({
        variables: {
          pageId: pageSlug,
          documentId: editDocumentId,
          description: editDocumentDescription,
        },
      });
    }
  }, [editDocumentId, editDocumentDescription]);

  const aboutUsEntityPage =
    data?.pagesAboutUs?.__typename === 'AboutEntity' ? data.pagesAboutUs : null;

  const aboutUsDocuments =
    aboutUsEntityPage?.documents?.__typename === 'EntityDocumentConnection'
      ? aboutUsEntityPage?.documents
      : null;

  const documentsEdges = aboutUsDocuments?.edges;

  const documentItems =
    documentsEdges?.map((edge) => edge?.node).filter((node) => !!node) ?? [];
  const hasError = !loading && typeof error !== 'undefined';
  const isEmpty = !loading && (!documentItems || documentItems?.length === 0);

  return (
    <>
      <DeleteDialog
        showDialog={showDialog}
        loading={isLoading}
        onCancel={() => {
          setShowDialog(false);
          setDeleteDocumentId('');
        }}
        onConfirm={async () => {
          setIsLoading(true);
          await deleteDocument({
            variables: {
              pageId: pageSlug ?? '',
              documentId: deleteDocumentId,
            },
          });
          trackDocumentSaving(false);
          setIsLoading(false);
          setDeleteDocumentId('');
          setShowDialog(false);
        }}
      />
      <DocumentsEditorHeader
        onSuccess={uploadNewDocument}
        hideButton={loading || hasError}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        disableUpload={!!editDocumentId}
      />
      {loading && <GalleryManagerSkeleton />}
      {hasError && (
        <WarningBox
          headerText="EP_ERROR_HEADER"
          bodyText="EP_ERROR_BODY"
          buttonText="EP_ERROR_RELOAD_CTA"
          onClick={() => refetch()}
        />
      )}
      {!loading && !hasError && (
        <>
          {isEmpty || !documentItems ? (
            <EmptyState
              headlineCopyKey="EP_NO_DOCUMENTS_HEADLINE"
              textCopyKey="EP_NO_DOCUMENTS"
            />
          ) : (
            <DocumentsEditor
              documents={documentItems}
              setEditDocumentDescription={setEditDocumentDescription}
              setDeleteDocumentId={setDeleteDocumentId}
              editDocumentId={editDocumentId}
              setEditDocumentId={setEditDocumentId}
            />
          )}
        </>
      )}
    </>
  );
};

export default DocumentsEditorContainer;
