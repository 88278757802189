import * as React from 'react';
import styled from 'styled-components';

import { xdlColorLink, xdlColorText } from '@xing-com/tokens';

type ProfileLinkProps = {
  children: React.ReactNode;
  pageName?: string;
  isMention?: boolean;
  className?: string;
};

export const ProfileLink: React.FC<ProfileLinkProps> = ({
  children,
  pageName,
  isMention,
  className,
}) =>
  pageName ? (
    <StyledLink
      className={className}
      $isMention={isMention}
      href={`/profile/${pageName}`}
    >
      {children}
    </StyledLink>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );

export const StyledLink = styled.a<{ $isMention?: boolean }>`
  ${({ $isMention }) => `color: ${$isMention ? xdlColorLink : xdlColorText}`}
`;
