import { useLazyQuery, useApolloClient } from '@apollo/client';
import * as React from 'react';

import { SocialInteractionTargetsDocument } from '@xing-com/crate-utils-social-interactions';

import { getPagedUrns, updateInteractionsCache } from '../../utils';

export type SocialCountersProviderProps = {
  urns?: string[];
};

export const SocialCountersProvider: React.FC<
  React.PropsWithChildren<SocialCountersProviderProps>
> = ({ children, urns }) => {
  const urnCacheRef = React.useRef<string[] | undefined>([]);
  const apolloClient = useApolloClient();

  const [refetch, { data = {} }] = useLazyQuery(
    SocialInteractionTargetsDocument
  );

  React.useEffect(() => {
    const pagedUrns = getPagedUrns(urns, urnCacheRef.current);
    if (pagedUrns.length > 0) refetch({ variables: { urns: pagedUrns } });
  }, [urns]);

  React.useEffect(() => {
    if (data?.viewer?.socialInteractionTargets?.length) {
      data.viewer.socialInteractionTargets.forEach((interaction) => {
        if (!interaction) return;
        updateInteractionsCache({
          apolloClient,
          interaction,
          viewer: data.viewer,
        });
      });
      urnCacheRef.current = urns;
    }
  }, [data]);

  return <React.Fragment {...{ children }} />;
};

SocialCountersProvider.displayName = 'SocialCountersProvider';
