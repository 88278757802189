import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { DotLoadingIndicator } from '@xing-com/dot-loading-indicator';
import XingLoading from '@xing-com/lazy-loading';

import {
  LAZY_LOADING_TYPE_BUTTON,
  LAZY_LOADING_TYPE_SCROLL,
} from '../../config/lazy_loading';

import * as Styled from './lazy-loading.styles';

const typesList = [LAZY_LOADING_TYPE_BUTTON, LAZY_LOADING_TYPE_SCROLL, null];

type LazyLoadingProps = {
  type?: (typeof typesList)[number];
  isLoading?: boolean;
  enabled: boolean;
  onLazyLoad: () => void;
  spinnerSize?: string;
  ctaText?: string;
  dotAnimation?: boolean;
};
export const LazyLoading: FC<LazyLoadingProps> = ({
  type,
  isLoading,
  onLazyLoad,
  enabled = true,
  spinnerSize = '32px',
  ctaText,
  dotAnimation = false,
}) => {
  if (!enabled) return null;
  return (
    <Styled.LazyLoadingContainer>
      {type === LAZY_LOADING_TYPE_BUTTON &&
        (isLoading ? (
          // @ts-expect-error TS(2322) FIXME: Type '{ size: string; }' is not assignable to type... Remove this comment to see the full error message
          <DotLoadingIndicator size={spinnerSize} />
        ) : (
          <Button variant={'secondary'} size={'small'} onClick={onLazyLoad}>
            <FormattedMessage id={ctaText || 'EP_LOAD_MORE_CTA'} />
          </Button>
        ))}
      {type === LAZY_LOADING_TYPE_SCROLL && (
        <XingLoading
          dotAnimation={dotAnimation}
          loading={isLoading}
          onLazyLoad={(unobserve: () => void) => {
            if (enabled) {
              onLazyLoad();
            } else {
              unobserve();
            }
          }}
        />
      )}
    </Styled.LazyLoadingContainer>
  );
};
