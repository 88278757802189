import type { FC } from 'react';
import { useState } from 'react';

import {
  scale000,
  scale390,
  xdlColorBorderSoft,
  xdlColorBorderStrong,
} from '@xing-com/tokens';

import { ACCEPTED_MIME_TYPES } from '../../constants';
import { trackAction } from '../../tracking';
import type { CommboxImagesType } from '../../types';

import * as Styled from './drop-zone.styles';
import { EmptyDropZone } from './empty-drop-zone';
import { FilledDropZone } from './filled-drop-zone';

export type DropZoneProps = {
  canUploadImages: boolean;
  clearDropzone: () => void;
  images: Array<CommboxImagesType>;
  isEditingPosting: boolean;
  isDragging: boolean;
  onClick: () => void;
  onDrop: (files: File[]) => Promise<void>;
  onRemove: (index: number) => void;
};

export const DropZone: FC<DropZoneProps> = ({
  canUploadImages,
  clearDropzone,
  images,
  isEditingPosting,
  isDragging,
  onDrop,
  onRemove,
}) => {
  const [dropZoneStyle, setDropZoneStyle] = useState({
    $borderColor: '',
    $borderStyle: '',
    $minHeight: '',
  });

  const isFilled = !!images.length;

  const handleOnFileDropped = async (file: File) => {
    if (canUploadImages) {
      trackAction({
        PropTrackAction: 'social_share_commbox_image_source_select',
        PropActionOrigin: 'social_share_commbox_image_source_drag_drop',
      });

      onDrop([file]);
    }
  };
  const handleOnHover = (hovering: boolean) => {
    setDropZoneStyle({
      $borderColor: hovering ? xdlColorBorderStrong : xdlColorBorderSoft,
      $borderStyle: !isFilled && !hovering ? 'dashed' : 'solid',
      $minHeight: isFilled ? scale000 : scale390,
    });
  };

  if (!isFilled && !isDragging) return null;

  return (
    <Styled.DropZone
      {...dropZoneStyle}
      onFileSelected={handleOnFileDropped}
      onHover={handleOnHover}
      data-testid="commbox-dropzone"
      accept={ACCEPTED_MIME_TYPES.join(',')}
    >
      {isFilled ? (
        <FilledDropZone
          {...{ clearDropzone, images, isEditingPosting, onRemove }}
        />
      ) : (
        <EmptyDropZone />
      )}
    </Styled.DropZone>
  );
};
