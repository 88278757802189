import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';

import { ContentSwitcher } from '@xing-com/content-switcher';
import { ContentArticleStatus } from '@xing-com/crate-common-graphql-types';

type FilterBarProps = {
  allAmount?: number;
  publishedAmount?: number;
  scheduledAmount?: number;
  draftsAmount?: number;
  hiddenAmount?: number;
  defaultActiveItemIndex?: number;
  className?: string;
  onNavigation?: (navigationStatus: ContentArticleStatus | undefined) => void;
};
export const FilterBar: FC<FilterBarProps> = ({
  allAmount,
  publishedAmount,
  scheduledAmount,
  draftsAmount,
  hiddenAmount,
  defaultActiveItemIndex = 0,
  className,
  onNavigation = () => undefined,
}) => {
  const { $t } = useIntl();

  const [activeItemIndex, setActiveItemIndex] = useState(
    defaultActiveItemIndex
  );

  const navigationData = {
    ['All']: {
      copy: 'EP-ARTICLES-MODULE-FILTER-ALL',
      amount: allAmount,
      value: undefined,
    },
    ['Published']: {
      copy: 'EP-ARTICLES-MODULE-FILTER-PUBLISHED',
      amount: publishedAmount,
      value: ContentArticleStatus.Published,
    },
    ['Scheduled']: {
      copy: 'EP-ARTICLES-MODULE-FILTER-SCHEDULED',
      amount: scheduledAmount,
      value: ContentArticleStatus.Scheduled,
    },
    ['Draft']: {
      copy: 'EP-ARTICLES-MODULE-FILTER-DRAFTS',
      amount: draftsAmount,
      value: ContentArticleStatus.Draft,
    },
    ['Disabled']: {
      copy: 'EP-ARTICLES-MODULE-FILTER-HIDDEN',
      amount: hiddenAmount,
      value: ContentArticleStatus.Disabled,
    },
  };

  const handleOnClicked = (
    index: number,
    value: ContentArticleStatus | undefined
  ) => {
    setActiveItemIndex(index);
    onNavigation(value);
  };

  const contentSwitcherData = Object.values(navigationData).map(
    ({ copy, amount, value }, index) => ({
      copy: `${$t({ id: copy })} ${amount !== undefined ? `(${amount})` : ''}`,
      onClick: () => handleOnClicked(index, value),
    })
  );

  return (
    <ContentSwitcher
      className={className}
      data={contentSwitcherData}
      activeItemIndex={activeItemIndex}
    />
  );
};
