import { type FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconArrowDown, IconArrowUp } from '@xing-com/icons';
import { usePopOver } from '@xing-com/pop-over';
import { Headline, BodyCopy } from '@xing-com/typography';

import { usePageContext } from '../../hooks/use-page-context/use-page-context';
import { trackEmployerBrandingFlag } from '../../tracking';

import * as Styled from './employer-branding-flag.styles';
import { UPSELL_URL_EN, UPSELL_URL_DE } from './utils';

type ContentEmployerBrandingFlagProps = {
  hasImage: boolean;
};
export const ContentEmployerBrandingFlag: FC<
  ContentEmployerBrandingFlagProps
> = ({ hasImage }) => {
  const intl = useIntl();

  const handleOnClick = (ev: MouseEvent) => {
    ev.preventDefault();

    const tracking = hasImage
      ? '?sc_o= entity_page_companies_start_upsell_cover_image_module&sc_o_PropActionOrigin=entity_page_companies_upgrade_main_draft'
      : '?sc_o= entity_page_companies_start_upsell_cover_image_module&sc_o_PropActionOrigin=entity_page_companies_upgrade_main_none';

    let link = intl.locale === 'en' ? UPSELL_URL_EN : UPSELL_URL_DE;
    link = link + tracking;

    window.open(link);
  };

  return (
    <>
      {/** @ts-expect-error no children but using dangerouslySetInnerHTML */}
      <Headline
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: hasImage
              ? 'EP_UPSELL_EMPLOYER_FLAG_CONTENT_HEADER_WITH_IMAGE'
              : 'EP_UPSELL_EMPLOYER_FLAG_CONTENT_HEADER',
          }),
        }}
      />

      {/** @ts-expect-error no children but using dangerouslySetInnerHTML */}
      <BodyCopy
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: hasImage
              ? 'EP_UPSELL_EMPLOYER_FLAG_CONTENT_BODY_WITH_IMAGE'
              : 'EP_UPSELL_EMPLOYER_FLAG_CONTENT_BODY',
          }),
        }}
      />
      <Styled.ContentEmployerBrandingFlagButton
        size="small"
        variant="proBusiness"
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        onClick={handleOnClick}
        to={intl.locale === 'en' ? UPSELL_URL_EN : UPSELL_URL_DE}
      >
        <FormattedMessage id="EP_UPSELL_EMPLOYER_FLAG_CONTENT_BUTTON" />
      </Styled.ContentEmployerBrandingFlagButton>
    </>
  );
};

type EmployerBrandingFlagProps = {
  hasImage: boolean;
};
export const EmployerBrandingFlag: FC<EmployerBrandingFlagProps> = ({
  hasImage,
}) => {
  const { pageContext } = usePageContext();
  const popOver = usePopOver();

  const Icon = () => (
    <>
      {popOver.show ? (
        <IconArrowUp width="16" height="100%" />
      ) : (
        <IconArrowDown width="16" height="100%" />
      )}
    </>
  );

  return (
    <>
      <Styled.EmployeBrandingFlagButton
        icon={Icon}
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        iconAfter
        onClick={() => {
          if (pageContext.focusType && pageContext.pageId) {
            trackEmployerBrandingFlag({
              focusType: pageContext.focusType,
              draft: hasImage,
              itemId: pageContext.pageId,
            });
          }
          popOver.togglePopOver();
        }}
        innerRef={popOver.triggerRef}
      >
        <FormattedMessage id="EP_UPSELL_EMPLOYER_FLAG_BUTTON" />
      </Styled.EmployeBrandingFlagButton>
      <Styled.PopOver
        onOutsideClick={popOver.handleHide}
        show={popOver.show}
        offset={10}
        triggerRef={popOver.triggerRef}
      >
        <ContentEmployerBrandingFlag hasImage={hasImage} />
      </Styled.PopOver>
    </>
  );
};
