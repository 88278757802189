import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useRef, useEffect, useState, Fragment } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import {
  EntityPageContractType,
  PostingPublicationState,
} from '@xing-com/crate-common-graphql-types';
import {
  LazyLoading,
  useEditContext,
  usePageContext,
  LAZY_LOADING_TYPE_SCROLL,
} from '@xing-com/crate-entity-pages-common';
import { SocialCountersProvider } from '@xing-com/crate-social-utils';

import {
  FREE_PAGES_BANNER_LINK_DE,
  FREE_PAGES_BANNER_LINK_EN,
} from '../../config/constants';
import { NewsListItemsDocument } from '../../graphql/queries/news-list-items.gql-types';
import { useNewsContext } from '../../hooks/use-news-context/use-news-context';
import {
  feedMetadataHasElements,
  getEmptyStateTextResources,
  getEmptyTopAreaTextResources,
  getFatalErrorTextResources,
  getPinnedArticle,
  getSubPageKey,
  isValidFeedItem,
  newsPath,
  searchForFatalErrorInModule,
} from '../../utils';
import { CreatePostTrigger } from '../create-post-trigger/create-post-trigger';
import { ModuleError } from '../module-error/module-error';
import { NewsItem } from '../news-item/news-item';
import { NewsNavigation } from '../news-navigation/news-navigation';
import { NewsPremiumBanner } from '../news-premium-banner/news-premium-banner';

import { NewsListSkeleton } from './news-list.skeleton';
import * as Styled from './news-list.style';

export type NewsListProps = {
  loadingSkeletonCount?: number;
  onError?: (error: any) => void;
};
export const NewsList: FC<NewsListProps> = ({
  loadingSkeletonCount = 1,
  onError = () => undefined,
}) => {
  const { locale, $t } = useIntl();
  const { hasSubpage, isDetailPage, currentPublicationState, size } =
    useNewsContext();
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext();

  const isPaidPage =
    pageContext.contractType === EntityPageContractType.PaidPlus ||
    pageContext.contractType === EntityPageContractType.Paid;

  const videoPlaying = useRef(undefined);
  const lazyLoadingTriggered = useRef(false);
  const limitReload = useRef<number>(size);
  const [firstLoad, setFirstLoad] = useState(true);
  const [lazyLoadingIsAllowed, setLazyLoadingIsAllowed] = useState(true);

  const { data, loading, error, refetch, fetchMore } = useQuery(
    NewsListItemsDocument,
    {
      variables: {
        id: pageContext.pageId as string,
        limit: size,
        filter: currentPublicationState,
      },
      onError: () => setFirstLoad(false),
      onCompleted: () => setFirstLoad(false),
      notifyOnNetworkStatusChange: false, // true is creating a 2nd request on refetch without the after variable
      errorPolicy: 'all',
    }
  );

  const feedItems = data?.entityPageContentFeeds?.collection;

  const hasFatalError =
    (error && searchForFatalErrorInModule(error.graphQLErrors, data)) ||
    !feedItems;

  const metadata = data?.entityPageContentFeeds?.metadata;
  const metadataHasElements = feedMetadataHasElements(metadata);
  const showNewsNavigation =
    isDetailPage &&
    isEditing &&
    (metadataHasElements ||
      currentPublicationState !== PostingPublicationState.Published);

  const topNewsItem =
    isPaidPage && feedItems ? getPinnedArticle(feedItems) : null;
  const showTopItem =
    isPaidPage &&
    topNewsItem &&
    currentPublicationState === PostingPublicationState.Published;
  const showTopNewsEmptyState =
    isPaidPage &&
    !topNewsItem &&
    isEditing &&
    currentPublicationState === PostingPublicationState.Published;

  const showFatalErrorEmptyState =
    feedItems?.length === 0 &&
    !loading &&
    !isEditing &&
    data?.entityPageContentFeeds;

  const handleLazyLoad = () => {
    if (lazyLoadingTriggered.current) {
      return;
    }
    lazyLoadingTriggered.current = true;

    if (feedItems?.length === 0) {
      return;
    }

    const object = feedItems?.[feedItems?.length - 1]?.object;
    const until =
      currentPublicationState === PostingPublicationState.Published
        ? feedItems?.[feedItems.length - 1].publishedAt
        : object?.__typename === 'EntityPageContentFeedPostingPost'
          ? object?.createdAt
          : undefined;

    fetchMore({
      variables: { limit: size, until },
      updateQuery: (prev: any, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          setLazyLoadingIsAllowed(false);
          return prev;
        } else {
          const newItems =
            fetchMoreResult?.entityPageContentFeeds?.collection || [];

          setLazyLoadingIsAllowed(newItems.length > 0);

          lazyLoadingTriggered.current = false;
          limitReload.current = +limitReload.current;
          return {
            entityPageContentFeeds: {
              ...prev.entityPageContentFeeds,
              collection: [
                ...prev.entityPageContentFeeds.collection,
                ...newItems,
              ],
            },
          };
        }
      },
    });
  };
  const handleOnItemActionCompleted = (
    action: 'edit' | 'pin' | 'hide' | 'delete'
  ) => {
    if (action === 'pin') {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
    setLazyLoadingIsAllowed(true);
    refetch({
      id: pageContext.pageId as string,
      limit: limitReload.current,
      filter: currentPublicationState,
    });
  };
  const handleOnItemActionStarted = () => {
    setLazyLoadingIsAllowed(false);
  };
  const handleOnCreatePostCompleted = () => {
    refetch({
      id: pageContext.pageId as string,
      limit: limitReload.current,
      filter: currentPublicationState,
    });
  };
  const handleOnClickNavigationItem = (state: PostingPublicationState) => {
    limitReload.current = 3;
    refetch({
      id: pageContext.pageId as string,
      limit: limitReload.current,
      filter: state,
    });
  };

  // gather all activity urns for providing social counter data below
  const allUrns = feedItems
    ?.map((f) => f.globalId)
    .filter((id): id is string => typeof id === 'string');

  const wrapperProps =
    currentPublicationState === PostingPublicationState.Published && !loading
      ? { urns: allUrns, children: Fragment }
      : undefined;
  const Wrapper = wrapperProps ? SocialCountersProvider : Fragment;

  const showEmptyState =
    !hasFatalError &&
    feedItems?.length === 0 &&
    !loading &&
    isEditing &&
    !!data?.entityPageContentFeeds;

  const emptyStateCopy = getEmptyStateTextResources(
    showEmptyState,
    !!isDetailPage,
    metadataHasElements
  );

  const fatalErrorCopy = getFatalErrorTextResources();
  const topAreaCopy = getEmptyTopAreaTextResources();

  useEffect(() => {
    if (typeof hasFatalError !== 'undefined') {
      onError(hasFatalError);
    }
  }, [data, error]);

  return (
    <>
      {isEditing && (
        <CreatePostTrigger onCompleted={handleOnCreatePostCompleted} />
      )}

      <Wrapper {...wrapperProps}>
        <Styled.NewsContainer
          $showActors={!!hasSubpage}
          data-testid="NEWS_MODULE_CONTAINER"
        >
          {showEmptyState && (
            <div data-testid="NEWS_MODULE">
              <Styled.EmptyTopAreaWrapper {...emptyStateCopy} />
            </div>
          )}

          {showNewsNavigation && metadata && (
            <NewsNavigation
              isLoading={loading}
              metadata={metadata}
              onClickItem={handleOnClickNavigationItem}
            />
          )}

          {firstLoad ? (
            <NewsListSkeleton
              loadingSkeletonCount={loadingSkeletonCount}
              showActors={!!hasSubpage}
            />
          ) : hasFatalError ? (
            <div data-testid="NEWS_MODULE">
              <ModuleError refetch={refetch} />
            </div>
          ) : (
            <>
              {showFatalErrorEmptyState && (
                <div data-testid="NEWS_MODULE">
                  <Styled.EmptyTopAreaWrapper {...fatalErrorCopy} />
                </div>
              )}

              {feedItems?.length > 0 && data?.entityPageContentFeeds && (
                <div data-testid="NEWS_MODULE">
                  {!isPaidPage && isEditing && (
                    <NewsPremiumBanner
                      buttonTextKey="TOP_ARTICLE_PAID_CTA"
                      flagTextKey="TOP_ARTICLE_PAID_FLAG"
                      headlineCopyKey="TOP_ARTICLE_PAID_HEADLINE"
                      href={
                        locale === 'de'
                          ? FREE_PAGES_BANNER_LINK_DE
                          : FREE_PAGES_BANNER_LINK_EN
                      }
                      moduleName="newsmodule"
                      testId="newsPremiumBanner"
                      textCopyKey="TOP_ARTICLE_PAID_COPY"
                    />
                  )}
                  {showTopNewsEmptyState && (
                    <div>
                      <Styled.EmptyTopAreaWrapper
                        {...topAreaCopy}
                        testId="topEmptyArea"
                      />
                    </div>
                  )}
                  {showTopItem && topNewsItem && (
                    <NewsItem
                      feedUpdate={topNewsItem}
                      showActor={!!hasSubpage}
                      videoPlaying={videoPlaying}
                      onItemActionCompleted={handleOnItemActionCompleted}
                      onItemActionStarted={handleOnItemActionStarted}
                    />
                  )}
                </div>
              )}

              {feedItems
                ?.filter((feedItem) =>
                  isValidFeedItem(feedItem, currentPublicationState)
                )
                .map((feedItem, index) => (
                  <NewsItem
                    key={`NEWS-ITEM-${index}`}
                    feedUpdate={feedItem}
                    showActor={!!hasSubpage}
                    videoPlaying={videoPlaying}
                    onItemActionCompleted={handleOnItemActionCompleted}
                    onItemActionStarted={handleOnItemActionStarted}
                  />
                ))}
            </>
          )}

          <LazyLoading
            type={isDetailPage ? LAZY_LOADING_TYPE_SCROLL : null}
            isLoading={loading}
            enabled={lazyLoadingIsAllowed && !loading}
            onLazyLoad={handleLazyLoad}
            dotAnimation={true}
          />

          {hasSubpage && (feedItems?.length ?? 0) > 0 && (
            <Styled.LazyloadContainer>
              <Button
                variant="secondary"
                size="small"
                to={newsPath(
                  pageContext.basePath ?? '',
                  pageContext.pageSlug as string
                )}
              >
                {$t({ id: getSubPageKey(!!isEditing) })}
              </Button>
            </Styled.LazyloadContainer>
          )}
        </Styled.NewsContainer>
      </Wrapper>
    </>
  );
};
