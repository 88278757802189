import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Headline } from '@xing-com/typography';

import { usePageContext } from '../../hooks/use-page-context/use-page-context';

import * as Styled from './upsell-banner.styles';
import { getMarketingPageUrl } from './utils';

type UpsellBannerProps = {
  headerCopyKey: string;
  bodyCopyKey: string;
  moduleName: string;
  column?: boolean;
  isProductPage?: boolean;
};
export const UpsellBanner: FC<UpsellBannerProps> = ({
  headerCopyKey,
  bodyCopyKey,
  column,
  moduleName,
  isProductPage,
}) => {
  const { pageContext } = usePageContext();
  const intl = useIntl();

  const marketingPageUrl = getMarketingPageUrl({
    locale: intl.locale,
    moduleName,
    focusType: pageContext.focusType,
    isProductPage,
  });

  return (
    <Styled.PremiumWrapper $column={column}>
      <Styled.TextWrapper>
        <Styled.Flag>
          <FormattedMessage id="EP_FLAG_EMPLOYER_BRANDING_PROFILE" />
        </Styled.Flag>
        <Styled.HeaderText>
          <Headline size="xlarge">
            <FormattedMessage id={headerCopyKey} />
          </Headline>
        </Styled.HeaderText>
        <Styled.Text>
          <FormattedMessage id={bodyCopyKey} />
        </Styled.Text>
      </Styled.TextWrapper>
      <Styled.PremiumButtonMargin>
        <Styled.PremiumButton
          size="medium"
          variant="proBusiness"
          href={marketingPageUrl}
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          target="_blank"
          rel="noopener nofollow"
        >
          <FormattedMessage id="EP_UPSELL_GALLERY_BUTTON" />
        </Styled.PremiumButton>
      </Styled.PremiumButtonMargin>
    </Styled.PremiumWrapper>
  );
};
