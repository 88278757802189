import * as Styled from './your-topics-edit-view.styles';

export const YourTopicsEditViewSkeleton: React.FC = () => {
  return (
    <div data-qa="your-topics-edit-view-skeleton">
      <Styled.SkeletonFormField size="medium" />
      <Styled.SkeletonFormField size="medium" />
      <Styled.SkeletonFormField size="medium" />
      <Styled.SkeletonFormField size="medium" />

      <Styled.SkeletonButtonContainer>
        <Styled.SkeletonBodyCopy alignment="left" amountRows={1} />
        <Styled.SkeletonButton size="large" />
      </Styled.SkeletonButtonContainer>
    </div>
  );
};
