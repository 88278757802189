import type { FC } from 'react';
import type React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import { usePopOver } from '@xing-com/pop-over';

import * as Styled from './add-new-topic.styles';

export const AddNewTopicContainer: FC = () => {
  const popOver = usePopOver();
  const [query, setQuery] = useState<string | null>(null);
  const [text] = useDebounce(query, 100);
  const intl = useIntl();

  const handleFocus = () => {
    if (text) popOver.handleShow();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    popOver.handleShow();
  };

  return (
    <>
      <Styled.InputWrapper>
        <Styled.FormField
          // @ts-expect-error FIXME: SC6
          ref={popOver.triggerRef}
          value={query || ''}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={intl.formatMessage({
            id: 'EP_EDIT_ABOUTUS_INDUSTRIES_ADDTOPIC_PLACEHOLDER',
            defaultMessage: 'EP_EDIT_ABOUTUS_INDUSTRIES_ADDTOPIC_PLACEHOLDER',
          })}
        />
      </Styled.InputWrapper>

      <Styled.PopOver
        triggerRef={popOver.triggerRef}
        show={popOver.show}
        onOutsideClick={() => popOver.handleHide()}
        // @ts-expect-error FIXME: SC6
        isNotCloseableByTriggerRef={true}
      >
        <div style={{ width: popOver.triggerRef?.current?.clientWidth }}>
          <Styled.ResultList>
            <Styled.ResultItem>display results here</Styled.ResultItem>
          </Styled.ResultList>
        </div>
      </Styled.PopOver>
    </>
  );
};

export default AddNewTopicContainer;
