import type React from 'react';

import { useUpdateEffect } from '@xing-com/crate-entity-pages-common';
import { IconShow } from '@xing-com/icons';

import { useEnableArticleMutation } from '../../../../hooks/use-enable-article-mutation';
import * as Styled from '../article-card-top.styles';

import type { ArticleControlButton } from './types';

export const ArticleShowButton: React.FC<ArticleControlButton> = ({
  article,
  disabled,
  onLoadingChange,
  onCompleted,
  onError,
}) => {
  const { handleEnableArticle, loading } = useEnableArticleMutation({
    onCompleted,
    onError,
  });

  useUpdateEffect(() => {
    onLoadingChange(loading);
  }, [loading]);

  const handleClick = () => {
    handleEnableArticle({ article });
  };

  return (
    <Styled.Button
      data-testid={`ARTICLE-SHOW-BUTTON-${article.id}`}
      icon={IconShow}
      size="small"
      variant="tertiary"
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
    />
  );
};
