import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';

const Panoramic = styled.svg`
  width: 300px;
  height: auto;

  @media ${mediaSmallOrTiny} {
    width: 100%;
  }
`;

export const PanoramicSvg: React.FC = () => {
  return (
    <Panoramic width="470" height="260" viewBox="0 0 470 260" fill="none">
      <g clipPath="url(#clip0_5275_11886)">
        <rect width="470" height="260" fill="#F4F4F5" />
        <path
          d="M106.35 178.43C95.24 169.25 78.89 170.31 69.06 180.86L0 254.951V260H470V0.5H467.863L357.87 107.18C349.72 115.09 336.33 113.42 330.38 103.74L324.18 93.6601C315.2 79.0701 295.16 76.2801 282.54 87.8601L164.6 196.07C154.58 205.27 139.31 205.66 128.82 197L106.35 178.43Z"
          fill="#DBE2E6"
        />
        <path
          d="M111.52 69.1501C122.383 69.1501 131.19 60.3435 131.19 49.4801C131.19 38.6167 122.383 29.8101 111.52 29.8101C100.657 29.8101 91.8496 38.6167 91.8496 49.4801C91.8496 60.3435 100.657 69.1501 111.52 69.1501Z"
          fill="#DBE2E6"
        />
        <path
          d="M41.3294 189.91H23.1094V191.91H41.3294V189.91Z"
          fill="#DBE2E6"
        />
        <path d="M137.19 172.96H117.91V174.96H137.19V172.96Z" fill="#DBE2E6" />
        <path
          d="M176.751 191.35H139.721V193.35H176.751V191.35Z"
          fill="#DBE2E6"
        />
        <path
          d="M27.8601 218.75H6.83008V220.75H27.8601V218.75Z"
          fill="#DBE2E6"
        />
        <path
          d="M85.3291 179.69H52.6191V181.69H85.3291V179.69Z"
          fill="#DBE2E6"
        />
        <path
          d="M200.06 158.39V142.27L165.06 107.27C160.5 102.71 153.1 102.71 148.54 107.27L120.62 135.19C116.06 139.75 108.66 139.75 104.1 135.19L75.7799 106.87C71.2199 102.31 63.8199 102.31 59.2599 106.87C54.6999 111.43 47.2999 111.43 42.7399 106.87L0.669922 64.79V158.39H200.06Z"
          fill="#DBE2E6"
        />
        <path
          d="M395.33 229.93C409.52 218.7 439.73 239.85 442.69 216.18C445.504 193.675 445.697 154.978 470 151.87V260H384.934C384.198 254.774 383.261 239.486 395.33 229.93Z"
          fill="#ACB8CA"
        />
        <path
          d="M461.329 184.685L458.27 262.862L460.268 262.941L463.327 184.763L461.329 184.685Z"
          fill="#DBE2E6"
        />
        <path
          d="M454.924 191.586L453.51 193L460.857 200.347L462.271 198.933L454.924 191.586Z"
          fill="#DBE2E6"
        />
        <path
          d="M466.148 213.079L459.961 219.266L461.375 220.68L467.562 214.493L466.148 213.079Z"
          fill="#DBE2E6"
        />
        <path
          d="M449.891 237.163L448.477 238.577L459.303 249.403L460.717 247.988L449.891 237.163Z"
          fill="#DBE2E6"
        />
        <path
          d="M404.311 243.258L402.572 244.246L412.234 261.254L413.973 260.266L404.311 243.258Z"
          fill="#DBE2E6"
        />
        <path
          d="M425.01 236.208L421.91 259.009L423.892 259.278L426.991 236.477L425.01 236.208Z"
          fill="#DBE2E6"
        />
        <path
          d="M350.03 260.97H305.51V224L327.6 202.8L350.03 224V260.97Z"
          fill="#ACB8CA"
        />
        <path
          d="M325.57 260.79H314.76V244.58C314.76 241.59 317.18 239.18 320.16 239.18C323.15 239.18 325.56 241.6 325.56 244.58V260.79H325.57Z"
          fill="#DBE2E6"
        />
        <path
          d="M334.479 239.09C338.82 239.09 342.339 235.571 342.339 231.23C342.339 226.889 338.82 223.37 334.479 223.37C330.138 223.37 326.619 226.889 326.619 231.23C326.619 235.571 330.138 239.09 334.479 239.09Z"
          fill="#DBE2E6"
        />
        <path
          d="M267.84 260.81L265.84 260.8L265.89 222.9L287.63 202.58H327.6V204.58H288.42L267.89 223.76L267.84 260.81Z"
          fill="#ACB8CA"
        />
        <path
          d="M310.089 222.99H266.789V224.99H310.089V222.99Z"
          fill="#ACB8CA"
        />
        <path
          d="M298.679 231.17H275.699V255.48H298.679V231.17Z"
          fill="#ACB8CA"
        />
        <path
          d="M247.621 259.68H171.641L184.051 100.69H235.211L247.621 259.68Z"
          fill="#ACB8CA"
        />
        <path
          d="M187.131 87.27C187.131 74.85 197.201 64.77 209.631 64.77C222.051 64.77 232.131 74.84 232.131 87.27H187.131Z"
          fill="#ACB8CA"
        />
        <path
          d="M199.891 86.6201H197.891V101.48H199.891V86.6201Z"
          fill="#ACB8CA"
        />
        <path
          d="M206.939 86.6201H204.939V101.48H206.939V86.6201Z"
          fill="#ACB8CA"
        />
        <path d="M214 86.6201H212V101.48H214V86.6201Z" fill="#ACB8CA" />
        <path
          d="M221.051 86.6201H219.051V101.48H221.051V86.6201Z"
          fill="#ACB8CA"
        />
        <path
          d="M228.109 86.6201H226.109V101.48H228.109V86.6201Z"
          fill="#ACB8CA"
        />
        <path
          d="M192.83 86.6201H190.83V101.48H192.83V86.6201Z"
          fill="#ACB8CA"
        />
        <path
          d="M219.961 259.85H199.391V239.73C199.391 234.05 203.991 229.45 209.671 229.45C215.351 229.45 219.951 234.05 219.951 239.73V259.85H219.961Z"
          fill="#DBE2E6"
        />
        <path
          d="M210.619 56.6299H208.619V78.9499H210.619V56.6299Z"
          fill="#ACB8CA"
        />
        <path
          d="M237.857 117.235L182.027 117.508L182.036 119.508L237.866 119.235L237.857 117.235Z"
          fill="#DBE2E6"
        />
        <path d="M239.38 139.72H180.51V141.72H239.38V139.72Z" fill="#DBE2E6" />
        <path d="M241.96 184.41H177.35V186.41H241.96V184.41Z" fill="#DBE2E6" />
        <path
          d="M243.709 206.75H175.699V208.75H243.709V206.75Z"
          fill="#DBE2E6"
        />
        <path
          d="M209.669 177.4C205.919 177.4 202.869 174.36 202.869 170.6V155.2C202.869 151.45 205.909 148.4 209.669 148.4C213.419 148.4 216.469 151.44 216.469 155.2V170.6C216.469 174.36 213.429 177.4 209.669 177.4Z"
          fill="#DBE2E6"
        />
        <path
          d="M235.101 102.06L233.241 101.33L235.971 94.4199H184.091L186.011 101.43L184.081 101.96L181.471 92.4199H238.911L235.101 102.06Z"
          fill="#ACB8CA"
        />
        <path
          d="M144.289 228.28H142.289V262.47H144.289V228.28Z"
          fill="#ACB8CA"
        />
        <path
          d="M134.92 237.45L133.51 236.03L143.29 226.25L153.08 236.03L151.66 237.45L143.29 229.08L134.92 237.45Z"
          fill="#ACB8CA"
        />
        <path
          d="M134.92 246.89L133.51 245.48L143.29 235.69L153.08 245.48L151.66 246.89L143.29 238.52L134.92 246.89Z"
          fill="#ACB8CA"
        />
        <path
          d="M134.92 255.55L133.51 254.13L143.29 244.35L153.08 254.13L151.66 255.55L143.29 247.18L134.92 255.55Z"
          fill="#ACB8CA"
        />
        <path
          d="M27.5605 228.28H25.5605V262.47H27.5605V228.28Z"
          fill="#ACB8CA"
        />
        <path
          d="M18.1895 237.45L16.7695 236.03L26.5595 226.25L36.3395 236.03L34.9295 237.45L26.5595 229.08L18.1895 237.45Z"
          fill="#ACB8CA"
        />
        <path
          d="M18.1895 246.89L16.7695 245.48L26.5595 235.69L36.3395 245.48L34.9295 246.89L26.5595 238.52L18.1895 246.89Z"
          fill="#ACB8CA"
        />
        <path
          d="M18.1895 255.55L16.7695 254.13L26.5595 244.35L36.3395 254.13L34.9295 255.55L26.5595 247.18L18.1895 255.55Z"
          fill="#ACB8CA"
        />
        <path d="M103.43 228.28H101.43V262.47H103.43V228.28Z" fill="#ACB8CA" />
        <path
          d="M94.0604 237.45L92.6504 236.03L102.43 226.25L112.22 236.03L110.8 237.45L102.43 229.08L94.0604 237.45Z"
          fill="#ACB8CA"
        />
        <path
          d="M94.0604 246.89L92.6504 245.48L102.43 235.69L112.22 245.48L110.8 246.89L102.43 238.52L94.0604 246.89Z"
          fill="#ACB8CA"
        />
        <path
          d="M94.0604 255.55L92.6504 254.13L102.43 244.35L112.22 254.13L110.8 255.55L102.43 247.18L94.0604 255.55Z"
          fill="#ACB8CA"
        />
        <path
          d="M64.9492 239.77H62.9492V262.47H64.9492V239.77Z"
          fill="#ACB8CA"
        />
        <path
          d="M55.5802 248.94L54.1602 247.53L63.9502 237.74L73.7302 247.53L72.3202 248.94L63.9502 240.57L55.5802 248.94Z"
          fill="#ACB8CA"
        />
        <path
          d="M55.5802 258.38L54.1602 256.97L63.9502 247.19L73.7302 256.97L72.3202 258.38L63.9502 250.01L55.5802 258.38Z"
          fill="#ACB8CA"
        />
        <path d="M12.9 244.38H0.75V246.38H12.9V244.38Z" fill="#DBE2E6" />
      </g>
      <defs>
        <clipPath id="clip0_5275_11886">
          <rect width="470" height="260" fill="white" />
        </clipPath>
      </defs>
    </Panoramic>
  );
};
