import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import type {
  BackLinkProps,
  EditItemMapProps,
} from '@xing-com/crate-entity-pages-common';
import {
  useDialogContext,
  NotSavedDialog,
  SmallGrid,
  useEditContext,
  usePageContext,
  ErrorBoundary,
} from '@xing-com/crate-entity-pages-common';
import { trackEditOpening } from '@xing-com/crate-entity-pages-common/src/tracking';
// eslint-disable-next-line monorepo/forbidden-imports
import { ZenViewSubHeader } from '@xing-com/platform-layout-zen';

import * as Styled from './edit-page.styles';

type EditPageLayoutProps = {
  editItemProps: EditItemMapProps;
  isWebview?: boolean;
  children?:
    | React.ReactNode
    | ((props: {
        setBackLink: Dispatch<SetStateAction<BackLinkProps | null>>;
        backLinkDefault: BackLinkProps;
      }) => React.ReactNode);
};
export const EditPageLayout: FC<EditPageLayoutProps> = ({
  editItemProps,
  isWebview = false,
  children,
}) => {
  const intl = useIntl();
  const { setTrackingData } = useEditContext();
  const { dataChanged, setDialogConfirmation, dialogConfirmation } =
    useDialogContext();
  const { pageContext } = usePageContext();

  const { backLink: backLinkDefault, tracking } = editItemProps;

  const trackingSent = useRef(false);
  const [backLink, setBackLink] = useState<BackLinkProps | null>(
    backLinkDefault
  );

  const backLinkAction = (to?: string, action?: () => void) => {
    if (typeof to === 'string') {
      if (pageContext.goBackUrl) pageContext.goBackUrl();
    } else {
      if (action) action();
    }
  };

  useEffect(() => {
    setTrackingData(tracking);
  }, []);

  useEffect(() => {
    if (tracking) {
      trackEditOpening({
        focusType: pageContext.focusType,
        itemId: pageContext.pageId,
        isCreate: tracking.isCreate || false,
        module: tracking.module,
        part: tracking.part,
      });

      trackingSent.current = true;
    }
  }, []);

  return (
    <SmallGrid inner>
      <Styled.HeaderWrapper>
        {!isWebview && (
          <ZenViewSubHeader
            backLink={
              backLink
                ? {
                    label: intl.formatMessage({
                      id: backLink.labelKey,
                    }),
                    onClose: !backLink.disabled
                      ? dataChanged
                        ? () =>
                            setDialogConfirmation({
                              dialogAction: () => {
                                backLinkAction(backLink?.to, backLink.action);
                              },
                            })
                        : () => backLinkAction(backLink?.to, backLink.action)
                      : () => undefined,
                  }
                : undefined
            }
          />
        )}
      </Styled.HeaderWrapper>
      <ErrorBoundary>
        {typeof children === 'function'
          ? children({ setBackLink, backLinkDefault })
          : children}
      </ErrorBoundary>
      {dataChanged && dialogConfirmation?.dialogAction && <NotSavedDialog />}
    </SmallGrid>
  );
};
