import type { FC, MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useEditContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { List } from '@xing-com/list';
import { Menu } from '@xing-com/menu';
import type { PopOverType } from '@xing-com/pop-over';

import * as Styled from './news-item-menu.styles';

type NewsItemMenuProps = {
  activityGlobalId: string;
  authorUrn: string;
  popOver: PopOverType;
};
const NewsItemMenu: FC<NewsItemMenuProps> = ({
  activityGlobalId,
  authorUrn,
  popOver,
}) => {
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext();

  const xbmUrl = isEditing
    ? `${window.location.origin}/brandmanager/${pageContext.pageSlug}/news-reach`
    : '';

  const handleOnClick = (ev: MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();

    window.open(xbmUrl + '?sc_o=entity_page_news_module_more_statistics');
  };

  return (
    <Menu
      onOutsideClick={popOver.handleHide}
      triggerRef={popOver.triggerRef}
      show={popOver.show}
    >
      <List>
        <Styled.ListItem>
          <Styled.ReportButton
            targetUrn={activityGlobalId}
            authorUrn={authorUrn}
          >
            <FormattedMessage id="EP_REPORT_NEWS_ITEM" />
          </Styled.ReportButton>
        </Styled.ListItem>
        {xbmUrl && (
          <Styled.ListItem>
            <Styled.TextButton
              size={'small'}
              href={xbmUrl}
              // @ts-expect-error FIXME: SC6
              target={'_blank'}
              rel={'noopener nofollow'}
              data-testid={'xbm-button'}
              onClick={handleOnClick}
            >
              <FormattedMessage id="NEWS_MODULE_SHOW_STATISTICS" />
            </Styled.TextButton>
          </Styled.ListItem>
        )}
      </List>
    </Menu>
  );
};

export { NewsItemMenu };
