import type { ApolloClient } from '@apollo/client';

import {
  SocialInteractionTargetDocument,
  SocialInteractionTargetsDocument,
} from '@xing-com/crate-utils-social-interactions';

import type { InteractionTarget } from '../types';

export type updateInteractionsCacheProps = (props: {
  interaction: InteractionTarget;
  apolloClient: ApolloClient<object>;
  viewer: any;
}) => void;

export const updateInteractionsCache: updateInteractionsCacheProps = ({
  interaction,
  apolloClient,
  viewer,
}) => {
  apolloClient.writeQuery({
    query: SocialInteractionTargetDocument,
    variables: { urn: interaction.id },
    data: {
      viewer: {
        ...viewer,
        socialInteractionTarget: {
          ...interaction,
          __typename: 'SocialInteractionTarget',
        },
        __typename: 'Viewer',
      },
    },
  });

  apolloClient.writeQuery({
    query: SocialInteractionTargetsDocument,
    variables: { urns: [interaction.id] },
    data: {
      viewer: {
        ...viewer,
        socialInteractionTargets: [
          { ...interaction, __typename: 'SocialInteractionTarget' },
        ],
        __typename: 'Viewer',
      },
    },
  });
};
