import React from 'react';

import { BASE_SELECTABLE_AUDIENCES_LIST } from '@xing-com/audience-selection';

import type { AudiencePropsType } from '../types';

export type UseFeedReducerType = () => {
  audienceProps: AudiencePropsType;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

export const useFeedReducer: UseFeedReducerType = () => {
  const [message, setMessage] = React.useState<string>('');
  const [selectedAudience, setSelectedAudience] = React.useState(
    BASE_SELECTABLE_AUDIENCES_LIST[0].id
  );

  return {
    audienceProps: {
      setSelectedAudience,
      selectedAudience,
      selectableAudiences: BASE_SELECTABLE_AUDIENCES_LIST,
    },
    message,
    setMessage,
  };
};
