import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import type { EntityPageTrackVisitorMutationInput } from '@xing-com/crate-common-graphql-types';
import { EntityPageTrackVisitorPlatform } from '@xing-com/crate-common-graphql-types';
import { useHost } from '@xing-com/crate-xinglet';
import { useVisitorId } from '@xing-com/hub';

import { TrackVisitorDocument } from '../../graphql/mutations/trackVisitor.gql-types';

const slugBlacklist = new Set(['video_player', 'new', 'null']);

const extractSlugDetails = (url: string) => {
  const slugRegex = /\/(pages)\/([^/&?.]+)\/?([^/&?.]+)?/;
  const match = url.match(slugRegex);

  if (!match) return null;

  const [, , pageType, optionalSlug] = match;
  return { pageType, pageSlug: pageType, optionalSlug };
};

const isValidPageSlug = ({
  pageType,
  optionalSlug,
}: {
  pageType: string;
  optionalSlug?: string;
}) => {
  const isNotInBlacklist = !slugBlacklist.has(pageType);
  const isOptionalSlugNotNull = !(optionalSlug && optionalSlug === 'null');

  return isNotInBlacklist && isOptionalSlugNotNull;
};

const buildTrackingVariables = (
  pageSlug: string,
  visitorId: string,
  isPreview: boolean
): { input: EntityPageTrackVisitorMutationInput } | null => {
  const originalUrl = new URL(window.location.href);
  const params = originalUrl.searchParams;
  const adId = params.get('ad_id') ?? undefined;

  const slugDetails = extractSlugDetails(originalUrl.href);
  if (!slugDetails || !isValidPageSlug(slugDetails) || isPreview) {
    return null;
  }

  return {
    input: {
      pageId: pageSlug,
      trackingData: {
        userAgent: navigator.userAgent,
        referer: document.referrer || undefined,
        platform: EntityPageTrackVisitorPlatform.Web,
        visitorId,
        adId,
        requestUri: originalUrl.href,
      },
    },
  };
};

type UseTrackingVisitorProps = { pageSlug: string };
export const useTrackingVisitor = ({ pageSlug }: UseTrackingVisitorProps) => {
  const visitorId = useVisitorId();
  const { isPreview, isServer } = useHost();

  const [trackVisitorMutation, { data, loading, error }] = useMutation(
    TrackVisitorDocument,
    {
      errorPolicy: 'all',
    }
  );

  const trackVisitor = useCallback(() => {
    if (typeof window === 'undefined' || isServer) return;

    const trackingVariables = buildTrackingVariables(
      pageSlug,
      visitorId,
      isPreview
    );
    if (trackingVariables) {
      trackVisitorMutation({ variables: trackingVariables });
    }
  }, [isServer, isPreview, pageSlug, visitorId, trackVisitorMutation]);

  return {
    trackVisitor,
    trackingData: data,
    trackingLoading: loading,
    trackingError: error,
  };
};
