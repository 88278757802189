import { useEffect } from 'react';

import { useModuleContext } from '../use-module-context/use-module-context';
import { usePageContext } from '../use-page-context/use-page-context';

export const useMainPageLoadModule = () => {
  const { pageContext } = usePageContext();
  const { moduleContext } = useModuleContext();

  useEffect(() => {
    if (pageContext.setModuleLoaded && moduleContext?.moduleType) {
      pageContext.setModuleLoaded(moduleContext.moduleType);
    }
  }, [moduleContext?.moduleType]);

  return null;
};
