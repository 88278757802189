/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconTitle2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconTitle2"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.947 17.404c.697-.64 1.24-1.182 1.62-1.612.426-.48.727-.917.898-1.297.17-.38.257-.786.257-1.206 0-.417-.11-.805-.328-1.153a2.3 2.3 0 0 0-.947-.831c-.41-.203-.902-.305-1.465-.305s-1.08.122-1.535.363c-.453.239-.845.577-1.17 1l-.068.091 1.225.976.073-.088c.223-.266.438-.46.635-.576q.287-.17.66-.17c.272 0 .477.075.632.23s.23.366.23.643q0 .454-.239.89c-.163.295-.454.677-.867 1.13a57 57 0 0 1-2.01 2.05l-.036.034V19h5.173l.212-1.596zM13.923 4 13.7 6.982l-3.7.012v12.005H7V7.005L3 7.02V4z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconTitle2;
