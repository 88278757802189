import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { EmptyState } from '@xing-com/empty-state';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { space3XL, spaceS, spaceXL, xdlColorInfoSoft } from '@xing-com/tokens';

import { FilterBar as DefaultFilterBar } from '../components/filter-bar/filter-bar';

export const ArticlesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${space3XL};
`;

export const HeaderEditButton = styled(Button)`
  background-color: ${xdlColorInfoSoft};
  margin-left: ${spaceS};
`;

export const ArticlesModuleFooter = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${space3XL};
`;

export const FilterBar = styled(DefaultFilterBar)`
  margin-bottom: ${spaceXL};
`;

export const EmptyStateFeatured = styled(EmptyState)`
  margin-bottom: ${spaceXL};
`;

export const MobileEditWarningContainer = styled.div`
  @media ${mediaConfined} {
    display: none;
  }
`;

export const ArticlesModuleContainer = styled.div<{ $isEditing: boolean }>`
  @media ${mediaSmallOrTiny} {
    display: ${({ $isEditing }) => {
      return $isEditing ? 'none' : 'block';
    }};
  }
`;

export const ArticleListSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space3XL};

  @media ${mediaSmallOrTiny} {
    & > :not(:first-child) {
      display: none;
    }
  }
`;
