import type { FC } from 'react';

import { ErrorContextProvider } from '@xing-com/crate-entity-pages-common';

import { ScrollToTop } from './components/scroll-to-top/scroll-to-top';
import { Router } from './router/router';

export const App: FC = () => (
  <ErrorContextProvider>
    <ScrollToTop />
    <Router />
  </ErrorContextProvider>
);
