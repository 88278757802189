import { useState, type FC } from 'react';

import { AudienceSelection } from '@xing-com/audience-selection';
import { PostingPublicationState } from '@xing-com/crate-common-graphql-types';

import { useCommboxContext } from '../../hooks/use-commbox';
import { useCommboxPermissions } from '../../hooks/use-commbox-permissions';
import { CommboxDatePicker } from '../base-components/commbox-date-picker';
import { CommboxPollsButton } from '../base-components/commbox-polls-button';
import { CommboxSubmitButton } from '../base-components/commbox-submit-button';
import { CommboxVideoButton } from '../base-components/commbox-video-button';
import { ImageUploadButton } from '../image-upload-button/image-upload-button';

import * as Styled from './commbox-footer.styles';

enum Scheduled {
  NOT_SCHEDULED = 0,
  SCHEDULED = 1,
}

export const CommboxFormFooter: FC = () => {
  const {
    onOpenAudienceSelection,
    onSelectAudience,
    posting,
    selectableAudiences,
  } = useCommboxContext();

  const { enableVideo, isEditingPosting, enablePolls } =
    useCommboxPermissions();

  const isPostScheduled =
    posting?.publicationState === PostingPublicationState.Scheduled;
  const isPostDraft =
    posting?.publicationState === PostingPublicationState.Draft;

  const [selectedAudience, setSelectedAudience] = useState(
    selectableAudiences[0].id
  );
  // Tracking. 0 = not scheduled, 1 = scheduled
  const [scheduled, setScheduled] = useState<Scheduled>(
    Scheduled.NOT_SCHEDULED
  );

  const [publishAt, setPublishAt] = useState(
    isPostScheduled || isPostDraft ? posting?.publishAt : ''
  );

  const showDatePicker = !posting || isPostScheduled || isPostDraft;

  return (
    <>
      <Styled.FooterDivider />
      <Styled.Footer>
        <Styled.LeftToolbar>
          <ImageUploadButton />
          {enableVideo && <CommboxVideoButton />}
          {enablePolls && <CommboxPollsButton />}
        </Styled.LeftToolbar>
        <Styled.RightToolbar>
          {selectableAudiences.length > 1 && !isEditingPosting && (
            <AudienceSelection
              disabled={isEditingPosting}
              onOpen={onOpenAudienceSelection}
              onSelect={onSelectAudience}
              selectableAudiences={selectableAudiences}
              selectedAudience={selectedAudience}
              setSelectedAudience={setSelectedAudience}
            />
          )}
          {showDatePicker && (
            <CommboxDatePicker
              publishAt={publishAt}
              onPublishDateSelect={(ISODateTime) => {
                setPublishAt(ISODateTime);
                setScheduled(Scheduled.SCHEDULED);
              }}
            />
          )}
          <CommboxSubmitButton
            scheduled={scheduled}
            selectedAudience={selectedAudience}
            publishAt={publishAt}
          />
        </Styled.RightToolbar>
      </Styled.Footer>
    </>
  );
};
