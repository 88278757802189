import type { FC } from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { StatusBannerAutoHide } from '@xing-com/banner';
import { usePageContext } from '@xing-com/crate-entity-pages-common';
import { Dialog } from '@xing-com/dialog';

type FollowContainerProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  onConfirm: () => void;
};
const FollowActionConfirmation: FC<FollowContainerProps> = ({
  show,
  setShow,
  onConfirm,
}) => {
  const { pageContext } = usePageContext();
  const intl = useIntl();
  const close = () => setShow(false);
  const confirm = () => {
    onConfirm();
    setShow(false);
  };

  useEffect(() => {
    setShow(false);
  }, [pageContext.isFollowingPage]);

  if (pageContext.isFollowingPage) {
    return (
      <StatusBannerAutoHide
        variant="info"
        show={show}
        timeout={5000}
        onExited={() => setShow(false)}
      >
        {intl.formatMessage(
          {
            id: 'EP_ALERT_DIALOG_FOLLOW_INFORMATION_WEB',
            defaultMessage: 'EP_ALERT_DIALOG_FOLLOW_INFORMATION_WEB',
          },
          { page: pageContext.pageTitle }
        )}
      </StatusBannerAutoHide>
    );
  }

  return (
    <Dialog
      cancelLabel={intl.formatMessage({
        id: 'EP_ALERT_DIALOG_FOLLOW_BTN_CANCEL',
        defaultMessage: 'EP_ALERT_DIALOG_FOLLOW_BTN_CANCEL',
      })}
      confirmLabel={intl.formatMessage({
        id: 'EP_ALERT_DIALOG_FOLLOW_BTN_CONFIRM',
        defaultMessage: 'EP_ALERT_DIALOG_FOLLOW_BTN_CONFIRM',
      })}
      onCancel={close}
      onConfirm={confirm}
      show={show}
      headline={intl.formatMessage(
        {
          id: 'EP_ALERT_DIALOG_FOLLOW_TITLE_WEB',
          defaultMessage: 'EP_ALERT_DIALOG_FOLLOW_TITLE_WEB',
        },
        { page: pageContext.pageTitle }
      )}
      text={intl.formatMessage(
        {
          id: 'EP_ALERT_DIALOG_FOLLOW_QUESTION_WEB',
          defaultMessage: 'EP_ALERT_DIALOG_FOLLOW_QUESTION_WEB',
        },
        { page: pageContext.pageTitle }
      )}
    />
  );
};

export default FollowActionConfirmation;
