import { useEffect, useRef, type FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFeatureSwitch } from '@xing-com/hub';
import { IconInfo, IconStatistics, IconEdit } from '@xing-com/icons';
import { OmView } from '@xing-com/platform-layout-om';

import { XBM_STATISTICS_URL, PROMOTE_URL } from '../../config/urls';
import { useEditContext } from '../../hooks/use-edit-context/use-edit-context';
import { usePageContext } from '../../hooks/use-page-context/use-page-context';
import { useResizeObserver } from '../../hooks/use-resize-observer/use-resize-observer';
import { useStickyBarWithOffset } from '../../hooks/use-sticky-bar-with-offset/use-sticky-bar-with-offset';
import { trackOpenNavInfoBox, getTrackingUrl } from '../../tracking';
import { CreatePostButton } from '../create-post-button/create-post-button';

import { EditBarBack } from './edit-bar-back';
import * as Styled from './edit-bar.styles';
import { mergeRefs } from './helpers';
import { ManageUpdates } from './manage-updates';
import { PageInfo } from './page-info';
import { ToggleEditMode } from './toggle-edit-mode';

type EditBarProps = {
  displayNewsButtons: boolean;
  displayBackButton: boolean;
  canEditOnMobile?: boolean;
  displayManageUpdates?: boolean;
};
export const EditBar: FC<EditBarProps> = ({
  displayNewsButtons,
  displayBackButton,
  canEditOnMobile = false,
  displayManageUpdates = false,
}) => {
  const closeOmViewPageInfo = useRef<any | undefined>();

  const { isEditing, setIsEditing } = useEditContext();
  const { pageContext } = usePageContext();

  const hasCanEditMobileFeatureSwitch = useFeatureSwitch(
    'ep_mobileEdit',
    false
  );

  const isArmstrong = useFeatureSwitch('armstrong_v1', false);

  const [ref, entry] = useResizeObserver();
  const [stickyBarRef, stickyBarFixed] = useStickyBarWithOffset();

  useEffect(() => {
    return () => closeOmViewPageInfo.current?.();
  }, []);

  if (!pageContext.isEditor) {
    return null;
  }

  return (
    <>
      <Styled.EditBarMobile
        $displayCta={!!(canEditOnMobile || displayManageUpdates)}
        data-testid="edit-bar-mobile"
      >
        <Styled.BannerMobileContainer>
          <Styled.BannerMobile>
            {displayBackButton ? (
              <Styled.BackButtonContainer>
                <EditBarBack />
              </Styled.BackButtonContainer>
            ) : null}
            {canEditOnMobile || (displayManageUpdates && !isArmstrong) ? (
              <div className="ctaContainer">
                {canEditOnMobile && (
                  <ToggleEditMode
                    isEditing={!!isEditing}
                    setIsEditing={setIsEditing}
                  />
                )}
                {displayManageUpdates && (
                  <ManageUpdates
                    basePath={pageContext.basePath ?? ''}
                    isArmstrong={isArmstrong}
                    pageSlug={pageContext.pageSlug}
                  />
                )}
              </div>
            ) : null}
            <Styled.LabelContainer>
              {canEditOnMobile && hasCanEditMobileFeatureSwitch ? (
                <IconInfo height={24} width={24} className="editMobileIcon" />
              ) : (
                <IconEdit height={24} width={24} className="editMobileIcon" />
              )}
              <Styled.MobileInfo size="small">
                <span data-testid="edit-bar-mobile-label">
                  <FormattedMessage
                    id={
                      canEditOnMobile && hasCanEditMobileFeatureSwitch
                        ? isEditing
                          ? 'EP_EDIT_INFO_EDIT_VIEW'
                          : 'EP_EDIT_INFO_VISITOR_VIEW'
                        : 'EP_EDIT_INFO_MOBILE'
                    }
                    values={{
                      span: (msg) => <span>{msg}</span>,
                    }}
                  />
                </span>
              </Styled.MobileInfo>
            </Styled.LabelContainer>
          </Styled.BannerMobile>
        </Styled.BannerMobileContainer>
      </Styled.EditBarMobile>

      <Styled.EditBar
        data-testid="edit-bar-desktop"
        $isSticky={!!(pageContext.isShowingCompanyBanner && stickyBarFixed)}
        ref={mergeRefs([ref, stickyBarRef])}
      >
        <Styled.EditBarSticky
          $isSticky={!!stickyBarFixed}
          $isEditing={isEditing}
          $isShowingCompanyBanner={
            !!(pageContext.isShowingCompanyBanner && !stickyBarFixed)
          }
          style={
            typeof entry !== 'undefined' && entry.contentRect.width > 0
              ? { width: entry.contentRect?.width }
              : { visibility: 'hidden' }
          }
        >
          {displayBackButton ? (
            <EditBarBack isArmstrong={isArmstrong} />
          ) : (
            <Styled.InfoContainer>
              <IconInfo height={24} width={24} />
              <Styled.Info size="small">
                <FormattedMessage
                  id={
                    isEditing
                      ? 'EP_EDIT_INFO_EDIT_VIEW'
                      : 'EP_EDIT_INFO_VISITOR_VIEW'
                  }
                />
              </Styled.Info>
            </Styled.InfoContainer>
          )}
          <Styled.ActionContainer>
            {isEditing ? (
              <>
                {pageContext.isCompanyPage ? (
                  <OmView
                    trigger={(activateOmView: () => void) => (
                      <Styled.Button
                        icon={IconInfo}
                        size="small"
                        variant="secondary"
                        data-testid="EP_EDIT_PAGE_INFO_BUTTON"
                        onClick={() => {
                          trackOpenNavInfoBox();
                          activateOmView();
                        }}
                        aria-label="Info"
                      />
                    )}
                  >
                    {({ handleClose }) => {
                      closeOmViewPageInfo.current = handleClose;

                      return <PageInfo />;
                    }}
                  </OmView>
                ) : null}
                {pageContext.isCompanyPage ? (
                  <Styled.Button
                    icon={IconStatistics}
                    size="small"
                    variant="secondary"
                    href={getTrackingUrl({
                      to: XBM_STATISTICS_URL(pageContext.pageSlug),
                      trackingKey: 'viewXBM',
                    })}
                    // @ts-expect-error FIXME: SC6
                    target="_blank"
                    aria-label="Statistics"
                    data-testid="EP_EDIT_PAGE_STATISTICS_BUTTON"
                  />
                ) : null}
                {pageContext.isCompanyPage ? (
                  <Styled.Button
                    variant="secondary"
                    size="small"
                    href={PROMOTE_URL(pageContext.globalId)}
                    onClick={(ev) => {
                      ev.preventDefault();
                      window.open(
                        getTrackingUrl({
                          to: PROMOTE_URL(pageContext.globalId),
                          trackingKey: 'viewPagePromote',
                        })
                      );
                    }}
                  >
                    <FormattedMessage id="EP_EDIT_ACTION_PROMOTE" />
                  </Styled.Button>
                ) : null}
              </>
            ) : (
              <>
                {displayNewsButtons ? (
                  <CreatePostButton>
                    {(onClick) => (
                      <Styled.CreatePostButton
                        variant="tertiary"
                        size="small"
                        onClick={() => onClick()}
                        data-testid="EP_EDIT_PAGE_COMMBOX_BUTTON"
                      >
                        <FormattedMessage id="EP_EDIT_ACTION_POST" />
                      </Styled.CreatePostButton>
                    )}
                  </CreatePostButton>
                ) : null}
              </>
            )}
            <ToggleEditMode
              isEditing={!!isEditing}
              setIsEditing={setIsEditing}
            />
          </Styled.ActionContainer>
        </Styled.EditBarSticky>
      </Styled.EditBar>
    </>
  );
};
