/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type EntityPagesArticleModuleContentArticleDisableMutationVariables =
  Types.Exact<{
    input?: Types.InputMaybe<Types.ContentDisableArticleInput>;
  }>;

export type EntityPagesArticleModuleContentArticleDisableMutation = {
  __typename?: 'Mutation';
  contentDisableArticle?: {
    __typename?: 'ContentArticleMutationResult';
    success?:
      | { __typename?: 'ContentArticle'; status: Types.ContentArticleStatus }
      | {
          __typename?: 'ContentKlartextKlarticle';
          status: Types.ContentArticleStatus;
        }
      | null;
    error?: {
      __typename?: 'ContentError';
      message?: string | null;
      description?: string | null;
      errors?: Array<string> | null;
    } | null;
  } | null;
};

export const EntityPagesArticleModuleContentArticleDisableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'entityPagesArticleModuleContentArticleDisable',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ContentDisableArticleInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentDisableArticle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EntityPagesArticleModuleContentArticleDisableMutation,
  EntityPagesArticleModuleContentArticleDisableMutationVariables
>;
