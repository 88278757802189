import React from 'react';

export const DEFAULT_BANNER_PROPS: ReturnType<UseSocialBannerType>['bannerProps'] =
  { show: false, redirectUrl: '', targetType: '' };

export type UseSocialBannerType = () => {
  bannerProps: {
    show: boolean;
    redirectUrl: string;
    targetType: string;
  };
  setBannerProps: (newBannerProps?: {
    show: boolean;
    redirectUrl?: string;
    targetType?: string;
  }) => void;
};

export const useSocialBanner: UseSocialBannerType = () => {
  const [bannerProps, setBannerProps] = React.useState(DEFAULT_BANNER_PROPS);

  return {
    bannerProps,
    setBannerProps: (newBannerProps) =>
      setBannerProps({ ...DEFAULT_BANNER_PROPS, ...newBannerProps }),
  };
};
