import { FormattedMessage } from 'react-intl';

import { useEditContext } from '@xing-com/crate-entity-pages-common';
import { SocialBar } from '@xing-com/crate-social-bar';
import { useCachedInteractionTarget } from '@xing-com/crate-social-utils';
import { IconLike, IconShow } from '@xing-com/icons';

import type { ArticleCardView } from '../../../types/types';
import { ICON_DIMENSION } from '../constants';

import {
  IconTextWrapper,
  SocialInfoLeft,
  SocialInfoRight,
  SocialInfoWrapper,
} from './article-card-bottom.styles';

type Props = {
  article: ArticleCardView;
};

export const ArticleCardBottom: React.FC<Props> = ({ article }) => {
  const { isEditing } = useEditContext();
  const interactionTarget = useCachedInteractionTarget(article.urn ?? '');

  if (!isEditing && article.urn) {
    return (
      <SocialBar
        data-testid="ARTICLE-CARD-BOTTOM-SOCIAL-BAR"
        urn={article.urn}
        url={article.url}
        hasReaction={true}
        hasShare={true}
        hasComment={true}
        commentTo={article.commentUrl}
        hasViewCounter
        propChannel="wbm/EntityPages"
      />
    );
  }

  const reactionsCount = article.urn
    ? interactionTarget.reactionsCount
    : article.socialInteractions.reactionsCount;

  const commentsCount = article.urn
    ? interactionTarget.commentsCount
    : article.socialInteractions.commentsCount;

  const sharesCount = article.urn
    ? interactionTarget.sharesCount
    : article.socialInteractions.sharesCount;

  const showReactionsCount = reactionsCount > 0 || isEditing;
  const showCommentsCount = commentsCount > 0 || isEditing;
  const showSharesCount =
    sharesCount !== undefined && (sharesCount > 0 || isEditing);
  const showViewsCount = isEditing;

  return (
    <>
      <SocialInfoWrapper>
        <SocialInfoLeft>
          {showReactionsCount ? (
            <IconTextWrapper>
              <IconLike width={ICON_DIMENSION} height={ICON_DIMENSION} />
              <span data-testid="ARTICLE-CARD-BOTTOM-REACTIONS-COUNT">
                {reactionsCount}
              </span>
            </IconTextWrapper>
          ) : null}
          {showCommentsCount ? (
            <span data-testid="ARTICLE-CARD-BOTTOM-COMMENTS-COUNT">
              <FormattedMessage
                id="EP_ARTICLES_SOCIAL_COMMENTS_COUNTER_LABEL"
                values={{ counter: commentsCount }}
              />
            </span>
          ) : null}
          {showSharesCount ? (
            <span data-testid="ARTICLE-CARD-BOTTOM-SHARES-COUNT">
              <FormattedMessage
                id="EP_ARTICLES_SOCIAL_SHARES_COUNTER_LABEL"
                values={{ counter: sharesCount }}
              />
            </span>
          ) : null}
        </SocialInfoLeft>
        <SocialInfoRight>
          {showViewsCount ? (
            <IconTextWrapper>
              <IconShow width={ICON_DIMENSION} height={ICON_DIMENSION} />
              <span>{`${article.socialInteractions.viewsCount}`}</span>
            </IconTextWrapper>
          ) : null}
        </SocialInfoRight>
      </SocialInfoWrapper>
    </>
  );
};
