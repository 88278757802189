import type React from 'react';

import { useUpdateEffect } from '@xing-com/crate-entity-pages-common';
import { IconStarFilled } from '@xing-com/icons';

import { useUnfeatureArticleMutation } from '../../../../hooks/use-unfeature-article-mutation';
import * as Styled from '../article-card-top.styles';

import type { ArticleControlButton } from './types';

export const ArticleUnfeatureButton: React.FC<ArticleControlButton> = ({
  article,
  disabled,
  onLoadingChange,
  onCompleted,
  onError,
}) => {
  const [makeArticleUnfeatured, { loading }] = useUnfeatureArticleMutation();

  useUpdateEffect(() => {
    onLoadingChange(loading);
  }, [loading]);

  const handleClick = () => {
    makeArticleUnfeatured({
      variables: { input: { globalId: article.globalId } },
      onCompleted,
      onError,
    });
  };

  return (
    <Styled.Button
      data-testid={`ARTICLE-UNFEATURE-BUTTON-${article.id}`}
      icon={IconStarFilled}
      size="small"
      variant="tertiary"
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
    />
  );
};
