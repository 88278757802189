import React from 'react';

import ttt from '@xing-com/ticktricktrack';

import { TrackingPropsContext } from '../contexts/tracking-props-context';
import { getPropInteractionType } from '../utils/tracking/get-prop-interaction-type';

export type UseTrackShareFormOpenedType = (props: {
  audience?: string;
  toChannel: 'startpage' | 'messenger';
}) => void;

export const useTrackShareFormOpened: UseTrackShareFormOpenedType = ({
  audience,
  toChannel,
}) => {
  const {
    interactionTargetUrn,
    propSocialInteractionId,
    propSourceType,
    propChannel,
    entryPoint,
    inputHintClick,
  } = React.useContext(TrackingPropsContext);

  React.useEffect(() => {
    ttt.event('EventShareBoxOpen', {
      EventShareBoxOpen: 1,
      PropInteractionType: getPropInteractionType(
        toChannel,
        interactionTargetUrn,
        propSourceType
      ),
      PropActionOrigin:
        audience === 'PUBLIC'
          ? 'social_share_audience_public'
          : 'social_share_audience_my_contacts',
      PropSocialObjectId: interactionTargetUrn,
      PropSocialInteractionId: propSocialInteractionId,
      PropChannel: propChannel,
      ...(inputHintClick && {
        PropContextDimension3: 'social_overview_comment_click',
      }),
      ...(toChannel === 'messenger' && {
        EventMessengerChatOpen: 1,
        EventMessengerNewChatCreate: 1,
        PropMessengerContext: `open|1:1|social_share|${entryPoint || 'misc'}`,
      }),
    });
  }, [audience]);
};
