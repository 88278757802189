import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { SingleSizeIllustrationErrorGenericSmall } from '@xing-com/illustrations';
import {
  scale000,
  scale300,
  scale410,
  space4XL,
  spaceL,
  spaceXL,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export class ShareErrorBoundary extends React.Component<React.PropsWithChildren> {
  state = { hasError: false };

  componentDidCatch(): void {
    this.setState({ hasError: true });
  }

  render(): React.ReactNode {
    return this.state.hasError ? (
      <StyledContainer>
        <SingleSizeIllustrationErrorGenericSmall width={scale300} />
        <StyledBodyCopy>
          <FormattedMessage id="SHARE_FETCH_ERROR" />
        </StyledBodyCopy>
        {
          // @ts-expect-error FIXME: SC6
          <Button Button variant="secondary" onClick={window.location.reload}>
            <FormattedMessage id="SHARE_FETCH_ERROR_RETRY" />
          </Button>
        }
      </StyledContainer>
    ) : (
      this.props.children
    );
  }
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${space4XL} ${scale000} ${spaceXL};
  text-align: center;
`;

const StyledBodyCopy = styled(BodyCopy)`
  max-width: ${scale410};
  margin: ${spaceL};
  text-align: center;
`;
