import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconFile, IconCross } from '@xing-com/icons';

import { FileUpload } from '../file-upload/file-upload';

import * as Styled from './subtitles.styles';

type SubtitlesProps = {
  displayEn?: string;
  displayDe?: string;
  setSubtitleEn?: (file: File | null) => void;
  setSubtitleDe?: (file: File | null) => void;
  onError?: (error: any) => void;
};
export const Subtitles: FC<SubtitlesProps> = ({
  displayEn,
  displayDe,
  setSubtitleEn,
  setSubtitleDe,
  onError = () => undefined,
}) => (
  <Styled.SubtitlesContainer>
    <Styled.Headline size="xxlarge">
      <FormattedMessage id="VIDEO_UPLOAD_SUBTITLES_TITLE" />
    </Styled.Headline>
    <Styled.SubtitlesDescription size="medium">
      <FormattedMessage id="VIDEO_UPLOAD_SUBTITLES_DESCRIPTION" />
    </Styled.SubtitlesDescription>
    <SubtitlesItem
      language="EN"
      display={displayEn}
      setFile={setSubtitleEn}
      onError={onError}
    />
    <SubtitlesItem
      language="DE"
      display={displayDe}
      setFile={setSubtitleDe}
      onError={onError}
    />
  </Styled.SubtitlesContainer>
);

type SubtitlesItemProps = {
  language?: string;
  display?: string;
  setFile?: (file: File | null) => void;
  onError?: (error: any) => void;
};
const SubtitlesItem: FC<SubtitlesItemProps> = ({
  language,
  display,
  setFile = () => undefined,
  onError = () => undefined,
}) => (
  <Styled.SubtitleItem>
    <Styled.SubtitlesLabel size="medium">
      <FormattedMessage id={`VIDEO_UPLOAD_SUBTITLES_LABEL_${language}`} />
    </Styled.SubtitlesLabel>
    <Styled.UploadCtaContainer>
      <FileUpload
        accept=".srt, .vtt"
        maxSize={10000000}
        onError={onError}
        // @ts-expect-error TS(2322) FIXME: Type '(file: File | null) => void' is not assignab... Remove this comment to see the full error message
        onChange={setFile}
        variant="tertiary"
        size="small"
        icon={IconFile}
      >
        <FormattedMessage id="VIDEO_UPLOAD_SUBTITLES_CTA" />
      </FileUpload>
    </Styled.UploadCtaContainer>
    {display && (
      <Styled.FileAddedContainer>
        <Styled.FileName>{display}</Styled.FileName>
        <Styled.DeleteButton onClick={() => setFile(null)}>
          <IconCross width="16" height="16" />
        </Styled.DeleteButton>
      </Styled.FileAddedContainer>
    )}
  </Styled.SubtitleItem>
);
