import type * as React from 'react';

import { TrackingPropsContext } from './tracking-props-context';
import type { TrackingPropsContextType } from './tracking-props-context';

export const TrackingPropsContextWrapper: React.FC<
  React.PropsWithChildren<TrackingPropsContextType>
> = ({
  backendTrackingMetaData,
  children,
  entryPoint,
  inputHintClick,
  interactionTargetUrn,
  propChannel,
  propSocialInteractionId,
  propSourceType,
}) => (
  <TrackingPropsContext.Provider
    value={{
      backendTrackingMetaData,
      entryPoint,
      inputHintClick,
      interactionTargetUrn,
      propChannel,
      propSocialInteractionId,
      propSourceType,
    }}
  >
    {children}
  </TrackingPropsContext.Provider>
);
