import type { FC, PropsWithChildren } from 'react';

// eslint-disable-next-line monorepo/forbidden-imports
import { ZenView } from '@xing-com/platform-layout-zen';
import { GridContainer } from '@xing-com/xing-grid';

type PageLayoutProps = {
  isZenView?: boolean;
  isWebview?: boolean;
};
export const PageLayout: FC<PropsWithChildren<PageLayoutProps>> = ({
  children,
  isZenView,
  isWebview,
}) => {
  if (isWebview) {
    return <>{children}</>;
  }

  if (isZenView) {
    return <ZenView>{children}</ZenView>;
  }

  return <GridContainer>{children}</GridContainer>;
};
