import type { SelectableAudienceType } from '@xing-com/audience-selection/lib/types';
import { getMentionsCount } from '@xing-com/content-editor-plugin-mention';
import type { PollDataType, PollPreviewType } from '@xing-com/poll';

import { trackAction, trackEvent } from '../tracking';
import type {
  CommboxAttachmentType,
  CommboxApplicationType,
  CommboxErrorMessageType,
  CommboxImagesType,
  CommboxOnPostType,
} from '../types';

import {
  audienceSurnFor,
  getPostTarget,
  getPostTypeTracking,
  trimArticleParagraph,
} from './commbox';

export type IsPostButtonDisabledType = (props: {
  attachmentType: CommboxAttachmentType;
  imageIsUploading: boolean;
  isAllowedToPost: boolean;
  isCreatingPosting: boolean;
  isEditingPosting: boolean;
  isPreviewCompleted: boolean;
  text: string;
}) => boolean;

export const isPostButtonDisabled: IsPostButtonDisabledType = ({
  attachmentType,
  imageIsUploading,
  isAllowedToPost,
  isCreatingPosting,
  isEditingPosting,
  isPreviewCompleted,
  text,
}) => {
  if (isEditingPosting) return false;
  if (attachmentType === 'BLOCKED') return true;

  if (attachmentType === 'LINK_PREVIEW' && !isPreviewCompleted) return true;
  if (attachmentType === 'EMPTY' && text.trim().length < 1) return true;
  if (!isAllowedToPost) return true;
  if (isCreatingPosting) return true;
  if (imageIsUploading) return true;

  return false;
};

export type HandlePostClickType = (props: {
  application: CommboxApplicationType;
  attachmentType: CommboxAttachmentType;
  globalId: string;
  images: CommboxImagesType[];
  isEditingPosting: boolean;
  linkShareUrl: string | null;
  onPost: CommboxOnPostType;
  onSuccess: () => void;
  pollData: PollDataType | PollPreviewType | null;
  posting: any;
  publishAt: string;
  scheduled: 0 | 1;
  selectableAudiences: SelectableAudienceType[];
  selectedActorGlobalId: string;
  selectedAudience: SelectableAudienceType['id'];
  setErrorMessage: React.Dispatch<
    React.SetStateAction<CommboxErrorMessageType>
  >;
  setIsAllowedPost: React.Dispatch<React.SetStateAction<boolean>>;
  slateMessage: any;
  uploadedFileId: string | null;
  videoState: { id: string | null; status: string | null };
}) => void;

export const handlePostClick: HandlePostClickType = async ({
  application,
  attachmentType,
  globalId,
  images,
  isEditingPosting,
  linkShareUrl,
  onPost,
  onSuccess,
  pollData,
  posting,
  publishAt,
  scheduled,
  selectableAudiences,
  selectedActorGlobalId,
  selectedAudience,
  setErrorMessage,
  setIsAllowedPost,
  slateMessage,
  videoState,
}) => {
  const audienceObject = selectableAudiences.find(
    (audience) => audience.id === selectedAudience
  ) || { id: 'PUBLIC', visibility: 'PUBLIC' };

  const userId = selectedActorGlobalId.split(':').pop() || '';

  const submitData = isEditingPosting
    ? {
        id: posting?.id,
        publishAt,
        commentArticleV1: trimArticleParagraph(slateMessage),
      }
    : {
        actorGlobalId: selectedActorGlobalId,
        commentArticleV1: slateMessage
          ? trimArticleParagraph(slateMessage)
          : undefined,
        publicationStrategy: publishAt ? 'LATER' : 'NOW',
        visibility: audienceObject.visibility,
        publishAt: publishAt,
        images:
          attachmentType === 'IMAGE'
            ? images.map(({ uploadedFileId: uploadId }) => ({ uploadId }))
            : undefined,
        links:
          attachmentType === 'LINK_PREVIEW' && linkShareUrl
            ? [{ url: linkShareUrl }]
            : undefined,
        videos:
          attachmentType === 'VIDEO' && videoState?.id
            ? [{ videoId: videoState.id }]
            : undefined,
        polls: attachmentType === 'POLLS' && pollData ? [pollData] : undefined,
        audience: audienceSurnFor(audienceObject.id, userId),
      };

  const isGifPost =
    images.find((image) => image.type === 'image/gif') !== undefined;
  const postType = getPostTypeTracking(attachmentType, isGifPost);

  // @ts-expect-error Commbox will be removed in Q2
  const data: any = await onPost(submitData);
  const { success, error } = data;

  if (!success || error) setErrorMessage(error?.message || 'GENERIC_ERROR');
  if (success) {
    setErrorMessage(null);
    if (isEditingPosting)
      trackAction({ PropTrackAction: 'social_share_commbox_edit_post_save' });
    onSuccess();
  }
  const postTarget = getPostTarget(application);
  const mentionsCount = getMentionsCount(slateMessage);

  trackEvent('EventPostCreate', {
    EventPostCreate: 1,
    PropContextDimension2: `${selectedAudience.toLowerCase()}|comment_1|scheduled_${scheduled}|images_${
      images.length
    }`,
    PropContextDimension4: `social_${postType}`,
    PropInteractionType: `social_commbox_share|startpage|${postTarget}_post`,
    PropSocialObjectId: globalId,
    ...(mentionsCount && { EventUserMention: mentionsCount }),
  });

  // Regardless of the result of the query,the user should be able to post again.
  setIsAllowedPost(true);
};
