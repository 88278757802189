import { NetworkStatus, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { EntityPagesArticleModuleListDocument } from '../../graphql/queries/entity-pages-article-module-list.gql-types';
import type { EntityPagesArticleModuleListQueryVariables } from '../../graphql/queries/entity-pages-article-module-list.gql-types';
import {
  createArticleCard,
  createArticleCards,
} from '../../models/article-card';

export const useArticleCards = (
  variables: EntityPagesArticleModuleListQueryVariables
) => {
  const { data, error, networkStatus, refetch, fetchMore } = useQuery(
    EntityPagesArticleModuleListDocument,
    {
      variables,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );

  const fetchMoreLoading = networkStatus === NetworkStatus.fetchMore;
  const initialLoading = networkStatus === NetworkStatus.loading;
  const refetchLoading =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  const metadata = data?.viewer?.contentPage?.metadata;
  const allAmount = useMemo(
    () =>
      Object.values({ ...metadata }).reduce<number>(
        (sum, value) => sum + (typeof value === 'number' ? value : 0),
        0
      ),
    [metadata]
  );

  const featuredArticle = data?.contentPageFeaturedArticle;
  const featuredArticleCard = featuredArticle
    ? createArticleCard(featuredArticle)
    : undefined;

  const articlesEdges = data?.contentPageArticles?.edges;
  const articlesNodes = useMemo(
    () =>
      articlesEdges
        ?.map((edge) => edge?.node)
        .filter((node) => node !== undefined)
        .filter((node) => !node.isFeatured) ?? [],
    [articlesEdges]
  );
  const nonFeaturedArticlesCards = createArticleCards(articlesNodes);

  const articlesCards = featuredArticleCard
    ? [featuredArticleCard, ...nonFeaturedArticlesCards]
    : nonFeaturedArticlesCards;

  const hasFeaturedArticle = !!featuredArticleCard;
  const hasNextPage = !!data?.contentPageArticles?.pageInfo.hasNextPage;
  const endCursor = data?.contentPageArticles?.pageInfo.endCursor;

  const handleFetchMore = () => {
    return new Promise((resolve) => {
      fetchMore({
        variables: {
          after: data?.contentPageArticles?.pageInfo.endCursor,
        },
        updateQuery: (prev: any, { fetchMoreResult }) => {
          resolve(true);
          if (!fetchMoreResult) return prev;
          return {
            viewer: {
              ...prev?.viewer,
            },
            contentPageArticles: {
              ...fetchMoreResult.contentPageArticles,
              edges: [
                ...(prev?.contentPageArticles?.edges ?? []),
                ...(fetchMoreResult?.contentPageArticles?.edges ?? []),
              ],
            },
          };
        },
      });
    });
  };
  const handleRefetch = (first?: number) => {
    return refetch({
      ...variables,
      first: first !== undefined ? first : variables.first,
    });
  };

  return {
    articlesCards,
    metadata: {
      allAmount: allAmount,
      disabledArticlesCount: metadata?.disabledArticlesCount,
      draftArticlesCount: metadata?.draftArticlesCount,
      publishedArticlesCount: metadata?.publishedArticlesCount,
      scheduledArticlesCount: metadata?.scheduledArticlesCount,
    },
    hasFeaturedArticle,
    hasNextPage,
    endCursor,
    fetchMoreLoading,
    initialLoading,
    refetchLoading,
    error,
    refetch: handleRefetch,
    fetchMore: handleFetchMore,
  };
};
