import { createContext } from 'react';

type SubpageHeaderContextValues = {
  pageType?: string;
};

type HeaderContextValues = SubpageHeaderContextValues & {
  title: string;
  subtitle: string;
  isSubpage?: boolean;
  hasTertiaryArea: boolean;
};
export const HeaderContext = createContext<HeaderContextValues | undefined>(
  undefined
);
