import type { FC } from 'react';

import { usePageContext } from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import * as Styled from './entity-info.styles';

type EntityInfoDataType = {
  value?: JSX.Element | string;
  label?: JSX.Element | string;
  className?: string;
  testId?: string;
};

type EntityInfoProps = {
  data: EntityInfoDataType[];
  className?: string;
};
export const EntityInfo: FC<EntityInfoProps> = ({ data, className }) => {
  const { isLoggedOut } = useLoginState();
  const { pageContext } = usePageContext();

  const socialProof = pageContext.socialProof;

  const condensed = isLoggedOut || socialProof?.total === 0;

  if (data.length === 0) {
    return null;
  }

  return (
    <Styled.EntityInfoBlockWrapper
      condensed={!!condensed}
      className={className}
    >
      {data.map(({ className, testId, label, value }, key) => (
        <Styled.EntityInfoBlock
          key={key}
          singleLine={className === 'singleLine'}
          className={className}
        >
          <Styled.InfoBlockText
            size="small"
            sizeWide="medium"
            data-testid={testId}
          >
            <Styled.CondensedEntityInfoBlockValue>
              {value}
            </Styled.CondensedEntityInfoBlockValue>
            <Styled.Label>{label}</Styled.Label>
          </Styled.InfoBlockText>
        </Styled.EntityInfoBlock>
      ))}
    </Styled.EntityInfoBlockWrapper>
  );
};
