import styled from 'styled-components';

import * as layoutTokens from '@xing-com/layout-tokens';
import * as tokens from '@xing-com/tokens';

export const MainPageWrapper = styled.div.attrs({
  className: 'pageWrapper',
})`
  position: relative;
  padding-bottom: ${tokens.scale220};

  @media ${layoutTokens.mediaSmallOnly} {
    padding-top: ${tokens.spaceM};
    border-radius: 8px;
  }

  @media ${layoutTokens.mediaXWide} {
    padding-top: ${tokens.spaceXL};
    border-radius: 8px;
  }
`;

type HeaderWrapperProps = {
  isShowingCompanyBanner: boolean;
};
export const HeaderWrapper = styled.div.attrs({
  className: 'headerWrapper',
})<HeaderWrapperProps>`
  position: relative;

  ${({ isShowingCompanyBanner }) =>
    isShowingCompanyBanner ? `margin-top: ${tokens.spaceXL};` : ''}
`;
