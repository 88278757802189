import type { FC } from 'react';

import type { ButtonProps } from '@xing-com/button';
import { useFeatureSwitch } from '@xing-com/hub';
import { IconEdit } from '@xing-com/icons';

import * as Styled from './edit-button.styles';

const isIconVisible = (showIcon?: boolean) => showIcon && IconEdit;

type EditButtonProps = ButtonProps & {
  showIcon?: boolean;
  ariaLabel?: string;
};
export const EditButton: FC<EditButtonProps> = ({
  children,
  showIcon,
  ariaLabel,
  ...props
}) => {
  const canEditMobile = useFeatureSwitch('ep_mobileEdit');

  return (
    <Styled.Button
      $mobileButton={canEditMobile}
      icon={isIconVisible(showIcon)}
      {...props}
      aria-label={ariaLabel || 'Edit'}
    >
      {children}
    </Styled.Button>
  );
};
