import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';

import { Carousel } from '@xing-com/carousel';
import { IconVideo } from '@xing-com/icons';
import { spaceXL } from '@xing-com/tokens';
import Video from '@xing-com/video';

import type { GalleryItemsType, ItemType } from '../../types';

import * as Styled from './gallery.styles';

const ExternalIcon: FC = () => (
  <Styled.ExternalIcon>
    <IconVideo width={30} height={30} />
  </Styled.ExternalIcon>
);

type MediaProps = {
  imageUrl?: string;
  videoId?: string;
  pageNumber: number | null;
  index: number;
  externalUrl?: string;
  videoButtonSize: number;
};
const Media: FC<MediaProps> = ({
  imageUrl,
  videoId,
  pageNumber,
  index,
  externalUrl,
}) => {
  const [videoActions, setVideoActions] = useState<any>(null);

  useEffect(() => {
    if (videoActions && pageNumber ? pageNumber - 1 !== index : null) {
      videoActions.pause(true);
    }
  }, [pageNumber]);

  const setPlayerActions = (api: any) => setVideoActions(api);

  return (
    <>
      {videoId && (
        <Video
          id={videoId}
          actions={setPlayerActions}
          section="entity_pages_gallery"
          onError={() => undefined}
        />
      )}
      {externalUrl && (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={externalUrl} target="_blank" rel="nofollow">
          <Styled.Image src={imageUrl} loading="lazy" alt="Gallery image" />
          <ExternalIcon />
        </a>
      )}
      {imageUrl && !externalUrl && (
        <Styled.Image src={imageUrl} loading="lazy" alt="Gallery image" />
      )}
    </>
  );
};

type GalleryProps = {
  galleryItems: GalleryItemsType;
  itemClicked?: number;
};
export const Gallery: FC<GalleryProps> = ({
  galleryItems = [],
  itemClicked = 0,
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [pageNumber, setPageNumber] = useState<number>(itemClicked);

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const actualPage = pageNumber + 1;

  return (
    <>
      <Styled.CarouselWrapper data-testid="CAROUSEL-WRAPPER" ref={carouselRef}>
        <Carousel
          itemWidth="100%"
          itemWidthConfined="100%"
          itemWidthWide="100%"
          itemSpacing={spaceXL}
          defaultActive={itemClicked}
          onItemEnter={(ev) => {
            handlePageChange(ev.itemIndex);
          }}
          customScrollWidth={
            (carouselRef.current?.clientWidth ?? 0) + parseInt(spaceXL)
          }
        >
          {galleryItems.map((item: ItemType, index: number) => (
            <Styled.SliderItemWrapper key={index}>
              <Styled.SliderItem data-testid={`gallery-item-${index}`}>
                <Media
                  imageUrl={item.media?.url}
                  videoId={item.media?.videoReferenceV2}
                  externalUrl={item.media?.externalLink}
                  videoButtonSize={64}
                  pageNumber={actualPage}
                  index={index}
                />
              </Styled.SliderItem>
            </Styled.SliderItemWrapper>
          ))}
        </Carousel>
      </Styled.CarouselWrapper>
      <Styled.InfoWrapper>
        <Styled.Pagination>
          {`${actualPage}/${galleryItems.length}`}
        </Styled.Pagination>
        <Styled.Description>
          {galleryItems[pageNumber]?.description}
        </Styled.Description>
      </Styled.InfoWrapper>
    </>
  );
};
