import { useState } from 'react';
import type * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import {
  useDialogContext,
  useUpdateEffect,
} from '@xing-com/crate-entity-pages-common';
import { FormField } from '@xing-com/text-field';
import { Headline } from '@xing-com/typography';

import { useAboutQuery } from '../../hooks/use-about-query';
import { useSaveDescription } from '../../hooks/use-save-description';
import { goToMainPage } from '../../utils';
import { ErrorState } from '../error-state/error-state';

import { AboutEditViewSkeleton } from './about-edit-view.skeleton';
import * as Styled from './about-edit-view.styles';
import { MAX_CHARACTERS_TEXTAREA, MIN_CHARACTERS_TEXTAREA } from './constants';

type AboutEditViewProps = {
  pageSlug: string;
};

export const AboutEditView: React.FC<AboutEditViewProps> = ({ pageSlug }) => {
  const intl = useIntl();
  const { executeWithDialog, setDataChanged } = useDialogContext();
  const { loading, error, description, refetch } = useAboutQuery(pageSlug);
  const { isSaving, saveDescription } = useSaveDescription(pageSlug);
  const [value, setValue] = useState('');
  const hasTooFewCharacters = value.length < MIN_CHARACTERS_TEXTAREA;

  const onCancel = (): void => {
    executeWithDialog(() => goToMainPage(pageSlug));
  };

  useUpdateEffect(() => {
    setValue(description);
  }, [description]);

  useUpdateEffect(() => {
    if (!loading) {
      setDataChanged(description !== value);
    }
  }, [loading, value, description]);

  return (
    <>
      <Headline size="xxlarge" noMargin>
        <FormattedMessage id="EP_ABOUT_EDIT_VIEW_TITLE" />
      </Headline>
      <Styled.AboutEditBodyCopy size="medium">
        <FormattedMessage
          id="EP_ABOUT_EDIT_VIEW_COPY"
          values={{ maxCharacters: MAX_CHARACTERS_TEXTAREA }}
        />
      </Styled.AboutEditBodyCopy>
      {loading ? (
        <AboutEditViewSkeleton />
      ) : error ? (
        <ErrorState loading={loading} refetch={refetch} />
      ) : (
        <>
          <FormField
            // @ts-expect-error FIXME: SC6
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              event.target.value = event.target.value.slice(
                0,
                MAX_CHARACTERS_TEXTAREA
              );
              setValue(event.target.value);
            }}
            value={value}
            label={intl.formatMessage({
              id: 'EP_ABOUT_EDIT_VIEW_LABEL_INPUT_FIELD',
            })}
            maxCharacterCount={MAX_CHARACTERS_TEXTAREA}
            showCharacterCounter
            minRows={4}
            multiline
          />
          <Styled.ButtonContainer>
            <Button onClick={onCancel} disabled={isSaving}>
              <FormattedMessage id="EP_EDIT_ABOUTUS_DESCRIPTION_DISCARD" />
            </Button>
            <Button
              variant="primary"
              onClick={() => saveDescription(value)}
              disabled={isSaving || hasTooFewCharacters}
            >
              <FormattedMessage id="EP_EDIT_ABOUTUS_DESCRIPTION_PUBLISH" />
            </Button>
          </Styled.ButtonContainer>
        </>
      )}
    </>
  );
};
