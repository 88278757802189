import { useEffect, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconFileImage } from '@xing-com/icons';

import { useErrorContext } from '../../hooks/use-error-context/use-error-context';
import { useUploadFile } from '../../hooks/use-upload-file/use-upload-file';

import { CropImage } from './crop-image';
import * as Styled from './image-upload.styles';
import { getImage } from './utils';

export type ImageUploadCopy = {
  headline?: string;
  discardButton?: string;
  publishButton?: string;
  hint: string;
  label: string;
  onDragLabel: string;
};
export type ImageUploadOnPublishData = {
  cropped: {
    id: string;
    file: File;
  };
  original?: {
    id: string;
    file: File;
  };
};

type UploadProps = {
  maxSize?: number;
  noHeadline?: boolean;
  descriptionCopyKey?: string;
  defaultImageCrop?: string;
  shouldUploadOriginal?: boolean;
  imageUploadCopy?: ImageUploadCopy;
  aspectRatio?: number;
  cropperLeftButton?: React.ReactNode;
  showUploading?: boolean;
  onCropReady?: () => void;
  onDragCrop?: () => void;
  onFileSelected?: (file: File) => void;
  onCancel?: () => void;
  onPublish: (data: ImageUploadOnPublishData) => void;
  onUploadStart?: () => void;
  onError?: (error: any) => void;
  onDiscard: () => void;
};
export const ImageUpload: FC<PropsWithChildren<UploadProps>> = ({
  maxSize = 5000000,
  noHeadline,
  descriptionCopyKey,
  defaultImageCrop = undefined,
  shouldUploadOriginal,
  aspectRatio,
  imageUploadCopy,
  children,
  cropperLeftButton = undefined,
  showUploading = false,
  onCropReady,
  onDragCrop,
  onDiscard,
  onFileSelected = () => undefined,
  onUploadStart = () => undefined,
  onError = () => undefined,
  onPublish,
}) => {
  const { showError } = useErrorContext();
  const { $t } = useIntl();
  const { uploadImage } = useUploadFile();

  const [resetCompontKey, setResetComponentKey] = useState(0);
  const [imageUploaded, setImageUploaded] = useState<File | undefined>();
  const [hovering, setHovering] = useState(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleFileSelected = (file: File) => {
    if (file?.size > maxSize) {
      showError({
        message: 'EP_IMAGE_UPLOAD_SIZE_FORMAT_ERROR',
      });
      setResetComponentKey(resetCompontKey + 1);
    } else {
      setImageUploaded(file);
      onFileSelected(file);
    }
  };
  const handleCroppedImage = async (file: File) => {
    if (!imageUploaded) {
      return;
    }

    setIsUploading(true);
    onUploadStart();

    const { data: croppedImageData, error } = await uploadImage(file);

    if (error || !croppedImageData?.id) {
      showError({ message: 'EP_GENERAL_FEEDBACK_ERROR', error });
      onError(error);
      setIsUploading(false);
      return;
    }

    const imageFile = new File([imageUploaded], imageUploaded.name, {
      type: imageUploaded.type,
    });

    const cropped = { id: croppedImageData?.id, file };
    let original = undefined;

    if (shouldUploadOriginal) {
      const { data: originalImageData } = await uploadImage(imageFile);

      original = originalImageData
        ? { id: originalImageData.id, file: imageFile }
        : undefined;
    }

    onPublish({ cropped, original });

    setIsUploading(false);
  };

  useEffect(() => {
    if (defaultImageCrop) {
      getImage(defaultImageCrop).then(({ file }) => {
        setImageUploaded(file);
      });
    }
  }, [defaultImageCrop]);

  const showUpload = defaultImageCrop ? false : !imageUploaded;

  return (
    <>
      {showUpload ? (
        <>
          {!noHeadline && (
            <Styled.UploadHeadline size="xxlarge">
              <FormattedMessage
                id={imageUploadCopy?.headline}
                defaultMessage={imageUploadCopy?.headline}
              />
            </Styled.UploadHeadline>
          )}
          <Styled.FileUploadDrop
            onHover={setHovering}
            accept="image/jpeg, image/png, image/bmp"
            onFileSelected={handleFileSelected}
            onError={() =>
              showError({ message: 'EP_IMAGE_UPLOAD_SIZE_FORMAT_ERROR' })
            }
          >
            <Styled.DropImageHeadline>
              {hovering
                ? $t({ id: imageUploadCopy?.onDragLabel })
                : imageUploadCopy?.label}
            </Styled.DropImageHeadline>
            <span>{imageUploadCopy?.hint}</span>
            <Styled.FileUploadButton
              accept="image/jpeg, image/png, image/bmp"
              variant="primary"
              icon={IconFileImage}
              onFileSelected={handleFileSelected}
              onError={() =>
                showError({ message: 'EP_IMAGE_UPLOAD_SIZE_FORMAT_ERROR' })
              }
            >
              {$t({ id: 'MALT_IMAGE_UPLOAD_BROWSE_BUTTON' })}
            </Styled.FileUploadButton>
          </Styled.FileUploadDrop>
        </>
      ) : (
        <>
          <CropImage
            imageUrl={imageUploaded ? URL.createObjectURL(imageUploaded) : ''}
            aspectRatio={aspectRatio}
            noHeadline={noHeadline}
            isUploading={isUploading || showUploading}
            onCropReady={onCropReady}
            onDragCrop={onDragCrop}
            onDiscard={onDiscard}
            showError={showError}
            onChange={({ file }) => handleCroppedImage(file)}
            descriptionCopyKey={descriptionCopyKey}
            discardCopy={imageUploadCopy?.discardButton}
            publishCopy={imageUploadCopy?.publishButton}
            leftButton={cropperLeftButton}
          >
            {children}
          </CropImage>
        </>
      )}
    </>
  );
};
