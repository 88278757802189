import type React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { useDialogContext } from '@xing-com/crate-entity-pages-common';
import { TagManagerId } from '@xing-com/winery-tag-manager';

import { ArticleEditor } from '../article-editor/article-editor';

import * as Styled from './description-edit.styles';
import ShortIntro from './short';
import type { FormDataType } from './types';

type DescriptionEditProps = {
  activeIndex?: number;
  formData?: FormDataType;
  setFormData?: React.Dispatch<React.SetStateAction<FormDataType>>;
  setDetailedValue: (detailedValue: string | React.ReactNode) => void;
  handlePublish?: () => void;
  isSaving?: boolean;
  goBackUrl?: () => void;
};

const DescriptionEdit = ({
  activeIndex,
  formData,
  setFormData,
  setDetailedValue,
  handlePublish,
  isSaving,
  goBackUrl,
}: DescriptionEditProps) => {
  const { executeWithDialog } = useDialogContext();

  return (
    <>
      <div>
        {activeIndex === 0 && (
          <ShortIntro formData={formData} setFormData={setFormData} />
        )}
        {activeIndex === 1 && (
          <ArticleEditor
            value={formData?.detailedValue}
            setValue={setDetailedValue}
          />
        )}
      </div>
      <Styled.ActionsContainer>
        <Styled.DiscardButton
          size={'medium'}
          disabled={isSaving}
          onClick={() => executeWithDialog(() => goBackUrl && goBackUrl())}
          data-cy="DISCARD_ABOUT_US_DESC_BUTTON"
        >
          <FormattedMessage
            id="EP_EDIT_ABOUTUS_DESCRIPTION_DISCARD"
            defaultMessage="EP_EDIT_ABOUTUS_DESCRIPTION_DISCARD"
          />
        </Styled.DiscardButton>
        <TagManagerId id="btn_saveaboutus">
          <Button
            variant={'primary'}
            size={'medium'}
            onClick={handlePublish}
            loading={isSaving}
            data-cy="PUBLISH_ABOUT_US_DESC_BUTTON"
            data-testid="PUBLISH_ABOUT_US_DESC_BUTTON"
          >
            <FormattedMessage
              id="EP_EDIT_ABOUTUS_DESCRIPTION_PUBLISH"
              defaultMessage="EP_EDIT_ABOUTUS_DESCRIPTION_PUBLISH"
            />
          </Button>
        </TagManagerId>
      </Styled.ActionsContainer>
    </>
  );
};

export default DescriptionEdit;
