import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { EntityPageContractType } from '@xing-com/crate-common-graphql-types';
import {
  useDialogContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import {
  IconEdit,
  IconHamburgerMenu,
  IconHide,
  IconReturn,
  IconTrash,
} from '@xing-com/icons';

import { useContactsEditContext } from '../../hooks/use-contacts-edit-context/use-contacts-edit-context';
import type { ContactEdit } from '../../types/contacts-edit';

import * as Styled from './contacts-edit-card-actions.styles';

type ContactsEditCardActionsProps = {
  contactData: ContactEdit;
  disableAllActions?: boolean;
  onEditItem: () => void;
};
export const ContactsEditCardActions: FC<ContactsEditCardActionsProps> = ({
  contactData,
  disableAllActions,
  onEditItem,
}) => {
  const { $t } = useIntl();
  const { pageContext } = usePageContext();
  const { setDataChanged } = useDialogContext();
  const { contactDataList, setContactUsersList, setContactsTouched } =
    useContactsEditContext();

  const handleUndoDelete = () => {
    setContactUsersList(
      contactDataList.map((mappedContactData) => {
        if (mappedContactData.contact.id === contactData.contact.id) {
          mappedContactData.deleted = false;
        } else if (pageContext.contractType === EntityPageContractType.Free) {
          mappedContactData.deleted = true;
        }
        return mappedContactData;
      })
    );
  };
  const handleDisableItem = () => {
    setContactUsersList(
      contactDataList?.map((contactData) => {
        if (contactData.default) {
          contactData.enabled = !contactData.enabled;
          return contactData;
        }

        return contactData;
      })
    );
    setContactsTouched(true);
    setDataChanged(true);
  };
  const handleDeleteItem = () => {
    setContactUsersList(
      contactDataList.map((mappedContactData) => {
        if (mappedContactData.contact.id === contactData.contact.id) {
          mappedContactData.deleted = true;
        }
        return mappedContactData;
      })
    );
    setDataChanged(true);
    setContactsTouched(true);
  };

  if (contactData.deleted) {
    return (
      <Styled.DeletedButton
        data-cy="RESTORE_CONTACT_BUTTON"
        data-testid="RESTORE_CONTACT_BUTTON"
        size="small"
        icon={IconReturn}
        onClick={handleUndoDelete}
      >
        {$t({ id: 'EP_RESTORE_CTA' })}
      </Styled.DeletedButton>
    );
  }

  const deletedContactsList = contactDataList.filter(({ deleted }) => !deleted);
  const disableDelete = deletedContactsList.length === 1;
  const showDragAndDrop = contactDataList.length > 1;

  return (
    <>
      <Styled.DefaultItemActionButton
        disabled={!contactData.enabled || disableAllActions}
        data-cy="EDIT_CONTACT_LABEL_BUTTON"
        data-testid="CONTACTS-EDIT-CARD-ACTIONS-EDIT"
        icon={IconEdit}
        onClick={onEditItem}
        size="small"
        variant="tertiary"
        aria-label="Edit"
      />
      {contactData.default ? (
        <Styled.DefaultUserButton
          data-testid="CONTACTS-EDIT-CARD-ACTIONS-DISABLE"
          $activeEnableButton={contactData.enabled}
          icon={IconHide}
          disabled={contactDataList.length === 1 || disableAllActions}
          size="small"
          variant="tertiary"
          onClick={handleDisableItem}
          aria-label="Hide"
        />
      ) : (
        <Styled.DefaultItemActionButton
          data-cy={`DELETE_CONTACT_BUTTON`}
          data-testid={`DELETE_CONTACT_BUTTON_${contactData.contact.xingId?.pageName}`}
          icon={IconTrash}
          disabled={disableAllActions || disableDelete}
          size="small"
          variant="tertiary"
          onClick={handleDeleteItem}
          aria-label="Trash"
        />
      )}
      {showDragAndDrop && (
        <Styled.DragButton
          data-testid="CONTACTS-EDIT-CARD-ACTIONS-DRAG"
          // @ts-expect-error ReactSortable handler
          name={disableAllActions ? '' : 'dragButton'}
          icon={IconHamburgerMenu}
          disabled={disableAllActions}
          size="small"
          aria-label="Hamburger"
        />
      )}
    </>
  );
};
