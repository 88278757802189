import { useMutation } from '@apollo/client';
import type { FetchResult } from '@apollo/client';

import { getSocialTrackingMetaData } from '@xing-com/crate-social-utils';

import type { TrackingPropsContextType } from '../contexts/tracking-props-context';
import { SocialShareToStartpageDocument } from '../graphql';
import type { SocialShareToStartpageMutation } from '../graphql';
import type { AudiencePropsType, OnErrorType, OnSuccessType } from '../types';
import { trackShareToFeedSuccess } from '../utils/tracking';

export type UseShareToFeedMutationType = (props: {
  audience: AudiencePropsType['selectedAudience'];
  isQuickShare?: boolean;
  message?: string;
  onError: OnErrorType;
  onSuccess: OnSuccessType;
  verb: 'SHARE' | 'POST';
  trackingProps: TrackingPropsContextType;
}) => {
  apiErrorMessage: string;
  hasError: boolean;
  loading: boolean;
  triggerShare: () => void;
};

export const useShareToFeedMutation: UseShareToFeedMutationType = ({
  audience,
  isQuickShare = false,
  message = '',
  onError,
  onSuccess,
  verb,
  trackingProps: {
    backendTrackingMetaData,
    inputHintClick,
    interactionTargetUrn,
    propChannel,
    propSocialInteractionId,
    propSourceType,
  },
}) => {
  const [mutation, { loading, data, error }] =
    useMutation<SocialShareToStartpageMutation>(
      SocialShareToStartpageDocument,
      { onError }
    );

  const triggerShare = async (): Promise<void> => {
    const response: FetchResult<SocialShareToStartpageMutation> =
      await mutation({
        variables: {
          interactionTargetUrn,
          message,
          verb,
          visibility: audience,
          ...getSocialTrackingMetaData({
            backendTrackingMetaData,
            propSocialInteractionId,
          }),
        },
      });

    const { success, error } = response?.data?.socialShareToStartpage || {
      success: undefined,
      error: undefined,
    };

    if (!success || error) onError(error || { message: 'GENERIC_ERROR' });
    if (success) {
      trackShareToFeedSuccess({
        audience,
        inputHintClick,
        interactionTargetUrn,
        isQuickShare,
        message,
        propChannel,
        propSocialInteractionId,
        propSourceType,
        toChannel: 'startpage',
      });
      onSuccess({
        ...success,
        redirectUrl: success?.redirectUrl || '',
        targetType: success?.targetType || '',
      });
    }
  };

  return {
    apiErrorMessage: data?.socialShareToStartpage?.error?.message || '',
    hasError: !!error || !!data?.socialShareToStartpage?.error,
    loading,
    triggerShare,
  };
};
