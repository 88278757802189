import type { FC, MouseEvent } from 'react';
import { useIntl } from 'react-intl';

import type { ImageUploadOnPublishData } from '@xing-com/crate-entity-pages-common';
import {
  MARKETING_URL_DE,
  MARKETING_URL_EN,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { trackEditSaving } from '@xing-com/crate-entity-pages-common/src/tracking';
import { Headline } from '@xing-com/typography';

import { useUpsellBannerContext } from '../../../../hooks/use-upsell-banner-context/use-upsell-banner-context';

import * as Styled from './upsell-banner-company-header-banner.styles';

type UpsellBannerCompanyHeaderBannerProps = {
  onClickBanner?: (data: ImageUploadOnPublishData) => void;
};
export const UpsellBannerCompanyHeaderBanner: FC<
  UpsellBannerCompanyHeaderBannerProps
> = ({ onClickBanner = () => undefined }) => {
  const { $t, locale } = useIntl();
  const { pageContext } = usePageContext();
  const { upsellCover } = useUpsellBannerContext();

  const urlMarketing = locale === 'en' ? MARKETING_URL_EN : MARKETING_URL_DE;

  const handleOnClickSave = async () => {
    if (!upsellCover) {
      return;
    }

    if (pageContext.focusType && pageContext.pageId) {
      trackEditSaving({
        isFreePage: true,
        focusType: pageContext.focusType,
        itemId: pageContext.pageId,
        module: 'header',
        part: 'image',
      });
    }

    onClickBanner(upsellCover);
  };
  const handleOnClickBanner = (ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    handleOnClickSave();
    const tracking =
      '?sc_o= entity_page_companies_start_upsell_cover_image_module&sc_o_PropActionOrigin=entity_page_companies_upgrade_overview';
    window.open(urlMarketing + tracking);
  };

  return (
    <Styled.Container>
      <Styled.PremiumWrapperWithIcon>
        <Styled.ContentWrapper>
          <Styled.Flag>
            {$t({ id: 'EP_FLAG_EMPLOYER_BRANDING_PROFILE' })}
          </Styled.Flag>
          <Styled.HeaderText>
            <Headline size="xlarge">
              {$t({ id: 'EP_HEADER_IMAGE_BANNER_HEADER' })}
            </Headline>
          </Styled.HeaderText>
          {/* @ts-expect-error FIXME: SC6 */}
          <Styled.Text
            dangerouslySetInnerHTML={{
              __html: $t({ id: 'EP_HEADER_IMAGE_BANNER_BODY' }),
            }}
          />
        </Styled.ContentWrapper>
        <Styled.SingleSizeIllustrationHeroOfferProduct width="380px" />
      </Styled.PremiumWrapperWithIcon>
      <Styled.Divider />
      <Styled.Buttons>
        <div>
          <Styled.Button
            data-testid="UPSELL-BANNER-DRAFT-BUTTON"
            size="small"
            onClick={handleOnClickSave}
          >
            {$t({ id: 'EP_HEADER_IMAGE_BANNER_DRAFT_BUTTON' })}
          </Styled.Button>
        </div>
        <div>
          <Styled.Button
            data-testid="UPSELL-BANNER-PUBLISH-BUTTON"
            variant="proBusiness"
            size="small"
            onClick={handleOnClickBanner}
            to={urlMarketing}
          >
            {$t({ id: 'EP_HEADER_IMAGE_BANNER_PUBLISH_BUTTON' })}
          </Styled.Button>
        </div>
      </Styled.Buttons>
    </Styled.Container>
  );
};
