import { FormattedMessage } from 'react-intl';

import type { ContentArticleStatus } from '@xing-com/crate-common-graphql-types';
import { IconClock, IconHide, IconTick } from '@xing-com/icons';

import { ICON_DIMENSION } from '../constants';

import * as Styled from './article-state.styles';

type Props = { state: `${ContentArticleStatus}` };

export const ArticleState: React.FC<Props> = ({ state }) => {
  switch (state) {
    case 'DISABLED':
      return (
        <Styled.HiddenFlag>
          <IconHide width={ICON_DIMENSION} height={ICON_DIMENSION} />
          <span>
            <FormattedMessage id="EP-ARTICLES-MODULE-FILTER-HIDDEN" />
          </span>
        </Styled.HiddenFlag>
      );
    case 'DRAFT':
      return (
        <strong>
          <FormattedMessage id="EP-ARTICLES-MODULE-FILTER-DRAFTS" />
        </strong>
      );
    case 'PUBLISHED':
      return (
        <Styled.PublishedFlag>
          <IconTick width={ICON_DIMENSION} height={ICON_DIMENSION} />
          <span>
            <FormattedMessage id="EP-ARTICLES-MODULE-FILTER-PUBLISHED" />
          </span>
        </Styled.PublishedFlag>
      );
    case 'SCHEDULED':
      return (
        <Styled.ScheduledFlag>
          <IconClock width={ICON_DIMENSION} height={ICON_DIMENSION} />
          <span>
            <FormattedMessage id="EP-ARTICLES-MODULE-FILTER-SCHEDULED" />
          </span>
        </Styled.ScheduledFlag>
      );
  }
};
