/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type YourTopicsContentCategoriesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type YourTopicsContentCategoriesQuery = {
  __typename?: 'Query';
  contentCategories?: {
    __typename?: 'ContentCategoryCollection';
    collection: Array<{ __typename?: 'ContentCategory'; name: string }>;
  } | null;
};

export const YourTopicsContentCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'YourTopicsContentCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  YourTopicsContentCategoriesQuery,
  YourTopicsContentCategoriesQueryVariables
>;
