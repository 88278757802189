import styled, { css } from 'styled-components';

import { AudienceSelection } from '@xing-com/audience-selection';
import { zIndexStickyLayer3 } from '@xing-com/layout-tokens';
import {
  scale000,
  space4XL,
  spaceL,
  spaceXL,
  xdlColorBackground,
} from '@xing-com/tokens';

// *** Base ***
const baseAudienceSelection = css`
  margin-right: ${spaceL};
`;
const baseStickyHeader = css`
  background-color: ${xdlColorBackground};
  position: fixed;
  left: ${scale000};
  right: ${scale000};
  top: ${scale000};
  z-index: ${zIndexStickyLayer3};
`;

const baseSmallScreenButtonWrapper = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${scale000} ${spaceL};
  height: ${space4XL};
`;

const baseBigScreenButtonWrapper = css`
  display: flex;
  justify-content: flex-end;
  margin-top: ${spaceXL};
`;

const baseRightActionButtons = css`
  display: flex;
  margin-left: auto;
`;

// *** Components ***
const StyledAudienceSelection = styled(AudienceSelection)`
  ${baseAudienceSelection}
`;

const StickyHeader = styled.div`
  ${baseStickyHeader}
`;

const BigScreenButtonWrapper = styled.div`
  ${baseBigScreenButtonWrapper}
`;

const SmallScreenButtonWrapper = styled.div`
  ${baseSmallScreenButtonWrapper}
`;

const RightActionButtons = styled.div`
  ${baseRightActionButtons}
`;

export const Styled = {
  AudienceSelection: StyledAudienceSelection,
  StickyHeader,
  BigScreenButtonWrapper,
  SmallScreenButtonWrapper,
  RightActionButtons,
};
