import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { EditButton } from '@xing-com/crate-entity-pages-common';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceM, xdlColorDisabledStrong } from '@xing-com/tokens';

export const EditSloganButton = styled(EditButton)`
  display: none;

  @media ${mediaWideNavless} {
    display: flex;
    margin-left: ${spaceM};
  }
`;

export const EditLabel = styled(TextButton)`
  color: ${xdlColorDisabledStrong};
  font-weight: 400;
`;
