import type { FC } from 'react';

import { IconTrash } from '@xing-com/icons';

import { trackAction } from '../../tracking';

import { Styled } from './drop-zone-image.styles';

export type DropZoneImageProps = {
  url: string;
  index: number;
  isEditingPosting: boolean;
  onRemove: (index: number) => void;
};
export const DropZoneImage: FC<DropZoneImageProps> = ({
  url,
  isEditingPosting,
  onRemove,
  index,
}) => (
  <Styled.ImageContainer>
    <Styled.DropZoneImage src={url} />
    {!isEditingPosting && (
      <Styled.Button
        variant="overlay"
        size="small"
        icon={IconTrash}
        onClick={() => {
          trackAction({
            PropTrackAction: 'social_share_commbox_image_delete',
          });
          onRemove(index);
        }}
      />
    )}
  </Styled.ImageContainer>
);
