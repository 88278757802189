import type * as React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  EP_NwtEditViewAction,
  useEditContext,
  useEntityPageNwtEditViewAction,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { IconEdit } from '@xing-com/icons';
import { SkeletonBodyCopy } from '@xing-com/skeleton';
import { BodyCopy, Hero } from '@xing-com/typography';

import { useAboutQuery } from '../../hooks/use-about-query';
import { ErrorState } from '../error-state/error-state';

import * as Styled from './about-visitor-view.styles';

export const AboutVisitorView: React.FC = () => {
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext();
  const { pageSlug } = pageContext;
  const { loading, description, error, refetch } = useAboutQuery(pageSlug);
  const { trackEntityPageEditView } = useEntityPageNwtEditViewAction();

  return (
    <Styled.AboutVisitorViewContainer>
      <Styled.AboutVisitorHeadlineContainer>
        <Hero size="small">
          <FormattedMessage id="EP_ABOUT_VISITOR_VIEW_TITLE" />
        </Hero>
        {isEditing ? (
          <Styled.AboutVisitorEditButton
            icon={IconEdit}
            size="small"
            to={`/pages/${pageSlug}/edit/aboutEditView?type=short`}
            onClick={() =>
              trackEntityPageEditView(
                EP_NwtEditViewAction.ABOUT_DESCRIPTION_OPEN
              )
            }
          />
        ) : null}
      </Styled.AboutVisitorHeadlineContainer>
      {loading ? (
        <SkeletonBodyCopy
          alignment="left"
          amountRows={4}
          data-qa="about-visitor-view-skeleton"
        />
      ) : error ? (
        <ErrorState loading={loading} refetch={refetch} />
      ) : (
        <BodyCopy size="medium">{description}</BodyCopy>
      )}
    </Styled.AboutVisitorViewContainer>
  );
};
