import { useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { TextButton } from '@xing-com/button';
import { useTracking } from '@xing-com/crate-communication-tracking';
import {
  useDialogContext,
  useErrorContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { IconTrash } from '@xing-com/icons';

import { CompanyUpdatesDeleteDocument } from '../../../graphql/mutations/company-delete-posting.gql-types';
import { DeletePostingDocument } from '../../../graphql/mutations/delete-posting.gql-types';
import { useNewsItemContext } from '../../../hooks/use-news-item-context/use-news-item-context';

import * as Styled from './news-item-actions.styles';

type ActionDeleteProps = {
  desktop?: boolean;
  itemId?: string;
  onItemActionCompleted?: () => void;
  onItemActionStarted?: () => void;
};
export const ActionDelete: FC<ActionDeleteProps> = ({
  desktop = true,
  itemId,
  onItemActionCompleted = () => undefined,
  onItemActionStarted = () => undefined,
}) => {
  const { $t } = useIntl();
  const { setDialogConfirmation } = useDialogContext();
  const { showError } = useErrorContext();
  const { post } = useNewsItemContext();
  const { pageContext } = usePageContext();

  const { track } = useTracking();

  const [deletePosting, { loading: deleteLoading }] = useMutation(
    DeletePostingDocument,
    {
      optimisticResponse: {
        __typename: 'Mutation',
        postingsDeletePosting: {
          error: null,
          __typename: 'PostingsPostingDeleteResult',
        },
      },
    }
  );

  const [deleteCompanyPost, { loading: deleteCompanyPostLoading }] =
    useMutation(CompanyUpdatesDeleteDocument, {});

  const dialogCopies = {
    headline: $t({ id: 'NEWS_MODULE_DIALOG_DELETE_HEADLINE' }),
    text: $t({ id: 'NEWS_MODULE_DIALOG_DELETE_TEXT' }),
    cancel: $t({ id: 'NEWS_MODULE_DIALOG_DELETE_NO' }),
    confirm: $t({ id: 'NEWS_MODULE_DIALOG_DELETE_YES' }),
  };

  const handleOnClick = () => {
    if (!itemId) {
      return;
    }

    if (post.object?.__typename === 'EntityPageContentFeedPostingPost') {
      setDialogConfirmation({
        ...dialogCopies,
        dialogAction: () => {
          onItemActionStarted();
          deletePosting({
            variables: { id: itemId },
            onCompleted: (data) => {
              const error = data.postingsDeletePosting?.error;

              if (error) {
                showError(error);
              } else {
                track({
                  type: 'nwt',
                  application: 'wbm',
                  eventSchema: 'extended',
                  event: 'deleted',
                  itemUrn: post?.globalId ?? '',
                  itemActorUrn: pageContext.globalId,
                  sentBy: 'commbox',
                  activityId: itemId,
                  moduleTitle: 'postings',
                  page: pageContext?.moduleTitle
                    ? 'entitypages/subpage/news'
                    : 'entity_pages/overview',
                });
                onItemActionCompleted();
              }
              setDialogConfirmation(null);
            },
          });
        },
      });
    }
    if (post.object?.__typename === 'EntityPageContentFeedCompanyArticlePost') {
      setDialogConfirmation({
        ...dialogCopies,
        dialogAction: () => {
          onItemActionStarted();
          deleteCompanyPost({
            variables: {
              companyId: pageContext.companyId as string,
              updateId: itemId,
            },
            onCompleted: (data) => {
              const error = data.companyUpdatesDelete?.error;

              if (error) {
                showError(error);
              } else {
                onItemActionCompleted();
              }
              setDialogConfirmation(null);
            },
          });
        },
      });
    }
  };

  const isLoading = deleteLoading || deleteCompanyPostLoading;

  const disabled =
    !itemId ||
    isLoading ||
    post.object?.__typename === 'EntityPageContentFeedArticlePost' ||
    post.object?.__typename === 'EntityPageContentFeedArticlesArticlePost';

  if (desktop === false) {
    return (
      <TextButton
        size="medium"
        icon={IconTrash}
        data-testid="DELETE_POSTING"
        disabled={disabled}
        onClick={handleOnClick}
      >
        {$t({ id: 'NEWS_MODULE_DELETE_LABEL' })}
      </TextButton>
    );
  }

  return (
    <Styled.EditButton
      size="small"
      icon={IconTrash}
      ariaLabel="Trash"
      data-testid="DELETE_POSTING"
      onClick={handleOnClick}
      disabled={disabled}
    />
  );
};
