import type React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import {
  PagesFormField,
  useDialogContext,
} from '@xing-com/crate-entity-pages-common';
import { BodyCopy } from '@xing-com/typography';

import type { FormDataType } from '../types';

import validate from './short-validation-rules';
import * as Styled from './short.styles';

type ShortIntroProps = {
  formData?: FormDataType;
  setFormData?: React.Dispatch<React.SetStateAction<FormDataType>>;
};

const ShortIntro = ({ formData, setFormData }: ShortIntroProps) => {
  const { setDataChanged } = useDialogContext();
  const intl = useIntl();

  const commonFields = {
    intl,
    validate,
    formState: formData,
    setFormState: setFormData,
    setFormTouched: setDataChanged,
    copyPrefix: 'EP_EDIT_ABOUTUS_INTRO_',
  };

  return (
    <Styled.ShortContainer>
      <BodyCopy size={'small'}>
        <FormattedMessage
          id="EP_EDIT_ABOUTUS_INTRO_INFO_TEXT"
          defaultMessage="EP_EDIT_ABOUTUS_INTRO_INFO_TEXT"
        />
      </BodyCopy>
      <PagesFormField
        maxAllowedChars={90}
        type="title"
        shouldTrim={true}
        {...commonFields}
        required
        data-testid="EDIT_ABOUTUS_TITLE"
      />
      <PagesFormField
        maxAllowedChars={350}
        type="description"
        shouldTrim={true}
        multiline
        {...commonFields}
        required
        data-testid="EDIT_ABOUTUS_DESC"
      />
    </Styled.ShortContainer>
  );
};

export default ShortIntro;
