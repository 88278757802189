/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconStarHalfFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconStarHalfFilled"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m12 2.876 3.115 5.837 6.514 1.159-4.589 4.766.91 6.553L12 18.299l-5.95 2.892.91-6.553-4.588-4.766 6.513-1.16zm0 4.248v8.952l3.454 1.678-.529-3.804 2.663-2.766-3.78-.672z"
    />
  </svg>
);
export default IconStarHalfFilled;
