/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateTextPostingMutationVariables = Types.Exact<{
  actorGlobalId: Types.Scalars['GlobalID']['input'];
  audience?: Types.InputMaybe<
    | Array<Types.Scalars['GlobalID']['input']>
    | Types.Scalars['GlobalID']['input']
  >;
  comment: Types.Scalars['String']['input'];
  commentArticleV1?: Types.InputMaybe<
    | Array<Types.ArticlesCreateArticleBlocksInput>
    | Types.ArticlesCreateArticleBlocksInput
  >;
  images?: Types.InputMaybe<
    | Array<Types.PostingsCreateImageAttachmentInput>
    | Types.PostingsCreateImageAttachmentInput
  >;
  links?: Types.InputMaybe<
    | Array<Types.PostingsCreateLinkAttachmentInput>
    | Types.PostingsCreateLinkAttachmentInput
  >;
  publicationStrategy?: Types.InputMaybe<Types.PostingPublicationStrategy>;
  publishAt?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  videos?: Types.InputMaybe<
    | Array<Types.PostingsCreateVideoAttachmentInput>
    | Types.PostingsCreateVideoAttachmentInput
  >;
  visibility?: Types.InputMaybe<Types.PostingsVisibility>;
}>;

export type CreateTextPostingMutation = {
  __typename?: 'Mutation';
  postingsCreatePosting?: {
    __typename?: 'PostingsPostingMutationResult';
    success?: {
      __typename?: 'PostingsPosting';
      id: string;
      globalId: string;
      actorGlobalId: string;
      comment: string;
      activityId?: string | null;
      opTrackingTokens?: Array<string | null> | null;
      visibility: Types.PostingsVisibility;
    } | null;
    error?: {
      __typename?: 'PostingsError';
      message?: string | null;
      details?: Array<string> | null;
    } | null;
  } | null;
};

export const CreateTextPostingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTextPosting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'actorGlobalId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GlobalID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'audience' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'GlobalID' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'commentArticleV1' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'ArticlesCreateArticleBlocksInput',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'images' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'PostingsCreateImageAttachmentInput',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'links' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'PostingsCreateLinkAttachmentInput',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publicationStrategy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PostingPublicationStrategy' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publishAt' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'videos' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'PostingsCreateVideoAttachmentInput',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'visibility' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PostingsVisibility' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postingsCreatePosting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'actorGlobalId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'actorGlobalId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'audience' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'audience' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'comment' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'comment' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'commentArticleV1' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'commentArticleV1' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'images' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'images' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'links' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'links' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publicationStrategy' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'publicationStrategy' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publishAt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'publishAt' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'videos' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'videos' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'visibility' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'visibility' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actorGlobalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activityId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'opTrackingTokens' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'visibility' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'details' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTextPostingMutation,
  CreateTextPostingMutationVariables
>;
