import type { FC } from 'react';

import * as Styled from './header-actions.styles';
import HeaderMoreActions from './header-more-actions';

const HeaderActions: FC = () => {
  return (
    <>
      <Styled.FollowContainer />
      <HeaderMoreActions />
    </>
  );
};

export default HeaderActions;
