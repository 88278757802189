import { useQuery } from '@apollo/client';
import { useMemo, useState, type FC } from 'react';

import {
  ModuleHeader,
  useEditContext,
  UpsellBanner,
  WarningMessageBox,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';

import { JobsModuleSimilarJobsDocument } from '../../graphql/queries/jobs-module-similar-jobs.gql-types';
import { createJobsCards } from '../../models/jobs-card';
import { JobsCardsCarousel } from '../jobs-cards-carousel/jobs-cards-carousel';
import { JobsEmptyState } from '../jobs-empty-state/jobs-empty-state';
import { JobsSkeleton } from '../jobs-skeleton/jobs-skeleton';

import * as Styled from './section-similar-jobs.styles';

export const SectionSimilarJobs: FC = () => {
  const { pageContext } = usePageContext();
  const industryId = pageContext.industryId;
  const { isEditing } = useEditContext();

  const [firstLoad, setFirstLoad] = useState(true);

  const { data, error, refetch } = useQuery(JobsModuleSimilarJobsDocument, {
    variables: {
      similarJobsLimit: 20,
      sort: 'date/desc',
      returnAggregations: false,
      similarJobsQuery: {
        filter: industryId ? { industry: { id: [industryId] } } : null,
      },
    },
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setFirstLoad(false);
    },
  });

  const jobsCards = useMemo(() => {
    const similarJobs = data?.jobSearchByQuery?.collection ?? [];
    // @ts-expect-error query generated as null
    return createJobsCards(similarJobs);
  }, [data]);

  return (
    <div data-testid="SIMILAR-JOBS-CONTAINER">
      <ModuleHeader headlineCopyKey="EP_SIMILAR_JOBS_HEADLINE" />
      {isEditing && (
        <Styled.BannerEditModeWrapper>
          <UpsellBanner
            headerCopyKey="EP_UPSELL_COMPANY_RECOMMENDED_HEADER"
            bodyCopyKey="EP_UPSELL_COMPANY_RECOMMENDED_BODY"
            moduleName="jobsrecommendationsmodule"
            isProductPage
          />
        </Styled.BannerEditModeWrapper>
      )}
      {firstLoad ? (
        <JobsSkeleton />
      ) : (
        <div data-testid="JOBS_MODULE">
          <JobsCardsCarousel
            cards={jobsCards}
            onCardBookmarkUpdate={() => refetch()}
          />
        </div>
      )}
      {error && (
        <div data-testid="errorContainer">
          <WarningMessageBox
            headerText="EP_ERROR_HEADER"
            bodyText="EP_ERROR_BODY"
            buttonText="EP_ERROR_RELOAD_CTA"
            onClick={() => refetch()}
          />
        </div>
      )}
      {jobsCards.length === 0 && (
        <div data-testid="JOBS_MODULE">
          <JobsEmptyState />
        </div>
      )}
    </div>
  );
};
