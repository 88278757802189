import { useMutation, useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';

import {
  useDialogContext,
  usePageContext,
  EditHeader,
  useErrorContext,
  EditActions,
  WarningMessageBox,
} from '@xing-com/crate-entity-pages-common';

import { EntityPageUpdateLocationDocument } from '../../graphql/mutations/update-location.gql-types';
import { LocationsDocument } from '../../graphql/queries/locations.gql-types';
import {
  addParenthisisOnNumbers,
  removeParenthisisOnNumbers,
  getLocationMutationInput,
} from '../location-form/helpers';
import { LocationForm } from '../location-form/location-form';
import { locationValidation } from '../location-form/validation';

import { EditDescriptionSkeleton } from './locations-edit.skeleton';
import * as Styled from './locations-edit.styles';
import type { LocationStateType } from './types';

type LocationsEditContainerProps = {
  pageSlug: string;
};
export const LocationsEditContainer: FC<LocationsEditContainerProps> = ({
  pageSlug,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { showError } = useErrorContext();
  const { setDataChanged, dataChanged, executeWithDialog } = useDialogContext();
  const { pageContext } = usePageContext();

  const [locationsTouched, setLocationsTouched] = useState(false);
  const [locationState, setLocationState] = useState<LocationStateType>({
    pageId: { value: pageSlug },
    address: { value: '', error: null },
    addressSuffix: { value: '', error: null },
    city: { value: '', error: null },
    country: { value: 'DE', error: null },
    email: { value: '', error: null },
    faxNumber: { value: '', error: null },
    faxNumberCountryCode: { value: 49, error: null },
    label: { value: '', error: null },
    phoneNumber: { value: '', error: null },
    phoneNumberCountryCode: { value: 49, error: null },
    postcode: { value: '', error: null },
    websiteURL: { value: '', error: null },
    locationId: { value: '' },
  });

  const [updateLocation, { loading: updateLocationLoading }] = useMutation(
    EntityPageUpdateLocationDocument,
    {
      // instead of cache handling we need to refetch queries to get the new locations gps coords
      refetchQueries: [
        { query: LocationsDocument, variables: { id: pageSlug } },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [createLocation, { loading: createLocationLoading }] = useMutation(
    EntityPageUpdateLocationDocument,
    {
      // refetch query for mainpage and subpage
      refetchQueries: [
        { query: LocationsDocument, variables: { id: pageSlug } },
      ],
      awaitRefetchQueries: true,
    }
  );

  const { data, loading, error, refetch } = useQuery(LocationsDocument, {
    variables: { id: pageSlug },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const location = data?.entityPageLocations?.collection?.filter(
    (x) => x.highlighted === true
  )[0];

  useEffect(() => {
    if (location) {
      setLocationState({
        pageId: { value: pageSlug },
        locationId: { value: location.id },
        address: { value: location.address || '', error: null },
        addressSuffix: { value: location.addressSuffix || '', error: null },
        city: { value: location.city || '', error: null },
        country: { value: location.country?.countryCode || 'DE', error: null },
        email: { value: location.email || '', error: null },
        faxNumber: {
          value: location.faxNumber?.number
            ? addParenthisisOnNumbers(String(location.faxNumber?.number))
            : '',
          error: null,
        },
        faxNumberCountryCode: {
          value: location.faxNumber?.countryCode || 49,
          error: null,
        },
        label: { value: location.label || '', error: null },
        phoneNumber: {
          value: location.phoneNumber?.number
            ? addParenthisisOnNumbers(String(location.phoneNumber?.number))
            : '',
          error: null,
        },
        phoneNumberCountryCode: {
          value: location.phoneNumber?.countryCode || 49,
          error: null,
        },
        postcode: { value: location.postcode || '', error: null },
        websiteURL: { value: location.websiteURL || '', error: null },
      });
    }
  }, [location]);

  const updateLocationState = (state: typeof locationState) => {
    setLocationState(state);
    setDataChanged(true);
  };

  const handleValidation = (action: () => void) => {
    let hasError: boolean | null = null;

    const validatedLocationState: LocationStateType = {};

    Object.keys(locationState).map((type) => {
      const error = locationValidation(
        type,
        type === 'phoneNumber' || type === 'faxNumber'
          ? removeParenthisisOnNumbers(locationState[type].value)
          : locationState[type].value
      );

      if (!hasError) {
        hasError = !!error;
      }

      validatedLocationState[type] = {
        value: locationState[type].value,
        showError: !!error,
        error,
      };
    });

    setLocationState(validatedLocationState);

    if (hasError) {
      window.scrollTo(0, 0);
    } else {
      action();
    }
  };
  const handleSave = () => {
    if (!dataChanged) {
      return;
    }

    if (!locationsTouched) {
      return;
    }
    handleValidation(() => {
      if (locationsTouched) {
        updateLocation({
          variables: getLocationMutationInput(locationState),
          onCompleted: (data) => {
            const error = data?.entityPageUpdateLocation?.error;
            if (error) {
              showError({ message: 'EP_GENERAL_FEEDBACK_ERROR', error });
            } else {
              if (pageContext.goBackUrl) pageContext.goBackUrl();
            }
          },
          onError: (error) => {
            showError({ message: 'EP_GENERAL_FEEDBACK_ERROR', error });
          },
        });
      } else {
        createLocation({
          variables: getLocationMutationInput(locationState),
          onCompleted: (data) => {
            const error = data?.entityPageUpdateLocation?.error;
            if (error) {
              showError({ message: 'EP_GENERAL_FEEDBACK_ERROR', error });
            } else {
              if (pageContext.goBackUrl) pageContext.goBackUrl();
            }
          },
          onError: (error) => {
            showError({ message: 'EP_GENERAL_FEEDBACK_ERROR', error });
          },
        });
      }
    });
  };

  if (loading) {
    return (
      <>
        <EditHeader titleKey="EP_LOCATIONS_EDIT_TITLE" />
        <EditDescriptionSkeleton />
      </>
    );
  }

  if (error) {
    return (
      <>
        <EditHeader titleKey="EP_LOCATIONS_EDIT_TITLE" />
        <WarningMessageBox
          headerText="EP_ERROR_HEADER"
          bodyText="EP_ERROR_BODY"
          buttonText="EP_ERROR_RELOAD_CTA"
          onClick={() => refetch()}
        />
      </>
    );
  }

  return (
    <>
      <EditHeader titleKey="EP_LOCATIONS_EDIT_TITLE" />
      <div ref={ref}>
        <Styled.EditAreaContainer data-testid="LOCATIONS_FORM">
          <LocationForm
            locationState={locationState}
            setLocationState={updateLocationState}
            // handleValidation={handleValidation}
            // locationsTouched={locationsTouched}
            setLocationsTouched={setLocationsTouched}
          />
        </Styled.EditAreaContainer>
        <EditActions
          discardAction={() =>
            executeWithDialog(
              () => pageContext.goBackUrl && pageContext.goBackUrl()
            )
          }
          saveAction={handleSave}
          disabled={updateLocationLoading || createLocationLoading}
          isSaving={updateLocationLoading || createLocationLoading}
        />
      </div>
    </>
  );
};

export default LocationsEditContainer;
