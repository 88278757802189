import { useQuery } from '@apollo/client';
import { Redirect, useLocation, useNavigate } from '@reach/router';
import { useEffect, type FC } from 'react';

import {
  ClickReasonsEnum,
  EntityPageFocusType,
} from '@xing-com/crate-common-graphql-types';
import {
  createEntityPageShared,
  EditContextProvider,
  ENABLE_NEW_ENTITY_PAGES_ARTICLES_MODULE,
  EntityPageDocument,
  PageContextProvider,
  PageError,
  PageLayout,
  Appcues,
  useTrackingVisitor,
} from '@xing-com/crate-entity-pages-common';
import { Header } from '@xing-com/crate-entity-pages-header';
import { useExperiment, useFeatureSwitches } from '@xing-com/hub';

import { MetaDataContainer } from '../../components/main-page-metadata/main-page-metadata';
import { modulesMap } from '../../config/modules-map';
import { EP_URL } from '../../config/urls';
import { getPathByFocusType } from '../../utils/get-path-by-focus-type';

import { MainPageLayout } from './main-page-layout';
import * as Styled from './main-page.styles';

type CustomErrorPageProps = {
  errorCode?: 404 | 500 | 503;
};
const CustomErrorPage: FC<CustomErrorPageProps> = ({ errorCode }) => {
  return (
    <PageLayout>
      <Styled.MainPageWrapper>
        <PageError type={errorCode} />
      </Styled.MainPageWrapper>
    </PageLayout>
  );
};

type MainPageContainerProps = {
  pageSlug: string;
};
export const MainPageContainer: FC<MainPageContainerProps> = ({ pageSlug }) => {
  const location = useLocation();
  const path = location.pathname?.split('/')[1];
  const navigate = useNavigate();
  const { data: featureSwitches } = useFeatureSwitches();
  const { trackVisitor } = useTrackingVisitor({ pageSlug });

  const { data, loading, error } = useQuery(EntityPageDocument, {
    variables: {
      id: pageSlug,
      socialProofClickReasonsKey:
        ClickReasonsEnum.CrWebPublisherSocialProofHeader,
    },
    errorPolicy: 'all',
  });

  useEffect(() => {
    trackVisitor();
  }, []);

  const isNewArticleModuleEnabled =
    useExperiment(ENABLE_NEW_ENTITY_PAGES_ARTICLES_MODULE) === 'B';

  const isModuleActive = (moduleType: string) => {
    // if the module is not tied to any feature switch return true
    if (!modulesMap[moduleType].featureSwitch) {
      return true;
    }
    const featureSwitch = modulesMap[moduleType].featureSwitch;
    return featureSwitch && featureSwitches?.includes(featureSwitch);
  };

  if (loading) {
    return (
      <PageLayout>
        <Styled.MainPageWrapper>
          <MetaDataContainer setDefault={true} />
          <Header.Skeleton />
        </Styled.MainPageWrapper>
      </PageLayout>
    );
  }

  const entityPage = data?.entityPageEX;

  if (error || !entityPage) {
    return <CustomErrorPage errorCode={503} />;
  }

  if (entityPage.__typename === 'EntityPageError') {
    return (
      <CustomErrorPage errorCode={entityPage.errorCode === 10404 ? 404 : 503} />
    );
  }

  if (entityPage.__typename === 'EntityPageMoved') {
    return <Redirect to={EP_URL(entityPage.slug)} />;
  }

  if (
    entityPage.__typename === 'EntityPage' &&
    entityPage.publicationStatus === 'CREATED'
  ) {
    return <Redirect to="/pages/new/company" />;
  }

  if (entityPage.__typename !== 'EntityPage') {
    return <CustomErrorPage errorCode={503} />;
  }

  const isAWebView = getPathByFocusType() !== `/${path}`;
  if (isAWebView) {
    return <CustomErrorPage errorCode={404} />;
  }

  let pageModules =
    entityPage.modules?.collection.filter(
      (module) =>
        module?.type !== 'header' &&
        module?.type &&
        modulesMap[module?.type] &&
        isModuleActive(module?.type)
    ) ?? [];

  // This should be removed once all the pages are migrated to the new articles module
  // >>>>
  const isIndustryPage =
    entityPage.focusType === EntityPageFocusType.IndustryPage;
  const isPublisherPage =
    entityPage.focusType === EntityPageFocusType.Publisher;
  const isTopicPage = entityPage.focusType === EntityPageFocusType.TopicPage;
  const isInsiderPage = entityPage.focusType === EntityPageFocusType.Insider;
  const hasArticlesModule = !!pageModules?.find(
    (module) => module?.type === 'articles'
  );
  const isAllowedArticleModuleFocusTypes =
    isIndustryPage || isPublisherPage || isTopicPage || isInsiderPage;
  // @ts-expect-error TODO: fix this type
  pageModules = pageModules?.reduce((moduleList, pageModule) => {
    if (pageModule?.type === 'news' && isNewArticleModuleEnabled) {
      if (hasArticlesModule) {
        return [...moduleList];
      } else {
        if (isAllowedArticleModuleFocusTypes) {
          return [...moduleList, { ...pageModule, type: 'articles' }];
        }
      }
    }
    return [...moduleList, pageModule];
  }, []);
  // <<<<<

  const pageModulesByType = pageModules
    .map((module) => module?.type)
    .filter((type) => type !== undefined);

  const hasModulesAvailable = pageModulesByType?.length > 0;

  if (!hasModulesAvailable) {
    return <CustomErrorPage />;
  }

  const hasNewsModule = !!pageModules?.find(
    (pageModule) => pageModule?.type === 'news'
  );

  const headerModule = entityPage.modules?.collection.find(
    (module) => module?.type === 'header'
  );
  const validHeaderModule =
    headerModule?.__typename === 'EntityPageHeaderModule'
      ? headerModule
      : undefined;
  const headerModuleCompanyIndustry =
    validHeaderModule?.content?.__typename === 'EntityPageCompanyHeaderContent'
      ? validHeaderModule?.content.company?.industry
      : undefined;

  const sharedEntityPageProperties = createEntityPageShared(entityPage);

  return (
    <PageContextProvider
      initialValue={{
        ...sharedEntityPageProperties,
        // Extra
        industryId: headerModuleCompanyIndustry?.id,
        hasNewsModule: hasNewsModule,
        pageModulesByType: pageModulesByType,
        headerModule: validHeaderModule,
        // @ts-expect-error TODO: fix this type once Article Module is released
        pageModules: pageModules,
        goBackUrl: entityPage.globalId
          ? () => navigate(-1)
          : () => navigate(EP_URL(entityPage.slug)),
      }}
    >
      <EditContextProvider>
        <Appcues>
          <MainPageLayout />
        </Appcues>
      </EditContextProvider>
    </PageContextProvider>
  );
};
