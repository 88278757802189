import type { FC } from 'react';

import type { CompanyKununuBadge } from '@xing-com/crate-common-graphql-types';
import {
  ModuleHeader,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import {
  trackAwardAction,
  getTrackingUrl,
} from '@xing-com/crate-entity-pages-common/src/tracking';

import * as Styled from './awards.styles';

type AwardsProps = {
  badges: CompanyKununuBadge[];
  pageSlug: string;
};
export const Awards: FC<AwardsProps> = ({ badges, pageSlug }) => {
  const AWARDS_NUMBER = 4;
  const { pageContext } = usePageContext();

  const showMore =
    badges.length > AWARDS_NUMBER
      ? {
          to: getTrackingUrl({
            to: `${pageContext.basePath}/${pageSlug}/about_us?scroll=awards`,
            trackingKey: 'allAwards',
          }),
          copyKey: 'EP_SEE_ALL_AWARDS',
        }
      : null;

  const slicedBadges = badges.slice(0, AWARDS_NUMBER);

  return (
    <>
      <ModuleHeader
        headlineCopyKey="EP_AWARDS_HEADLINE"
        showMore={showMore}
        small={true}
      />

      {slicedBadges.map(
        (badge, index) =>
          badge?.image?.[0].url && (
            <Styled.Card
              target={'_blank'}
              // @ts-expect-error FIXME: SC6
              href={badge.awardUrl}
              onClick={() => trackAwardAction('entity_page_mainpage')}
              linkProps={{
                'aria-label': 'award link',
              }}
              key={index}
              hideContent={index > 1}
            >
              <Styled.Image
                src={badge?.image?.[0].url}
                loading="lazy"
                alt="Award"
              />
            </Styled.Card>
          )
      )}
    </>
  );
};
