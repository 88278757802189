import type React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { IconCross } from '@xing-com/icons';

import { Styled } from './cancel-button.styles';

export type CancelButtonType = {
  isSmallScreen?: boolean;
  onClick: () => void;
};

export const CancelButton: React.FC<CancelButtonType> = ({
  isSmallScreen = false,
  onClick,
}) =>
  isSmallScreen ? (
    <Button icon={IconCross} {...{ onClick }} />
  ) : (
    <Styled.TextButton {...{ onClick }}>
      <FormattedMessage id="SHARE_CANCEL_BUTTON" />
    </Styled.TextButton>
  );
