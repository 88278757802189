import { css } from 'styled-components';

import { spaceS, spaceXXS } from '@xing-com/tokens';

export const dotSeparator = css`
  & > :not(:last-child):after {
    content: '•';
    display: inline-block;
    padding: 0 ${spaceS};
  }
`;

export const iconWrapper = css`
  align-items: baseline;
  display: inline-flex;
  gap: ${spaceXXS};

  & > svg {
    align-self: center;
  }
`;
