import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  space4XL,
  spaceM,
  spaceXXL,
  xdlColorInfoSoft,
  xdlPaletteBlue03,
} from '@xing-com/tokens';

export const AboutVisitorViewContainer = styled.div`
  padding-right: 332px;

  @media ${mediaSmallOrTiny} {
    padding-right: 0;
  }
`;

export const AboutVisitorHeadlineContainer = styled.div`
  align-items: baseline;
  display: flex;
  gap: ${spaceM};
  margin: ${space4XL} 0 ${spaceXXL};
`;

export const AboutVisitorEditButton = styled(Button)`
  background-color: ${xdlColorInfoSoft};

  &:hover {
    background-color: ${xdlPaletteBlue03};
  }
`;
