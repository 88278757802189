/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconLikeFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconLikeFilled"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.5 2a2.5 2.5 0 0 1 2.495 2.336L16 4.5V8h4a2 2 0 0 1 1.994 1.85L22 10v3c0 4.446-3.36 7.869-7.746 7.996L14 21H9V7.46l1.49-3.475A3.28 3.28 0 0 1 13.5 2M7 9v4.053L6.947 9z"
    />
    <path
      fill="currentColor"
      d="M2 8v14h3.75C6.44 22 7 21.328 7 20.5v-11C7 8.672 6.44 8 5.75 8z"
    />
  </svg>
);
export default IconLikeFilled;
