import { useLocation } from '@reach/router';
import type { FC } from 'react';

import { useLoggedoutAction } from '@xing-com/crate-entity-pages-common';

import type { ContactCard as ContactCardValues } from '../../types/contact-card';
import { ContactCard } from '../contact-card/contact-card';

import { ContactsSkeleton } from './contacts-grid.skeleton';
import * as Styled from './contacts-grid.styles';

type ContactsGridProps = {
  contactCards: ContactCardValues[];
  messageEntryPoint: string;
  trackingModuleType?: string;
  className?: string;
};
export const ContactsGrid: FC<ContactsGridProps> & {
  Skeleton: typeof ContactsSkeleton;
} = ({ contactCards, messageEntryPoint, className, trackingModuleType }) => {
  const location = useLocation();

  useLoggedoutAction(
    () =>
      window.location.assign(
        `/chats/new/${new URLSearchParams(location.search).get('recipientName')}?contextId=entity_pages&entryPoint=${messageEntryPoint}`
      ),
    'sendMessage'
  );

  return (
    <Styled.ContactsWrapper data-testid="CONTACTS_MODULE" className={className}>
      {contactCards.map((card, index: number) => (
        <ContactCard
          key={`CONTACT-CARD-${card.id}-${index}`}
          card={card}
          messageEntryPoint={messageEntryPoint}
          trackingModuleType={trackingModuleType}
        />
      ))}
    </Styled.ContactsWrapper>
  );
};

ContactsGrid.Skeleton = ContactsSkeleton;
