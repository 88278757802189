import {
  LOGIN_PREVIEW_LINK,
  LOGIN_PRODUCTION_LINK,
  PREVIEW_HOSTNAME,
  XINGBOX_IDENTIFIER,
} from '../constants';

export type getXingboxNameProps = (hostname: string) => string;

const getXingboxName: getXingboxNameProps = (hostname) => {
  const partsBeforeXingboxIdentifier = hostname
    .split(XINGBOX_IDENTIFIER)[0]
    .split('.');
  const lastItemIndex = partsBeforeXingboxIdentifier.length - 1;
  return partsBeforeXingboxIdentifier[lastItemIndex];
};

export type getLoginLinkProps = () => string;

export const getLoginLink: getLoginLinkProps = () => {
  const hostname = global?.location?.hostname || '';

  if (hostname.includes('localhost') || hostname.includes(PREVIEW_HOSTNAME))
    return LOGIN_PREVIEW_LINK;

  if (hostname.includes(XINGBOX_IDENTIFIER)) {
    const xingboxName = getXingboxName(hostname);
    return `http://login.${xingboxName}.kenv.xing.com`;
  }

  return LOGIN_PRODUCTION_LINK;
};
