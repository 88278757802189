import { useRef, useState, type FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import { ContentArticleStatus } from '@xing-com/crate-common-graphql-types';
import {
  ModuleHeader,
  EP_NwtEditViewAction,
  useEditContext,
  useEntityPageNwtEditViewAction,
  usePageContext,
  useUpdateEffect,
} from '@xing-com/crate-entity-pages-common';
import { SocialCountersProvider } from '@xing-com/crate-social-utils';
import { ErrorState, EmptyState } from '@xing-com/empty-state';
import { IconPlus, IconLink } from '@xing-com/icons';
import {
  IllustrationSpotError,
  IllustrationSpotTop,
} from '@xing-com/illustrations';
import { Headline } from '@xing-com/typography';

import { ArticleCard } from '../components/article-card/article-card';
import { EmptyStateSwitch } from '../components/empty-state-switch/empty-state-switch';
import {
  OLD_NEWS_NEW_ARTICLE,
  OLD_NEWS_NEW_ARTICLE_LINK,
} from '../constants/urls';
import { useArticleCards } from '../hooks/use-article-cards/use-article-cards';

import { ArticleListSkeleton } from './articles-module.skeleton';
import * as Styled from './articles-module.styles';

const LIMIT = 3;

export const ArticlesModule: FC = () => {
  const { $t } = useIntl();
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext();
  const { trackEntityPageEditView } = useEntityPageNwtEditViewAction();

  const showMoreSize = useRef(LIMIT);
  const [navigationStatus, setNavigationStatus] = useState<
    ContentArticleStatus | undefined
  >(isEditing ? undefined : ContentArticleStatus.Published);

  const pageCppId = pageContext.cppId ?? '';
  const pageCppIdSlug = pageCppId.split('.')[0];
  const globalId = `surn:x-xing:content:publisher_page:${pageCppId}`;

  const isValidNavigationForFeatured =
    navigationStatus === undefined ||
    navigationStatus === ContentArticleStatus.Published;

  const {
    articlesCards,
    metadata,
    hasFeaturedArticle,
    hasNextPage,
    fetchMoreLoading,
    initialLoading,
    refetchLoading,
    error,
    refetch,
    fetchMore,
  } = useArticleCards({
    pageUrn: globalId,
    status: navigationStatus,
    first: LIMIT,
    requestMetadata: isEditing,
    requestFeaturedArticle: isValidNavigationForFeatured,
  });

  useUpdateEffect(() => {
    setNavigationStatus(isEditing ? undefined : ContentArticleStatus.Published);
  }, [isEditing]);

  const isFeatureArticleVisible =
    isEditing && !hasFeaturedArticle && isValidNavigationForFeatured;
  const isEmptyState = articlesCards.length === 0;
  const isShowMoreAvailable = hasNextPage;

  const oldNewsNewArticleLink = OLD_NEWS_NEW_ARTICLE_LINK(pageCppIdSlug);
  const oldNewsNewArticle = OLD_NEWS_NEW_ARTICLE(pageCppIdSlug);

  const handleOnFilter = (navigationStatus?: ContentArticleStatus) => {
    setNavigationStatus(navigationStatus);
    showMoreSize.current = LIMIT;
  };
  const handleShowMoreArticles = async () => {
    await fetchMore();
    showMoreSize.current += LIMIT;
  };

  const articlesUrns = articlesCards.reduce<string[]>(
    (urns, article) => (article.urn ? [...urns, article.urn] : urns),
    []
  );

  return (
    <>
      {isEditing ? (
        <Styled.MobileEditWarningContainer>
          <Headline size="xxxlarge">
            <FormattedMessage id="EP_ARTICLES_MODULE_HEADLINE" />
          </Headline>
          <EmptyState
            illustration={IllustrationSpotError}
            headlineText={$t({
              id: 'EP-ARTICLES-MODULE-MOBILE-HINT-TITLE',
            })}
            bodyCopyText={$t({
              id: 'EP-ARTICLES-MODULE-MOBILE-HINT-TEXT',
            })}
            variant="engage"
            size="medium"
            withBackground
          />
        </Styled.MobileEditWarningContainer>
      ) : null}

      <Styled.ArticlesModuleContainer $isEditing={isEditing}>
        <ModuleHeader
          headlineCopyKey="EP_ARTICLES_MODULE_HEADLINE"
          CustomEditButton={[
            <Styled.HeaderEditButton
              key="EP-ARTICLES-MODULE-WRITE-NEW-ARTICLE-BUTTON"
              data-testid="EP-ARTICLES-MODULE-WRITE-NEW-ARTICLE-BUTTON"
              to={oldNewsNewArticle}
              icon={IconPlus}
              onClick={() => {
                trackEntityPageEditView(
                  EP_NwtEditViewAction.ARTICLES_MODULE_EDITOR_OPEN
                );
              }}
            >
              {$t({ id: 'EP-ARTICLES-MODULE-WRITE-NEW-ARTICLE-BUTTON' })}
            </Styled.HeaderEditButton>,
            <Styled.HeaderEditButton
              key="EP-ARTICLES-MODULE-SHARE-NEW-ARTICLE-BUTTON"
              data-testid="EP-ARTICLES-MODULE-SHARE-NEW-ARTICLE-BUTTON"
              to={oldNewsNewArticleLink}
              icon={IconLink}
            >
              {$t({ id: 'EP-ARTICLES-MODULE-SHARE-NEW-ARTICLE-BUTTON' })}
            </Styled.HeaderEditButton>,
          ]}
        />
        {isEditing ? (
          <Styled.FilterBar
            allAmount={metadata?.allAmount}
            publishedAmount={metadata?.publishedArticlesCount}
            scheduledAmount={metadata?.scheduledArticlesCount}
            draftsAmount={metadata?.draftArticlesCount}
            hiddenAmount={metadata?.disabledArticlesCount}
            onNavigation={handleOnFilter}
          />
        ) : null}
        {error ? (
          <ErrorState
            headlineText={$t({ id: 'EP_GENERAL_ERROR_TITLE' })}
            bodyCopyText={$t({ id: 'EP_GENERAL_ERROR_TEXT' })}
            buttonText={$t({ id: 'EP_GENERAL_ERROR_BUTTON' })}
            size="medium"
            withBackground
            buttonProps={{
              loading: refetchLoading,
              onClick: () => refetch(showMoreSize.current),
            }}
          />
        ) : initialLoading || refetchLoading ? (
          <ArticleListSkeleton />
        ) : isEmptyState ? (
          <EmptyStateSwitch path={oldNewsNewArticle} />
        ) : (
          <>
            {isFeatureArticleVisible ? (
              <Styled.EmptyStateFeatured
                illustration={IllustrationSpotTop}
                headlineText={$t({
                  id: 'EP-ARTICLES-MODULE-EMPTY-STATE-PINNED-TITLE',
                })}
                bodyCopyText={$t({
                  id: 'EP-ARTICLES-MODULE-EMPTY-STATE-PINNED-TEXT',
                })}
                size="medium"
                variant="engage"
                withBackground
              />
            ) : null}
            <Styled.ArticlesGrid data-testid="EP-ARTICLES-MODULE-ARTICLES-GRID">
              <SocialCountersProvider urns={articlesUrns}>
                {articlesCards.map((article) => (
                  <ArticleCard
                    key={article.id}
                    article={article}
                    onButtonActionCompleted={() =>
                      refetch(showMoreSize.current)
                    }
                  />
                ))}
              </SocialCountersProvider>
            </Styled.ArticlesGrid>
          </>
        )}
        {isShowMoreAvailable ? (
          <Styled.ArticlesModuleFooter>
            <Button
              data-testid="EP-ARTICLES-MODULE-SHOW-MORE-ARTICLES-BUTTON"
              variant="secondary"
              loading={fetchMoreLoading}
              onClick={handleShowMoreArticles}
            >
              {$t({ id: 'EP-ARTICLES-MODULE-SHOW-MORE-ARTICLES-BUTTON' })}
            </Button>
          </Styled.ArticlesModuleFooter>
        ) : null}
      </Styled.ArticlesModuleContainer>
    </>
  );
};
