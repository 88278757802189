import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { EntityPageContractType } from '@xing-com/crate-common-graphql-types';
import {
  useEditContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { IconInfo, IconCross } from '@xing-com/icons';
import { usePopOver } from '@xing-com/pop-over';

import * as Styled from './title-tooltip.styles';

export const TitleTooltip: FC = () => {
  const { $t } = useIntl();
  const popOver = usePopOver();
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext();
  const isAdmin = pageContext.isAdmin;
  const isFreePage = pageContext.contractType === EntityPageContractType.Free;

  const displayTitleInfo = pageContext.isCompanyPage && isAdmin && isEditing;

  if (!displayTitleInfo) {
    return null;
  }

  const message = isFreePage
    ? 'EP_CHANGE_TITLE_INFO_FREE_V2'
    : 'EP_CHANGE_TITLE_INFO_V2';

  return (
    <Styled.Container>
      <Styled.IconWrapper
        onClick={popOver.handleShow}
        innerRef={popOver.triggerRef}
        aria-label="Info"
      >
        <IconInfo width={16} height={16} />
      </Styled.IconWrapper>
      <Styled.PopOver
        onOutsideClick={popOver.handleHide}
        triggerRef={popOver.triggerRef}
        show={popOver.show}
      >
        <Styled.PopOverCloseCTA
          size="small"
          onClick={popOver.handleHide}
          aria-label="Cross"
        >
          <IconCross width={20} height={20} />
        </Styled.PopOverCloseCTA>
        <Styled.NoticeWrapper>
          {$t(
            { id: message },
            { a: (b: any) => <a href={'mailto:' + b}>{b}</a> }
          )}
        </Styled.NoticeWrapper>
      </Styled.PopOver>
    </Styled.Container>
  );
};
