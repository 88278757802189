import type { ButtonProps } from '@xing-com/button';

import * as Styled from './empty-state.styles';

const { sizes, variants } = Styled;

export type EmptyStateSize = keyof typeof sizes;
export type EmptyStateVariant = keyof typeof variants;

export type EmptyStateProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Text of the body copy */
  bodyCopyText?: string;
  /** Text of the button */
  buttonText?: string;
  /** Properties of the button */
  buttonProps?: Omit<ButtonProps, 'size' | 'variant'>;
  /** Custom content */
  children?: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** Text of the headline */
  headlineText?: string;
  /** Hand over an illustration component */
  illustration?: React.FC<React.SVGProps<SVGSVGElement>>;
  /** Size of the empty state */
  size?: EmptyStateSize;
  /** Variant of the empty state */
  variant?: EmptyStateVariant;
  /** Show the empty state with a background */
  withBackground?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;
