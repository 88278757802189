import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';

import { EntityPageContractType } from '@xing-com/crate-common-graphql-types';
import {
  useDialogContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { IconSearch } from '@xing-com/icons';
import { OmView } from '@xing-com/platform-layout-om';
import { usePopOver } from '@xing-com/pop-over';
import { BodyCopy } from '@xing-com/typography';

import { MembersSearchDocument } from '../../graphql/queries/members-search-query.gql-types';
import { useContactsEditContext } from '../../hooks/use-contacts-edit-context/use-contacts-edit-context';
import { createSearchCards } from '../../models/search-card/search-card';
import type { ContactEdit } from '../../types/contacts-edit';
import type { SearchCard } from '../../types/search-card';
import { SearchInputListCards } from '../search-input-list-cards/search-input-list-cards';
import { SearchInputMoreResults } from '../search-input-more-results/search-input-more-results';

import * as Styled from './search-input.styles';

type SearchInputProps = {
  onAddContact?: () => void;
};
export const SearchInput: FC<SearchInputProps> = ({
  onAddContact = () => undefined,
}) => {
  const { $t } = useIntl();
  const popOver = usePopOver();
  const { pageContext } = usePageContext();
  const { setDataChanged } = useDialogContext();
  const {
    setContactsTouched,
    contactUsersList,
    setContactUsersList,
    disablePageActions,
  } = useContactsEditContext();

  const isFreeProfile =
    pageContext.contractType === EntityPageContractType.Free;

  const openOmView = useRef<() => void | null>();
  const closeOmView = useRef<() => void | null>();
  const lastCommitedText = useRef<string | null>(null);
  const [query, setQuery] = useState('');
  const [contactIdAdded, setContactIdAdded] = useState<string>('');
  const [text] = useDebounce(query, 100);

  const { data, loading, error } = useQuery(MembersSearchDocument, {
    variables: {
      first: 6,
      query: {
        consumer: 'loggedin.web.entitypages.search.react.search-results',
        queries: {
          name: text,
        },
      },
    },
    fetchPolicy: 'no-cache',
    skip: !text && text !== lastCommitedText.current,
  });

  const membersSearchEdges = data?.viewer?.membersSearch?.edges ?? [];
  const membersSearchNodes = membersSearchEdges
    .map((edge) => edge.node)
    .filter((node) => !!node);

  useEffect(() => {
    lastCommitedText.current = text;

    if (membersSearchNodes && lastCommitedText.current) {
      popOver.handleShow();
    }
  }, [data]);

  useEffect(() => {
    if (!text) {
      popOver.handleHide();
    }
  }, [text]);

  const handleOnClose = () => {
    setQuery('');
  };

  const handleAddContact = async (cardData: SearchCard) => {
    const member = membersSearchNodes?.find(
      (node) =>
        node?.__typename === 'MembersSearchUnfencedItem' &&
        node?.xingId?.id === cardData.xingId
    );

    const memberXingId =
      member?.__typename === 'MembersSearchUnfencedItem' ? member.xingId : null;

    if (!memberXingId) {
      return;
    }

    setContactIdAdded(memberXingId.id);

    setContactsTouched(true);
    setDataChanged(true);

    const userList: ContactEdit[] =
      (isFreeProfile
        ? contactUsersList?.map((contact) => ({
            contact: contact.contact,
            deleted: true,
          }))
        : contactUsersList) ?? [];

    const newContact: ContactEdit = {
      contact: {
        id: `temp_${memberXingId.id}_${new Date().getTime()}`,
        enabled: true,
        label: null,
        xingId: memberXingId,
      },
      enabled: true,
      new: true,
    };

    setContactUsersList([newContact, ...userList]);

    onAddContact();

    setContactIdAdded('');
    handleOnClose();
    if (closeOmView?.current) closeOmView.current();
  };

  const handleShowMore = () => {
    popOver.handleHide();
    if (openOmView.current) openOmView.current();
  };

  const showEmptyState =
    text && !loading && !error && membersSearchNodes?.length === 0;
  const searchCards = createSearchCards(membersSearchNodes?.slice(0, 5));

  return (
    <>
      <Styled.NoticeWrapper>
        <BodyCopy size="small" noMargin>
          {$t({
            id: isFreeProfile
              ? 'EP_EDIT_CONTACTS_FREE_INFO'
              : 'EP_EDIT_CONTACTS_PAID_INFO',
          })}
        </BodyCopy>
      </Styled.NoticeWrapper>
      <Styled.InputSectionWrapper>
        <Styled.LabelWrapper>
          <BodyCopy fontWeight="bold" noMargin size="medium">
            {$t({ id: 'EP_ADD_CONTACTS_SEARCH_LABEL' })}
          </BodyCopy>
        </Styled.LabelWrapper>
        <Styled.InputWrapper ref={popOver.triggerRef as any}>
          <Styled.InputBar
            icon={IconSearch}
            placeholder={$t({ id: 'EP_CONTACTS_SEARCH_PLACEHOLDER' })}
            data-cy="SEARCH_CONTACT_PERSON"
            data-testid="SEARCH_CONTACT_PERSON"
            value={query}
            // @ts-expect-error error in value not existing
            onChange={(ev) => setQuery(ev.target.value)}
            onFocus={() => text && popOver.handleShow()}
            disabled={disablePageActions}
          />
        </Styled.InputWrapper>
      </Styled.InputSectionWrapper>

      <Styled.PopOver
        triggerRef={popOver.triggerRef}
        show={popOver.show}
        onOutsideClick={() => popOver.handleHide()}
        // @ts-expect-error FIXME: SC6
        isNotCloseableByTriggerRef={true}
      >
        <div style={{ width: popOver.triggerRef?.current?.clientWidth }}>
          {membersSearchNodes?.length > 0 ? (
            <SearchInputListCards
              cards={searchCards}
              totalCards={membersSearchNodes?.length ?? 0}
              contactIdAdded={contactIdAdded}
              onAddContact={handleAddContact}
              onShowMoreClick={handleShowMore}
            />
          ) : showEmptyState ? (
            <SearchInputListCards.Empty />
          ) : (
            !loading && error && <SearchInputListCards.Error />
          )}
        </div>
      </Styled.PopOver>

      {membersSearchNodes?.length > 5 && (
        <OmView
          onClose={handleOnClose}
          trigger={(activateOmView) => {
            openOmView.current = activateOmView;
            return undefined;
          }}
        >
          {({ handleClose }: { handleClose: () => void }) => {
            closeOmView.current = handleClose;
            return (
              <SearchInputMoreResults
                queryText={text}
                contactIdAdded={contactIdAdded}
                onAddContact={handleAddContact}
              />
            );
          }}
        </OmView>
      )}
    </>
  );
};
