import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';

import { useEditContext } from '../../hooks/use-edit-context/use-edit-context';
import { usePageContext } from '../../hooks/use-page-context/use-page-context';
import { trackEditSaving } from '../../tracking';

import * as Styled from './edit-actions.styes';

type EditActionsProps = {
  disabled?: boolean;
  discardAction: () => void;
  saveAction: () => void;
  isSaving?: boolean;
  noMarginTop?: boolean;
  default?: string;
};
export const EditActions: FC<EditActionsProps> = ({
  disabled,
  discardAction,
  saveAction,
  isSaving,
  noMarginTop = false,
}) => {
  const { pageContext } = usePageContext();
  const { trackingData } = useEditContext();

  return (
    <Styled.ActionsContainer $noMarginTop={noMarginTop}>
      <Styled.DiscardButton
        size="medium"
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        tabindex=""
        data-cy="DISCARD_CONTACT_BUTTON"
        disabled={isSaving || disabled}
        onClick={discardAction}
      >
        <FormattedMessage
          id="EP_EDIT_ACTION_DISCARD"
          defaultMessage="EP_EDIT_ACTION_DISCARD"
        />
      </Styled.DiscardButton>
      <Button
        variant="primary"
        size="medium"
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; variant: "primary"; siz... Remove this comment to see the full error message
        tabindex=""
        disabled={disabled}
        onClick={() => {
          if (pageContext.focusType && trackingData?.module) {
            trackEditSaving({
              focusType: pageContext.focusType,
              itemId: pageContext.pageId,
              module: trackingData.module,
              part: trackingData.part,
            });
          }
          saveAction();
        }}
        data-cy="PUBLISH_CONTACT_BUTTON"
        loading={isSaving}
      >
        <FormattedMessage
          id="EP_EDIT_ACTION_PUBLISH"
          defaultMessage="EP_EDIT_ACTION_PUBLISH"
        />
      </Button>
    </Styled.ActionsContainer>
  );
};
