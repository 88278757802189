import { useQuery } from '@apollo/client';
import { Redirect, useNavigate } from '@reach/router';
import type { Dispatch, FC, SetStateAction } from 'react';

import { ClickReasonsEnum } from '@xing-com/crate-common-graphql-types';
import type { BackLinkProps } from '@xing-com/crate-entity-pages-common';
import {
  PageError,
  EntityPageDocument,
  PageContextProvider,
  EditContextProvider,
  DialogContextProvider,
  createEntityPageShared,
  PageLayout,
  PAGES_PATH,
} from '@xing-com/crate-entity-pages-common';
import { useFeatureSwitch } from '@xing-com/hub';

import { EDIT_ITEMS_MAP } from '../../config/edit-items-map';

import { EditPageLayout } from './edit-page-layout';

interface EditPageProps {
  pageSlug: string;
  item: string;
  isWebview?: boolean;
  children?:
    | React.ReactNode
    | ((props: {
        setBackLink: Dispatch<SetStateAction<BackLinkProps | null>>;
        backLinkDefault: BackLinkProps;
      }) => React.ReactNode);
}
export const EditPage: FC<EditPageProps> = ({
  pageSlug,
  item,
  isWebview,
  children,
}) => {
  const navigate = useNavigate();
  const canEditMobile = useFeatureSwitch('ep_mobileEdit', false);
  const editItemProps = EDIT_ITEMS_MAP?.[item] ?? null;

  const { data, loading, error } = useQuery(EntityPageDocument, {
    variables: {
      id: pageSlug,
      socialProofClickReasonsKey:
        ClickReasonsEnum.CrWebPublisherSocialProofHeader,
    },
    errorPolicy: 'all',
  });

  if (loading) {
    return null;
  }

  const entityPage = data?.entityPageEX;

  if (error || !entityPage) {
    return <PageError type={503} />;
  }

  if (entityPage.__typename === 'EntityPageError') {
    return <PageError type={entityPage.errorCode === 10404 ? 404 : 503} />;
  }

  if (entityPage.__typename === 'EntityPageMoved') {
    return <Redirect to={PAGES_PATH(entityPage.slug)} />;
  }

  if (
    entityPage.__typename === 'EntityPage' &&
    entityPage.publicationStatus === 'CREATED'
  ) {
    return <Redirect to="/pages/new/company" />;
  }

  if (entityPage.__typename !== 'EntityPage') {
    return <PageError type={503} />;
  }

  if (!editItemProps) {
    return (
      <div>
        <PageError type={404} />
      </div>
    );
  }

  if (
    typeof window !== 'undefined' &&
    window.innerWidth < 1113 &&
    !canEditMobile
  ) {
    return <Redirect to={PAGES_PATH(entityPage.slug)} />;
  }

  const sharedEntityPageProperties = createEntityPageShared(entityPage);

  if (!sharedEntityPageProperties.isEditor) {
    return <Redirect to={PAGES_PATH(entityPage.slug)} />;
  }

  return (
    <DialogContextProvider>
      <PageContextProvider
        initialValue={{
          ...sharedEntityPageProperties,
          // Extra
          pageModulesByType: [],
          pageModules: [],
          goBackUrl: entityPage.globalId
            ? () => navigate(-1)
            : () => navigate(PAGES_PATH(entityPage.slug)),
        }}
      >
        <EditContextProvider>
          <PageLayout isZenView={true}>
            <EditPageLayout
              editItemProps={EDIT_ITEMS_MAP[item]}
              isWebview={isWebview}
            >
              {children}
            </EditPageLayout>
          </PageLayout>
        </EditContextProvider>
      </PageContextProvider>
    </DialogContextProvider>
  );
};
