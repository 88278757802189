import styled from 'styled-components';

import { Button as DefaultButton } from '@xing-com/button';
import {
  scale060,
  scale070,
  scale080,
  spaceL,
  spaceS,
  xdlColorInfoSoft,
  xdlColorTextSecondary,
} from '@xing-com/tokens';

import { dotSeparator } from '../../../utils/shared-styles';

export const ArticleCardTopWrapper = styled.div`
  display: flex;
  gap: ${spaceL};
  font-size: ${scale060};
  line-height: ${scale080};
  color: ${xdlColorTextSecondary};
`;

export const InfoContainer = styled.div`
  flex: 1 1 auto;
  font-size: ${scale070};

  ${dotSeparator}
`;

export const ButtonsContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: ${spaceS};
  justify-content: flex-end;
`;

export const Button = styled(DefaultButton)`
  &:after {
    background-color: ${xdlColorInfoSoft};
  }
`;
