import { useQuery } from '@apollo/client';
import type * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { useViewerData } from '@xing-com/hub';
import LazyLoading from '@xing-com/lazy-loading';
import { ViewWrapperDefaultGrid } from '@xing-com/platform-layout-view-wrapper';

import { SocialReactionsDocument } from '../../graphql';
import { useTrackOnRender } from '../../hooks';
import type { SocialCommentsData } from '../../types';
import {
  extractTotalEdgesAndCursor,
  flattenUser,
  mergeLikersListResponses,
  useScrollToTop,
} from '../../utils';
import { LikersListItems } from '../likers-list-items/likers-list-items';

import { Styled } from './likers-list.styles';

export type LikersListProps = {
  first?: number;
  propChannel: string;
  urn: string;
};

export const LikersList: React.FC<LikersListProps> = ({
  first = 20,
  propChannel,
  urn,
}) => {
  const userPageName = useViewerData()?.data?.user?.pageName || '';

  useScrollToTop();
  useTrackOnRender(propChannel, 'social_likers_list_show', urn);

  const { data, loading, fetchMore, error } = useQuery<SocialCommentsData>(
    SocialReactionsDocument,
    {
      variables: { urn, first, after: undefined },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    }
  );

  const { hasNextPage, edges, cursor } = extractTotalEdgesAndCursor(data);

  return (
    <ViewWrapperDefaultGrid>
      <div data-qa="likers-list">
        <Styled.Headline size="xxlarge">
          <FormattedMessage id="SOCIAL_LIKERS_LIST_HEADLINE" />
        </Styled.Headline>
        <Styled.BodyCopy>
          <FormattedMessage id="SOCIAL_LIKERS_LIST_POST_NOTIFICATION" />
        </Styled.BodyCopy>
        <LikersListItems
          {...{ propChannel, hasError: !!error }}
          userPageName={userPageName}
          likers={flattenUser(edges)}
        />
        <LazyLoading
          {...{ loading }}
          onLazyLoad={(unobserve: () => void) => {
            hasNextPage && !error
              ? fetchMore({
                  query: SocialReactionsDocument,
                  updateQuery: (previousResult, { fetchMoreResult }) =>
                    mergeLikersListResponses(
                      previousResult,
                      fetchMoreResult as SocialCommentsData
                    ),
                  variables: { after: cursor, first, urn },
                })
              : unobserve();
          }}
        />
      </div>
    </ViewWrapperDefaultGrid>
  );
};
