import React from 'react';

import { useIsSmallScreen } from '@xing-com/crate-social-utils';
import { Divider } from '@xing-com/divider';

import { SubmitButton, CancelButton } from '..';
import type { AudiencePropsType } from '../../types';

import { Styled } from './share-action-bar.styles';

export type ShareActionBarType = {
  audienceProps?: AudiencePropsType;
  disabled: boolean;
  loading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

export const ShareActionBar: React.FC<ShareActionBarType> = ({
  audienceProps,
  disabled,
  loading,
  onCancel,
  onSubmit,
}) => {
  const isSmallScreen = useIsSmallScreen();

  React.useEffect(() => {
    // Remove the X button from the OmView
    const OmViewCrossIcon = document
      .querySelector('[data-xds="IconCross"]')
      ?.closest('button');

    if (isSmallScreen && OmViewCrossIcon)
      OmViewCrossIcon.style.display = 'none';

    if (!isSmallScreen && OmViewCrossIcon)
      OmViewCrossIcon.style.display = 'initial';

    return () => {
      if (OmViewCrossIcon) OmViewCrossIcon.style.display = 'initial';
    };
  }, [isSmallScreen]);

  return isSmallScreen ? (
    <Styled.StickyHeader>
      <Styled.SmallScreenButtonWrapper>
        <CancelButton isSmallScreen onClick={onCancel} />
        <Styled.RightActionButtons>
          {audienceProps && <Styled.AudienceSelection {...audienceProps} />}
          <SubmitButton {...{ disabled, loading }} onClick={onSubmit} />
        </Styled.RightActionButtons>
      </Styled.SmallScreenButtonWrapper>
      <Divider />
    </Styled.StickyHeader>
  ) : (
    <Styled.BigScreenButtonWrapper>
      {audienceProps ? (
        <Styled.AudienceSelection {...audienceProps} />
      ) : (
        <CancelButton onClick={onCancel} />
      )}
      <SubmitButton {...{ disabled, loading }} onClick={onSubmit} />
    </Styled.BigScreenButtonWrapper>
  );
};
