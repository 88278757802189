import type { ComponentType, FC } from 'react';

import type { BREAKPOINTS } from './media-listener';
import { useMediaListener } from './media-listener';

/**
 * @deprecated Use the hook version `useMediaListener` instead.
 */
export const withMediaListener = <Props,>(
  WrappedComponent: ComponentType<
    Props & { matchedMedia: keyof typeof BREAKPOINTS }
  >
): ComponentType<Props> => {
  const Component: FC<Props> = (props) => {
    const matchedMedia = useMediaListener();

    return <WrappedComponent matchedMedia={matchedMedia} {...props} />;
  };
  Component.displayName = `withMediaListener(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return Component;
};
