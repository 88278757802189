/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AppcuesGetCompanyInfoQueryVariables = Types.Exact<{
  id: Types.Scalars['SlugOrID']['input'];
}>;

export type AppcuesGetCompanyInfoQuery = {
  __typename?: 'Query';
  company?: {
    __typename?: 'Company';
    id: string;
    contractPackageName?: Types.CompanyContractPackage | null;
    createdAt?: string | null;
    hasXamAccount?: boolean | null;
    companySize?: string | null;
    companySizeId?: string | null;
    companyName?: string | null;
    userContext?: {
      __typename?: 'CompanyUserContext';
      userRole?: Types.CompanyUserRole | null;
    } | null;
    industry?: {
      __typename?: 'Industry';
      id: string;
      localizationValue: string;
    } | null;
  } | null;
};

export const AppcuesGetCompanyInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppcuesGetCompanyInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractPackageName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userContext' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userRole' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasXamAccount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'companySize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companySizeId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizationValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AppcuesGetCompanyInfoQuery,
  AppcuesGetCompanyInfoQueryVariables
>;
