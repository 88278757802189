import { dataURLtoBlob } from '@xing-com/crate-x4e-file-upload';

import type { CroppedImage } from '../../types/upload-files';

type GetBase64Image = {
  url?: any;
  outputFormat?: string;
};

export type CropType = {
  width: number;
  height: number;
  x: number;
  y: number;
};

export const getBase64Image = ({
  url,
  outputFormat,
}: GetBase64Image): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // To avoid CORS issues

    img.onload = () => {
      // Create a canvas element
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return reject(new Error('Failed to get canvas context'));
      }
      ctx.drawImage(img, 0, 0);

      // Convert the canvas content to base64
      const dataURL = canvas.toDataURL(outputFormat);
      resolve(dataURL);
    };

    img.onerror = (err) => {
      reject(err);
    };

    // Start loading the image
    img.src = url;
  });
};

const createImage = async (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image(); // eslint-disable-line
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export const getCroppedImg = async (
  imageSrc: string,
  crop: CropType | null
) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const maxSafeSize = 4096;

  if (crop) {
    const maxSize = Math.max(crop.width, crop.height);
    const ratioSafeSize = maxSize > maxSafeSize ? maxSafeSize / maxSize : 1;
    canvas.width = crop.width * ratioSafeSize;
    canvas.height = crop.height * ratioSafeSize;
    const ctx = canvas?.getContext('2d');

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * ratioSafeSize,
      crop.height * ratioSafeSize
    );
  }

  // As Base64 string
  // const base64Image = canvas.toDataURL("image/jpeg");
  // return base64Image;

  // As a blob
  return new Promise<CroppedImage>((resolve) => {
    const dataUrl = canvas.toDataURL('image/jpeg');
    const fileBlob = dataURLtoBlob(dataUrl);

    const fileName = (fileBlob as any).name;

    const file = new File([fileBlob], fileName, {
      type: fileBlob.type,
    });

    resolve({ file, croppedImageUrl: dataUrl });
  });
};

export const getImage = async (imageSrc: string) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas?.getContext('2d');
  ctx?.drawImage(image, 0, 0);

  return new Promise<CroppedImage>((resolve) => {
    const dataUrl = canvas.toDataURL('image/jpeg');
    const fileBlob = dataURLtoBlob(dataUrl);

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    const fileName = (fileBlob as any).name;

    const file = new File([fileBlob], fileName, {
      type: fileBlob.type,
    });

    resolve({ file, croppedImageUrl: dataUrl });
  });
};

export default getCroppedImg;
