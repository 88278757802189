import { useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import { EntityMediaType } from '@xing-com/crate-common-graphql-types';
import {
  ImageUpload,
  useDialogContext,
  useErrorContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { FormField } from '@xing-com/text-field';

import { EntityPageCreateMediaDocument } from '../../../../graphql/mutation/entity-page-create-media.gql-types';
import { AboutUsDocument } from '../../../../graphql/queries/about-us-query.gql-types';
import { EditMediaDocument } from '../../../../graphql/queries/edit-media-query.gql-types';

import * as Styled from './image-upload.styles';

type GalleryImageUploadProps = {
  imageDescription?: string;
  onDiscard?: () => void;
  onSave?: () => void;
};

export const GalleryImageUpload: FC<GalleryImageUploadProps> = ({
  imageDescription,
  onDiscard = () => undefined,
  onSave = () => undefined,
}) => {
  const { $t } = useIntl();
  const { showError } = useErrorContext();
  const { setDataChanged, dataChanged, setDialogConfirmation } =
    useDialogContext();
  const { pageContext } = usePageContext();
  const { pageSlug } = pageContext ?? {};

  const descriptionValue = useRef(imageDescription);
  const keyPressed = useRef(null);
  const newImage = useRef<string | null>(null);
  const [description, setDescription] = useState(imageDescription || '');

  const [createMedia, { loading }] = useMutation(
    EntityPageCreateMediaDocument,
    {
      update: (cache, { data: createMediaData }) => {
        const newItem = {
          __typename: 'EntityMediaEdge',
          node: {
            __typename: 'EntityMedia',
            // @ts-expect-error TODO: fix this type
            id: createMediaData.entityPageCreateMedia?.success?.id,
            description:
              // @ts-expect-error TODO: fix this type
              createMediaData.entityPageCreateMedia?.success?.description,
            media: {
              __typename: 'ScaledImage',
              url: newImage.current,
            },
          },
        };

        const queryMediaData: any = cache.readQuery({
          query: EditMediaDocument,
          variables: {
            id: pageSlug,
            first: 100,
          },
        });

        cache.writeQuery({
          query: EditMediaDocument,
          variables: {
            id: pageSlug,
            first: 100,
          },
          data: {
            // @ts-expect-error TODO: fix this type
            entityPage: {
              ...queryMediaData.entityPage,
            },
            pagesAboutUs: {
              __typename: 'AboutEntity',
              media: {
                __typename: 'EntityMediaConnection',
                edges: [newItem, ...queryMediaData.pagesAboutUs.media.edges],
              },
            },
          },
        });

        try {
          const aboutUsQueryData: any = cache.readQuery({
            query: AboutUsDocument,
            variables: {
              id: pageSlug,
              companyId: pageContext.companyId,
              first: 4,
            },
          });

          cache.writeQuery({
            query: AboutUsDocument,
            variables: {
              id: pageSlug,
              companyId: pageContext.companyId,
              first: 4,
            },
            data: {
              pagesAboutUs: {
                ...aboutUsQueryData.pagesAboutUs,
                __typename: 'AboutEntity',
                media: {
                  __typename: 'EntityMediaConnection',
                  edges: [
                    newItem,
                    ...aboutUsQueryData.pagesAboutUs.media.edges.slice(0, 3),
                  ],
                },
              },
            },
          });
        } catch (e) {
          // Caused when there is no cache entry
        }
      },
    }
  );

  const handleDescriptionChange = (newDescription: string) => {
    if (newDescription.length <= 200 && keyPressed.current !== 13) {
      setDescription(newDescription);
      descriptionValue.current = newDescription;
    }
  };

  const onPublish = async (imageUploadId: string, image: File) => {
    const loadedImage = await image.text();
    newImage.current = loadedImage;

    createMedia({
      variables: {
        pageId: pageSlug,
        description: descriptionValue.current,
        mediaType: EntityMediaType.Image,
        uploadId: imageUploadId,
      },
      onCompleted: (data) => {
        const error = data?.entityPageCreateMedia?.error;

        if (error) {
          showError({
            message: 'EP_GENERAL_FEEDBACK_ERROR',
            error,
          });
        } else {
          onSave();
          setDataChanged(false);
        }
      },
      onError: (error) => {
        showError({
          message: 'EP_GENERAL_FEEDBACK_ERROR',
          error,
        });
      },
    });
  };

  const handlerOnDiscard = () => {
    if (dataChanged) {
      setDialogConfirmation({
        dialogAction: () => {
          setDialogConfirmation(null);
          setDataChanged(false);
          onDiscard();
        },
      });
    } else {
      onDiscard();
    }
  };

  return (
    <>
      <ImageUpload
        onDiscard={handlerOnDiscard}
        onFileSelected={() => setDataChanged(true)}
        showUploading={loading}
        shouldUploadOriginal={true}
        onPublish={({ cropped }) => onPublish(cropped.id, cropped.file)}
        onCancel={() => undefined}
        aspectRatio={16 / 9}
        noHeadline
        imageUploadCopy={{
          hint: $t({ id: 'EP_UPLOAD_IMAGE_HINT' }),
          label: $t({ id: 'EP_UPLOAD_IMAGE_LABEL' }),
          onDragLabel: $t({ id: 'EP_UPLOAD_IMAGE_DRAG_LABEL' }),
        }}
      >
        <Styled.DescriptionWrapper>
          <FormField
            onKeyDown={(e: any) => (keyPressed.current = e.keyCode)}
            // @ts-expect-error FIXME: SC6
            medium
            multiline
            value={description}
            onChange={(e: any) => handleDescriptionChange(e.target.value)}
            label={$t({
              id: 'EP_GM_IMAGE_CAPTION_LABEL',
              defaultMessage: 'EP_GM_IMAGE_CAPTION_LABEL',
            })}
            helperText={
              description && description.length > 0
                ? $t(
                    {
                      id: 'EP_INPUT_CHARS_LEFT',
                      defaultMessage: 'EP_INPUT_CHARS_LEFT',
                    },
                    { charactersNumber: 200 - description.length }
                  )
                : $t({
                    id: 'EP_GM_IMAGE_CAPTION_HINT',
                    defaultMessage: 'EP_GM_IMAGE_CAPTION_HINT',
                  })
            }
          />
        </Styled.DescriptionWrapper>
      </ImageUpload>
      <Styled.ExternalEditInfoBanner
        copyKey="EP_GM_IMAGE_INFO_BANNER"
        ctaUrl={`https://help.onlyfy.com/hc/de/articles/8803872010001-Erstbef%C3%BCllung-des-Employer-Branding-Profils-auf-XING#titel--/-headerbild`}
        ctaCopyKey="EP_GM_IMAGE_INFO_BANNER_CTA"
      />
    </>
  );
};
