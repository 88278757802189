import { useState, type FC } from 'react';

import { EntityPagePublicationStatus } from '@xing-com/crate-common-graphql-types';
import {
  usePageContext,
  CompanyBannersContainer,
  CustomerFeedBackButton,
  PageLayout,
  EditBar,
} from '@xing-com/crate-entity-pages-common';
import { Header } from '@xing-com/crate-entity-pages-header';
import { useFeatureSwitch } from '@xing-com/hub';

import { InsiderEditProfileBanner } from '../../components/banners/insider-edit-profile-banner/insider-edit-profile-banner';
import { MetaDataContainer } from '../../components/main-page-metadata/main-page-metadata';
import { ModulesRenderer } from '../../components/modules-renderer/modules-renderer';
import { Navigation } from '../../components/navigation/navigation';
import { TopBar } from '../../components/top-bar/top-bar';
import { useNavigationOffset } from '../../hooks/useNavigationOffset/useNavigationOffset';

import * as Styled from './main-page.styles';

export const MainPageLayout: FC = () => {
  const { pageContext } = usePageContext();
  const [
    mainPageRef,
    navigationRef,
    navigationOffset,
    frameSize,
    navigationBarHeight,
  ] = useNavigationOffset();

  const [activeModuleIndex, setActiveModuleIndex] = useState<
    number | undefined
  >(undefined);

  const canEditMobile = useFeatureSwitch('ep_mobileEdit', false);

  const isCompanyDraft =
    pageContext.publicationStatus === EntityPagePublicationStatus.Draft &&
    pageContext.isCompanyPage;

  return (
    <PageLayout>
      <TopBar />
      <Styled.MainPageWrapper ref={mainPageRef}>
        <MetaDataContainer
          title={pageContext.pageTitle}
          url={pageContext.url}
          image={pageContext.logo}
          focusType={pageContext.focusType}
        />
        {pageContext.isEditor && pageContext.isCompanyPage ? (
          <CompanyBannersContainer
            companyId={pageContext.companyId}
            isDraft={isCompanyDraft}
          />
        ) : null}

        {pageContext.isEditor ? <CustomerFeedBackButton module="main" /> : null}

        {pageContext.isEditor ? (
          <EditBar
            displayNewsButtons={pageContext.isCompanyPage}
            displayBackButton={false}
            displayManageUpdates={pageContext.hasNewsModule}
            canEditOnMobile={canEditMobile}
          />
        ) : null}

        <Styled.HeaderWrapper isShowingCompanyBanner={false}>
          <Header />
        </Styled.HeaderWrapper>

        {pageContext.isEditor && pageContext.isInsiderPage ? (
          <InsiderEditProfileBanner />
        ) : null}

        <Navigation
          overwriteIndex={activeModuleIndex}
          navigationOffset={navigationOffset}
          frameSize={frameSize}
          navigationRef={navigationRef}
          navigationBarHeight={navigationBarHeight}
        />

        <ModulesRenderer
          navigationOffset={navigationOffset}
          onEnterModule={(type) =>
            setActiveModuleIndex(pageContext.pageModulesByType.indexOf(type))
          }
        />
      </Styled.MainPageWrapper>
    </PageLayout>
  );
};
