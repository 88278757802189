import { lazy, Suspense } from 'react';

import type { ShareToMessengerType } from '@xing-com/crate-social-share-to-messenger';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { SkeletonBody } from '@xing-com/skeleton';

const LazyShareToMessengerComponent = lazy(() => {
  return import('@xing-com/crate-social-share-to-messenger').then((module) => ({
    default: module.ShareToMessenger,
  }));
});

export const LazyShareToMessenger: React.FC<ShareToMessengerType> = (props) => {
  const fallback = <SkeletonBody amountRows={4} />;

  return (
    <NoSSR fallback={fallback}>
      <Suspense fallback={fallback}>
        <LazyShareToMessengerComponent {...props} />
      </Suspense>
    </NoSSR>
  );
};
