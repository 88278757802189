import type { Dispatch, FC, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';

import { xdlColorSuccess } from '@xing-com/tokens';

import { BANNER_TIMEOUT } from '../../constants';

import { Styled } from './commbox-success-banner.styles';

export type CommboxSuccessBannerType = {
  newlyCreatedPostingUrl?: string;
  setShowSuccessMessage: Dispatch<SetStateAction<boolean>>;
  showSuccessMessage: boolean;
  timeout?: number;
  description?: string;
  descriptionValues?: Record<string, unknown>;
  headline?: string;
  headlineValues?: Record<string, unknown>;
};

export const CommboxSuccessBanner: FC<CommboxSuccessBannerType> = ({
  description = 'COMMBOX_SUCCESS_DESC',
  descriptionValues = {},
  headline = 'COMMBOX_SUCCESS_HEADLINE',
  headlineValues = {},
  newlyCreatedPostingUrl = '',
  setShowSuccessMessage,
  showSuccessMessage,
  timeout = BANNER_TIMEOUT,
}) => (
  <Styled.SuccessBannerAutoHide
    {...{ timeout }}
    handleOnClose={() => setShowSuccessMessage(false)}
    show={showSuccessMessage}
  >
    <Styled.MessageWrapper data-testid="commboxSuccessMessage">
      <div>
        <Styled.TickIcon height={24} width={24} color={xdlColorSuccess} />
        <Styled.Message noMargin>
          <Styled.Headline>
            <FormattedMessage
              id={headline}
              // @ts-expect-error - this package will be removed on Q2
              values={headlineValues}
            />
          </Styled.Headline>
          <FormattedMessage
            id={description}
            // @ts-expect-error - this package will be removed on Q2
            values={descriptionValues}
          />
        </Styled.Message>
      </div>
      {newlyCreatedPostingUrl && (
        <Styled.ViewPostButton
          to={newlyCreatedPostingUrl}
          size="small"
          variant="secondary"
        >
          <FormattedMessage id="COMMBOX_SUCCESS_ACTION" />
        </Styled.ViewPostButton>
      )}
    </Styled.MessageWrapper>
  </Styled.SuccessBannerAutoHide>
);
