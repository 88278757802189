import type { FC } from 'react';
import { useIntl } from 'react-intl';

import {
  formatAmount,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';

import { EntityInfo } from './entity-info';

export const EntityInfoPublisherPage: FC = () => {
  const { pageContext } = usePageContext();
  const intl = useIntl();

  const headerContent = pageContext.headerModule?.content;

  if (headerContent?.__typename !== 'EntityPagePublisherHeaderContent') {
    return null;
  }

  const entityInfoData = [];
  const followersTotal = headerContent.followers?.total;

  if (followersTotal && followersTotal > 0) {
    entityInfoData.push({
      value: formatAmount({ value: followersTotal, intl }),
      label: ` ${intl.formatMessage({
        id: followersTotal > 1 ? 'EP_FOLLOWERS.other' : 'EP_FOLLOWERS.one',
      })}`,
      testId: 'HEADER_FOLLOWERS_TOTAL',
    });
  }

  return <EntityInfo data={entityInfoData} />;
};
