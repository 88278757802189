import { useQuery } from '@apollo/client';

import { AboutDocument } from '../graphql/about-query.gql-types';

type UseAboutQuery = {
  loading: boolean;
  error: boolean;
  description: string;
  refetch: () => void;
};

export const useAboutQuery = (pageSlug: string): UseAboutQuery => {
  const {
    data,
    loading,
    error: queryError,
    refetch: refetchAbout,
  } = useQuery(AboutDocument, {
    variables: { id: pageSlug },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const refetch = (): void => {
    refetchAbout({ id: pageSlug });
  };

  const description =
    data?.pagesAboutUs?.__typename === 'AboutEntity' &&
    data.pagesAboutUs.summary
      ? data.pagesAboutUs.summary
      : '';

  const error =
    !!queryError || data?.pagesAboutUs?.__typename === 'EntityPageError';

  return { loading, error, description, refetch };
};
