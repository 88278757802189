import styled, { css } from 'styled-components';

import {
  spaceS,
  xdlColorBeta,
  xdlColorCaution,
  xdlColorTextInvert,
} from '@xing-com/tokens';

import type { SocialUtilsSize, SocialUtilsVariant } from '../..';

type SocialUtilsTransientProps = {
  $size?: SocialUtilsSize;
  $variant?: SocialUtilsVariant;
};

// *** Base ***
const baseSocialUtils = css`
  background: ${xdlColorBeta};
  color: ${xdlColorTextInvert};
  padding: ${spaceS};
`;

// *** Sizes ***
export const sizes = {
  small: css`
    font-size: 12px;
  `,
  medium: css`
    font-size: 18px;
  `,
};

// *** Variants ***
export const variants = {
  test: css`
    background: ${xdlColorCaution};
  `,
};

// *** Components ***
const SocialUtils = styled.span<SocialUtilsTransientProps>`
  ${baseSocialUtils}
  ${({ $size }) => $size && sizes[$size]}
  ${({ $variant }) => $variant && variants[$variant]}
`;

export const Styled = {
  SocialUtils,
};
