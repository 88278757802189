import type { RouteComponentProps } from '@reach/router';
import { Router as ReachRouter, useParams } from '@reach/router';
import { useMemo, type FC } from 'react';

import { PageError } from '@xing-com/crate-entity-pages-common';

import { RedirectToDemo } from '../components/redirect-to-demo/redirect-to-demo';
import { EditPageTransition } from '../pages/edit/edit-page-transition';
import { MainPageContainer } from '../pages/main-page/main-page';

type RouteProps = RouteComponentProps & {
  render: (
    props: RouteComponentProps & { params: URLSearchParams }
  ) => JSX.Element;
};
const Route: FC<RouteProps> = ({ render, ...rest }) => {
  const { search } = useParams();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  return render({ ...rest, params });
};

export const Router: FC = () => (
  <ReachRouter>
    <Route
      // This is a workarround because some assets path are conflicting with this route
      path="/:slug"
      render={({ slug }: RouteComponentProps<{ slug: string }>) => (
        <MainPageContainer pageSlug={slug ?? ''} />
      )}
    />
    <Route
      path="/:slug/demo"
      render={({ slug }: RouteComponentProps<{ slug: string }>) => (
        <RedirectToDemo slug={slug ?? ''} />
      )}
    />
    <Route
      path="/:slug/edit/:item"
      render={({
        slug,
        item,
      }: RouteComponentProps<{ slug: string; item: string }>) => (
        <EditPageTransition pageSlug={slug ?? ''} item={item ?? ''} />
      )}
    />

    <Route
      path="/webview/:slug/edit/:item"
      render={({
        slug,
        item,
      }: RouteComponentProps<{ slug: string; item: string }>) => (
        <EditPageTransition
          isWebview={true}
          pageSlug={slug ?? ''}
          item={item ?? ''}
        />
      )}
    />

    <Route
      // This is the Not Found Page for Subpages
      path="/:slug/:pageType"
      render={() => <PageError type={404} />}
    />
  </ReachRouter>
);
