import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

import { useDialogContext } from '../../hooks/use-dialog-context/use-dialog-context';

import * as Styled from './not-saved-dialog.styles';

export const NotSavedDialog: FC = () => {
  const { $t } = useIntl();
  const { dialogPreAction, dialogConfirmation, setDialogConfirmation } =
    useDialogContext();

  const confirmHandler = () => {
    if (dialogPreAction?.current) dialogPreAction.current();
    if (dialogConfirmation?.dialogAction) dialogConfirmation.dialogAction();
  };
  const [show, setShow] = React.useState(true);

  return (
    <Styled.Dialog
      show={show}
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      testId={'CONFIRM_DISCARD_POPUP'}
      isDestructive={true}
      headline={$t({
        id: 'EP_DISCARD_DIALOG_HEADLINE',
        defaultMessage: 'EP_DISCARD_DIALOG_HEADLINE',
      })}
      text={$t({
        id: 'EP_DISCARD_DIALOG_TEXT',
        defaultMessage: 'EP_DISCARD_DIALOG_TEXT',
      })}
      cancelLabel={$t({
        id: 'EP_DISCARD_DIALOG_CANCEL',
        defaultMessage: 'EP_DISCARD_DIALOG_CANCEL',
      })}
      confirmLabel={$t({
        id: 'EP_DISCARD_DIALOG_CONFIRM',
        defaultMessage: 'EP_DISCARD_DIALOG_CONFIRM',
      })}
      onCancel={() => {
        setDialogConfirmation(null);
        setShow(false);
      }}
      onConfirm={() => confirmHandler()}
    />
  );
};
