/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconFile"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m18 4 2 2v16H4V2h12zm0 2.828L15.172 4H6v16h12z"
    />
  </svg>
);
export default IconFile;
