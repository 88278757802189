import styled, { css } from 'styled-components';

import {
  cornerRadiusXL,
  scale370,
  scale450,
  spaceS,
  spaceXL,
  spaceL,
  spaceXXL,
  xdlColorBackgroundTertiary,
  xdlColorText,
  xdlColorTextSecondary,
} from '@xing-com/tokens';

import type { EmptyStateSize, EmptyStateVariant } from '.';

type EmptyStateTProps = {
  $size?: EmptyStateSize;
  $withBackground?: boolean;
};

type TextContentTProps = {
  $size?: EmptyStateSize;
  $variant?: EmptyStateVariant;
};

// *** Sizes ***
export const sizes = {
  medium: {
    container: css`
      padding-block-start: ${spaceXL};
      padding-block-end: ${spaceXL};
      padding-inline-start: ${spaceL};
      padding-inline-end: ${spaceL};
    `,
    textContent: css`
      max-width: ${scale370};
    `,
  },
  large: {
    container: css`
      padding-block-start: ${spaceXXL};
      padding-block-end: ${spaceXXL};
      padding-inline-start: ${spaceXL};
      padding-inline-end: ${spaceXL};
    `,
    textContent: css`
      max-width: ${scale450};
    `,
  },
};

// *** Variants ***
export const variants = {
  default: css`
    & > * {
      color: ${xdlColorTextSecondary};
    }
  `,
  engage: css`
    & > * {
      color: ${xdlColorText};
    }
  `,
};

// *** Components ***
export const EmptyState = styled.div<EmptyStateTProps>`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${spaceXL};

  ${(props) => css`
    ${props.$size && sizes[props.$size].container};
    ${props.$withBackground &&
    css`
      background-color: ${xdlColorBackgroundTertiary};
      border-radius: ${cornerRadiusXL};
    `};
  `};
`;

export const TextContent = styled.div<TextContentTProps>`
  display: flex;
  flex-direction: column;
  gap: ${spaceS};
  text-align: center;
  text-wrap: balance;

  ${(props) => css`
    ${props.$size && sizes[props.$size].textContent};
    ${props.$variant && variants[props.$variant]}
  `};
`;

export const ButtonContainer = styled.div``;
export const CustomContent = styled.div``;
export const IllustrationContainer = styled.div``;
